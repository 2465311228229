import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { PricingSurcharge, PricingSurchargeVaryCondition } from '@idearoom/types';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataBranchMetadata } from '../types/ClientData';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';

import { ClientDataType } from '../constants/ClientDataType';
import { config } from '../config/config';
import { publishMessagePrefix, sitesText } from '../constants/VendorData';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { MergeStatus } from '../constants/ClientData';
import { setSiteDetailPublishMergeResult } from '../ducks/vendorDataSlice';
import { getConfiguratorPreviewUrl, getSetBranch } from './clientDataUtils';
import { mapClientIdToConfiguratorAndVendor } from './clientIdUtils';
import { getConfiguratorUrlWithLocale } from './vendorUtils';
import { useDeleteBranchMutation, usePublishClientDataMutation } from '../services/clientDataApi';
import { openNotificationDialog } from '../ducks/notificationDialog';
import { AppRoutes } from '../constants/AppRoutes';
import { PricingTab } from '../constants/Pricing';
import { getValidationErrorMessage, validateSurcharge } from './pricingValidation';
import { Vendor } from '../types/VendorData';
import { defaultPricingSurcharge } from '../constants/PricingSurcharge';
import { SalesViewUILayout } from '../constants/Viewer';

export const onRevert = (
  {
    clientId,
    groupId,
    clientDataType,
    pricingTab,
    path,
    activeBranches,
    branch,
    layout = SalesViewUILayout.MapNeeded,
  }: {
    clientId: string;
    groupId: string;
    clientDataType: ClientDataType;
    pricingTab: PricingTab | undefined;
    path: string;
    activeBranches: ClientDataBranchMetadata[];
    branch: ClientDataBranch;
    layout: SalesViewUILayout | undefined;
  },
  deleteBranch: ReturnType<typeof useDeleteBranchMutation>[0],
  dispatch: Dispatch<any>,
  t: TFunction,
) => {
  if (path.startsWith(AppRoutes.Sites) && layout === SalesViewUILayout.Horizon) {
    dispatch(openDialog({ dialog: Dialogs.SitesRevertBranch }));
    return;
  }

  let title = t(sitesText.revertConfirmationTitle);
  let message = t(sitesText.revertConfirmationBody);
  let dialogId = 'sites-revert';
  if (path.startsWith(AppRoutes.Pricing)) {
    switch (pricingTab) {
      case PricingTab.Base:
        dialogId = 'pricing-base-revert-';
        break;
      case PricingTab.Component:
        dialogId = 'pricing-component-revert-';
        break;
      case PricingTab.Surcharge:
        dialogId = 'pricing-surcharge-revert-';
        break;
      case PricingTab.SizeBased:
        dialogId = 'pricing-size-based-revert-';
        break;
      default:
        break;
    }
    title =
      branch === ClientDataBranch.ClientUpdate
        ? I18nKeys.PricingComponentRevertConfirmationTitle
        : I18nKeys.PricingBaseRevertConfirmationTitle;
    message =
      branch === ClientDataBranch.ClientUpdate
        ? I18nKeys.PricingComponentRevertConfirmationMessage
        : I18nKeys.PricingBaseRevertConfirmationMessage;
  }
  const branchExists = !!activeBranches.find((b) => b.branchType === branch);
  const setBranch = getSetBranch(branch);

  dispatch(
    openConfirmationDialog(
      title,
      message,
      undefined,
      [
        {
          onClick: () => {
            if (branchExists) {
              dispatch(setBranch(ClientDataBranch.Main));
              deleteBranch({
                dataType: clientDataType,
                branch,
                clientId,
                groupId,
              })
                .unwrap()
                .catch(() => {
                  dispatch(setBranch(branch));
                });
            }
          },
          label: t(I18nKeys.RevertButton),
        },
      ],
      false,
      dialogId,
    ),
  );
  dispatch(openDialog({ dialog: Dialogs.Confirmation }));
};

export const onPreview = (
  {
    clientId,
    vendorData = {},
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge = defaultPricingSurcharge,
    pricingSurchargeVaryConditions = [],
    path,
  }: {
    clientId: string;
    vendorData: Vendor | undefined;
    clientDataBranch: ClientDataBranch | undefined;
    pricingTab: PricingTab | undefined;
    selectedPricingSurcharge: PricingSurcharge | undefined;
    pricingSurchargeVaryConditions: PricingSurchargeVaryCondition[] | undefined;
    path: string;
  },
  dispatch: Dispatch<any>,
  t: TFunction,
) => {
  if (path.startsWith(AppRoutes.Sites)) {
    const { locale = '', productionURL = '' } = vendorData;
    const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
    const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
    const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientDataBranch, undefined, undefined);
    window.open(previewUrl, '_blank', 'noopener noreferrer');
    return;
  }

  let validationMessage = '';
  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Surcharge) {
    validationMessage = getValidationErrorMessage(
      validateSurcharge(selectedPricingSurcharge, pricingSurchargeVaryConditions),
      pricingSurchargeVaryConditions,
      t,
    );
  }
  if (!validationMessage) {
    let dialog;
    switch (pricingTab) {
      case PricingTab.Base:
        dialog = Dialogs.PricingBasePreview;
        break;
      case PricingTab.Component:
      case PricingTab.SizeBased:
        dialog = Dialogs.PricingClientUpdatePreview;
        break;
      case PricingTab.Surcharge:
        dialog = Dialogs.PricingSurchargePreview;
        break;
      default:
        break;
    }
    if (!dialog) return;
    dispatch(
      openDialog({
        dialog,
      }),
    );
  } else {
    dispatch(openNotificationDialog('', validationMessage));
    dispatch(openDialog({ dialog: Dialogs.Notification }));
  }
};

export const onPublish = (
  {
    clientId,
    groupId,
    clientDataType,
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge = defaultPricingSurcharge,
    pricingSurchargeVaryConditions = [],
    path,
  }: {
    clientId: string;
    groupId: string;
    clientDataType: ClientDataType;
    clientDataBranch: ClientDataBranch | undefined;
    pricingTab: PricingTab | undefined;
    selectedPricingSurcharge: PricingSurcharge | undefined;
    pricingSurchargeVaryConditions: PricingSurchargeVaryCondition[] | undefined;
    path: string;
  },
  publishClientData: ReturnType<typeof usePublishClientDataMutation>[0],
  dispatch: Dispatch<any>,
  t: TFunction,
) => {
  if (path.startsWith(AppRoutes.Sites)) {
    dispatch(
      openConfirmationDialog(
        t(sitesText.publishConfirmationTitle),
        t(sitesText.publishConfirmationBody, { env: config.environment.STAGE || '' }),
        undefined,
        [
          {
            onClick: () => {
              if (clientDataBranch) {
                publishClientData({
                  dataType: clientDataType,
                  branch: clientDataBranch,
                  clientId,
                  groupId,
                  message: `${publishMessagePrefix}${clientId}`,
                })
                  .unwrap()
                  .then((result) => {
                    const data = result;
                    if (data?.mainMerge?.status === MergeStatus.Succeed) {
                      dispatch(setClientDataBranch(ClientDataBranch.Main));
                    }
                    dispatch(setSiteDetailPublishMergeResult({ data, isSuccess: true, error: undefined }));
                  })
                  .catch((error) => {
                    dispatch(setSiteDetailPublishMergeResult({ data: undefined, isSuccess: false, error }));
                    dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                  })
                  .finally(() => {
                    dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                  });
              }
            },
          },
        ],
        false,
        'sites-publish', // confirmation dialog id
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    return;
  }

  let validationMessage = '';
  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Surcharge) {
    validationMessage = getValidationErrorMessage(
      validateSurcharge(selectedPricingSurcharge, pricingSurchargeVaryConditions),
      pricingSurchargeVaryConditions,
      t,
    );
  }
  if (!validationMessage) {
    dispatch(
      openDialog({
        dialog: Dialogs.PricingPublish,
      }),
    );
  } else {
    dispatch(openNotificationDialog('', validationMessage));
    dispatch(openDialog({ dialog: Dialogs.Notification }));
  }
};
