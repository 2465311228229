import {
  Alert,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { BRANCH_LABELS, MergeStatus } from '../constants/ClientData';
import { mapClientIdToConfiguratorAndVendor, mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { clientDataApi } from '../services/clientDataApi';
import { useGetClientName } from '../hooks/useGetClientName';
import { ClientDataType } from '../constants/ClientDataType';
import { PublishMergeResult } from '../types/ClientData';
import { unknownGroup } from '../constants/Group';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { config } from '../config/config';

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const useStyles = makeStyles(() => ({
  vendorPublishBoxIcon: {
    width: '24px',
    height: '24px',
  },
}));

const ProductionLinkUrl: React.FC<{ url?: string; children?: React.ReactNode }> = ({ url, children }) => (
  <Link
    href={url}
    target="_blank"
    rel="noreferrer noopener"
    underline="hover"
    sx={{ color: '#323B4B', cursor: 'pointer', opacity: 0.6 }}
  >
    {children}
  </Link>
);

interface StateProps {
  dialogKey: Dialogs;
  clientDataType: ClientDataType;
  publishMergeResult?: PublishMergeResult;
  vendorsToPublish: string[];
}

export const PublishResultDialog: React.FC<StateProps> = ({
  dialogKey,
  clientDataType,
  publishMergeResult,
  vendorsToPublish,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.clientData);
  const {
    data,
    error,
    isSuccess: isPublishSuccess,
  } = publishMergeResult || { data: undefined, error: undefined, isSuccess: false };
  const { vendorData: { locale = '', productionURL: vendorUrl = '' } = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });

  const { configurator = '', vendor = '' } = clientId ? mapClientIdToConfiguratorAndVendor(clientId) : {};
  const [publishingResults, setPublishingResults] = useState<{
    [vendorKey: string]: { isPublishing: boolean; isSuccess: boolean; error?: string; productionUrl?: string };
  }>({});
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [productionUrl, setProductionUrl] = useState('');
  const [isPublishingVendors, setIsPublishingVendors] = useState(false);
  const openDialogKey = useAppSelector((state) => state.dialog.key);
  const getClientName = useGetClientName();

  useEffect(() => {
    if (openDialogKey === dialogKey) {
      setPublishingResults({});
      if (clientDataType === ClientDataType.Supplier) {
        const publishVendors = async () => {
          setIsPublishingVendors(true);
          for (let i = 0; i < vendorsToPublish.length; i += 1) {
            const vendorKey = vendorsToPublish[i];
            const url = getConfiguratorUrlWithLocale(configurator, vendorKey, locale, vendorUrl);
            const vendorProductionUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
            try {
              setPublishingResults((prevResults) => ({
                ...prevResults,
                [vendorKey]: { isSuccess: false, isPublishing: true },
              }));

              // eslint-disable-next-line no-await-in-loop
              await dispatch(
                clientDataApi.endpoints.publishVendor.initiate({
                  clientId: mapConfiguratorToClientId({ key: configurator, vendor: vendorKey }),
                  suppliers: [vendor],
                  groupId,
                }),
              )
                .unwrap()
                .then(() =>
                  setPublishingResults((prevResults) => ({
                    ...prevResults,
                    [vendorKey]: { isSuccess: true, isPublishing: false, productionUrl: vendorProductionUrl },
                  })),
                );
            } catch (e: any) {
              setPublishingResults((prevResults) => ({
                ...prevResults,
                [vendorKey]: {
                  isSuccess: false,
                  isPublishing: false,
                  productionUrl: vendorProductionUrl,
                  error: e.data,
                },
              }));
            }
          }
          setIsPublishingVendors(false);
        };
        publishVendors();
      }
    }
  }, [vendorsToPublish, openDialogKey, clientDataType, dispatch, configurator, vendor, dialogKey, locale, vendorUrl]);

  useEffect(() => {
    if (clientId !== undefined && clientId !== '' && data && !isLoadingVendorData) {
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, vendorUrl);
      const configuratorUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
      setProductionUrl(configuratorUrl);
    }
  }, [clientId, data, configurator, vendor, isLoadingVendorData]);

  const additionalBranchesMergesWithConflicts =
    data?.additionalBranchMerges?.filter(({ status }) => status === MergeStatus.Conflicts) || [];

  const mergeSuccessful = isPublishSuccess && data && data.mainMerge.status === MergeStatus.Succeed;
  const errorMessage = error && 'data' in error ? error.data.replace(URL_REGEX, '') : '';
  const errorCellLink = error && 'data' in error ? (error.data.match(URL_REGEX) || [])[0] : undefined;

  const someVendorFailedToPublish = Object.keys(publishingResults).some(
    (vendorKey) => publishingResults[vendorKey].error,
  );
  const allVendorFailedToPublish = Object.keys(publishingResults).every(
    (vendorKey) => publishingResults[vendorKey].error,
  );
  const isSuccess = mergeSuccessful && !isPublishingVendors && !someVendorFailedToPublish;
  const isLoading = mergeSuccessful && isPublishingVendors;
  const isError = (!mergeSuccessful || someVendorFailedToPublish) && !isPublishingVendors;
  const environment = config.environment.STAGE || 'development';

  return (
    <Dialog dialogKey={dialogKey} maxWidth="lg" disableClose={isPublishingVendors}>
      <DialogContent sx={{ minWidth: '400px', maxWidth: '500px' }}>
        {isLoading && (
          <Stack direction="row" spacing="16px" alignItems="center">
            <CircularProgress color="secondary" size={30} style={{ padding: '2px' }} />
            <DialogTitle>
              <Trans i18nKey={I18nKeys.ClientDataPublishPublishingVendors as string} context={environment} />
            </DialogTitle>
          </Stack>
        )}

        {isSuccess && (
          <>
            <Stack direction="row" spacing="16px" alignItems="center">
              <CheckCircleIcon sx={{ width: '30px', height: '30px', color: (theme) => theme.palette.success.dark }} />
              <DialogTitle sx={{ flexGrow: 1 }}>
                <Trans
                  i18nKey={I18nKeys.ClientDataPublishSuccess as string}
                  components={{ a: <ProductionLinkUrl url={productionUrl} /> }}
                  context={environment}
                />
              </DialogTitle>
            </Stack>
            <Stack sx={{ pl: '46px' }} direction="column" spacing="16px">
              {data &&
                data.additionalBranchMerges?.length &&
                (additionalBranchesMergesWithConflicts.length > 0 ? (
                  <Typography>
                    <Trans
                      i18nKey={I18nKeys.ClientDataPublishSuccessConflicts as string}
                      values={{
                        branches: additionalBranchesMergesWithConflicts.map(({ branch }) => t(BRANCH_LABELS[branch])),
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography>
                    <Trans
                      i18nKey={I18nKeys.ClientDataPublishSuccessNoConflicts as string}
                      values={{
                        mergedBranches: data.additionalBranchMerges.map(({ branch }) => t(BRANCH_LABELS[branch])),
                      }}
                      count={data.additionalBranchMerges.length}
                    />
                  </Typography>
                ))}
            </Stack>
          </>
        )}

        {isError && (
          <>
            <Stack direction="row" spacing="16px" alignItems="center">
              <CancelIcon sx={{ width: '30px', height: '30px', color: (theme) => theme.palette.error.dark }} />
              <DialogTitle>
                <Trans
                  i18nKey={
                    (allVendorFailedToPublish
                      ? I18nKeys.ClientDataPublishError
                      : I18nKeys.ClientDataPublishErrorSome) as string
                  }
                  context={environment}
                />
              </DialogTitle>
            </Stack>
            <Stack sx={{ pl: '46px' }} direction="column" spacing="16px">
              {error && (
                <Alert severity="error" icon={false}>
                  <code>{errorMessage}</code>
                  {errorCellLink && (
                    <>
                      <br />
                      <a href={errorCellLink}>Cell with error</a>
                    </>
                  )}
                </Alert>
              )}
              {data && data.mainMerge.status === MergeStatus.Conflicts && (
                <Typography>
                  <Trans i18nKey={I18nKeys.ClientDataPublishUnresolvedMergeConflicts as string} />
                </Typography>
              )}
            </Stack>
          </>
        )}

        {mergeSuccessful && clientDataType === ClientDataType.Supplier && vendorsToPublish.length > 0 && (
          <Stack direction="column" sx={{ pl: '46px', maxHeight: '500px', minWidth: '500px', overflow: 'auto' }}>
            {vendorsToPublish.map((vendorKey) => {
              const vendorName = getClientName(vendorKey);
              const {
                isSuccess: vendorIsSuccess,
                isPublishing,
                error: vendorError,
                productionUrl: vendorProductionUrl,
              } = publishingResults[vendorKey] || {};

              return (
                <Alert
                  severity="error"
                  sx={{ backgroundColor: !vendorError ? 'transparent' : undefined, borderRadius: '0px' }}
                  icon={false}
                >
                  <Stack key={vendorKey} direction="row" spacing="16px" alignItems="center">
                    {vendorIsSuccess && (
                      <CheckCircleIcon
                        className={`${classes.vendorPublishBoxIcon}`}
                        sx={{ color: (theme) => theme.palette.success.dark }}
                      />
                    )}
                    {vendorError && (
                      <CancelIcon
                        className={`${classes.vendorPublishBoxIcon}`}
                        sx={{ color: (theme) => theme.palette.error.dark }}
                      />
                    )}
                    {isPublishing && (
                      <CircularProgress
                        className={classes.vendorPublishBoxIcon}
                        color="secondary"
                        size={24}
                        style={{ padding: '2px' }}
                      />
                    )}
                    {!vendorIsSuccess && !vendorError && !isPublishing && (
                      <MoreHorizIcon className={classes.vendorPublishBoxIcon} sx={{ color: '#323B4B', opacity: 0.8 }} />
                    )}
                    {vendorIsSuccess || vendorError ? (
                      <Typography>
                        Vendor: <ProductionLinkUrl url={vendorProductionUrl}>{vendorName}</ProductionLinkUrl>
                      </Typography>
                    ) : (
                      <Typography>Vendor: {vendorName}</Typography>
                    )}
                  </Stack>
                </Alert>
              );
            })}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {!!additionalBranchesMergesWithConflicts.length && (
          <Button
            variant="contained"
            sx={{
              ...(mergeSuccessful ? {} : { bgcolor: (theme) => `${theme.palette.error.dark} !important` }),
            }}
          >
            {t(I18nKeys.ClientDataPublishResolveNowButton)}
          </Button>
        )}
        <Button
          disabled={isPublishingVendors}
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant={additionalBranchesMergesWithConflicts.length ? 'outlined' : 'contained'}
          sx={{
            ...(mergeSuccessful || additionalBranchesMergesWithConflicts.length
              ? {}
              : { bgcolor: (theme) => `${theme.palette.error.dark} !important` }),
          }}
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
