import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../../constants/Dialogs';
import { Dialog } from './Dialog';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch } from '../../hooks';
import supportIcon from '../../images/contactSupport.svg';

const useStyles = makeStyles(() => ({
  diaglogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  supportIconContainer: {
    backgroundColor: 'rgb(51, 51, 51)',
    borderRadius: '50%',
    height: '65px',
    width: '70px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
  },
  supportIconSvg: { verticalAlign: 'middle', objectFit: 'contain', height: '20%', color: 'white', margin: '8px' },
  title: {
    textFont: 'bold',
  },
  message: {
    margin: '10px',
  },
}));

export const PricingContactSupportDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const showIntercomDialog = () => {
    window.Intercom('show');
    dispatch(closeDialogFunc());
  };
  return (
    <Dialog dialogKey={Dialogs.PricingContactSupport}>
      <DialogTitle className={classes.title}>{t(I18nKeys.PricingBaseContactSupportDialogTitle)}</DialogTitle>
      <DialogContent className={classes.diaglogContent}>
        <div className={classes.supportIconContainer}>
          <Button onClick={showIntercomDialog}>
            <img className={classes.supportIconSvg} alt="Support" src={supportIcon} />
          </Button>
        </div>
        <span
          role="button"
          onClick={showIntercomDialog}
          onKeyDown={showIntercomDialog}
          tabIndex={0}
          className={classes.message}
        >
          {t(I18nKeys.PricingBaseContactSupportMessage)}
        </span>
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button onClick={showIntercomDialog} color="primary">
          {t(I18nKeys.DialogContactSupportButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCloseButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
