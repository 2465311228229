import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, Grid2 as Grid, MenuItem, Stack } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, Field, formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { EmailFormat, SendEmailsTo } from '@idearoom/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { DocumentsFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { useGetClientDataTableDataQuery, useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SelectField } from '../redux-form/SelectField';
import { ImageSelectField } from '../redux-form/ImageSelectField';
import { deleteFileFromS3, uploadEmailLogoImage } from '../../ducks/vendorDataSlice';
import { openDialog } from '../../ducks/dialogSlice';
import { openNotificationDialog } from '../../ducks/notificationDialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { Dialogs } from '../../constants/Dialogs';
import { openConfirmationDialog } from '../../ducks/confirmation';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { unknownGroup } from '../../constants/Group';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { SitesDivider } from './SitesDivider';
import { Header } from '../library/Header';

export interface FormData {
  [DocumentsFormFields.CheckoutEmailFormat]: string | number | boolean;
  [DocumentsFormFields.QuoteEmailFormat]: string | number | boolean;
  [DocumentsFormFields.SaveEmailFormat]: string | number | boolean;
  [DocumentsFormFields.PrintEmailFormat]: string | number | boolean;
  [DocumentsFormFields.SendEmailsTo]: string | number | boolean;
  [DocumentsFormFields.EmailLogoUrl]: string | number | boolean;
  [DocumentsFormFields.ShowLogoWatermarkUnderEstimate]: boolean;
  [DocumentsFormFields.EmailFloorPlan]: boolean;
  [DocumentsFormFields.UseFloorplanFullPage]: boolean;
}

const DocumentsFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { clientId, clientDataType, selectedTable, isCreatingBranch } = useAppSelector((state) => state.clientData);
  const groupId = useAppSelector((state) => (state.currentUser.group || unknownGroup).groupId);
  const { imageProcessing, vendorEmailLogos } = useAppSelector((state) => state.vendorData);
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [initializing, setInitializing] = useState(true);

  const { currentData: [{ emailLogoUrl: publishedEmailLogoUrl } = {} as Record<string, string>] = [] } =
    useGetClientDataTableDataQuery(
      {
        dataType: clientDataType,
        clientId,
        groupId,
        branch: ClientDataBranch.Main,
        table: selectedTable,
      },
      {
        skip: !clientId,
        refetchOnMountOrArgChange: true,
      },
    );

  const emailFloorPlan = useAppSelector((state) =>
    formValueSelector(Forms.Documents)(state, DocumentsFormFields.EmailFloorPlan),
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  const onImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const file = files?.[0];
      if (!file) return;
      dispatch(uploadEmailLogoImage({ image: file }));
    },
    [dispatch],
  );

  const onImageDelete = useCallback(
    (field: string, image: string) => {
      if (image !== publishedEmailLogoUrl) {
        dispatch(
          openConfirmationDialog(
            t(I18nKeys.SitesLogosDeleteLogoConfirmTitle),
            t(I18nKeys.SitesLogosDeleteLogoConfirmMessage),
            undefined,
            [
              {
                actions: [deleteFileFromS3({ path: image })],
              },
            ],
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      } else {
        dispatch(openNotificationDialog('', t(I18nKeys.SitesLogosDeletePublishImageWarning)));
        dispatch(openDialog({ dialog: Dialogs.Notification }));
      }
    },
    [dispatch, t, publishedEmailLogoUrl],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="24px">
        <Grid container spacing="24px" size={12}>
          <Grid key={DocumentsFormFields.CheckoutEmailFormat} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.CheckoutEmailFormat}
                label="Checkout email format"
                variant="filled"
                component={SelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.CheckoutEmailFormat, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(EmailFormat).map((format) => (
                  <MenuItem key={format} value={format}>
                    {t(`sites-email-format-${format}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid key={DocumentsFormFields.QuoteEmailFormat} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.QuoteEmailFormat}
                label="Quote email format"
                variant="filled"
                component={SelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.QuoteEmailFormat, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(EmailFormat).map((format) => (
                  <MenuItem key={format} value={format}>
                    {t(`sites-email-format-${format}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid key={DocumentsFormFields.SaveEmailFormat} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.SaveEmailFormat}
                label="Save email format"
                variant="filled"
                component={SelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.SaveEmailFormat, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(EmailFormat).map((format) => (
                  <MenuItem key={format} value={format}>
                    {t(`sites-email-format-${format}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid key={DocumentsFormFields.PrintEmailFormat} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.PrintEmailFormat}
                label="Print email format"
                variant="filled"
                component={SelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.PrintEmailFormat, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(EmailFormat).map((format) => (
                  <MenuItem key={format} value={format}>
                    {t(`sites-email-format-${format}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid key={DocumentsFormFields.SendEmailsTo} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.SendEmailsTo}
                label="Send emails to"
                variant="filled"
                component={SelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.SendEmailsTo, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(SendEmailsTo).map((format) => (
                  <MenuItem key={format} value={format}>
                    {t(`sites-send-email-to-${format}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <Grid size={12}>
          <SitesDivider initializing={initializing} />
        </Grid>
        <Grid container spacing="24px" size={12}>
          <Grid size={12}>
            <Header initializing={initializing} header="Email content" />
          </Grid>
          <Grid key={DocumentsFormFields.EmailLogoUrl} size={{ xs: 12, lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={DocumentsFormFields.EmailLogoUrl}
                label="Email logo"
                variant="filled"
                component={ImageSelectField}
                onChange={(value: any) => onChange(DocumentsFormFields.EmailLogoUrl, value)}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                images={vendorEmailLogos}
                onDelete={onImageDelete}
                onUpload={onImageUpload}
                loading={imageProcessing}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Stack spacing="8px" sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <Field
                  name={DocumentsFormFields.ShowLogoWatermarkUnderEstimate}
                  component={LabeledCheckboxField}
                  label="Show logo below estimate"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(DocumentsFormFields.ShowLogoWatermarkUnderEstimate, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={DocumentsFormFields.EmailFloorPlan}
                  component={LabeledCheckboxField}
                  label="Show floorplan"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(DocumentsFormFields.EmailFloorPlan, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={DocumentsFormFields.UseFloorplanFullPage}
                  component={LabeledCheckboxField}
                  label="Full-page floorplan"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(DocumentsFormFields.UseFloorplanFullPage, !!event.target.checked)
                  }
                  disabled={!emailFloorPlan}
                  initializing={initializing}
                />
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export const DocumentsForm = reduxForm<FormData>({
  form: Forms.Documents,
  enableReinitialize: true,
})(DocumentsFormComponent);
