import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import { WatermarkPosition, UILayoutVersion } from '@idearoom/types';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { ColorsAndLogosFormFields } from '../../constants/FormFields';
import { ColorsAndLogosForm } from './ColorsAndLogosForm';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getConfiguratorFromClientId } from '../../utils/clientIdUtils';
import { fetchSceneEnvironment } from '../../ducks/settings';
import { DEFAULT_SCENE_KEY } from '../../types/SceneEnvironment';
import { getLogosAndWatermarks } from '../../ducks/vendorDataSlice';

export const ColorsAndLogos: React.FC<{ clientId: string }> = () => {
  const dispatch = useAppDispatch();

  const clientId = useAppSelector((state) => state.clientData.clientId);
  const sceneEnvironments = useAppSelector((state) => state.settings.sceneEnvironment);
  const {
    selectedTableData: [
      {
        layout,
        logoBarColor,
        logoBarTextColor,
        contactBarColor,
        contactBarTextColor,
        selectedTextColor,
        defaultSceneEnvironment,
        logoUrl,
        watermark,
        showLogoOnMobile,
        watermarkPosition,
        watermarkText,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  useEffect(() => {
    const { key: configurator } = getConfiguratorFromClientId(clientId) || {};
    if (configurator) {
      dispatch(fetchSceneEnvironment(configurator));
      dispatch(getLogosAndWatermarks());
    }
  }, [dispatch, clientId]);

  return (
    <SitesPage header="Colors & Logos">
      <Card sx={{ p: '24px' }} elevation={0}>
        <ColorsAndLogosForm
          initialValues={{
            [ColorsAndLogosFormFields.Layout]: layout || UILayoutVersion.Modern,
            [ColorsAndLogosFormFields.LogoBarColor]: logoBarColor || '',
            [ColorsAndLogosFormFields.LogoBarTextColor]: logoBarTextColor || '',
            [ColorsAndLogosFormFields.ContactBarColor]: contactBarColor || '',
            [ColorsAndLogosFormFields.ContactBarTextColor]: contactBarTextColor || '#FFFFFF',
            [ColorsAndLogosFormFields.SelectedTextColor]: selectedTextColor || '',
            [ColorsAndLogosFormFields.DefaultSceneEnvironment]:
              sceneEnvironments.find((scene) => scene.key === defaultSceneEnvironment)?.key || DEFAULT_SCENE_KEY,
            [ColorsAndLogosFormFields.LogoUrl]: logoUrl || '',
            [ColorsAndLogosFormFields.Watermark]: watermark || '',
            [ColorsAndLogosFormFields.ShowLogoOnMobile]: !!showLogoOnMobile,
            [ColorsAndLogosFormFields.WatermarkPosition]: watermarkPosition || WatermarkPosition.None,
            [ColorsAndLogosFormFields.WatermarkText]: watermarkText || '',
          }}
        />
      </Card>
    </SitesPage>
  );
};
