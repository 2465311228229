import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  styled,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Tooltip } from './library/Tooltip';
import { MainMenuItems } from '../constants/MainMenuItems';
import { MenuVariant } from '../constants/Menu';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { MenuIcon, MenuIconKey } from './MenuIcon';

const SELECTION_INDICATOR_WIDTH = '5px';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  currentItem: {
    borderTop: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
  },
  menuItemText: {
    width: 'auto',
    textWrap: 'nowrap',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

interface Props {
  id?: MainMenuItems | string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ideaRoomOnly?: boolean;
  primaryIcon?: MenuIconKey | React.ReactNode | undefined;
  secondaryIcon?: MenuIconKey | undefined;
  variant?: MenuVariant;
  selected?: boolean;
  highlighted?: boolean;
}

const MenuSelectionIndicator = styled(Box)({
  width: SELECTION_INDICATOR_WIDTH,
  height: '100%',
  flexShrink: 0,
  borderWidth: '1px 0px 1px 0px',
  borderStyle: 'solid',
});

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: '20px',
  alignItems: 'center',
  alignSelf: 'center',
});

export const MainMenuItem: React.FC<Props> = ({
  id,
  label,
  onClick,
  ideaRoomOnly,
  primaryIcon,
  secondaryIcon,
  variant = MenuVariant.STANDARD,
  selected = false,
  highlighted = false,
}: Props) => {
  const classes = useStyles();

  return (
    <ListItem
      disablePadding
      className={selected ? classes.currentItem : undefined}
      sx={{
        height: '44px',
        borderRight: '1px solid #DEDEDE',
        ...(selected && variant === MenuVariant.STANDARD
          ? { backgroundColor: '#F2F4F5', borderRight: '1px solid #F2F4F5' }
          : {}),
        ...(selected && variant === MenuVariant.MINI
          ? { backgroundColor: '#FFFFFF', borderRight: '1px solid #FFFFFF' }
          : {}),
      }}
    >
      {selected && (
        <MenuSelectionIndicator
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            borderColor: (theme: Theme) => theme.palette.primary.main,
            height: '44px',
          }}
        />
      )}
      <Box
        sx={{
          width: '100%',
          justifyItems: 'center',
          ...(selected && variant === MenuVariant.MINI ? { ml: `-${SELECTION_INDICATOR_WIDTH}` } : {}),
        }}
      >
        <ListItemButton
          id={id}
          onClick={(e) => onClick?.(e)}
          sx={{
            p: '0px',
            height: '44px',
            ...(variant === MenuVariant.STANDARD
              ? { width: '100%' }
              : { border: 'none', borderRadius: '100px', height: '42px', width: '42px' }),
          }}
        >
          <Box
            display="flex"
            sx={{
              height: '44px',
              minWidth: '0px',
              flex: 1,
              justifyContent: 'center',
              ...(variant === MenuVariant.STANDARD
                ? {
                    p: '9px 10px',
                    pl: !selected ? `calc(${SELECTION_INDICATOR_WIDTH} + 9px)` : '9px',
                  }
                : {}),
            }}
          >
            {primaryIcon &&
              (variant === MenuVariant.STANDARD ? (
                <ListItemIcon sx={{ m: '0px', mr: '16px' }}>
                  {typeof primaryIcon === 'string' ? (
                    <MenuIcon iconKey={primaryIcon as MenuIconKey} highlighted={highlighted} />
                  ) : (
                    primaryIcon
                  )}
                </ListItemIcon>
              ) : (
                <Tooltip
                  title={label}
                  placement="right"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 4.5],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <ListItemIcon sx={{ m: '0px' }}>
                    {typeof primaryIcon === 'string' ? (
                      <MenuIcon iconKey={primaryIcon as MenuIconKey} highlighted={highlighted} />
                    ) : (
                      primaryIcon
                    )}
                  </ListItemIcon>
                </Tooltip>
              ))}

            {variant === MenuVariant.STANDARD && (
              <ListItemText
                className={classes.menuItemText}
                primary={label}
                sx={{ pl: !primaryIcon ? '40px' : '0px', my: '0px', alignSelf: 'center' }}
                slotProps={{
                  primary: {
                    fontWeight: '500',
                    color: highlighted ? 'primary' : '#323B4B',
                    sx: { opacity: 0.8 },
                  },
                }}
              />
            )}
          </Box>
          {variant === MenuVariant.STANDARD && (ideaRoomOnly || secondaryIcon) && (
            <ListItemIcon sx={{ m: '0px', pr: '16px' }}>
              {ideaRoomOnly && <IdeaRoomOnlyIndicator disableMargin />}
              {secondaryIcon && <MenuIcon iconKey={secondaryIcon} />}
            </ListItemIcon>
          )}
        </ListItemButton>
      </Box>
    </ListItem>
  );
};
