import React from 'react';
import { Field, InjectedFormProps, reduxForm, Validator } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Forms } from '../constants/Forms';
import { InputField } from './redux-form/InputField';
import { IntegrationsFormFields } from '../constants/FormFields';
import { I18nKeys } from '../constants/I18nKeys';
import { Form } from './redux-form/Form';

export interface FormData {
  [IntegrationsFormFields.FacebookPixelId]: string;
}

type FormProps = InjectedFormProps<FormData>;

const nullOrEmpty: Validator = (value: string) => {
  if (!value || value.length === 0) {
    return 'error';
  }
  return undefined;
};

const IntegrationsPixelFormComponent: React.FC<FormProps> = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.IntegrationsFacebookPixelForm}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="16px">
        <Grid size={12}>
          <Field
            autoFocus
            type="string"
            autoComplete="off"
            hideHelperText
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormFacebookPixelId)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.FacebookPixelId}
            variant="filled"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export const IntegrationsPixelForm = reduxForm<FormData>({
  form: Forms.IntegrationsFacebookPixelForm,
})(IntegrationsPixelFormComponent);
