import { DialogTitle, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { Dialogs } from '../constants/Dialogs';
import { Dialog } from './library/Dialog';
import { useAppDispatch, useAppSelector } from '../hooks';
import { closeDialog } from '../ducks/dialogSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataGoToSourceDataDialogOptions } from '../types/DialogState';

export const ClientDataGoToSourceDataDialog: React.FC = () => {
  const dispatch = useAppDispatch();

  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { menuItems = [] } = options as ClientDataGoToSourceDataDialogOptions;

  return (
    <Dialog dialogKey={Dialogs.ClientDataGoToSourceData}>
      <DialogTitle>
        <Stack direction="row" spacing="16px" alignItems="center">
          <OpenInNew />
          <Typography sx={{ fontWeight: 'bold' }}>
            <Trans i18nKey={I18nKeys.ClientDataHeaderMenuGoToSource as string} />
          </Typography>
        </Stack>
      </DialogTitle>
      <List disablePadding>
        {menuItems.map((item) => (
          <ListItem disableGutters disablePadding key={item.name}>
            <ListItemButton
              onClick={() => {
                item.action();
                dispatch(closeDialog());
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
