import { Card, Stack, Box, darken } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreferencesFormFields, ProfileFormFields } from '../constants/FormFields';
import { I18nKeys } from '../constants/I18nKeys';
import { PasswordForm } from './PasswordForm';
import { PreferencesForm } from './PreferencesForm';
import { ProfileForm } from './ProfileForm';
import { useAppSelector } from '../hooks';
import { UserPreference } from '../constants/User';
import { unknownUser } from '../types/User';
import { Page } from './library/Page';
import { SalesViewUILayout } from '../constants/Viewer';
import { Tabs } from './library/Tabs';

enum ProfileComponentTabs {
  General = 'general',
  Preferences = 'preferences',
  Password = 'password',
}

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = React.useState(ProfileComponentTabs.General);

  const {
    user = unknownUser,
    preferences: {
      [UserPreference.ProfilePreferences]: {
        [PreferencesFormFields.Layout]: editorLayout = SalesViewUILayout.MapNeeded,
        [PreferencesFormFields.Theme]: editorTheme = 'light',
        [PreferencesFormFields.MiniMap]: editorMiniMap = false,
      } = {},
    } = {},
  } = useAppSelector((state) => state?.currentUser);
  const { firstName = '', lastName = '', email = '', phone = '' } = user;

  const tabs = [
    {
      label: t(I18nKeys.ProfileProfileTab),
      value: ProfileComponentTabs.General,
      id: 'profile-tab',
      ideaRoomOnly: false,
    },
    {
      label: t(I18nKeys.ProfilePreferencesTab),
      value: ProfileComponentTabs.Preferences,
      id: 'preferences-tab',
      ideaRoomOnly: true,
    },
    {
      label: t(I18nKeys.ProfilePasswordTab),
      value: ProfileComponentTabs.Password,
      id: 'password-tab',
      ideaRoomOnly: false,
    },
  ];

  return (
    <Page header="Profile">
      <Card elevation={0}>
        <Stack spacing="24px">
          <Tabs
            tabs={tabs}
            value={tab}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: ProfileComponentTabs): void => {
              setTab(newValue);
            }}
            TabIndicatorProps={{ sx: { display: 'none' } }}
            slotProps={{ appBar: { sx: { backgroundColor: darken('#F2F4F5', 0.1) } } }}
          />
          <Box sx={{ px: '24px', pb: '24px' }}>
            {tab === ProfileComponentTabs.General && (
              <ProfileForm
                initialValues={{
                  [ProfileFormFields.FirstName]: firstName,
                  [ProfileFormFields.LastName]: lastName,
                  [ProfileFormFields.Email]: email,
                  [ProfileFormFields.PhoneNumber]: phone,
                }}
              />
            )}
            {tab === ProfileComponentTabs.Preferences && (
              <PreferencesForm
                initialValues={{
                  [PreferencesFormFields.Layout]: editorLayout,
                  [PreferencesFormFields.Theme]: editorTheme,
                  [PreferencesFormFields.MiniMap]: editorMiniMap,
                }}
              />
            )}
            {tab === ProfileComponentTabs.Password && <PasswordForm />}
          </Box>
        </Stack>
      </Card>
    </Page>
  );
};
