import React from 'react';
import { Card } from '@mui/material';
import { PricingVisibility } from '@idearoom/types';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { VisibilityFormFields } from '../../constants/FormFields';
import { VisibilityForm } from './VisibilityForm';

export const Visibility: React.FC<{ clientId: string }> = () => {
  const {
    selectedTableData: [
      {
        pricingVisibility,
        priceRangePercentage,
        pricingVisibilityStaging,
        pricingEnableClientManaged,
        pricingEnableClientUpdates,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  return (
    <SitesPage header="Visibility">
      <Card sx={{ p: '24px' }} elevation={0}>
        <VisibilityForm
          initialValues={{
            [VisibilityFormFields.PricingVisibility]: pricingVisibility || PricingVisibility.Detailed,
            [VisibilityFormFields.PriceRangePercentage]: priceRangePercentage
              ? parseFloat((parseFloat(`${priceRangePercentage}`) * 100).toFixed(7)).toString()
              : 0,
            [VisibilityFormFields.PricingVisibilityStaging]: pricingVisibilityStaging || PricingVisibility.Detailed,
            [VisibilityFormFields.PricingEnableClientManaged]: !!pricingEnableClientManaged,
            [VisibilityFormFields.PricingEnableClientUpdates]: !!pricingEnableClientUpdates,
          }}
        />
      </Card>
    </SitesPage>
  );
};
