import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { ClientDataCreateBranchFields } from '../constants/FormFields';
import { BRANCH_LABELS } from '../constants/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { Form } from './redux-form/Form';

export interface FormData {
  [ClientDataCreateBranchFields.Description]: string;
}

interface CustomProps {
  onSubmit: { (values: FormData): void };
  isSubmitting: boolean;
  branch: ClientDataBranch;
}
type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const ClientDataCreateBranchFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  onSubmit,
  isSubmitting,
  branch,
}: FormProps) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={isSubmitting}
        autoComplete="off"
        style={{ minWidth: '500px' }}
        component={InputField}
        label={t(I18nKeys.ClientDataCreateBranchDescriptionField, { branch: t(BRANCH_LABELS[branch]) })}
        name={ClientDataCreateBranchFields.Description}
        multiline
        rows={3}
        variant="filled"
      />
    </Form>
  );
};

export const ClientDataCreateBranchForm = reduxForm<FormData, CustomProps>({ form: Forms.ClientDataCreateBranch })(
  ClientDataCreateBranchFormComponent,
);
