import { Box, Divider, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { A } from 'hookrouter';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AppRoutes } from '../../constants/AppRoutes';
import { SignInFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { I18nKeys } from '../../constants/I18nKeys';
import { required } from '../../utils/reduxFormUtils';
import { IdeaRoomLogo } from '../IdeaRoomLogo';
import { LoadingButton } from '../LoadingButton';
import { InputField } from './InputField';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openDialog } from '../../ducks/dialogSlice';
import { Dialogs } from '../../constants/Dialogs';
import { OneTimePasswordDialog } from './OneTimePasswordDialog';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: '#1b69b6',
    float: 'right',
    textDecoration: 'none',
    marginBottom: '10px',
  },
  error: {
    color: 'red',
    marginTop: '30px',
    marginBottom: '20px',
  },
  image: {
    marginBottom: '20px',
  },
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: theme.spacing(3, 3),
  },
  field: {
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
}));

interface DispatchProps {
  onSubmit(data: FormData): Promise<AnyAction>;
}

interface FormData {
  [SignInFormFields.Email]: string;
  [SignInFormFields.Password]: string;
}

type Props = DispatchProps & InjectedFormProps<FormData>;

const extractParamFromQueryString = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const SignInComponent: React.FC<Props> = ({ error, change, handleSubmit, onSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { signingIn } = useAppSelector((state) => state.currentUser);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSendOtpClick = () => {
    dispatch(openDialog({ dialog: Dialogs.SendOtp }));
  };

  useEffect(() => {
    // Attempt to pull the email and password from the url params
    const email = extractParamFromQueryString('email');
    const password = extractParamFromQueryString('password');

    // Populate the form with the email and password from the url params if they exist
    if (email) change(SignInFormFields.Email, email);
    if (password) change(SignInFormFields.Password, password);

    // If the email and password are populated, submit the form
    if (email && password && !signingIn) {
      onSubmit({ [SignInFormFields.Email]: email, [SignInFormFields.Password]: password });
    }
  }, []);

  return (
    <Paper className={classes.paper}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <IdeaRoomLogo className={classes.image} height="100px" />

        <Typography component="p">{t(I18nKeys.SignInMessage, 'Sign in with your email and password')}</Typography>

        <Field
          autoComplete="username"
          validate={required}
          className={classes.field}
          name={SignInFormFields.Email}
          component={InputField}
          label={`${t(I18nKeys.FieldEmail, 'Email')}*`}
          disabled={signingIn}
        />

        <Field
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          validate={required}
          className={classes.field}
          name={SignInFormFields.Password}
          component={InputField}
          label={`${t(I18nKeys.FieldPassword, 'Password')}*`}
          disabled={signingIn}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />

        <A className={classes.link} href={AppRoutes.ForgotPassword}>
          <Typography>{t(I18nKeys.ForgotPasswordLink, 'Forgot your password?')}</Typography>
        </A>

        {!!error && <Typography className={classes.error}>{error}</Typography>}

        <LoadingButton
          className={classes.button}
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          loading={submitting || signingIn || false}
          disabled={submitting || signingIn}
        >
          {t(I18nKeys.SignInButton, 'Sign in')}
        </LoadingButton>

        <Divider sx={{ margin: '10px 0' }}>or</Divider>

        <Box mt={2}>
          <LoadingButton
            className={classes.button}
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleSendOtpClick}
            loading={submitting || signingIn || false}
            disabled={submitting || signingIn}
          >
            {t(I18nKeys.SendOtpButton, 'Send One Time Password')}
          </LoadingButton>
        </Box>
      </Form>

      <OneTimePasswordDialog />
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.SignIn}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

export const SignIn = reduxForm<FormData>({ form: Forms.SignIn })(
  connect(undefined, mapDispatchToProps)(SignInComponent),
);
