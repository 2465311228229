import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { InputField } from './redux-form/InputField';
import { SceneEnvironmentDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { ImageDropField } from './redux-form/ImageDropField';
import { Form } from './redux-form/Form';

export interface FormData {
  [SceneEnvironmentDialogFormFields.Key]: string;
  [SceneEnvironmentDialogFormFields.Label]: string;
  [SceneEnvironmentDialogFormFields.FileUrl]: string;
  [SceneEnvironmentDialogFormFields.PreviewUrl]: string;
  [SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier]: number;
  [SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength]: number;
}

type FormProps = InjectedFormProps<FormData>;

const SceneEnvironmentFormComponent: React.FC<FormProps> = ({ error, handleSubmit, initialValues }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.SceneEnvironment}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Box sx={{ minWidth: '400px' }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          autoComplete="off"
          component={InputField}
          label={t(I18nKeys.SceneEnvironmentFieldKey)}
          name={SceneEnvironmentDialogFormFields.Key}
          disabled={!!initialValues[SceneEnvironmentDialogFormFields.Key]}
        />
        <Field
          autoComplete="off"
          component={InputField}
          label={t(I18nKeys.SceneEnvironmentFieldLabel)}
          name={SceneEnvironmentDialogFormFields.Label}
        />
        <Field
          autoComplete="off"
          component={InputField}
          label={t(I18nKeys.SceneEnvironmentFieldFileUrl)}
          name={SceneEnvironmentDialogFormFields.FileUrl}
        />
        <Field
          autoComplete="off"
          component={ImageDropField}
          label={t(I18nKeys.SceneEnvironmentFieldPreviewUrl)}
          name={SceneEnvironmentDialogFormFields.PreviewUrl}
          maxSize={150000} // 150 KB
        />
        <Field
          autoComplete="off"
          component={InputField}
          label={t(I18nKeys.SceneEnvironmentFieldMaxCameraDistanceMultiplier)}
          name={SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier}
          type="number"
        />
        <Field
          autoComplete="off"
          component={InputField}
          label={t(I18nKeys.SceneEnvironmentFieldMaxDiagonalBuildingLength)}
          name={SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength}
          type="number"
        />
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </Form>
    </Box>
  );
};

export const SceneEnvironmentForm = reduxForm<FormData>({ form: Forms.SceneEnvironment })(
  SceneEnvironmentFormComponent,
);
