import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Stack,
} from '@mui/material';
import React, { useMemo } from 'react';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { closeDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import { setDealerDialog, setLoading } from '../ducks/dealersSlice';
import { DealerForm } from './DealerForm';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DealerFormFields } from '../constants/FormFields';
import { DepositAmount } from '../types/DepositAmount';
import { DragAndDropUpload } from './DragAndDropUpload';
import { exportBulkDealersTemplate, importBulkDealers } from '../utils/dealerUtils';
import { validateSpreadsheetFile } from '../utils/fileUtils';
import { unknownGroup } from '../constants/Group';
import { Tabs } from './library/Tabs';
import { DealerAdvancedForm } from './DealerAdvancedForm';
import { ContentList } from './library/ContentList';

enum DealerComponentTabs {
  SingleDealer = 'SINGLE_DEALER',
  Advanced = 'ADVANCED',
  BulkDealers = 'BULK_DEALERS',
}

export const DealerDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [tab, setTab] = React.useState(DealerComponentTabs.SingleDealer);

  const [bulkDealersFile, setBulkDealersFile] = React.useState<File | undefined>();
  const [bulkDealersErrorMessage, setBulkDealersErrorMessage] = React.useState<string | undefined>(undefined);

  const { loading, dialogDealer, dialogClientId = '', logoUrl } = useAppSelector((state) => state.dealers);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const configuratorLink = useAppSelector(
    ({ dealers: { dialogClientId: clientId }, currentUser: { group: { configurators = [] } = unknownGroup } }) => {
      const selectedConfigurator = configurators.find((config) => clientId === `${config.key}-${config.vendor}`);
      return selectedConfigurator ? selectedConfigurator.url || '' : '';
    },
  );

  const closeAndResetDialog = (): void => {
    dispatch(reset(Forms.Dealer));
    dispatch(closeDialog());
    dispatch(setDealerDialog({ dialogDealer: undefined, dialogClientId: undefined, logoUrl: undefined }));
    setBulkDealersFile(undefined);
    setBulkDealersErrorMessage(undefined);
  };

  const initialValues = useMemo(() => {
    const depositAmounts: DepositAmount[] = [];
    if (dialogDealer) {
      if (dialogDealer.depositPrice instanceof Array && dialogDealer.depositPercent instanceof Array) {
        depositAmounts.push(
          ...dialogDealer.depositPrice.reduce<DepositAmount[]>((res, cur, index): DepositAmount[] => {
            res.push({ price: cur, percent: dialogDealer.depositPercent[index] });
            return res;
          }, []),
        );
      } else if (typeof dialogDealer.depositPrice === 'string' && typeof dialogDealer.depositPercent === 'string') {
        depositAmounts.push({ price: dialogDealer.depositPrice, percent: dialogDealer.depositPercent });
      }
    }
    // depositPrice and depositPercent are historically store descending. Reverse the depositAmounts objects so that
    // the form can use them in ascending order.
    depositAmounts.reverse();

    return dialogDealer
      ? {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.DealerName]: dialogDealer.name,
          [DealerFormFields.City]: dialogDealer.city,
          [DealerFormFields.State]: dialogDealer.state,
          [DealerFormFields.ZipCode]: dialogDealer.zip,
          [DealerFormFields.Key]: dialogDealer.key,
          [DealerFormFields.Id]: Number(dialogDealer.id) || undefined,
          [DealerFormFields.PhoneNumber]: dialogDealer.phoneNumber,
          [DealerFormFields.CustomLogoUrl]: dialogDealer.logoUrl,
          [DealerFormFields.DealerUrl]: dialogDealer.dealerURL,
          [DealerFormFields.HomeLinkUrl]: dialogDealer.homeLinkUrl,
          [DealerFormFields.EmailAddress]: dialogDealer.emailAddress,
          [DealerFormFields.QuoteEmailReplyToSame]: dialogDealer.emailAddress === dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailReplyToAddress]: dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailCopySame]: dialogDealer.emailAddress === dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.QuoteEmailCopyAddress]: dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.DepositAmounts]: depositAmounts,
          [DealerFormFields.Integration]: JSON.stringify(dialogDealer.integration),
          [DealerFormFields.ContactBarHtml]: dialogDealer.contactBarCustomHtml,
          [DealerFormFields.EmailLogoUrl]: dialogDealer.emailLogoUrl,
          [DealerFormFields.IntegrationsKeySame]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey,
          [DealerFormFields.IntegrationsKey]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey
              ? ''
              : dialogDealer.integrationsKey,
        }
      : {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.QuoteEmailReplyToSame]: true,
          [DealerFormFields.QuoteEmailCopySame]: true,
          [DealerFormFields.CustomLogoUrl]: logoUrl,
          [DealerFormFields.IntegrationsKeySame]: true,
        };
  }, [dialogDealer, dialogClientId, logoUrl]);

  const tabs = [
    {
      label: t(I18nKeys.DealerDialogTabSingleDealer),
      value: DealerComponentTabs.SingleDealer,
      ideaRoomOnly: false,
    },
    {
      label: t(I18nKeys.DealerDialogTabAdvanced),
      value: DealerComponentTabs.Advanced,
      ideaRoomOnly: true,
    },
    {
      label: t(I18nKeys.DealerDialogTabBulkImport),
      value: DealerComponentTabs.BulkDealers,
      ideaRoomOnly: true,
    },
  ];

  return (
    <Dialog dialogKey={Dialogs.Dealer} onClosed={closeAndResetDialog} maxWidth="sm">
      <DialogTitle>{t(I18nKeys.DealerDialogTitle)}</DialogTitle>
      <Tabs
        tabs={tabs}
        value={tab}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: DealerComponentTabs): void => {
          setTab(newValue);
        }}
      />
      <DialogContent sx={{ width: '552px' }}>
        {tab === DealerComponentTabs.SingleDealer && <DealerForm initialValues={initialValues} />}
        {tab === DealerComponentTabs.Advanced && <DealerAdvancedForm initialValues={initialValues} />}
        {tab === DealerComponentTabs.BulkDealers && (
          <Stack sx={{ pb: '8px' }} spacing="16px">
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: t(I18nKeys.DealerDialogBulkImportHeader),
              }}
            />
            <ContentList
              items={[
                {
                  label: t(I18nKeys.DealerDialogBulkImportStep1),
                  content: (
                    <Box>
                      <Button variant="contained" onClick={() => exportBulkDealersTemplate(t)}>
                        {t(I18nKeys.DealerDialogBulkImportDownloadTemplate)}
                      </Button>
                    </Box>
                  ),
                },
                {
                  label: t(I18nKeys.DealerDialogBulkImportStep2),
                  content: (
                    <Box>
                      <DragAndDropUpload setFile={setBulkDealersFile} />
                      {bulkDealersErrorMessage && <FormHelperText error>{bulkDealersErrorMessage}</FormHelperText>}
                    </Box>
                  ),
                },
              ]}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button loading={loading} onClick={closeAndResetDialog} variant="outlined">
            {t(I18nKeys.DialogCloseButton)}
          </Button>
          <Button
            disabled={tab === DealerComponentTabs.BulkDealers && !bulkDealersFile}
            loading={loading}
            onClick={() => {
              if (tab === DealerComponentTabs.BulkDealers) {
                dispatch(setLoading(true));
                const errorMessage = validateSpreadsheetFile(bulkDealersFile);
                setBulkDealersErrorMessage(errorMessage);

                if (!errorMessage && bulkDealersFile) {
                  importBulkDealers(dialogClientId, groupId, configuratorLink, bulkDealersFile, dispatch, t)
                    .catch((e) => {
                      setBulkDealersErrorMessage(e.message);
                      return Promise.reject(e);
                    })
                    .then(() => closeAndResetDialog());
                }
                return;
              }
              dispatch(submit(tab === DealerComponentTabs.SingleDealer ? Forms.Dealer : Forms.DealerAdvanced));
            }}
            variant="contained"
          >
            {t(I18nKeys.DialogSaveButton)}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
