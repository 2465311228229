import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { NoteDialog } from './NoteDialog';
import { NoteDialog as MapNeededNoteDialog } from './map-needed/NoteDialog';
import { updateCellMetadata } from '../utils/clientDataUtils';
import { UpdateClientDataMetadata } from '../ducks/clientDataSlice';
import { Dialogs } from '../constants/Dialogs';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SalesViewUILayout } from '../constants/Viewer';

export const ClientDataNoteDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );
  const { clientDataBranch, clientDataType, selectedTable } = useAppSelector(
    (state: AppState) => state.clientData || {},
  );
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(selectedViewerId, clientDataType, selectedTable);
  const { cellMetadata } = useClientDataRepo({ useCellMetadata: true });
  const saveNote = (updates: UpdateClientDataMetadata[]) => {
    updateCellMetadata(clientDataTableId, updates, dispatch);
  };
  return layout === SalesViewUILayout.Horizon ? (
    <NoteDialog
      dialogKey={Dialogs.ClientDataNote}
      branch={clientDataBranch}
      cellMetadata={cellMetadata}
      saveNote={saveNote}
    />
  ) : (
    <MapNeededNoteDialog
      dialogKey={Dialogs.ClientDataNote}
      branch={clientDataBranch}
      cellMetadata={cellMetadata}
      saveNote={saveNote}
    />
  );
};
