import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { defaultAllowedHTMLTags } from '../constants/Input';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch } from '../hooks';

export const AllowedHTMLTagsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeDialog = (): void => {
    dispatch(closeDialogFunc());
  };

  return (
    <Dialog dialogKey={Dialogs.AllowedHtmlTags}>
      <DialogTitle>{t(I18nKeys.AllowedHTMLDialogTitle)}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing="16px">
          <Typography variant="body1">{t(I18nKeys.AllowedHTMLDialogContent)}</Typography>
          <Typography variant="body1">{defaultAllowedHTMLTags.map((tag) => `“${tag}”`).join(', ')}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="contained">
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
