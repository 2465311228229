import React from 'react';
import { Card } from '@mui/material';
import { Language, I18nKeys as ConfiguratorI18nKeys, PromotionalMessageType, UILayoutVersion } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { BannersAndMessagesFormFields, ColorsAndLogosFormFields } from '../../constants/FormFields';
import { BannersAndMessagesForm } from './BannersAndMessagesForm';
import { useVendorDataRepo } from '../../hooks/useVendorDataRepo';
import { i18n } from '../../i18n';
import { replaceTokens } from '../../utils/sitesUtils';
import { I18nKeys } from '../../constants/I18nKeys';

export const BannersAndMessages: React.FC<{ clientId: string }> = () => {
  const { t } = useTranslation();

  const {
    selectedTableData: [
      {
        layout,
        emailAddress: email = '',
        phoneNumber: phone = '',
        promoBannerType,
        desktopPromo1,
        desktopPromo2,
        mobilePromo1,
        mobilePromo2,
        showInformationalPanel,
        informationPanelTitle,
        informationPanelSubtitle,
        informationPanelMessage,
        deliveryMessageType,
        deliveryMessage,
        installationMessageType,
        installationMessage,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const { translations } = useVendorDataRepo({ useTranslations: true });
  const languageTranslations = translations.translation[i18n.language as Language];

  return (
    <SitesPage header="Banners & Messages">
      <Card sx={{ p: '24px' }} elevation={0}>
        <BannersAndMessagesForm
          initialValues={{
            [ColorsAndLogosFormFields.Layout]: layout || UILayoutVersion.Modern,
            [BannersAndMessagesFormFields.PromoBannerType]: promoBannerType || PromotionalMessageType.Standard,
            [BannersAndMessagesFormFields.StandardDesktopPromo]: replaceTokens(
              languageTranslations?.[ConfiguratorI18nKeys.BoostPromoHeaderContactTextWide],
              {
                email,
                phone,
              },
            ),
            [BannersAndMessagesFormFields.StandardMobilePromo]: replaceTokens(
              languageTranslations?.[ConfiguratorI18nKeys.BoostPromoHeaderContactTextNarrow],
              {
                email,
                phone,
              },
            ),
            [BannersAndMessagesFormFields.DesktopPromo1]:
              desktopPromo1 ||
              t(I18nKeys.SitesPromotionalBannerDefaultDesktopMessage, {
                email,
                phone,
              }),
            [BannersAndMessagesFormFields.MobilePromo1]:
              mobilePromo1 ||
              t(I18nKeys.SitesPromotionalBannerDefaultMobileMessage, {
                phone,
              }),
            [BannersAndMessagesFormFields.DesktopPromo2]: desktopPromo2 || '',
            [BannersAndMessagesFormFields.MobilePromo2]: mobilePromo2 || '',
            [BannersAndMessagesFormFields.ShowInformationalPanel]: !!showInformationalPanel,
            [BannersAndMessagesFormFields.InformationPanelTitle]: informationPanelTitle || '',
            [BannersAndMessagesFormFields.InformationPanelSubtitle]: informationPanelSubtitle || '',
            [BannersAndMessagesFormFields.InformationPanelMessage]: informationPanelMessage || '',
            [BannersAndMessagesFormFields.DeliveryMessageType]: deliveryMessageType || PromotionalMessageType.Standard,
            [BannersAndMessagesFormFields.StandardDeliveryMessage]: replaceTokens(
              languageTranslations?.[ConfiguratorI18nKeys.BoostDeliveryTo],
              {},
            ),
            [BannersAndMessagesFormFields.DeliveryMessage]: deliveryMessage || '',
            [BannersAndMessagesFormFields.InstallationMessageType]:
              installationMessageType || PromotionalMessageType.Standard,
            [BannersAndMessagesFormFields.StandardInstallationMessage]: replaceTokens(
              languageTranslations?.[ConfiguratorI18nKeys.InstallationMessage],
              {},
            ),
            [BannersAndMessagesFormFields.InstallationMessage]: installationMessage || '',
          }}
        />
      </Card>
    </SitesPage>
  );
};
