import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps &
  Omit<TextFieldProps, 'slotProps'> & {
    slotProps?: TextFieldProps['slotProps'];
  };

export const RenderSelectField: React.FC<Props> = ({
  input,
  children,
  slotProps,
  meta: { touched, error },
  ...customProps
}): JSX.Element => (
  <TextField
    /* eslint-disable react/jsx-props-no-spreading */
    {...customProps}
    {...input}
    helperText={touched && error}
    error={!!(touched && error)}
    select
    value={input.value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => input.onChange(event.target.value)}
    onBlur={(event: React.FocusEvent<HTMLInputElement>): void => input.onBlur(event.target.value)}
    slotProps={{
      select: {
        displayEmpty: true,
        autoWidth: true,
        ...slotProps?.select,
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          PaperProps: {
            style: {
              maxHeight: 500,
            },
          },
        },
      },
    }}
    margin="normal"
  >
    {children}
  </TextField>
);
