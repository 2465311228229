export enum ForgotPasswordFormFields {
  Email = 'EMAIL',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
  VerificationCode = 'VERIFICATION_CODE',
}

export enum SignInFormFields {
  Email = 'EMAIL',
  Password = 'PASSWORD',
}

export enum SignUpFormFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
}

export enum ClientSignUpFormFields {
  Email = 'EMAIL',
  Password = 'PASSWORD',
  ConfirmPassword = 'CONFIRM_PASSWORD',
  SupplierOrTemplate = 'SUPPLIER_OR_TEMPLATE',
  Industry = 'INDUSTRY',
  Type = 'TYPE',
}

export enum PasswordFormFields {
  CurrentPassword = 'CURRENT_PASSWORD',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
}

export enum ProfileFormFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER',
}

export enum PreferencesFormFields {
  Layout = 'LAYOUT',
  Theme = 'THEME',
  MiniMap = 'MINIMAP',
}

export enum ConfiguratorFormFields {
  Name = 'NAME',
  GroupId = 'GROUP_ID',
  Key = 'KEY',
  GenerateKey = 'GENERATE_KEY',
  Subdomain = 'SUBDOMAIN',
  Domain = 'DOMAIN',
  Industry = 'INDUSTRY',
  ExistingKey = 'EXISTING_KEY',
  Supplier = 'SUPPLIER',
  SupplierDataTemplate = 'SUPPLIER_DATA_TEMPLATE',
  VendorDataTemplate = 'VENDOR_DATA_TEMPLATE',
  Type = 'TYPE',
}

export enum UserDialogFormFields {
  NewUser = 'NEW_USER',
  GroupId = 'GROUP_ID',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  PhoneNumber = 'PHONE_NUMBER',
  Configurators = 'CONFIGURATORS',
  Dealers = 'DEALERS',
  Permissions = 'PERMISSIONS',
  LastLogin = 'LAST_LOGIN',
}

export enum BulkUserDialogFormFields {
  Emails = 'EMAILS',
  Dealers = 'DEALERS',
  Permissions = 'PERMISSIONS',
}

export enum DealerFormFields {
  ClientId = 'CLIENT_ID',
  DealerName = 'DEALER_NAME',
  City = 'CITY',
  State = 'STATE',
  ZipCode = 'ZIPCODE',
  PhoneNumber = 'PHONE_NUMBER',
  Key = 'KEY',
  Id = 'ID',
  CustomLogoUrl = 'CUSTOM_LOGO_URL',
  DealerUrl = 'DEALER_URL',
  HomeLinkUrl = 'HOME_LINK_URL',
  EmailAddress = 'EMAIL_ADDRESS',
  QuoteEmailReplyToSame = 'QUOTE_EMAIL_REPLY_TO_SAME',
  QuoteEmailReplyToAddress = 'QUOTE_EMAIL_REPLY_TO_ADDRESS',
  QuoteEmailCopySame = 'QUOTE_EMAIL_COPY_SAME',
  QuoteEmailCopyAddress = 'QUOTE_EMAIL_COPY_ADDRESS',
  DepositAmounts = 'DEPOSIT_AMOUNTS',
  IntegrationsKeySame = 'INTEGRATIONS_KEY_SAME',
  IntegrationsKey = 'INTEGRATIONS_KEY',
  // 'Advanced' fields
  Integration = 'INTEGRATION',
  ContactBarHtml = 'CONTACT_BAR_HTML ',
  EmailLogoUrl = 'EMAIL_LOGO_URL',
}

export enum WhatsNewDialogFormFields {
  ClientId = 'CLIENT_ID',
  Id = 'ID',
  Date = 'DATE',
  Title = 'TITLE',
  Message = 'MESSAGE',
  Readonly = 'READONLY',
}

export enum SceneEnvironmentDialogFormFields {
  ClientId = 'CLIENT_ID',
  Key = 'KEY',
  Label = 'LABEL',
  FileUrl = 'FILE_URL',
  PreviewUrl = 'PREVIEW_URL',
  MaxCameraDistanceMultiplier = 'MAX_CAMERA_DISTANCE_MULTIPLIER',
  MaxDiagonalBuildingLength = 'MAX_DIAGONAL_BUILDING_LENGTH',
}

export enum LanguageDialogFormFields {
  Language = 'LANGUAGE',
}

export enum I18nDialogFormFields {
  ClientId = 'CLIENT_ID',
  Language = 'LANGUAGE',
  Key = 'KEY',
  Value = 'VALUE',
  Override = 'OVERRIDE',
}

export enum ImpersonationDialogFormFields {
  ValidationCode = 'VALIDATION_CODE',
}

export enum ClientDataNoteDialogFormFields {
  Note = 'NOTE',
}

export enum ClientDataCreateBranchFields {
  Description = 'DESCRIPTION',
}

export enum ClientDataPublishFields {
  PreviewedChanges = 'PREVIEWED_CHANGES',
  CustomerIsAware = 'CUSTOMER_IS_AWARE',
  VerifiedQuotes = 'VERIFIED_QUOTES',
  Description = 'DESCRIPTION',
  PublishVendors = 'PUBLISH_VENDORS',
}

export enum ClientDataPreviewFields {
  Description = 'DESCRIPTION',
}

export enum PreviewFields {
  Description = 'DESCRIPTION',
}

export enum PricingSheetNameFormFields {
  SheetTitle = 'SHEET_TITLE',
}

export enum PricingBaseAddSizeFormFields {
  Width = 'WIDTH',
  Length = 'LENGTH',
}

export enum PublishFields {
  Description = 'DESCRIPTION',
}

export enum PricingComponentEditFields {
  Component = 'COMPONENT',
  Table = 'TABLE',
}

export enum SetupPaymentIntegrationFormFields {
  LegalEntityName = 'LEGAL_ENTITY_NAME',
  ShopperStatement = 'SHOPPER_STATEMENT',
}

export enum IntegrationsFormFields {
  Address1 = 'ADDRESS1',
  Address2 = 'ADDRESS2',
  City = 'CITY',
  State = 'STATE',
  PostalCode = 'POSTAL_CODE',
  Phone = 'PHONE',
  Country = 'COUNTRY',
  RowId = 'rowId',
  FacebookPixelId = 'facebookPixelId',
  GoogleAnalyticsId = 'analyticsTracker',
}

export enum BusinessInfoFormFields {
  Name = 'NAME',
  Address = 'ADDRESS',
  City = 'CITY',
  State = 'STATE',
  ZipCode = 'ZIP_CODE',
  PhoneNumber = 'PHONE_NUMBER',
  CompanyFax = 'COMPANY_FAX',
  HomeLinkUrl = 'HOME_LINK_URL',
  EmailAddress = 'EMAIL_ADDRESS',
  QuoteEmailFromAddressSame = 'QUOTE_EMAIL_FROM_ADDRESS_SAME',
  QuoteEmailFromAddress = 'QUOTE_EMAIL_FROM_ADDRESS',
  QuoteEmailCopyAddressSame = 'QUOTE_EMAIL_COPY_ADDRESS_SAME',
  QuoteEmailCopyAddress = 'QUOTE_EMAIL_COPY_ADDRESS',
}

export enum ColorsAndLogosFormFields {
  Layout = 'LAYOUT',
  LogoBarColor = 'LOGO_BAR_COLOR',
  LogoBarTextColor = 'LOGO_BAR_TEXT_COLOR',
  ContactBarColor = 'CONTACT_BAR_COLOR',
  ContactBarTextColor = 'CONTACT_BAR_TEXT_COLOR',
  SelectedTextColor = 'SELECTED_TEXT_COLOR',
  DefaultSceneEnvironment = 'DEFAULT_SCENE_ENVIRONMENT',
  LogoUrl = 'LOGO_URL',
  Watermark = 'WATERMARK',
  WatermarkText = 'WATERMARK_TEXT',
  ShowLogoOnMobile = 'SHOW_LOGO_ON_MOBILE',
  WatermarkPosition = 'WATERMARK_POSITION',
}

export enum DocumentsFormFields {
  CheckoutEmailFormat = 'CHECKOUT_EMAIL_FORMAT',
  QuoteEmailFormat = 'QUOTE_EMAIL_FORMAT',
  SaveEmailFormat = 'SAVE_EMAIL_FORMAT',
  PrintEmailFormat = 'PRINT_EMAIL_FORMAT',
  SendEmailsTo = 'SEND_EMAILS_TO',
  EmailLogoUrl = 'EMAIL_LOGO_URL',
  ShowLogoWatermarkUnderEstimate = 'SHOW_LOGO_WATERMARK_UNDER_ESTIMATE',
  EmailFloorPlan = 'EMAIL_FLOOR_PLAN',
  UseFloorplanFullPage = 'USE_FLOORPLAN_FULL_PAGE',
}

export enum ControlsAndFeaturesFormFields {
  DisableSharing = 'DISABLE_SHARING',
  DisableAR = 'DISABLE_AR',
  HideCenterComponentsButton = 'HIDE_CENTER_COMPONENTS_BUTTON',
  DisableSceneEnvironmentChange = 'DISABLE_SCENE_ENVIRONMENT_CHANGE',
  EnableDesignAutoSave = 'ENABLE_DESIGN_AUTO_SAVE',
}

export enum VisibilityFormFields {
  PricingVisibility = 'PRICING_VISIBILITY',
  PriceRangePercentage = 'PRICE_RANGE_PERCENTAGE',
  PricingVisibilityStaging = 'PRICING_VISIBILITY_STAGING',
  PricingEnableClientManaged = 'PRICING_ENABLE_CLIENT_MANAGED',
  PricingEnableClientUpdates = 'PRICING_ENABLE_CLIENT_UPDATES',
}

export enum FloorplanFormFields {
  UseFloorPlanGridDimensions = 'USE_FLOOR_PLAN_GRID_DIMENSIONS',
  FloorplanLabelingMethod = 'FLOORPLAN_LABELING_METHOD',
  FloorplanShowSideTexts = 'FLOORPLAN_SHOW_SIDE_TEXTS',
  UseFloorPlanGridDimensionsLegend = 'USE_FLOOR_PLAN_GRID_DIMENSIONS_LEGEND',
}

export enum LeadCaptureFormFields {
  LeadsEnableEmailPopup = 'LEADS_ENABLE_EMAIL_POPUP',
  LeadsPopupDelay = 'LEADS_POPUP_DELAY',
  LeadsPopupTitle = 'LEADS_POPUP_TITLE',
  LeadsPopupCallToActionText = 'LEADS_POPUP_CALL_TO_ACTION_TEXT',
  LeadsPopupMessage = 'LEADS_POPUP_MESSAGE',
  LeadsPopupCollectEmail = 'LEADS_POPUP_COLLECT_EMAIL',
  LeadsPopupCollectPhone = 'LEADS_POPUP_COLLECT_PHONE',
  LeadsPopupCollectName = 'LEADS_POPUP_COLLECT_NAME',
  LeadsPopupCollectZipCode = 'LEADS_POPUP_COLLECT_ZIPCODE',
  LeadsRequireForDesign = 'LEADS_REQUIRE_FOR_DESIGN',
}

export enum BannersAndMessagesFormFields {
  PromoBannerType = 'PROMO_BANNER_TYPE',
  StandardDesktopPromo = 'STANDARD_DESKTOP_PROMO',
  StandardMobilePromo = 'STANDARD_MOBILE_PROMO',
  DesktopPromo1 = 'DESKTOP_PROMO1',
  MobilePromo1 = 'MOBILE_PROMO1',
  DesktopPromo2 = 'DESKTOP_PROMO2',
  MobilePromo2 = 'MOBILE_PROMO2',
  ShowInformationalPanel = 'SHOW_INFORMATIONAL_PANEL',
  InformationPanelTitle = 'INFORMATION_PANEL_TITLE',
  InformationPanelSubtitle = 'INFORMATION_PANEL_SUBTITLE',
  InformationPanelMessage = 'INFORMATION_PANEL_MESSAGE',
  DeliveryMessageType = 'DELIVERY_MESSAGE_TYPE',
  StandardDeliveryMessage = 'STANDARD_DELIVERY_MESSAGE',
  DeliveryMessage = 'DELIVERY_MESSAGE',
  InstallationMessageType = 'INSTALLATION_MESSAGE_TYPE',
  StandardInstallationMessage = 'STANDARD_INSTALLATION_MESSAGE',
  InstallationMessage = 'INSTALLATION_MESSAGE',
}
