import { makeStyles } from '@mui/styles';
import { Typography, Grid2 as Grid, FormControl } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { required } from '../utils/reduxFormUtils';
import { InputField } from './redux-form/InputField';
import { ProfileFormFields } from '../constants/FormFields';
import { useAppDispatch } from '../hooks';
import { Button } from './library/Button';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

export interface FormData {
  [ProfileFormFields.FirstName]: string | undefined;
  [ProfileFormFields.LastName]: string | undefined;
  [ProfileFormFields.Email]: string | undefined;
  [ProfileFormFields.PhoneNumber]: string | undefined;
}

type FormProps = InjectedFormProps<FormData>;

const ProfileFormComponent: React.FC<FormProps> = ({ error, handleSubmit, submitting }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const onSubmit = (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Profile}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="24px">
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              autoComplete="given-name"
              validate={required}
              variant="filled"
              name={ProfileFormFields.FirstName}
              component={InputField}
              label={`${t(I18nKeys.FieldFirstName)}*`}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              autoComplete="family-name"
              validate={required}
              variant="filled"
              name={ProfileFormFields.LastName}
              component={InputField}
              label={`${t(I18nKeys.FieldLastName)}*`}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              disabled
              validate={required}
              variant="filled"
              name={ProfileFormFields.Email}
              component={InputField}
              label={`${t(I18nKeys.FieldEmail)}*`}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              variant="filled"
              name={ProfileFormFields.PhoneNumber}
              component={InputField}
              label={t(I18nKeys.ProfilePhoneNumber)}
            />
          </FormControl>
        </Grid>

        {!!error && <Typography className={classes.error}>{error}</Typography>}

        <Button
          key="submit-profile-form-btn"
          type="submit"
          variant="outlined"
          loading={submitting}
          disabled={submitting}
        >
          {t(I18nKeys.ProfileUpdateProfile)}
        </Button>
      </Grid>
    </Form>
  );
};

export const ProfileForm = reduxForm<FormData>({
  form: Forms.Profile,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(connect()(ProfileFormComponent));
