import { Chip, Divider, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Permission } from '@idearoom/types';
import { InputField } from './InputField';
import { BulkUserDialogFormFields, PreferencesFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { getPermissionLabel } from '../../constants/Permissions';
import { required } from '../../utils/reduxFormUtils';
import { AppState } from '../../types/AppState';
import { Dealer } from '../../types/Dealer';
import { isCurrentUserGroupAdmin, isIdeaRoomUser } from '../../utils/userUtils';
import { I18nKeys } from '../../constants/I18nKeys';
import { UserPreference } from '../../constants/User';
import { useAppSelector } from '../../hooks';
import { RenderSelectField } from './RenderSelectField';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: '15px',
    width: '100%',
  },
});

const getDealerLabel = (availableDealers: Dealer[], dealerKey: string): string => {
  const dealer = availableDealers.find((availableDealer) => availableDealer.key === dealerKey);
  return dealer ? dealer.name : dealerKey;
};

export interface FormData {
  [BulkUserDialogFormFields.Emails]: string[];
  [BulkUserDialogFormFields.Permissions]: string[];
}

interface FormDispatchProps {
  onSubmit(data: FormData): Promise<AnyAction>;
}

interface StateProps {
  newMember: boolean;
  availableDealers: Dealer[];
  adminUser: boolean;
  idearoomSuperUser: boolean;
}

type FormProps = FormDispatchProps & StateProps & InjectedFormProps<FormData>;

const BulkUserFormComponent: React.FC<FormProps> = ({
  newMember,
  availableDealers,
  adminUser,
  idearoomSuperUser,
  error,
  handleSubmit,
  onSubmit,
}: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state: AppState) =>
      state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={!newMember}
        autoFocus={newMember}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        validate={required}
        label={`${t(I18nKeys.BulkUserFormUsernamesLabel)}*`}
        name={BulkUserDialogFormFields.Emails}
      />

      <Field
        autoFocus={!newMember}
        autoComplete="off"
        multiple
        className={classes.field}
        component={RenderSelectField}
        validate={required}
        label={`${t(I18nKeys.FieldPermissions)}*`}
        name={BulkUserDialogFormFields.Permissions}
        variant="filled"
        slotProps={{
          select: {
            displayEmpty: true,
            multiple: true,
            renderValue: (selectedValues: unknown): JSX.Element => {
              const selectedPermissions = selectedValues as Permission[];

              if (selectedPermissions.length > 0) {
                selectedPermissions.sort();
                return (
                  <div>
                    {(selectedValues as Permission[]).map((selectedValue) => (
                      <Chip
                        style={{ margin: '0px 2px' }}
                        key={selectedValue}
                        label={getPermissionLabel(selectedValue, t)}
                      />
                    ))}
                  </div>
                );
              }

              return <div style={{ height: '18px' }} />;
            },
          },
        }}
      >
        {idearoomSuperUser && (
          <MenuItem key="super-user-permission" value={Permission.SuperUser}>
            {getPermissionLabel(Permission.SuperUser, t)}
          </MenuItem>
        )}

        {adminUser && (
          <MenuItem key="admin-permission" value={Permission.Admin}>
            {getPermissionLabel(Permission.Admin, t)}
          </MenuItem>
        )}

        <MenuItem key="sales-tools-permission" value={Permission.Manager}>
          {getPermissionLabel(Permission.Manager, t)}
        </MenuItem>

        <MenuItem key="sales-view-permission" value={Permission.Sales}>
          {getPermissionLabel(Permission.Sales, t)}
        </MenuItem>
      </Field>

      {availableDealers.length > 0 && (
        <Field
          autoFocus={!newMember}
          autoComplete="off"
          className={classes.field}
          component={RenderSelectField}
          label={t(I18nKeys.BulkUserFormDealerLabel)}
          name={BulkUserDialogFormFields.Dealers}
          variant="filled"
          slotProps={{
            select: {
              displayEmpty: true,
              renderValue: (selectedValues: unknown): JSX.Element => {
                const selectedDealer = selectedValues as string;

                if (selectedDealer) {
                  return (
                    <div style={{ margin: '0px 2px' }} key={selectedDealer}>
                      {getDealerLabel(availableDealers, selectedDealer)}
                    </div>
                  );
                }

                return <div style={{ height: '18px' }} />;
              },
            },
          }}
        >
          {adminUser && (
            <>
              <MenuItem key="" value="">
                {t(I18nKeys.BulkUserFormAllDealersLabel)}
              </MenuItem>
              <Divider />
            </>
          )}
          {availableDealers.map((dealer) => (
            <MenuItem key={dealer.key} value={dealer.key}>
              {dealer.name}
            </MenuItem>
          ))}
        </Field>
      )}

      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.BulkUser}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = ({
  group: { newMember, group },
  currentUser: { user, group: usersGroup, availableDealers = [] },
}: AppState): StateProps => {
  const adminUser = isCurrentUserGroupAdmin(user, usersGroup, group);
  const idearoomSuperUser =
    (isIdeaRoomUser(user) && (user?.email.startsWith('bkelley') || user?.email.startsWith('nperalta'))) || false;

  return {
    newMember,
    availableDealers,
    adminUser,
    idearoomSuperUser,
  };
};

export const BulkUserForm = reduxForm<FormData>({ form: Forms.BulkUser, enableReinitialize: true })(
  connect(mapStateToProps, mapDispatchToProps)(BulkUserFormComponent),
);
