import { createTheme } from '@mui/material';
import { SalesViewUILayout } from './constants/Viewer';

declare module '@mui/material/styles' {
  interface Palette {
    diffAdd: Palette['primary'];
    diffRemove: Palette['primary'];
  }
  interface PaletteOptions {
    diffAdd: PaletteOptions['primary'];
    diffRemove: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonGray: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonGray: true;
  }
}

const { palette } = createTheme();
export const theme = (
  layout: SalesViewUILayout,
  selectedTextColor = '#F36F3A',
  contactBarColor = '#FFFFFF',
  contactBarTextColor = '#FFFFFF',
): any => ({
  palette: {
    primary: {
      main: selectedTextColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: layout === SalesViewUILayout.Horizon ? '#3787FF' : contactBarColor,
      contrastText: layout === SalesViewUILayout.Horizon ? '#FFFFFF' : contactBarTextColor,
    },
    buttonGray: palette.augmentColor({
      color: {
        main: '#000000DE',
      },
    }),
    diffAdd: {
      main: 'rgba(56, 118, 30, 0.20)',
    },
    diffRemove: {
      main: 'rgba(176, 0, 32, 0.20)',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          background: '$labelcolor',
        },
      },
    },
    MuiCollapse: {
      hidden: {
        display: 'none',
      },
    },
  },
  // UI Layout Remove Me
  ...(layout === SalesViewUILayout.Horizon
    ? {
        fontFamily: 'Inter, sans-serif !important',
        typography: {
          body1: {
            fontSize: '16px',
            color: '#323B4B',
          },
        },
      }
    : {}),
  transitions: {
    duration: {
      enteringScreen: 250,
      leavingScreen: 250,
    },
  },
});

// this could be pushed into the mui text: disabled but would need a little refactoring
export const disabled = 0.4;
