/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControlLabel, Radio, RadioProps, Typography } from '@mui/material';
import { ContentSkeleton } from '../library/ContentSkeleton';

type Props = RadioProps & { initializing?: boolean; label: string };

export const RadioField: React.FC<Props> = ({
  initializing = false,
  label,
  value,
  ...customProps
}: Props): JSX.Element => (
  <FormControlLabel
    sx={{ mx: '0px' }}
    value={value}
    control={
      <ContentSkeleton sx={{ my: '4px', mr: '12px' }} initializing={initializing} variant="circular">
        <Radio {...customProps} value={value} sx={{ p: '0px !important', ...customProps?.sx }} />
      </ContentSkeleton>
    }
    label={
      <ContentSkeleton initializing={initializing}>
        <Typography>{label}</Typography>
      </ContentSkeleton>
    }
  />
);
