/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps, Skeleton } from '@mui/material';
import React from 'react';

export const ContentSkeleton: React.FC<
  BoxProps & {
    initializing?: boolean;
    variant?: 'text' | 'rectangular' | 'rounded-rectangular' | 'circular' | 'input';
    height?: string;
    width?: string;
  }
> = ({ initializing, variant = 'text', children, height, width, ...props }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box height={height || contentRef.current?.offsetHeight} {...props}>
      {initializing && (
        <Skeleton
          variant={variant === 'circular' ? 'circular' : 'rectangular'}
          height={height || contentRef.current?.offsetHeight}
          width={variant !== 'input' ? width || contentRef.current?.offsetWidth : '100%'}
          sx={{
            ...(variant === 'rectangular' ? { borderRadius: '0px' } : {}),
            ...(variant === 'rounded-rectangular' ? { borderRadius: '4px' } : {}),
            ...(variant === 'text' ? { borderRadius: '2px' } : {}),
            ...(variant === 'input' ? { borderTopRightRadius: '4px', borderTopLeftRadius: '4px' } : {}),
          }}
        />
      )}
      <Box
        ref={contentRef}
        sx={{
          display: 'flex',
          visibility: initializing ? 'hidden' : 'visible',
          height,
          width: variant !== 'input' ? width || 'fit-content' : '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
