import { Box, Collapse, List, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { mainMenu } from '../constants/MenuItems';
import { MenuVariant } from '../constants/Menu';
import { unknownGroup } from '../constants/Group';
import { unknownUser } from '../types/User';
import { useAppDispatch, useAppSelector } from '../hooks';
import { isIdeaRoomGroup as isIdeaRoomGroupFunc } from '../utils/userUtils';
import { MainMenuItem } from './MainMenuItem';
import { setMainMenuItem, setSubMenuItem } from '../middleware/menuThunk';
import { Menu } from './Menu';
import { MainMenuDivider } from './MainMenuDivider';
import { UserMenuSection } from './UserMenuSection';
import { MainMenuItems } from '../constants/MainMenuItems';
import { useCompleteSetupStepMutation, useGetGroupSetupStepsQuery } from '../services/groupApi';
import { GettingStartedSteps } from '../constants/gettingStartedConfig';

const Spacer = styled(Box)({
  height: '8px',
  borderRight: '1px solid #DEDEDE',
});

export const MainMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { mainMenuItem, subMenuItem, variant } = useAppSelector((state) => state.menu);
  const currentUserState = useAppSelector((state) => state.currentUser);
  const { group = unknownGroup, user = unknownUser, availableDealers = [] } = currentUserState;
  const viewerState = useAppSelector((state) => state.viewer);
  const { selectedClientId = '' } = viewerState;

  const [completeSetupStep] = useCompleteSetupStepMutation();

  let groupConfigurators = group.configurators || [];
  const userDealers = user.dealers || [];

  if (userDealers.length && groupConfigurators.length) {
    if (userDealers.length === 1) {
      groupConfigurators = groupConfigurators.map((config: any) => {
        const dealer = availableDealers.find(
          (deal) => deal.clientId === `${config.key}-${config.vendor}` && userDealers.includes(deal.key),
        );
        return {
          ...config,
          url: dealer ? `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}` : config.url,
        };
      });
    } else {
      // Loop through each dealer and add the dealer key to the configurator url and set the name as the dealer name
      groupConfigurators = groupConfigurators.reduce((acc: any[], config: any) => {
        const dealers = availableDealers.filter(
          (dealer) => dealer.clientId === `${config.key}-${config.vendor}` && userDealers.includes(dealer.key),
        );
        return [
          ...acc,
          ...(dealers && dealers.length
            ? dealers.map((dealer) => ({
                ...config,
                url: `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}`,
                name: dealer.name,
              }))
            : [config]),
        ];
      }, []);
    }
  }

  const { groupId } = useAppSelector((state) => state?.currentUser.group || unknownGroup) || {};
  const isDayOneClient = groupConfigurators?.[0]?.vendorData?.vendor?.maxioSubscriptionId;
  const { data: { steps: gettingStartedSteps = [] } = {} } = useGetGroupSetupStepsQuery(
    {
      clientId: selectedClientId || '',
      groupId,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: false, skip: !selectedClientId || !groupId || !isDayOneClient },
  );
  const shouldCompleteLeadsStep =
    isDayOneClient &&
    gettingStartedSteps.find((step) => step.key === GettingStartedSteps.VIEW_LEADS_LIST)?.completed === false;

  const isIdearoomGroup = isIdeaRoomGroupFunc(group.groupId);
  const selectedGroupId = group.groupId;

  return (
    <Menu>
      <List disablePadding id="menu-list-items-container">
        <UserMenuSection />
        <MainMenuDivider sx={{ pt: '16px' }} />
        {mainMenu.map((section, i, menuItems) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={i} sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
            {section
              .filter(({ visible }) => visible(user, isIdearoomGroup, groupConfigurators, selectedGroupId, viewerState))
              .map(({ id, ideaRoomOnly, label, primaryIcon, secondaryIcon, subMenu }, j, sectionItems) => {
                const subMenuItems = subMenu?.(dispatch, currentUserState) || [];
                const subMenuSelected = subMenuItems.some((item) => item.id === subMenuItem);
                return (
                  <Box key={id}>
                    <MainMenuItem
                      id={`${id}-menu-item`}
                      label={label(t)}
                      onClick={() => {
                        if (id === MainMenuItems.Help) window.Intercom('show');
                        if (id === MainMenuItems.Leads && shouldCompleteLeadsStep) {
                          completeSetupStep({
                            groupId: selectedGroupId,
                            setupStepId: GettingStartedSteps.VIEW_LEADS_LIST,
                          });
                        } else dispatch(setMainMenuItem(id));
                      }}
                      ideaRoomOnly={ideaRoomOnly(user, selectedGroupId, viewerState)}
                      primaryIcon={primaryIcon?.(viewerState)}
                      secondaryIcon={secondaryIcon?.(viewerState)}
                      variant={variant}
                      selected={(!subMenuSelected || variant === MenuVariant.MINI) && mainMenuItem === id}
                      highlighted={mainMenuItem === id}
                    />
                    {variant === MenuVariant.STANDARD && (
                      <Collapse in={mainMenuItem === id} timeout="auto" unmountOnExit>
                        {subMenuItems.map(
                          ({ id: subMenuId, ideaRoomOnly: subMenuIdeaRoomOnly, label: nestedLabel }) => (
                            <MainMenuItem
                              key={subMenuId}
                              id={subMenuId}
                              label={nestedLabel(t)}
                              onClick={() => {
                                dispatch(setSubMenuItem(subMenuId));
                              }}
                              ideaRoomOnly={subMenuIdeaRoomOnly?.(user, selectedGroupId, viewerState)}
                              variant={variant}
                              selected={subMenuItem === subMenuId}
                              highlighted={subMenuItem === subMenuId}
                            />
                          ),
                        )}
                        {!!subMenuItems.length && <Spacer />}
                      </Collapse>
                    )}
                    {i < menuItems.length - 1 && j === sectionItems.length - 1 && <MainMenuDivider />}
                  </Box>
                );
              })}
          </Box>
        ))}
      </List>
    </Menu>
  );
};
