import React from 'react';
import { formValueSelector } from 'redux-form';
import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingSheetNameForm, FormData } from './PricingSheetNameForm';
import { PricingSheetNameFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { getPricingSheetDefaultLabel, getPricingSheetTable, updateSheetTitle } from '../utils/pricingSheetUtils';
import { PricingSheet } from '../types/PricingSheet';
import { PricingSheetAttributeType } from '../constants/PricingSheetAttributeType';
import { kebabCaseToTitleCase } from '../utils/stringUtils';
import { ContentList } from './library/ContentList';

export const PricingSheetNameDialog: React.FC<{ selectedPricingSheet?: PricingSheet }> = ({ selectedPricingSheet }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedPricingTabId: pricingTab = '' } = useAppSelector((state) => state.viewer);
  const { clientId } = useAppSelector((state) => state.clientData);
  const initialSheetName =
    selectedPricingSheet?.priceSetLabel || getPricingSheetDefaultLabel(selectedPricingSheet, pricingTab);
  const initialValues = {
    [PricingSheetNameFormFields.SheetTitle]: initialSheetName,
  } as FormData;

  const placeholder = `Pricing Sheet ${selectedPricingSheet?.id || ''}`;
  const currentSheetName = useAppSelector((state: AppState) =>
    formValueSelector(Forms.PricingSheetName)(state, PricingSheetNameFormFields.SheetTitle),
  );

  const saveDisabled = currentSheetName === initialSheetName;

  const onSave = () => {
    if (selectedPricingSheet) {
      updateSheetTitle(
        currentSheetName,
        getPricingSheetTable(clientId, pricingTab, selectedPricingSheet.category),
        selectedPricingSheet,
        dispatch,
      );
      dispatch(closeDialogFunc());
    }
  };

  return (
    <Dialog dialogKey={Dialogs.PricingSheetName}>
      <DialogTitle>{t(I18nKeys.PricingBaseSheetNameDialogTitle)}</DialogTitle>
      <DialogContent>
        <PricingSheetNameForm placeholder={placeholder} initialValues={initialValues} />
        {selectedPricingSheet?.attributes
          .filter(({ hide }) => !hide)
          .map(({ value, type, label }) => {
            const count = `${value}`.split(',').length;

            const displayAsList = !(
              [PricingSheetAttributeType.CustomExpression, PricingSheetAttributeType.SingleSlope] as string[]
            ).includes(type);
            const header = displayAsList
              ? `${count} ${kebabCaseToTitleCase(type).toLowerCase()}${count > 1 ? 's' : ''}`
              : label;

            return (
              <Stack key={type} spacing="4px">
                <Typography sx={{ fontWeight: 'bold' }}>{header}</Typography>
                {displayAsList && (
                  <ContentList
                    listStyleType="disc"
                    spacing="0px"
                    sx={{ pl: '36px' }}
                    items={[
                      {
                        label: `${label || value}`,
                      },
                    ]}
                  />
                )}
              </Stack>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSave} variant="contained" disabled={saveDisabled}>
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
