import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import pLimit from 'p-limit';

import { Dialogs } from '../constants/Dialogs';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppDispatch, useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { closeDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { ClientDataType } from '../constants/ClientDataType';
import { unknownGroup } from '../constants/Group';
import { portalApi } from '../services/portalApi';
import { Button } from './library/Button';
import { SelectField } from './library/SelectField';

// Possible YAGNI but keeping this piece for possible internal-only feature
const BranchSelector: React.FC<{
  type: ClientDataType;
  branch: ClientDataBranch;
  setBranch: (newBranch: ClientDataBranch) => void;
}> = ({ type, branch, setBranch }) => {
  const allBranches = Object.values(ClientDataBranch);

  const mapOldBranchToNewLabel = new Map<string, string>([
    [ClientDataType.Vendor, 'Client'],
    [ClientDataType.Supplier, 'Structure'],
    [ClientDataType.Reference, 'System'],
  ]);

  const id = `${type}-selector`;
  const labelId = `${type}-label`;
  const newLabel = mapOldBranchToNewLabel.get(type);
  const inputLabel = `${newLabel} (${type}) Branch`;

  return (
    <SelectField
      labelId={labelId}
      id={id}
      label={inputLabel}
      value={branch}
      onChange={(event) => setBranch(event.target.value as ClientDataBranch)}
    >
      {allBranches.map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </SelectField>
  );
};

// simple state flags for UI transitions
const [INIT_SELECT_VENDOR, RUNNING, COMPLETE, ERROR, NOQUOTES] = [
  'INIT_SELECT_VENDOR',
  'RUNNING',
  'COMPLETE',
  'ERROR',
  'NOQUOTES',
];

// pLimit(NUM) combined with Promise API allows us to set a precise limit of NUM concurrent running functions
const limit = pLimit(3);

export const ClientDataVerifiedQuotesDialog: React.FC = () => {
  const { t } = useTranslation();
  const { clientId, clientDataBranch, clientDataType } = useAppSelector((state) => state.clientData);
  const dispatch = useAppDispatch();

  const open = useAppSelector(({ dialog: { key } }) => key === Dialogs.VerifiedQuotes);

  const { group = unknownGroup, user } = useAppSelector((state) => state?.currentUser);
  const requestorEmail = user?.email || user?.username || 'unknown user'; // TODO: what happens with no user?
  const { configurators: configs = [] } = group;

  const [quotesToVerify, setQuotesToVerify] = useState<string[]>([]);
  const [numCompleted, setNumCompleted] = useState(0);
  const [priceDiffCount, setPriceDiffCount] = useState(0);
  const [invalidConfigurationCount, setInvalidConfigurationCount] = useState(0);
  const [newQuoteCount, setNewQuoteCount] = useState(0);
  const [quoteMgrLink, setQuoteMgrLink] = useState('');

  const vendorClientIds = configs.map((c) => c.clientId).filter((id): id is string => !!id);
  const multipleVendors = vendorClientIds.length > 1;

  // Start with current client, then enable switching for multi-clients
  const [clientIdToVerify, setClientIdToVerify] = useState<string>(clientId);
  useEffect(() => {
    setClientIdToVerify(clientId);
  }, [clientId]);

  const [runState, setRunState] = useState(INIT_SELECT_VENDOR);
  useEffect(() => {
    // This is just for debugging
    console.debug(`runState = ${runState}`);
  }, [runState]);

  // Assume unpublished for VQ, backend will fallback to main if unpublished does not exist.
  const [clientBranch, setClientBranch] = useState(ClientDataBranch.Unpublished);
  const [structureBranch, setStructureBranch] = useState(ClientDataBranch.Unpublished);
  const [systemBranch, setSystemBranch] = useState(ClientDataBranch.Unpublished);
  // Keeping these actually synced right while the page loads
  useEffect(() => {
    if (clientDataBranch) {
      if (clientDataType === ClientDataType.Vendor) {
        setClientBranch(clientDataBranch);
      } else if (clientDataType === ClientDataType.Supplier) {
        setStructureBranch(clientDataBranch);
      } else if (clientDataType === ClientDataType.Reference) {
        setSystemBranch(clientDataBranch);
      }
    }
  }, [clientDataType, clientDataBranch]);

  const actionCloseDialog = () => dispatch(closeDialog());
  const actionCancel = () => {
    setTimeout(() => setRunState(INIT_SELECT_VENDOR), 100); // anti-flash measure
    setNumCompleted(0);
    setPriceDiffCount(0);
    setInvalidConfigurationCount(0);
    setNewQuoteCount(0);
    limit.clearQueue(); // dump requests that haven't started yet
  };

  // Reset based on open state and other critical data
  useEffect(() => {
    if (!open) {
      setRunState(INIT_SELECT_VENDOR);
    }
  }, [open, clientId, clientDataBranch, multipleVendors]);

  // Gather quotes.
  useEffect(() => {
    if (runState !== INIT_SELECT_VENDOR) return;
    if (!clientIdToVerify) return;

    // Load up the manager link (temp until SV manager built) then load quote hashes
    const fetchData = async () => {
      const linkRes = await dispatch(portalApi.endpoints.getVerifiedQuotesMgmtLink.initiate()).unwrap();
      if (!('link' in linkRes)) {
        throw new Error(linkRes.message);
      }

      // Embed clientId as params, enable auto-search of quote data for current client
      const updatedUrl = new URL(linkRes.link);
      updatedUrl.search = `cid=${clientIdToVerify}`;
      setQuoteMgrLink(updatedUrl.toString());

      const res = await dispatch(portalApi.endpoints.getVerifiedQuotes.initiate(clientIdToVerify)).unwrap();
      if ('error' in res) {
        throw new Error(res.error);
      }

      if ('quoteList' in res && res.clientId === clientIdToVerify && res.quoteList.length > 0) {
        console.log(`Quotes updated, count=${res.quoteList.length}`);
        setQuotesToVerify(res.quoteList);
      } else {
        setRunState(NOQUOTES);
      }
    };

    fetchData().catch(() => {
      setRunState(ERROR);
    });
  }, [clientIdToVerify, runState]);

  // Core quote running logic
  const runQuotes = async () => {
    if (runState !== INIT_SELECT_VENDOR) return;
    setRunState(RUNNING);
    const postRunSingleQuote = async (hash: string) => {
      const response = await dispatch(
        portalApi.endpoints.runVerifiedQuote.initiate({
          clientId,
          hash,
          requestorEmail,
          clientBranch,
          structureBranch,
          systemBranch,
        }),
      ).unwrap();
      return response;
    };

    let abort = false; // Abort on error. Otherwise wasted time waiting for error dialog anyway.
    const quoteRequests = quotesToVerify.map(async (hash) =>
      limit(async () => {
        if (abort) {
          return;
        }
        await postRunSingleQuote(hash)
          .then((res) => {
            // TODO FIXME: fix return type to single error case
            if ('error' in res || 'message' in res) {
              console.error(res);
            } else if ('invalidConfigurationDetected' in res) {
              setInvalidConfigurationCount((count) => count + 1);
            } else if (res.priceDiffDetected) {
              setPriceDiffCount((count) => count + 1);
            } else if (res.newQuoteDetected) {
              setNewQuoteCount((count) => count + 1);
            }
          })
          .catch(() => {
            // no need to log - will show in console anyway
            abort = true;
          })
          .finally(() => setNumCompleted((prev) => prev + 1));
      }),
    );

    await Promise.allSettled(quoteRequests);

    if (abort) {
      setRunState(ERROR);
      return;
    }

    setRunState(COMPLETE);
  };

  if (!clientId) return null;

  // Important for handling clients with multiple vendors
  const clientIdToName = new Map<string, string>();
  configs.forEach((configurator) => {
    if (!configurator.clientId) {
      console.warn(`configurator.clientId undefined`);
      return;
    }
    if (!configurator.name) {
      console.warn(`configurator.name undefined`);
      return;
    }
    clientIdToName.set(configurator.clientId, configurator.name);
  });

  const clientName = clientIdToName.get(clientIdToVerify as string);
  const noMismatch = priceDiffCount === 0;
  const noInvalid = invalidConfigurationCount === 0;
  const allMatch = noInvalid && noMismatch;

  const uiBranchSelect = (
    <>
      <Typography>Pick your data branches to verify with:</Typography>
      <BranchSelector branch={clientBranch} setBranch={setClientBranch} type={ClientDataType.Vendor} />
      <BranchSelector branch={structureBranch} setBranch={setStructureBranch} type={ClientDataType.Supplier} />
      <BranchSelector branch={systemBranch} setBranch={setSystemBranch} type={ClientDataType.Reference} />
    </>
  );

  return (
    <Dialog
      dialogKey={Dialogs.VerifiedQuotes}
      maxWidth="xs"
      fullWidth
      onClosed={actionCloseDialog}
      onCloseAnimationComplete={actionCancel}
      disableClose={runState === RUNNING}
    >
      <DialogTitle>{t(I18nKeys.VerifiedQuotesDialogTitle)}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        {runState === INIT_SELECT_VENDOR && (
          <SelectField
            disabled={multipleVendors}
            labelId={t(I18nKeys.VerifiedQuotesVendorSelectTitle)}
            label={t(I18nKeys.VerifiedQuotesVendorSelectTitle)}
            value={clientIdToVerify}
            onChange={(event) => {
              const { value } = event.target;
              if (!value || typeof value !== 'string') return;
              setClientIdToVerify(value);
            }}
          >
            {vendorClientIds.map((value) => (
              <MenuItem key={value} value={value}>
                {clientIdToName.get(value) || value}
              </MenuItem>
            ))}
          </SelectField>
        )}
        {runState === NOQUOTES && (
          <Typography variant="subtitle1">
            <Trans
              // @ts-expect-error not ideal to use Trans component but only way we can do link.
              i18nKey={I18nKeys.VerifiedQuotesDialogNoQuotesBody}
              components={{
                // This is the confluence wiki link for "Follow the instructions" when no
                // quotes exist and this comment is for searchability
                anchor: <Link href={t(I18nKeys.VerifiedQuotesDialogInternalHelpLink)} target="_blank" />,
                br: <br />,
              }}
            />
          </Typography>
        )}
        {runState === RUNNING && (
          <Stack direction="row" spacing="16px">
            <Box>
              <CircularProgress color="secondary" size={30} sx={{ p: '2px' }} />
            </Box>
            <Stack direction="column" spacing="8px">
              <Typography variant="subtitle1" paddingBottom={1}>
                {t(I18nKeys.VerifiedQuotesDialogInProgressTitle, { clientName })}
              </Typography>
              <Typography variant="body2">
                {t(I18nKeys.VerifiedQuotesDialogInProgressBody, {
                  complete: numCompleted,
                  total: quotesToVerify.length,
                })}
              </Typography>
            </Stack>
          </Stack>
        )}
        {runState === ERROR && (
          <Stack direction="row" spacing="16px">
            <Box>
              <CancelIcon sx={{ color: 'error.dark', width: '30px', height: '30px' }} />
            </Box>
            <Stack direction="column" spacing="8px">
              <Typography variant="subtitle1">{t(I18nKeys.VerifiedQuotesDialogErrorTitle)}</Typography>
              <Typography variant="body1">{t(I18nKeys.VerifiedQuotesDialogErrorBody)}</Typography>
            </Stack>
          </Stack>
        )}
        {runState === COMPLETE && (
          <Stack direction="row" spacing="16px">
            <Box>
              {allMatch ? (
                <CheckCircleIcon sx={{ color: 'success.dark', width: '30px', height: '30px' }} />
              ) : (
                <CancelIcon sx={{ color: 'error.dark', width: '30px', height: '30px' }} />
              )}
            </Box>
            <Stack direction="column" spacing="8px">
              {allMatch && (
                <Typography variant="subtitle1">
                  {t(I18nKeys.VerifiedQuotesDialogCompleteBodyAllMatch, { clientName, completedCount: numCompleted })}
                </Typography>
              )}
              {noInvalid ? (
                <Typography variant="subtitle1">
                  {t(I18nKeys.VerifiedQuotesDialogCompleteBodySomeMismatch, {
                    clientName,
                    differentCount: priceDiffCount,
                    completedCount: numCompleted,
                  })}
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  {t(I18nKeys.VerifiedQuotesDialogCompleteBodySomeInvalid, {
                    clientName,
                    invalidCount: invalidConfigurationCount,
                    differentCount: priceDiffCount,
                    completedCount: numCompleted,
                  })}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {[INIT_SELECT_VENDOR, RUNNING, COMPLETE].includes(runState) ? (
          <Button variant="outlined" onClick={actionCloseDialog}>
            {t(I18nKeys.DialogCloseButton)}
          </Button>
        ) : (
          <Button variant="contained" onClick={actionCloseDialog}>
            {t(I18nKeys.DialogGotItButton)}
          </Button>
        )}
        {runState === INIT_SELECT_VENDOR && (
          <Button variant="contained" disabled={runState === RUNNING} onClick={runQuotes}>
            {t(I18nKeys.VerifiedQuotesVerify)}
          </Button>
        )}
        {runState === COMPLETE && (
          <Button variant="contained" sx={{ target: '_blank' }} href={quoteMgrLink}>
            {t(I18nKeys.VerifiedQuotesViewReport)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
