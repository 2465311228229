import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { PreviewDialog } from './PreviewDialog';
import { Dialogs } from '../constants/Dialogs';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { useAppSelector } from '../hooks';
import { SalesViewUILayout } from '../constants/Viewer';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { PreviewDialog as MapNeededPreviewDialog } from './map-needed/PreviewDialog';

export const PricingSurchargePreviewDialog: React.FC = () => {
  const { t } = useTranslation();
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });
  const branch = activeBranches.find((metadata) => metadata.branchType === ClientDataBranch.PricingSurcharge);

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  return layout !== SalesViewUILayout.Horizon ? (
    <MapNeededPreviewDialog
      dialogKey={Dialogs.PricingSurchargePreview}
      dialogTitle={t(I18nKeys.PricingSurchargePreviewConfirmationTitle)}
      dialogMessage={t(I18nKeys.PricingBasePreviewConfirmationMessage)}
      dialogConfirmationButton={t(I18nKeys.PricingBasePreviewConfirmationButton)}
      branch={branch}
      clientBranch={ClientDataBranch.PricingSurcharge}
      dataType={ClientDataType.Vendor}
    />
  ) : (
    <PreviewDialog
      dialogKey={Dialogs.PricingSurchargePreview}
      dialogTitle={t(I18nKeys.PricingSurchargePreviewConfirmationTitle)}
      dialogMessage={t(I18nKeys.PricingBasePreviewConfirmationMessage)}
      dialogConfirmationButton={t(I18nKeys.PricingBasePreviewConfirmationButton)}
      branch={branch}
      clientBranch={ClientDataBranch.PricingSurcharge}
      dataType={ClientDataType.Vendor}
    />
  );
};
