import { CircularProgress, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import { closeDialog } from '../ducks/dialogSlice';
import { useAppSelector } from '../hooks';
import { LoadingDialogOptions } from '../types/DialogState';

export const LoadingDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { title = '', content = '' } = options as LoadingDialogOptions;

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog dialogKey={Dialogs.Loading}>
      <DialogContent>
        <Stack direction="row" spacing="16px">
          <CircularProgress size="36px" color="secondary" />
          <Stack direction="column" spacing="16px">
            <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
