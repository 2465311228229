import React from 'react';
import { Dialogs } from '../constants/Dialogs';
import { useAppSelector } from '../hooks';
import { PublishResultDialog } from './PublishResultDialog';
import { PublishResultDialog as MapNeededPublishResultDialog } from './map-needed/PublishResultDialog';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SalesViewUILayout } from '../constants/Viewer';

export const ClientDataPublishResultDialog: React.FC = () => {
  const { clientDataType, vendorsToPublish, publishMergeResult } = useAppSelector((state) => state.clientData);

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  return layout !== SalesViewUILayout.Horizon ? (
    <MapNeededPublishResultDialog
      publishMergeResult={publishMergeResult}
      dialogKey={Dialogs.ClientDataPublishResult}
      clientDataType={clientDataType}
      vendorsToPublish={vendorsToPublish}
    />
  ) : (
    <PublishResultDialog
      publishMergeResult={publishMergeResult}
      dialogKey={Dialogs.ClientDataPublishResult}
      clientDataType={clientDataType}
      vendorsToPublish={vendorsToPublish}
    />
  );
};
