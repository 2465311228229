import {
  Box,
  DialogContentText,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  styled,
} from '@mui/material';
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { Form } from './redux-form/Form';
import { DealerFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { InputField } from './redux-form/InputField';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setLoading } from '../ducks/dealersSlice';
import { unknownDealer } from '../types/Dealer';
import { FormData } from './DealerForm';

const ImagePreview = styled(Box)(() => ({
  width: '150px',
  height: '56px',
  textAlign: 'center',
  alignContent: 'center',
}));

const Image = styled('img')(() => ({
  maxHeight: '56px',
}));

const DealerAdvancedFormComponent: React.FC<InjectedFormProps<FormData>> = ({
  handleSubmit,
  error,
}: InjectedFormProps<FormData>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { emailLogoUrl: initialEmailLogoUrl = '', homeLinkUrl: initialHomeLinkUrl = '' } = useAppSelector(
    (state) => state.dealers.dialogDealer || unknownDealer,
  );
  const initialLogoUrl = useAppSelector(({ dealers: { dialogDealer, logoUrl } }) => dialogDealer?.logoUrl || logoUrl);

  const [customLogoLoaded, setCustomLogoLoaded] = React.useState<boolean>(false);
  const [customLogoUrl, setCustomLogoUrl] = React.useState(initialLogoUrl);
  const [homeLinkUrl, setHomeLinkUrl] = React.useState(initialHomeLinkUrl);
  const [emailLogoLoaded, setEmailLogoLoaded] = React.useState<boolean>(false);
  const [emailLogoUrl, setEmailLogoUrl] = React.useState(initialEmailLogoUrl);

  useEffect(() => setCustomLogoUrl(initialLogoUrl), [initialLogoUrl]);
  useEffect(() => setEmailLogoUrl(initialEmailLogoUrl), [initialEmailLogoUrl]);
  useEffect(() => setHomeLinkUrl(initialHomeLinkUrl), [initialHomeLinkUrl]);

  const handleCustomLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomLogoLoaded(false);
    setCustomLogoUrl(event.target.value);
  };

  const handleEmailLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailLogoLoaded(false);
    setEmailLogoUrl(event.target.value);
  };

  const handleHomeLinkUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHomeLinkUrl(event.target.value);
  };

  return (
    <Form
      onSubmit={handleSubmit((values: FormData): Promise<AnyAction> => {
        dispatch(setLoading(true));
        return new Promise(
          (resolve, reject): AnyAction =>
            // eslint-disable-next-line no-promise-executor-return
            dispatch({
              type: `${Forms.DealerAdvanced}_SUBMIT`,
              values,
              resolve,
              reject,
            }),
        );
      })}
      onReset={() => {
        setCustomLogoUrl(undefined);
        setCustomLogoLoaded(false);
      }}
    >
      <Grid container direction="column" spacing="16px">
        <Grid size={12}>
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: t(I18nKeys.DealerDialogAdvancedHeader),
            }}
          />
        </Grid>
        <Grid container size={12}>
          <Grid size="grow">
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogCustomLogoUrlField)}
              name={DealerFormFields.CustomLogoUrl}
              onChange={handleCustomLogoUrlChanged}
            />
          </Grid>
          <Grid sx={{ width: '150px', textAlign: 'center' }}>
            <ImagePreview>
              <Image
                style={{
                  visibility: customLogoLoaded && !!customLogoUrl ? 'initial' : 'hidden',
                  position: customLogoLoaded && !!customLogoUrl ? 'initial' : 'absolute',
                }}
                src={customLogoUrl}
                alt="Custom Logo"
                onLoad={(): void => setCustomLogoLoaded(true)}
                onError={(): void => setCustomLogoLoaded(false)}
              />
              {(!customLogoLoaded || !customLogoUrl) && (
                <FormHelperText error>{t(I18nKeys.DealerDialogCustomLogoError)}</FormHelperText>
              )}
            </ImagePreview>
          </Grid>
        </Grid>
        <Grid container size={12}>
          <Grid size="grow">
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogEmailLogoUrlField)}
              name={DealerFormFields.EmailLogoUrl}
              onChange={handleEmailLogoUrlChanged}
            />
          </Grid>
          <Grid sx={{ width: '150px', textAlign: 'center' }}>
            <ImagePreview>
              <Image
                style={{
                  visibility: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'hidden',
                  position: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'absolute',
                }}
                src={emailLogoUrl}
                alt="Email Logo"
                onLoad={(): void => setEmailLogoLoaded(true)}
                onError={(): void => setEmailLogoLoaded(false)}
              />
              {(!emailLogoLoaded || !emailLogoUrl) && (
                <FormHelperText error>{t(I18nKeys.DealerDialogEmailLogoError)}</FormHelperText>
              )}
            </ImagePreview>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            label={t(I18nKeys.DealerDialogHomeLinkField)}
            name={DealerFormFields.HomeLinkUrl}
            onChange={handleHomeLinkUrlChange}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!homeLinkUrl}
                      edge="end"
                      sx={{ p: '16px' }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
                        event.stopPropagation();
                        window.open(homeLinkUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <OpenInNew sx={{ color: '#323B4B', opacity: 0.7 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            label={t(I18nKeys.DealerDialogContactBarHTMLField)}
            name={DealerFormFields.ContactBarHtml}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            label={t(I18nKeys.DealerDialogIntegrationField)}
            name={DealerFormFields.Integration}
          />
        </Grid>
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
    </Form>
  );
};

export const DealerAdvancedForm = reduxForm<FormData>({ form: Forms.DealerAdvanced, enableReinitialize: true })(
  DealerAdvancedFormComponent,
);
