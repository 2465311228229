import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Dialog } from './Dialog';
import { Dialogs } from '../../constants/Dialogs';
import { AppState } from '../../types/AppState';
import { closeDialog } from '../../ducks/dialogSlice';

export const ConfirmDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { title, description, cancelButtonText, buttons, keepDialogOpen, id } = useAppSelector(
    (state: AppState) => state?.confirmation,
  );

  return (
    <Dialog
      dialogKey={Dialogs.Confirmation}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      {title && <DialogTitle id="confirmation-dialog-title">{t(title)}</DialogTitle>}
      <DialogContent>
        <DialogContentText
          id="confirmation-dialog-description"
          dangerouslySetInnerHTML={{
            __html: t(description || ''),
          }}
        />
      </DialogContent>
      <DialogActions>
        {[
          {
            id: `${id}-confirmation-dialog-cancel-button`,
            label: cancelButtonText,
            actions: [closeDialog()],
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: (): void => {},
          },
          ...buttons,
        ].map(({ label, actions, onClick }, index, allButtons) => (
          <Button
            id={`${id}-confirmation-dialog-confirm-button`}
            key={label}
            onClick={() => {
              actions?.forEach((action) => dispatch(action));
              onClick?.();
              if (!keepDialogOpen) dispatch(closeDialog());
            }}
            color="primary"
            autoFocus={index === allButtons.length - 1}
          >
            {t(label || '')}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
