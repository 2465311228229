export enum MainMenuItems {
  ClientData = 'DATA',
  Dealers = 'DEALERS',
  Groups = 'GROUPS',
  Home = 'HOME',
  Integrations = 'INTEGRATIONS',
  Leads = 'LEADS',
  Orders = 'ORDERS',
  Analyze = 'ANALYZE',
  Payments = 'PAYMENTS',
  Pricing = 'PRICING',
  Reports = 'REPORTS',
  Settings = 'SETTINGS',
  Sites = 'SITES',
  Sales = 'SALES',
  Help = 'HELP',
  Team = 'TEAM',
}
