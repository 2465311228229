import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Form } from './redux-form/Form';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { PublishFields } from '../constants/FormFields';
import { required } from '../utils/reduxFormUtils';

const useStyles = makeStyles(() => ({
  descriptionField: {
    width: '100%',
    minWidth: '500px',
  },
}));

export interface FormData {
  [PublishFields.Description]: string;
}

interface CustomProps {
  onSubmit: { (values: FormData): void };
  isPublishing: boolean;
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const PublishFormComponent: React.FC<FormProps> = ({ handleSubmit, onSubmit, isPublishing }: FormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={isPublishing}
        variant="filled"
        autoComplete="off"
        className={classes.descriptionField}
        component={InputField}
        validate={required}
        label={t(I18nKeys.ClientDataPublishDescriptionField)}
        name={PublishFields.Description}
        multiline
        rows={3}
        helperText=""
      />
    </Form>
  );
};

export const PublishForm = reduxForm<FormData, CustomProps>({
  form: Forms.Publish,
})(PublishFormComponent);
