import { Drawer, Theme, useMediaQuery, DrawerProps, Box } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { usePath } from 'hookrouter';
import React from 'react';
import { mainMenuWidth, MenuStatus, SalesViewUILayout, secondaryMenuWidth } from '../constants/Viewer';
import { getLogoBarHeight } from '../utils/viewerUtils';
import { Menu as MenuType, MenuVariant } from '../constants/Menu';
import { useAppSelector } from '../hooks';

const useStyles = makeStyles<
  Theme,
  { mobileView: boolean; path: string; variant: MenuVariant; menu: MenuType; menuStatus: MenuStatus }
>((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    position: 'fixed',
    zIndex: ({ menu }) => theme.zIndex.drawer - (menu === MenuType.Secondary ? 1 : 0),
    marginTop: ({ mobileView, path }): string =>
      `${mobileView ? 0 : getLogoBarHeight({ layout: SalesViewUILayout.Horizon, mobileView, path })}px`,
    height: ({ mobileView, path }): string =>
      `calc(100% - ${!mobileView ? getLogoBarHeight({ layout: SalesViewUILayout.Horizon, mobileView, path }) : 0}px)`,
    left: ({ menu, variant }) => (menu === MenuType.Secondary ? mainMenuWidth[variant] : 0),
    backgroundColor: ({ variant, menu }) =>
      variant === MenuVariant.MINI && menu === MenuType.Main ? '#EEEEF2' : '#FFFFFF',
    width: ({ menu, variant, menuStatus }) =>
      `${
        menu === MenuType.Main
          ? (menuStatus !== MenuStatus.Closed && mainMenuWidth[variant]) || 0
          : (menuStatus !== MenuStatus.Closed && secondaryMenuWidth[variant]) || 0
      }px`,
    transition: theme.transitions.create(['width', 'background-color', 'left'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    overflow: 'hidden',
  },
}));

const Spacer = styled(Box)({
  height: '100%',
});

type Props = React.PropsWithChildren<{
  menu?: MenuType;
}> &
  Omit<DrawerProps, 'variant'>;

export const Menu: React.FC<Props> = ({ menu = MenuType.Main, children }) => {
  const path = usePath();
  const variant = useAppSelector((state) => state.menu.variant);
  const menuStatus = useAppSelector((state) => state.viewer.menuStatus);

  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles({ mobileView, path, menu, variant, menuStatus });

  return (
    <Drawer
      variant={mobileView ? 'temporary' : 'permanent'}
      open={menuStatus !== MenuStatus.Closed}
      anchor="left"
      className={classes.drawer}
      PaperProps={{
        className: `${classes.drawer} ${classes.drawerPaper}`,
        sx: {
          borderRight: menu === MenuType.Secondary && variant === MenuVariant.MINI ? '1px solid #DEDEDE' : '0px',
        },
      }}
    >
      {children}
      {menu === MenuType.Main && (
        <Spacer
          sx={{
            borderRight: '1px solid #DEDEDE',
          }}
        />
      )}
    </Drawer>
  );
};
