/* eslint-disable react/jsx-props-no-spreading */
import {
  AppBar,
  TabsProps,
  AppBarProps,
  useMediaQuery,
  Theme,
  alpha,
  Stack,
  Tab,
  Tabs as MuiTabs,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { unknownUser } from '../../types/User';
import { isIdeaRoomUser as isIdeaRoomUserFunc } from '../../utils/userUtils';
import { IdeaRoomOnlyIndicator } from '../IdeaRoomOnlyIndicator';

type Props = TabsProps & {
  slotProps?: { appBar?: AppBarProps };
  tabs: { id?: string; label: string; value: string; ideaRoomOnly?: boolean }[];
};

export const Tabs: React.FC<Props> = ({ tabs, ...props }) => {
  const isIdeaRoomUser = useAppSelector((state) => isIdeaRoomUserFunc(state.currentUser.user || unknownUser));

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  const visibleTabs = useMemo(
    () => tabs.filter(({ ideaRoomOnly }) => !ideaRoomOnly || isIdeaRoomUser),
    [tabs, isIdeaRoomUser],
  );

  if (visibleTabs.length < 2) return null;
  return (
    <AppBar position="relative" color="inherit" elevation={0} {...props.slotProps?.appBar}>
      <MuiTabs aria-label="tabs" variant={tabsVariant} indicatorColor="secondary" {...props}>
        {visibleTabs.map(({ id, label, value, ideaRoomOnly }) => (
          <Tab
            key={value}
            label={
              <Stack direction="row" alignItems="center" spacing="10px">
                {ideaRoomOnly && <IdeaRoomOnlyIndicator />}
                <Typography>{label}</Typography>
              </Stack>
            }
            value={value}
            id={id}
            aria-controls={id}
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#323B4B !important',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: alpha('#F2F4F5', 0.8),
              },
              '&.Mui-selected': {
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: alpha('#F2F4F5', 0.8),
                },
              },
            }}
          />
        ))}
      </MuiTabs>
    </AppBar>
  );
};
