/* eslint-disable react-hooks/rules-of-hooks */

import { useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { CARPORTVIEW, IDEAROOM_CLIENT_ID } from '../constants/ClientId';
import { CUSTOM_EXPRESSION_FUNCTION_TABLE } from '../constants/ClientData';
import { DOCUMENTATION_COLUMN, KEY_COLUMN, PRODUCT_COLUMN } from '../constants/ClientDataColumn';
import { useGetClientDataTablesDataQuery } from '../services/clientDataApi';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';

type UseFunLibraryReturn = {
  lib: string;
  loading: boolean;
};

export const useFunLibrary = <UseSkip extends boolean = false>({
  useSkip,
}: {
  useSkip?: UseSkip;
} = {}): UseFunLibraryReturn => {
  if (useSkip) {
    return { lib: '', loading: false };
  }

  const {
    clientId,
    clientDataType,
    clientDataBranch = ClientDataBranch.Main,
  } = useAppSelector((state) => state?.clientData);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const funLibraryTables = [];
  const tableNames = [CUSTOM_EXPRESSION_FUNCTION_TABLE];
  const columns = [KEY_COLUMN, DOCUMENTATION_COLUMN];
  const keyColumn = KEY_COLUMN;

  const refCustomExpressionFunctionTable = {
    dataType: ClientDataType.Reference,
    branch: ClientDataBranch.Main,
    groupId,
    clientId: IDEAROOM_CLIENT_ID,
    tableNames,
    columns: [...columns, PRODUCT_COLUMN],
    keyColumn,
  };
  funLibraryTables.push(refCustomExpressionFunctionTable);

  const supCustomExpressionFunctionTable = {
    dataType: ClientDataType.Supplier,
    branch: clientDataType === ClientDataType.Supplier ? clientDataBranch : ClientDataBranch.Main,
    groupId,
    clientId,
    tableNames,
    columns,
    keyColumn,
  };

  const { data = [], isLoading: loading } = useGetClientDataTablesDataQuery({
    tables: [refCustomExpressionFunctionTable, supCustomExpressionFunctionTable],
  });

  const { configurator } = mapClientIdToConfiguratorAndVendor(clientId);
  const productToIgnore = configurator === CARPORTVIEW ? 'sheds' : 'carports';
  const lib = [
    'declare const fun: {',
    ...data
      .map(({ customExpressionFunction }) => customExpressionFunction)
      .flat()
      .filter(({ [KEY_COLUMN]: key, [PRODUCT_COLUMN]: product }) => !!key && product !== productToIgnore)
      .map(({ [KEY_COLUMN]: key, [DOCUMENTATION_COLUMN]: documentation }) => {
        const lines: string[] = [];
        if (typeof documentation === 'string') {
          lines.push('  /**');
          lines.push(`   * ${documentation}`);
          lines.push('   */');
        }
        lines.push(`  ${key}: () => any`);
        return lines;
      })
      .flat(),
    '}',
  ].join('\n');

  return { lib, loading };
};
