import { List, Typography, Stack, Box } from '@mui/material';
import { navigate } from 'hookrouter';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Menu as MenuType, MenuVariant } from '../constants/Menu';
import { SecondaryMenuItem } from './SecondaryMenuItem';
import { Menu } from './Menu';
import { AppRoutes } from '../constants/AppRoutes';
import { setSecondaryMenuItem } from '../ducks/menuSlice';
import { SitesSelect } from './sites/SitesSelect';
import { publishBarHeight } from '../constants/Viewer';
import { Button } from './library/Button';
import { titleCaseToKebabCase } from '../utils/stringUtils';
import { useGetClientDataBranchChangesSummaryQuery } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { getChangedSecondaryMenuSections, getSecondaryMenu } from '../utils/menuUtils';
import { viewSite } from '../utils/configuratorUtils';
import { unknownUser } from '../types/User';
import { useGetGroupSetupStepsQuery } from '../services/groupApi';
import { GettingStartedSteps } from '../constants/gettingStartedConfig';

export const SecondaryMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientId,
    clientDataType,
    clientDataBranch = ClientDataBranch.Main,
  } = useAppSelector((state) => state.clientData);
  const viewState = useAppSelector((state) => state.viewer);
  const { selectedClientId } = viewState;
  const { group: { groupId, configurators = [] } = unknownGroup, user = unknownUser } = useAppSelector(
    (state) => state.currentUser,
  );
  const { tourIsActive } = useAppSelector((state) => state.gettingStarted);
  const { mainMenuItem, secondaryMenuItem, variant } = useAppSelector((state) => state.menu);

  const { currentData: changesSummary } = useGetClientDataBranchChangesSummaryQuery(
    { dataType: clientDataType, clientId, groupId, branch: clientDataBranch },
    {
      skip: !clientId || !clientDataType || clientDataBranch === ClientDataBranch.Main,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: { steps: gettingStartedSteps = [] } = {} } = useGetGroupSetupStepsQuery(
    {
      clientId: selectedClientId || '',
      groupId,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: false },
  );
  const isDayOneClient = configurators?.[0]?.vendorData?.vendor?.maxioSubscriptionId;
  const shouldCompleteConfiguratorStep =
    isDayOneClient &&
    gettingStartedSteps.find((step) => step.key === GettingStartedSteps.TRY_NEW_SITE)?.completed === false;

  const secondaryMenu = useMemo(() => getSecondaryMenu(mainMenuItem), [mainMenuItem]);
  const changedMenuSections = useMemo(
    () => getChangedSecondaryMenuSections(mainMenuItem, changesSummary),
    [mainMenuItem, changesSummary],
  );
  const selectedConfigurator = useMemo(
    () => configurators.find(({ clientId: configuratorClientId }) => configuratorClientId === clientId),
    [configurators, clientId],
  );

  return (
    <Menu menu={MenuType.Secondary}>
      <Stack sx={{ pt: '18px', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <SitesSelect />
          {secondaryMenu.map(({ label: sectionLabel, items }) => {
            const changedItems = changedMenuSections.find(({ label }) => label(t) === sectionLabel(t))?.items || [];
            return (
              <Stack key={titleCaseToKebabCase(sectionLabel(t))} sx={{ mt: '26px' }}>
                <Typography
                  fontSize="10px"
                  sx={{
                    px: '20px',
                    mb: '6px',
                    color: (theme) =>
                      changedItems.length ? theme.palette.secondary.main : theme.typography.body1.color,
                  }}
                >
                  {sectionLabel(t).toLocaleUpperCase()}
                </Typography>
                <List disablePadding>
                  {items.map(({ id: itemId, label, ideaRoomOnly }) => {
                    const itemChanged = changedItems.some(({ id }) => id === itemId);
                    return (
                      <SecondaryMenuItem
                        key={itemId}
                        label={label(t)}
                        ideaRoomOnly={ideaRoomOnly?.(user, groupId, viewState)}
                        onClick={() => {
                          dispatch(setSecondaryMenuItem(itemId));
                          navigate(`${AppRoutes.Sites}/${clientId}/${itemId}`);
                        }}
                        selected={secondaryMenuItem === itemId}
                        edited={itemChanged}
                      />
                    );
                  })}
                </List>
              </Stack>
            );
          })}
        </Box>
        <Box
          sx={{
            height: variant === MenuVariant.MINI ? publishBarHeight : '0px',
            borderTop: '1px solid #DEDEDE',
            textAlign: 'center',
            alignContent: 'center',
            transition: (theme) =>
              theme.transitions.create(['height'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <Button
            className="group-configurator-btn"
            variant="outlined"
            sx={{
              width: '214px',
              height: '44px',
            }}
            onClick={(): Window | null =>
              viewSite(selectedConfigurator?.url, {
                tourIsActive,
                shouldCompleteConfiguratorStep,
                selectedGroupId: groupId,
                dispatch,
              })
            }
          >
            View Site
          </Button>
        </Box>
      </Stack>
    </Menu>
  );
};
