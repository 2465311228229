import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IdeaRoomCode, MonacoEditor, EditorApi, Language } from '@idearoom/ir-code';
import { Button, CircularProgress, Theme } from '@mui/material';
import { BugReport } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';
import { formatExpression, getInitialEditorValue } from '../utils/clientDataUtils';
import {
  ClientDataEditor,
  ColumnDataType,
  CUSTOM_EXPRESSION_FUNCTION_TABLE,
  EXPRESSION_DEBUG_DELIMITER,
} from '../constants/ClientData';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import dragHandle from '../images/dragHandle.svg';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { useFunLibrary } from '../hooks/useFunLibrary';

const FUN_LIBRARY_URI = 'ts:filename/fun.d.ts';

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
  },
  debugButton: {
    marginTop: '8px',
  },
  debugButtonIcon: { marginRight: '5px' },
  editor: {
    padding: '18px',
  },
  dragHandle: {
    height: '17px',
    position: 'absolute',
    bottom: '1px',
    right: '1px',
  },
}));

export const ClientDataLargeTextEditor = React.forwardRef<ICellEditorReactComp, ICellEditorParams>(
  (props: ICellEditorParams, ref) => {
    const classes = useStyles();
    const { colDef } = props;
    const [value, setValue] = useState<string | undefined>(getInitialEditorValue(props));
    const editorRef = useRef<MonacoEditor | null>(null);
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector((state: AppState) => state?.currentUser);
    const {
      preferences: {
        [UserPreference.ProfilePreferences]: {
          [PreferencesFormFields.Theme]: theme = undefined,
          [PreferencesFormFields.MiniMap]: minimapEnabled = false,
          editorSize: { width = '50vw', height = '30vh' } = {},
          ...otherProfilePreferences
        } = {},
      } = {},
    } = currentUser || {};
    const {
      context: {
        selectedTable,
        tableMetadata: { metadata },
      },
      column,
    } = props;

    const colId = column.getColId();
    const columnMetadata = metadata[colId || ''];
    const dataType = columnMetadata?.dataType;
    let language: Language;

    switch (dataType) {
      case ColumnDataType.Expression:
        language = Language.Expression;
        break;
      case ColumnDataType.Html:
        language = Language.Html;
        break;
      case ColumnDataType.Json:
        language = Language.JSON;
        break;
      default:
        language = Language.PlainText;
        break;
    }

    React.useImperativeHandle(ref, () => ({
      getEditorType() {
        return ClientDataEditor.Large;
      },

      getValue() {
        return value;
      },

      insertValue(val: string) {
        const { editor, monaco } = (editorRef.current || {}) as EditorApi;
        const currentSelection = editor?.getSelection();
        if (!editor || !monaco || !currentSelection) return;

        const model = editor?.getModel();
        // Will allow undo/redo
        model?.pushEditOperations(
          [currentSelection],
          [
            {
              range: currentSelection,
              text: val,
            },
          ],
          () => null,
        );

        const { Selection } = monaco;
        const newSelection = editor?.getSelection();
        if (!newSelection) return;
        const { endLineNumber, endColumn } = newSelection;

        // Set selection to end of inserted text
        editor?.setSelection(new Selection(endLineNumber, endColumn, endLineNumber, endColumn));
      },

      getSelection() {
        const { editor } = (editorRef.current || {}) as EditorApi;
        const { startColumn, endColumn } = editor?.getSelection() || {};
        return { start: startColumn, end: endColumn };
      },

      setSelection(start: number, end: number) {
        const { editor, monaco } = (editorRef.current || {}) as EditorApi;
        const currentSelection = editor?.getSelection();
        if (!editor || !monaco || !currentSelection) return;

        const { startLineNumber } = currentSelection;
        const { Selection } = monaco;
        editor?.setSelection(new Selection(startLineNumber, start, startLineNumber, end));
      },
    }));

    const isExpression = colDef.type === ColumnDataType.Expression;
    const isDebugEnabled = isExpression && value && value.includes(EXPRESSION_DEBUG_DELIMITER);
    const valueWithoutDelimiter = isDebugEnabled ? value.replace(EXPRESSION_DEBUG_DELIMITER, '') : value;
    const addDebugDelimiter = (v = '') =>
      v.startsWith('=') ? v.replace('=', `=${EXPRESSION_DEBUG_DELIMITER}`) : `${EXPRESSION_DEBUG_DELIMITER}${v}`;

    const { lib: funLibSource, loading } = useFunLibrary({
      useSkip: language !== Language.Expression || selectedTable === CUSTOM_EXPRESSION_FUNCTION_TABLE,
    });

    const beforeMount = (monaco: MonacoEditor) => {
      monaco.languages.typescript.javascriptDefaults.addExtraLib(funLibSource, FUN_LIBRARY_URI);
      const funModelUri = monaco.Uri.parse(FUN_LIBRARY_URI);
      if (!monaco.editor.getModel(funModelUri)) {
        monaco.editor.createModel(funLibSource, 'typescript', funModelUri);
      }
    };

    return (
      <div className="ag-large-text">
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <div className={`ag-large-text-input ag-text-area ag-input-field ${classes.root}`}>
            <div className="ag-wrapper ag-input-wrapper ag-text-area-input-wrapper">
              <img alt="Drag handle" src={dragHandle} className={classes.dragHandle} />
              <IdeaRoomCode
                ref={editorRef}
                className={`ag-input-field-input ag-text-area-input ${classes.editor}`}
                width={width}
                height={height}
                value={valueWithoutDelimiter}
                language={language}
                theme={theme}
                options={{
                  minimap: {
                    enabled: minimapEnabled,
                  },
                  lineNumbers: language === Language.PlainText ? 'off' : 'on',
                  folding: language !== Language.PlainText,
                  overviewRulerLanes: language === Language.PlainText ? 0 : 3,
                  lineDecorationsWidth: 3,
                  fixedOverflowWidgets: true,
                }}
                resizable
                beforeMount={beforeMount}
                onChange={(editorValue: string | undefined) =>
                  setValue(isDebugEnabled ? addDebugDelimiter(editorValue || '') : editorValue || '')
                }
                onResize={({ width: newWidth, height: newHeight }: { width: string; height: string }) => {
                  dispatch(
                    saveUserPreferences({
                      userPreference: UserPreference.ProfilePreferences,
                      preferences: {
                        [PreferencesFormFields.Theme]: theme,
                        [PreferencesFormFields.MiniMap]: minimapEnabled,
                        editorSize: { width: newWidth, height: newHeight },
                        ...otherProfilePreferences,
                      },
                    }),
                  );
                }}
                customFormatters={{ [Language.Expression]: formatExpression }}
              />
            </div>
            {isExpression && (
              <Button
                className={classes.debugButton}
                variant={isDebugEnabled ? 'contained' : 'text'}
                color="info"
                size="small"
                onClick={() => {
                  if (isDebugEnabled) {
                    setValue((v) => (v || '').replace(EXPRESSION_DEBUG_DELIMITER, ''));
                  } else {
                    setValue((v) => addDebugDelimiter(v));
                  }
                }}
              >
                <BugReport className={classes.debugButtonIcon} />
                DEBUG
              </Button>
            )}
          </div>
        )}
      </div>
    );
  },
);
