import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Field, Form, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Grid2 as Grid, FormControl } from '@mui/material';
import { IdeaRoomCode, CustomTheme, Language } from '@idearoom/ir-code';
import { PreferencesFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { SelectField } from './redux-form/SelectField';
import { kebabCaseToTitleCase } from '../utils/stringUtils';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LabeledCheckboxField } from './redux-form/LabeledCheckboxField';
import { SalesViewUILayout } from '../constants/Viewer';
import { Button } from './library/Button';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

interface FormData {
  [PreferencesFormFields.Layout]: SalesViewUILayout;
  [PreferencesFormFields.Theme]: string;
  [PreferencesFormFields.MiniMap]: boolean;
}

type Props = InjectedFormProps<FormData>;

const PreferencesFormComponent: React.FC<Props> = ({ error, handleSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [previewValue, setPreviewValue] = React.useState<string>(
    t(I18nKeys.ProfilePreferencesThemePreviewValue).split('\\n').join('\n'),
  );

  const theme = useAppSelector((state) => formValueSelector(Forms.Preferences)(state, PreferencesFormFields.Theme));
  const miniMap = useAppSelector((state) => formValueSelector(Forms.Preferences)(state, PreferencesFormFields.MiniMap));

  const onSubmit = (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Preferences}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="24px">
        <Grid size={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <Field
                variant="filled"
                name={PreferencesFormFields.Layout}
                component={SelectField}
                label="Layout"
                margin="none"
                slotProps={{
                  select: {
                    displayEmpty: true,
                    autoWidth: false,
                  },
                }}
              >
                {Object.values(SalesViewUILayout).map((key) => (
                  <MenuItem key={key} value={key}>
                    {kebabCaseToTitleCase(key)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              variant="filled"
              name={PreferencesFormFields.Theme}
              component={SelectField}
              label={t(I18nKeys.ProfilePreferencesTheme)}
              margin="none"
              slotProps={{
                select: {
                  displayEmpty: true,
                  autoWidth: false,
                },
              }}
            >
              {['light', 'vs-dark', ...Object.values(CustomTheme)].map((key) => (
                <MenuItem key={key} value={key}>
                  {kebabCaseToTitleCase(key)}
                </MenuItem>
              ))}
            </Field>
            {!!error && <Typography className={classes.error}>{error}</Typography>}
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} sx={{ alignContent: 'center' }}>
          <FormControl fullWidth>
            <Field
              id={PreferencesFormFields.MiniMap}
              name={PreferencesFormFields.MiniMap}
              component={LabeledCheckboxField}
              label={t(I18nKeys.ProfilePreferencesMinimap)}
            />
          </FormControl>
        </Grid>
        <Grid size={12} style={{ height: '200px' }}>
          <IdeaRoomCode
            width="100%"
            height="100%"
            value={previewValue}
            language={Language.JavaScript}
            theme={theme}
            options={{
              minimap: {
                enabled: miniMap,
              },
            }}
            onChange={(editorValue?: string) => setPreviewValue(editorValue || '')}
          />
        </Grid>
        <Button
          key="submit-preferences-form-btn"
          type="submit"
          variant="outlined"
          loading={submitting}
          disabled={submitting}
        >
          {t(I18nKeys.ProfileUpdatePreferences)}
        </Button>
      </Grid>
    </Form>
  );
};

export const PreferencesForm = reduxForm<FormData>({ form: Forms.Preferences })(PreferencesFormComponent);
