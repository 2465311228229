import {
  confirmSignIn,
  resetPassword,
  signIn,
  updateUserAttributes,
  confirmResetPassword,
  signOut,
  updatePassword,
  SignInOutput,
} from '@aws-amplify/auth';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import {
  CognitoUserPool,
  CognitoUser as AmazonCognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { change, SubmissionError } from 'redux-form';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { CustomTheme } from '@idearoom/ir-code/lib';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { config as configuration } from '../config/config';
import { AppRoutes } from '../constants/AppRoutes';
import { AuthStatus } from '../constants/AuthStatus';
import { SignInChallenges } from '../constants/Cognito';
import {
  ForgotPasswordFormFields,
  ImpersonationDialogFormFields,
  PasswordFormFields,
  PreferencesFormFields,
  ProfileFormFields,
  SignInFormFields,
  SignUpFormFields,
} from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { closeDialog } from '../ducks/dialogSlice';
import { setForgotPasswordState } from '../ducks/forgotPassword';
import { setSignUpState } from '../ducks/signUpSlice';
import { AppState } from '../types/AppState';
import { Group } from '../types/Group';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { CurrentUserState } from '../types/CurrentUserState';
import { CurrentUserData, unknownUser } from '../types/User';
import { SignUpState } from '../types/SignUpState';
import { SessionStorage } from '../constants/SessionStorage';
import { extractErrorProps } from '../utils/errorUtils';
import { defaultErrorMessage } from '../constants/Error';
import { UserPreference } from '../constants/User';
import { userApi } from '../services/userApi';
import { LocalStorage } from '../constants/LocalStorage';
import {
  impersonationFailed,
  impersonationSuccess,
  saveUserPreferences,
  saveUserPreferencesComplete,
  setCurrentUserAuthStatus,
  setSigningIn,
  signOutAction,
  signedIn,
  signedInFailed,
  signedInSuccess,
  updateCurrentUser,
} from '../ducks/currentUserSlice';

/* global localStorage */

const clearUserLocalStorageItems = () => {
  [
    LocalStorage.LastPricingBaseMergeCommit,
    LocalStorage.LastPricingComponentMergeCommit,
    LocalStorage.LastPricingSizeBasedMergeCommit,
    LocalStorage.HaveShownPricingBaseDeletingPriceDialog,
  ].forEach((key) => localStorage.removeItem(key));
};

function* submitSignIn({
  reject,
  resolve,
  values: { [SignInFormFields.Email]: email, [SignInFormFields.Password]: password },
}: any): Generator {
  const isSigningIn = yield select(({ currentUser: { signingIn } }: AppState) => signingIn);
  if (isSigningIn) {
    yield call(resolve);
    return;
  }

  yield put(setSigningIn(true));

  const trimmedEmail = email.trim();
  try {
    const { nextStep: { signInStep = SignInChallenges.Done } = {} } = (yield signIn({
      username: trimmedEmail,
      password,
    })) as SignInOutput;

    yield put(setSigningIn(false));

    if (signInStep === SignInChallenges.NewPasswordRequired) {
      yield call(resolve);

      yield put(setSignUpState({ email: trimmedEmail }));

      // User needs to sign up before continuing
      yield navigate(AppRoutes.SignUp);
    } else if (signInStep === SignInChallenges.Done) {
      // User successfully signed in
      yield put(signedIn());

      yield call(resolve);
    }
  } catch (error) {
    yield put(setSigningIn(false));
    const { code = '' } = error as { code?: string };

    window.heap?.track('SignInFailed', { email: trimmedEmail, code });

    if (code === 'PasswordResetRequiredException') {
      yield put(setCurrentUserAuthStatus(AuthStatus.ForgotPasswordCodeSent));

      yield navigate(AppRoutes.ForgotPassword);

      yield put(change(Forms.ForgotPassword, ForgotPasswordFormFields.Email, trimmedEmail));
    } else if (['NotAuthorizedException', 'UserNotFoundException'].includes(code)) {
      yield call(
        reject,
        new SubmissionError({
          _error: "The email address or password doesn't match. Please try again.",
        }),
      );
    } else {
      const { errorMessage } = extractErrorProps(error);
      yield call(reject, new SubmissionError({ _error: errorMessage }));
    }
  }
}

function* submitSignUp({
  reject,
  resolve,
  values: {
    [SignUpFormFields.FirstName]: firstName,
    [SignUpFormFields.LastName]: lastName,
    [SignUpFormFields.NewPassword]: newPassword,
  },
}: any): Generator {
  try {
    const { email } = (yield select(({ signUp }: AppState) => signUp)) as SignUpState;

    yield confirmSignIn({ challengeResponse: newPassword });

    // Update user attributes
    yield updateUserAttributes({ userAttributes: { email, given_name: lastName, name: firstName } });

    // Clear sign up state
    yield put(setSignUpState({ email: undefined }));

    // User successfully signed in
    yield put(signedIn());

    yield call(resolve);
  } catch (error) {
    const { message } = error as Error;
    yield call(reject, new SubmissionError({ _error: message }));
  }
}

function* sendForgotPasswordCode({
  reject,
  resolve,
  values: {
    [ForgotPasswordFormFields.Email]: email,
    [ForgotPasswordFormFields.NewPassword]: newPassword,
    [ForgotPasswordFormFields.VerificationCode]: verificationCode,
  },
}: any): Generator {
  try {
    const authenticationStatus = yield select(({ currentUser: { authStatus } }: AppState) => authStatus);

    if (authenticationStatus === AuthStatus.ForgotPasswordCodeSent) {
      // Remove any whitespace from the verification code. AWS regex for verification code ([\S]+) doesn't allow whitespace.
      yield confirmResetPassword({
        username: email,
        confirmationCode: verificationCode.replace(/\s+/g, ''),
        newPassword,
      });

      yield put(setCurrentUserAuthStatus(AuthStatus.PasswordReset));

      yield call(resolve);
    } else {
      yield resetPassword({ username: email });

      yield put(setForgotPasswordState({ email }));
      yield put(setCurrentUserAuthStatus(AuthStatus.ForgotPasswordCodeSent));

      yield call(resolve);
    }
  } catch (error) {
    const { errorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* submitProfileForm({
  reject,
  resolve,
  values: {
    [ProfileFormFields.FirstName]: firstName,
    [ProfileFormFields.LastName]: lastName,
    [ProfileFormFields.PhoneNumber]: phone,
  },
}: any): Generator {
  try {
    const formattedPhoneNumber = formatPhoneNumber(phone);
    yield updateUserAttributes({
      userAttributes: {
        given_name: lastName,
        name: firstName,
        'custom:phone': formattedPhoneNumber,
      },
    });

    const { group } = (yield select(({ currentUser }: AppState) => currentUser)) as { group: Group };

    const userDataFetch = (yield put(
      userApi.endpoints.getCurrentUserData.initiate(
        {
          groupId: group.groupId,
        },
        { forceRefetch: true },
      ) as any,
    )) as QueryActionCreatorResult<any>;
    userDataFetch.unsubscribe();

    const { data: { user = unknownUser } = {} } = (yield userDataFetch) as {
      data: CurrentUserData;
    };

    yield put(updateCurrentUser(user));

    yield call(resolve);

    yield call(toast.success, `Profile successfully updated.`);
  } catch (error) {
    const { errorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* submitPreferencesForm({ reject, resolve, values }: any): Generator {
  try {
    const {
      [PreferencesFormFields.Layout]: layout,
      [PreferencesFormFields.Theme]: theme,
      [PreferencesFormFields.MiniMap]: miniMap,
    } = values;
    if (!['light', 'vs-dark', ...Object.values(CustomTheme)].includes(theme)) {
      throw new Error('Invalid theme');
    }

    yield put(
      saveUserPreferences({
        userPreference: UserPreference.ProfilePreferences,
        preferences: {
          [PreferencesFormFields.Layout]: layout,
          [PreferencesFormFields.Theme]: theme,
          [PreferencesFormFields.MiniMap]: miniMap,
        },
      }),
    );

    yield take(saveUserPreferencesComplete.type);

    const { preferences: { [UserPreference.ProfilePreferences]: profilePreferences = {} } = {} } = (yield select(
      ({ currentUser }: AppState) => currentUser,
    )) as CurrentUserState;

    if (!Object.entries(values).every(([key, value]) => (profilePreferences as any)[key] === value)) {
      throw new Error('Preferences failed to update.');
    }

    yield call(resolve);

    yield call(toast.success, `Preferences successfully updated.`);
  } catch (error) {
    const { errorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* submitPasswordForm({
  reject,
  resolve,
  values: { [PasswordFormFields.CurrentPassword]: oldPassword, [PasswordFormFields.NewPassword]: newPassword },
}: any): Generator {
  try {
    yield updatePassword({ oldPassword, newPassword });

    yield call(resolve);

    yield call(toast.success, `Password successfully updated.`);

    yield put(signOutAction());
  } catch (error) {
    const { message } = error as Error;
    yield call(reject, new SubmissionError({ _error: message }));
  }
}

function* validateImpersonation({
  resolve,
  values: { [ImpersonationDialogFormFields.ValidationCode]: code },
}: any): Generator {
  try {
    const { impersonation: { user: { username: usernameToImpersonate = '', name = '' } = {} } = {} } = (yield select(
      ({ currentUser }: AppState) => currentUser,
    )) as CurrentUserState;

    const session = sessionStorage.getItem(SessionStorage.ImpersonationSession);

    if (!session) throw new Error('Impersonation session not found');

    const validateImpersonationFetch = (yield put(
      userApi.endpoints.validateImpersonation.initiate({
        code,
        username: usernameToImpersonate,
        session,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    validateImpersonationFetch.unsubscribe();

    const { data: { authenticationResult: authResult } = {} } = (yield validateImpersonationFetch) as {
      data: {
        authenticationResult: {
          AccessToken: string;
          IdToken: string;
          RefreshToken: string;
        };
      };
    };

    if (!authResult) throw new Error('Authentication failed. The validation code may be invalid.');

    yield signOut();
    clearUserLocalStorageItems();
    const cognitoUser = new AmazonCognitoUser({
      Username: usernameToImpersonate,
      Pool: new CognitoUserPool({
        UserPoolId: configuration.cognito.USER_POOL_ID || '',
        ClientId: configuration.cognito.APP_CLIENT_ID || '',
      }),
    });
    const cognitoSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: authResult.IdToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: authResult.AccessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: authResult.RefreshToken }),
    });
    cognitoUser.setSignInUserSession(cognitoSession);

    yield put(signedIn());
    yield take((action: any) => [signedInSuccess.type, signedInFailed.type].includes(action.type));

    yield put(closeDialog());
    yield put(impersonationSuccess());
    yield navigate(AppRoutes.Portal);
    yield call(toast.success, `Impersonating ${name}`);

    yield call(resolve);
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield put(closeDialog());
    yield put(impersonationFailed());
    yield call(toast.error, errorMessage);
  }
}

function* watchSubmitPasswordForm(): Generator {
  yield takeLatest(`${Forms.Password}_SUBMIT`, submitPasswordForm);
}

function* watchSubmitProfileForm(): Generator {
  yield takeLatest(`${Forms.Profile}_SUBMIT`, submitProfileForm);
}

function* watchSubmitPreferencesForm(): Generator {
  yield takeLatest(`${Forms.Preferences}_SUBMIT`, submitPreferencesForm);
}

function* watchSubmitForgotPasswordForm(): Generator {
  yield takeLatest(`${Forms.ForgotPassword}_SUBMIT`, sendForgotPasswordCode);
}

function* watchSubmitSignUpForm(): Generator {
  yield takeLatest(`${Forms.SignUp}_SUBMIT`, submitSignUp);
}

function* watchSubmitSignInForm(): Generator {
  yield takeLatest(`${Forms.SignIn}_SUBMIT`, submitSignIn);
}

function* watchValidateImpersonation(): Generator {
  yield takeLatest(`${Forms.Impersonation}_SUBMIT`, validateImpersonation);
}

export function* userSaga(): Generator {
  yield all([
    watchSubmitForgotPasswordForm(),
    watchSubmitPasswordForm(),
    watchSubmitProfileForm(),
    watchSubmitPreferencesForm(),
    watchSubmitSignInForm(),
    watchSubmitSignUpForm(),
    watchValidateImpersonation(),
  ]);
}
