import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export const BetaIndicator: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '4px',
        padding: '2px 6px',
        ml: '2px',
      }}
    >
      <Typography sx={{ textTransform: 'uppercase', fontSize: '11px', color: '#FFFFFF' }}>Beta</Typography>
    </Box>
  );
};
