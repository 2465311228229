import { Button, CircularProgress, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../../constants/Dialogs';
import { Dialog } from './Dialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { closeDialog } from '../../ducks/dialogSlice';
import { useAppSelector } from '../../hooks';
import { LoadingDialogOptions } from '../../types/DialogState';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    color: '#000000DE',
  },
  content: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    marginTop: '8px',
    color: '#000000DE',
  },
}));

export const LoadingDialog: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { title = '', content = '' } = options as LoadingDialogOptions;

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog dialogKey={Dialogs.Loading}>
      <DialogContent>
        <div style={{ display: 'flex' }}>
          <CircularProgress style={{ marginRight: '15px' }} color="primary" />
          <div>
            <Typography className={classes.title}>{title}</Typography>
            <Typography
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
