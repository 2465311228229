import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { LoadingButton } from '../LoadingButton';
import { useGetClientPublishedVersionsQuery, usePublishVendorMutation } from '../../services/clientDataApi';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { ClientDataPublishDialogStripes } from './ClientDataPublishDialogStripes';
import { ClientDataType } from '../../constants/ClientDataType';
import { MultiselectField } from './MultiselectField';
import { mapClientIdToConfiguratorAndVendor } from '../../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../../utils/vendorUtils';
import { setPublishMergeResult, setVendorsToPublish } from '../../ducks/clientDataSlice';
import { MergeStatus } from '../../constants/ClientData';
import { ClientDataSuppliersVersionBumpListDetails } from './ClientDataSuppliersVersionBumpListDetails';
import { useGetClientName } from '../../hooks/useGetClientName';
import { unknownGroup } from '../../constants/Group';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
  vendorSelect: {
    minWidth: '500px',
    marginBottom: '10px',
  },
  list: {
    paddingLeft: '25px',
    margin: '0',
  },
  listBox: {
    maxHeight: '500px',
    minWidth: '500px',
    overflow: 'auto',
    flexBasis: '100%',
  },
});

export const ClientDataPublishUpdatesDialog: React.FC = () => {
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [publishVendor, { isLoading: isPublishing }] = usePublishVendorMutation();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const getClientName = useGetClientName();
  const {
    clientDataBranch = ClientDataBranch.Main,
    clientDataType,
    clientId,
  } = useAppSelector((state) => state?.clientData);
  const [vendorsToPublishList, setVendorsToPublishList] = useState<string[]>([]);

  const { vendorData: { locale = '', productionURL = '' } = {} } = useClientDataRepo({ useVendorData: true });
  const { currentData: clientPublishedVersions, isFetching: isLoadingClientPublishedVersions } =
    useGetClientPublishedVersionsQuery(
      { clientId, groupId, dataType: clientDataType },
      {
        skip:
          !clientId ||
          !clientDataType ||
          clientDataType === ClientDataType.Reference ||
          clientDataBranch !== ClientDataBranch.Main,
      },
    );

  useEffect(() => {
    setVendorsToPublishList([]);
  }, [clientId, clientDataType, clientDataBranch, clientPublishedVersions]);

  const vendorsList = clientPublishedVersions?.vendors
    ? clientPublishedVersions.vendors
        .filter((vendor) => vendor.published?.structureVersion !== clientPublishedVersions.latestVersion)
        .map((vendor) => vendor.key)
    : [];

  return (
    <Dialog dialogKey={Dialogs.ClientDataPublishUpdates} maxWidth="sm" disableClose={isPublishing}>
      <ClientDataPublishDialogStripes isPublishing={isPublishing} />
      <DialogContent>
        {clientDataType === ClientDataType.Supplier && (
          <>
            <MultiselectField
              input={{
                onChange: (value: any) => {
                  setVendorsToPublishList(value);
                },
                value: vendorsToPublishList,
              }}
              disabled={isPublishing || isLoadingClientPublishedVersions}
              label={t(I18nKeys.ClientDataPublishVendorsList)}
              options={vendorsList}
              renderValue={(selected: string[]) =>
                selected.length === vendorsList.length
                  ? `All Vendors (${selected.length})`
                  : selected.map((key) => getClientName(key)).join(', ')
              }
              renderItemValue={(key) => getClientName(key)}
              className={classes.vendorSelect}
            />
            {vendorsToPublishList.length > 0 && (
              <Typography gutterBottom>
                <Trans
                  i18nKey={I18nKeys.ClientDataPublishUpdatesSupplierDialogText as string}
                  values={{
                    supplier: mapClientIdToConfiguratorAndVendor(clientId).vendor,
                    currentVersion: clientPublishedVersions?.latestVersion || 0,
                  }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
            )}
            {vendorsToPublishList.length > 0 && (
              <Box className={classes.listBox}>
                <ul className={classes.list}>
                  {vendorsToPublishList.map((vendorKey) => {
                    const vendorSupplierPublishedVersions = clientPublishedVersions?.vendors?.find(
                      (vendor) => vendor.key === vendorKey,
                    );
                    const isVendorSupplierPublished = !!vendorSupplierPublishedVersions?.published;
                    return (
                      <li key={vendorKey}>
                        <Typography>
                          <Trans
                            i18nKey={
                              (isVendorSupplierPublished
                                ? I18nKeys.ClientDataPublishUpdatesSupplierVendorVersionBump
                                : I18nKeys.ClientDataPublishUpdatesSupplierVendorNotPublishedVersionBump) as string
                            }
                            values={{
                              client: getClientName(vendorKey),
                              currentVersion: clientPublishedVersions?.latestVersion || 0,
                              publishedVersion: vendorSupplierPublishedVersions?.published?.structureVersion || 0,
                            }}
                            components={{ bold: <strong /> }}
                          />
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Box>
            )}
          </>
        )}
        {clientDataType === ClientDataType.Vendor && clientPublishedVersions && (
          <>
            <Typography gutterBottom>
              <Trans i18nKey={I18nKeys.ClientDataPublishUpdatesDialogText as string} />
            </Typography>
            <Box className={classes.listBox}>
              <ClientDataSuppliersVersionBumpListDetails clientPublishedVersions={clientPublishedVersions} />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isPublishing}
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
          id="site-data-publish-updates-dialog-cancel-button"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        {clientDataType === ClientDataType.Vendor && (
          <Button
            disabled={isPublishing}
            onClick={() => {
              const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
              const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
              const viewUrl =
                `${configuratorUrl}` +
                `${configuratorUrl.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2` +
                `&fetchFromServer=true`;
              window.open(viewUrl, '_blank', 'noopener noreferrer');
            }}
            color="primary"
            id="site-data-publish-updates-dialog-preview-button"
          >
            {t(I18nKeys.PreviewButton)}
          </Button>
        )}
        <LoadingButton
          onClick={async () => {
            if (clientDataType === ClientDataType.Vendor) {
              publishVendor({
                clientId,
                suppliers: [],
                groupId,
              })
                .unwrap()
                .then(() => {
                  dispatch(
                    setPublishMergeResult({
                      data: { mainMerge: { branch: ClientDataBranch.Main, status: MergeStatus.Succeed } },
                      isSuccess: true,
                      error: undefined,
                    }),
                  );
                })
                .catch((error) => {
                  dispatch(setPublishMergeResult({ data: undefined, isSuccess: false, error }));
                })
                .finally(() => {
                  dispatch(closeDialogFunc());
                  dispatch(openDialog({ dialog: Dialogs.ClientDataPublishResult }));
                });
            } else {
              dispatch(
                setPublishMergeResult({
                  data: { mainMerge: { branch: ClientDataBranch.Main, status: MergeStatus.Succeed } },
                  isSuccess: true,
                  error: undefined,
                }),
              );
              dispatch(setVendorsToPublish(vendorsToPublishList || []));
              dispatch(closeDialogFunc());
              dispatch(openDialog({ dialog: Dialogs.ClientDataPublishResult }));
            }
          }}
          loading={isPublishing}
          disabled={isPublishing || (clientDataType === ClientDataType.Supplier && vendorsToPublishList.length === 0)}
          color="primary"
          id="site-data-publish-updates-dialog-update-button"
        >
          {t(I18nKeys.ClientDataUpdateButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
