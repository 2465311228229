import React from 'react';

interface Props {
  height?: string;
  className?: string;
}

export const IdeaRoomLogo: React.FC<Props> = ({ className, height }: Props) => (
  <img alt="IdeaRoom" className={className} height={height} src="/idearoom-logo.svg" />
);
