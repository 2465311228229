import { Alert, DialogActions, DialogContent, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { BRANCH_LABELS, MergeStatus } from '../constants/ClientData';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { config } from '../config/config';
import { Button } from './library/Button';

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const ProductionLinkUrl: React.FC<{ url?: string; children?: React.ReactNode }> = ({ url, children }) => (
  <Link href={url} target="_blank" rel="noreferrer noopener" underline="hover">
    {children}
  </Link>
);

export const SiteDetailPublishResultDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.clientData);
  const { publishMergeResult } = useAppSelector((state) => state.vendorData);
  const {
    data,
    error,
    isSuccess: isPublishSuccess,
  } = publishMergeResult || { data: undefined, error: undefined, isSuccess: false };

  const { configurator = '', vendor = '' } = clientId ? mapClientIdToConfiguratorAndVendor(clientId) : {};
  const [productionUrl, setProductionUrl] = useState('');
  const { vendorData: { locale = '', productionURL: vendorUrl = '' } = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });

  useEffect(() => {
    if (clientId !== undefined && clientId !== '' && data && !isLoadingVendorData) {
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, vendorUrl);
      const configuratorUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
      setProductionUrl(configuratorUrl);
    }
  }, [clientId, data, isLoadingVendorData]);

  const additionalBranchesMergesWithConflicts =
    data?.additionalBranchMerges?.filter(({ status }) => status === MergeStatus.Conflicts) || [];

  const mergeSuccessful = isPublishSuccess && data && data.mainMerge.status === MergeStatus.Succeed;
  const errorMessage = error && 'data' in error ? error.data.replace(URL_REGEX, '') : '';
  const errorCellLink = error && 'data' in error ? (error.data.match(URL_REGEX) || [])[0] : undefined;
  const environment = config.environment.STAGE || 'development';

  return (
    <Dialog dialogKey={Dialogs.SiteDetailPublishResult} maxWidth="lg">
      <DialogContent sx={{ minWidth: '300px', maxWidth: '500px' }}>
        {mergeSuccessful && (
          <>
            <Stack direction="row" spacing="16px" alignItems="center">
              <CheckCircleIcon sx={{ width: '30px', height: '30px', color: (theme) => theme.palette.success.dark }} />
              <Typography sx={{ flexGrow: 1 }}>
                <Trans
                  i18nKey={I18nKeys.ClientDataPublishSuccess as string}
                  components={{ a: <ProductionLinkUrl url={productionUrl} /> }}
                  context={environment}
                />
              </Typography>
            </Stack>
            <Stack direction="row" spacing="16px" alignItems="center">
              {data &&
                data.additionalBranchMerges?.length &&
                (additionalBranchesMergesWithConflicts.length > 0 ? (
                  <Typography>
                    <Trans
                      i18nKey={I18nKeys.ClientDataPublishSuccessConflicts as string}
                      values={{
                        branches: additionalBranchesMergesWithConflicts.map(({ branch }) => t(BRANCH_LABELS[branch])),
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography>
                    <Trans i18nKey={I18nKeys.ClientDataPublishSuccessNoConflicts as string} />
                  </Typography>
                ))}
            </Stack>
          </>
        )}

        {!mergeSuccessful && (
          <>
            <Stack direction="row" spacing="16px" alignItems="center">
              <CancelIcon sx={{ width: '30px', height: '30px', color: (theme) => theme.palette.error.dark }} />
              {error && (
                <Alert severity="error" icon={false} sx={{ flexGrow: 1 }}>
                  <code>{errorMessage}</code>
                  {errorCellLink && (
                    <>
                      <br />
                      <a href={errorCellLink}>Cell with error</a>
                    </>
                  )}
                </Alert>
              )}
            </Stack>
            <Stack direction="row" spacing="16px" alignItems="center">
              {data && data.mainMerge.status === MergeStatus.Conflicts && (
                <Typography>
                  <Trans i18nKey={I18nKeys.ClientDataPublishUnresolvedMergeConflicts as string} />
                </Typography>
              )}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!!additionalBranchesMergesWithConflicts.length && (
          <Button
            variant="contained"
            sx={{
              ...(mergeSuccessful ? {} : { bgcolor: (theme) => `${theme.palette.error.dark} !important` }),
            }}
          >
            {t(I18nKeys.ClientDataPublishResolveNowButton)}
          </Button>
        )}
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant={additionalBranchesMergesWithConflicts.length ? 'outlined' : 'contained'}
          sx={{
            ...(mergeSuccessful || additionalBranchesMergesWithConflicts.length
              ? {}
              : { bgcolor: (theme) => `${theme.palette.error.dark} !important` }),
          }}
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
