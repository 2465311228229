/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, BoxProps } from '@mui/material';
import ideaRoomIconSrc from '../images/ideaRoomIcon.svg';

export const IdeaRoomOnlyIndicator: React.FC<BoxProps & { height?: string; disableMargin?: boolean }> = ({
  height = '19px',
  disableMargin = false,
  ...props
}) => (
  <Box {...props} sx={{ pt: '1px', mr: disableMargin ? '0px' : '8px', ...props?.sx, height }}>
    <img alt="IdeaRoom" height={height} src={ideaRoomIconSrc} />
  </Box>
);
