/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { SelectField } from '../library/SelectField';

type Props = Pick<SelectProps, 'className' | 'style' | 'label' | 'disabled'> & {
  options: string[];
  renderValue?: { (selected: unknown): string };
  renderItemValue?: { (value: string): string };
  input: Pick<WrappedFieldInputProps, 'onChange' | 'value'>;
  meta?: Pick<WrappedFieldMetaProps, 'touched' | 'error'>;
};

const SELECT_ALL_VALUE = 'select-all';

export const MultiselectField: React.FC<Props> = (props: Props) => {
  const {
    input: { onChange, value = [] },
    options,
    meta: { touched, error } = {},
    renderValue = (selected: unknown) => (Array.isArray(selected) ? selected.join(', ') : ''),
    renderItemValue = (itemValue: string) => itemValue,
    ...otherProps
  } = props;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value: newValue },
    } = event;

    let newValueAsStringArray: string[] = [];
    if (typeof newValue === 'string') newValueAsStringArray = newValue.split(',');
    else if (Array.isArray(newValue)) newValueAsStringArray = newValue;

    if (newValueAsStringArray.includes(SELECT_ALL_VALUE)) {
      if (newValueAsStringArray.length <= options.length) {
        onChange([...options]);
      } else {
        onChange([]);
      }
    } else {
      onChange(newValueAsStringArray);
    }
  };

  return (
    <SelectField
      {...otherProps}
      defaultValue={undefined}
      id="multiple-select-checkbox"
      multiple
      value={value as string[]}
      onChange={handleChange}
      renderValue={renderValue}
      error={!!(touched && error)}
    >
      <MenuItem key="select-all" value={SELECT_ALL_VALUE}>
        <Checkbox checked={value.length > 0} indeterminate={value.length > 0 && value.length < options.length} />
        <ListItemText primary="(Select All)" />
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={value.includes(option)} />
          <ListItemText primary={renderItemValue(option)} />
        </MenuItem>
      ))}
    </SelectField>
  );
};
