import { styled, Button as MuiButton, alpha } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  height: '44px',
  padding: '8px 32px',
  textTransform: 'none',
  borderRadius: '22px',
  fontWeight: 500,
  borderColor: '#D7DBDF',
  fontSize: '16px',
  boxShadow: 'none',
  '&.MuiButton-outlined': {
    color: '#323B4B',
    '&:hover': {
      backgroundColor: '#EBEFF1',
      boxShadow: 'none',
    },
    '& .MuiCircularProgress-root': {
      color: alpha('#D7DBDF', 0.9),
    },
    '& .MuiButton-loadingIndicator': {
      backgroundColor: '#FFFFFF',
    },
  },
  '&.MuiButton-contained': {
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      opacity: 0.8,
      boxShadow: 'none',
    },
    '& .MuiCircularProgress-root': {
      color: '#FFFFFF',
    },
    '& .MuiButton-loadingIndicator': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  '& .MuiButton-loadingIndicator': {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 64px)',
  },
  '&.MuiButton-outlined.Mui-disabled': {
    borderColor: alpha('#D7DBDF', 0.7),
    color: alpha('#D7DBDF', 0.9),
  },
  '&.MuiButton-contained.Mui-disabled': {
    opacity: 0.4,
    backgroundColor: theme.palette.secondary.main,
  },
}));
