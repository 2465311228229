import { ListItemText, MenuItem as MuiMenuItem, Divider, Box, Menu, MenuList } from '@mui/material';
import { styled } from '@mui/styles';
import { navigate } from 'hookrouter';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../constants/AppRoutes';
import { setSelectedClientId, resetClientIds } from '../ducks/viewerSlice';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { Language } from '../constants/Languages';
import { signOutAction } from '../ducks/currentUserSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { UserMenuItem } from './UserMenuItem';

const Spacer = styled(Box)({
  height: '14px',
  borderRight: '1px solid #DEDEDE',
});

export const UserMenuSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentLanguage, setCurrentLanguage] = React.useState<Language | undefined>(undefined);

  const { defaultClientId = '', languages = [] } = useAppSelector((state) => state.viewer);
  const variant = useAppSelector((state) => state.menu.variant);

  useEffect(() => {
    setCurrentLanguage(languages.find((lng: Language) => lng.key === i18n.language));
  }, [languages]);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleProfileClicked = (): void => {
    dispatch(setSelectedClientId(defaultClientId));
    navigate(AppRoutes.Profile);
  };

  const handleSignOutClicked = (): void => {
    dispatch(resetClientIds());
    dispatch(signOutAction());
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
        <Spacer />
        <UserMenuItem variant={variant} onClick={handleClick} />
      </Box>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList style={{ overflow: 'overlay', width: '281px' }}>
          <MuiMenuItem key={I18nKeys.UserMenuItemMyProfile} onClick={handleProfileClicked}>
            <ListItemText primary={t(I18nKeys.UserMenuItemMyProfile)} />
          </MuiMenuItem>
          {languages.filter((lng: Language) => !lng.hidden).length > 1 && (
            <MuiMenuItem key="select-language" onClick={() => dispatch(openDialog({ dialog: Dialogs.Language }))}>
              <ListItemText primary={(currentLanguage && currentLanguage.label) || t(I18nKeys.UserMenuItemLanguage)} />
            </MuiMenuItem>
          )}
          <Divider />
          <MuiMenuItem key={I18nKeys.UserMenuItemLogOut}>
            <ListItemText onClick={handleSignOutClicked} primary={t(I18nKeys.UserMenuItemLogOut)} />
          </MuiMenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
