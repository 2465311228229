import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog } from '../../ducks/dialogSlice';
import { Dialog } from '../library/Dialog';
import { Button } from '../library/Button';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { unknownUser } from '../../types/User';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { useGetClientDataBranchChangesSummaryQuery, useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { setClientDataBranch } from '../../ducks/clientDataSlice';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { unknownGroup } from '../../constants/Group';
import { getChangedSecondaryMenuSections } from '../../utils/menuUtils';

export const SitesRevertDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user: { name: userName } = unknownUser } = useAppSelector((state) => state?.currentUser);
  const { mainMenuItem } = useAppSelector((state) => state?.menu);
  const { clientDataBranch, clientId, clientDataType } = useAppSelector((state) => state?.clientData);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [deleteBranch, { isLoading }] = useSharedDeleteBranchMutation();
  const branchExists = !!activeBranches.find((b) => b.branchType === ClientDataBranch.SiteDetail);

  const { currentData: changesSummary } = useGetClientDataBranchChangesSummaryQuery(
    { dataType: clientDataType, clientId, groupId, branch: ClientDataBranch.SiteDetail },
    {
      skip: !clientId || !branchExists,
      refetchOnMountOrArgChange: true,
    },
  );

  const changedPages = useMemo(
    () =>
      getChangedSecondaryMenuSections(mainMenuItem, changesSummary).flatMap(({ items }) =>
        items.map(({ label }) => label(t)),
      ),
    [mainMenuItem, changesSummary, t],
  );
  const authors = useMemo(
    () =>
      Array.from(
        new Set(
          changesSummary?.flatMap(({ changes }) =>
            changes.flatMap(({ authors: a }) =>
              a.map((name) => (name === userName ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : name)),
            ),
          ) || [],
        ),
      ).sort((a, b) => {
        if (a === t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor)) return -1;
        if (b === t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor)) return 1;
        return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
      }),
    [changesSummary, userName, t],
  );

  return (
    <Dialog id="sites-revert" dialogKey={Dialogs.SitesRevertBranch} maxWidth="sm">
      <DialogTitle id="confirmation-dialog-title">{t(I18nKeys.SitesRevertBranchDialogTitle)}</DialogTitle>
      <DialogContent>
        <Typography>
          <Trans
            i18nKey={I18nKeys.SitesRevertBranchDialogText as string}
            values={{
              authors: t(I18nKeys.SitesRevertBranchDialogTextAuthors, {
                authors,
                count: authors.length,
              }),
              changedPages: t(I18nKeys.SitesRevertBranchDialogTextChangedPages, {
                changedPages,
                count: changedPages.length,
              }),
            }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialog());
          }}
          variant="outlined"
          id="sites-revert-dialog-cancel-button"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          onClick={(): void => {
            if (branchExists) {
              deleteBranch({
                dataType: clientDataType,
                branch: ClientDataBranch.SiteDetail,
                clientId,
                groupId,
              })
                .unwrap()
                .then(() => {
                  dispatch(closeDialog());
                })
                .catch(() => {
                  dispatch(setClientDataBranch(clientDataBranch));
                });
              dispatch(setClientDataBranch(ClientDataBranch.Main));
            }
          }}
          loading={isLoading}
          variant="contained"
          id="sites-revert-dialog-revert-button"
        >
          {t(I18nKeys.ClientDataRevertBranchDialogRevertButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
