/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CheckboxProps, Box, FormControlLabel, Typography, Checkbox, Stack } from '@mui/material';
import { IdeaRoomOnlyIndicator } from '../IdeaRoomOnlyIndicator';
import { BetaIndicator } from '../sites/BetaIndicator';
import { ContentSkeleton } from './ContentSkeleton';

type Props = CheckboxProps & {
  label: string;
  initializing?: boolean;
  startAdornment?: React.ReactNode;
  ideaRoomOnly?: boolean;
  beta?: boolean;
};

export const LabeledCheckboxField: React.FC<Props> = (props: Props) => {
  const { initializing = false, disabled, label, startAdornment, ideaRoomOnly, beta } = props;

  return (
    <FormControlLabel
      sx={{ width: '100%', mx: '0px' }}
      control={
        <ContentSkeleton sx={{ my: '4px', mr: '12px' }} initializing={initializing}>
          <Checkbox color="secondary" sx={{ p: '0px' }} {...props} />
        </ContentSkeleton>
      }
      slotProps={{
        typography: {
          sx: { width: '100%' },
        },
      }}
      label={
        <ContentSkeleton initializing={initializing}>
          <Stack direction="row" alignItems="center" spacing="6px" sx={{ width: 'fit-content' }}>
            {startAdornment}
            {ideaRoomOnly && <IdeaRoomOnlyIndicator height="19.6px" disableMargin sx={{ pt: '0.6px' }} />}
            <Typography sx={{ color: disabled ? '#000000' : '#323B4B', opacity: disabled ? 0.6 : 1 }}>
              {label}
            </Typography>
            {beta && (
              <Box>
                <BetaIndicator />
              </Box>
            )}
          </Stack>
        </ContentSkeleton>
      }
    />
  );
};
