import { Box, Breadcrumbs, Grid2 as Grid, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { navigate, usePath } from 'hookrouter';
import React from 'react';
import { Trans } from 'react-i18next';
import { IntegrationStatus } from '@idearoom/types';
import { AppRoutes } from '../../constants/AppRoutes';
import { Breadcrumb } from '../../types/Breadcrumb';
import { i18n } from '../../i18n';
import { I18nKeys } from '../../constants/I18nKeys';
import { unknownGroup } from '../../constants/Group';
import { useAppSelector } from '../../hooks';
import { ViewerState } from '../../types/ViewerState';
import greenCheckConnected from '../../images/greenCheckConnected.svg';

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbs: {
    paddingLeft: theme.spacing(2),
  },
  breadcrumbLink: {
    cursor: 'pointer',
    fontWeight: 'lighter',
  },
  paymentConnectedChip: {
    padding: '4px 8px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 12px',
    gap: '8px',
    borderRadius: '16px',
    opacity: '0px',
    background: '#FFFFFFE5',
  },
  paymentConnectedText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#000000DE',
  },
}));

interface StateProps {
  readonly groupName?: string;
  readonly groupId?: string;
  readonly contactBarTextColor?: string;
  readonly selectedTable?: string;
}

type Props = StateProps;

interface GetBreadcrumbsOwnProps {
  readonly groupName?: string;
  readonly groupId?: string;
  readonly path: string;
}

/**
 * Get AppRoute and SubPath from url path
 */
const destructPath = (path: string) => {
  let subPath; // Not using yet, but if need to do an additional "lookup" could be used for that.
  let appPath = path;
  let appRoute;
  // First check if the current path resolves to an AppRoutes
  let foundKey = Object.keys(AppRoutes).find((key) => AppRoutes[key as keyof typeof AppRoutes] === appPath);
  if (foundKey) {
    appRoute = AppRoutes[foundKey as keyof typeof AppRoutes];
  } else {
    // Path didn't resolve to an AppRoutes to assume it is in the shape of '/path/to/something' where
    // 'path/to' is one of the AppRoutes and 'something' is a subPath paramater (see Portal.tsx routes
    // for an example of this).
    subPath = path.slice(path.lastIndexOf('/') + 1);
    appPath = path.slice(0, path.lastIndexOf('/'));
    foundKey = Object.keys(AppRoutes).find((key) => AppRoutes[key as keyof typeof AppRoutes] === appPath);
    if (foundKey) {
      appRoute = AppRoutes[foundKey as keyof typeof AppRoutes];
    }
  }
  return { appRoute, subPath };
};

/**
 * Gets an array of Breadcrumb (only 2 levels deep supported) based on the navigation path
 * passed in.
 */
const getBreadcrumbs = ({ groupName, path }: GetBreadcrumbsOwnProps): Breadcrumb[] => {
  const breadcrumbs: Breadcrumb[] = [];
  const { appRoute, subPath } = destructPath(path);

  // This is the mapping layer of an AppRoutes to Breadcrumb[] for the entire app
  switch (appRoute) {
    case AppRoutes.ForgotPassword:
      breadcrumbs.push({ label: i18n.t(I18nKeys.TopBarBreadcrumbsForgotPassword), appRoute });
      break;
    case AppRoutes.Groups:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuGroupsOption), appRoute });
      // groupName was passed in because it was already resolved so use it instead of looking up the
      // value using 'subPath' which in this case would be 'groupId'.
      if (subPath && groupName) {
        breadcrumbs.push({ label: groupName });
      }
      break;
    case AppRoutes.Home:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuGettingStartedOption), appRoute });
      break;
    case AppRoutes.Leads:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuLeadsOption), appRoute });
      break;
    case AppRoutes.Analyze:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuUsageOption), appRoute });
      break;
    case AppRoutes.Dealers:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuDealersOption), appRoute });
      break;
    case AppRoutes.Integrations:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuIntegrationsOption), appRoute });
      break;
    case AppRoutes.Portal:
      breadcrumbs.push({ label: i18n.t(I18nKeys.TopBarBreadcrumbsPortal), appRoute });
      break;
    case AppRoutes.Profile:
      breadcrumbs.push({ label: i18n.t(I18nKeys.TopBarBreadcrumbsProfile), appRoute });
      break;
    case AppRoutes.Team:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuUsersOption), appRoute });
      break;
    case AppRoutes.Orders:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuOrdersOption), appRoute });
      break;
    case AppRoutes.Payments:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuPaymentsOption), appRoute });
      break;
    case AppRoutes.Pricing:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuPricingOption), appRoute });
      break;
    case AppRoutes.ClientData:
      breadcrumbs.push({
        label: i18n.t(I18nKeys.MenuClientDataOption),
        appRoute,
      });
      break;
    case AppRoutes.Reports:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuReportsOption), appRoute });
      break;
    case AppRoutes.Settings:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuSettingsOption), appRoute });
      break;
    case AppRoutes.SignIn:
      breadcrumbs.push({ label: '', appRoute });
      break;
    case AppRoutes.SignUp:
      breadcrumbs.push({ label: '', appRoute });
      break;
    case AppRoutes.ClientSignUp:
      breadcrumbs.push({ label: '', appRoute });
      break;
    case AppRoutes.Sites:
      breadcrumbs.push({ label: i18n.t(I18nKeys.MenuSitesOption), appRoute });
      break;
    default:
      break;
  }

  return breadcrumbs;
};

interface GetChildOwnProps {
  readonly path: string;
  readonly viewer: ViewerState;
  readonly classes: ReturnType<typeof useStyles>;
}

const getRightChild = ({ viewer, classes, path }: GetChildOwnProps) => {
  const { appRoute } = destructPath(path);
  switch (appRoute) {
    case AppRoutes.Payments: {
      if (viewer.paymentIntegrationStatus?.status === IntegrationStatus.Connected) {
        return (
          <Grid container justifyContent="end" size={2}>
            <Box className={classes.paymentConnectedChip}>
              <Typography variant="body1" className={classes.paymentConnectedText}>
                <Trans i18nKey={I18nKeys.MenuPaymentsOptionConnected as string} />
              </Typography>
              <img alt="Connected" src={greenCheckConnected} />
            </Box>
          </Grid>
        );
      }
      return undefined;
    }
    default:
      return undefined;
  }
};

export const TopBarBreadcrumbs: React.FC<Props> = () => {
  const classes = useStyles();
  const path = usePath();

  const { groupName } = useAppSelector((state) => state.group.group || unknownGroup);
  const viewer = useAppSelector((state) => state.viewer);
  const { contactBarTextColor = '#fff' } = useAppSelector((state) => state.viewer.theme) || {};

  const breadcrumbs = getBreadcrumbs({ groupName, path });
  const rightChild = getRightChild({ path, viewer, classes });

  return (
    <Grid container spacing={0} size={12}>
      <Grid size={rightChild ? 10 : 12}>
        <Breadcrumbs
          className={classes.breadcrumbs}
          style={{ color: contactBarTextColor }}
          separator="›"
          maxItems={2}
          aria-label="breadcrumbs"
        >
          {breadcrumbs && breadcrumbs[0] && (
            <Box display="flex" alignItems="center">
              <Typography
                variant="h6"
                id={breadcrumbs[0].label}
                onClick={
                  breadcrumbs[0].appRoute && breadcrumbs.length === 2
                    ? (): void => {
                        const { appRoute } = breadcrumbs[0];
                        navigate(appRoute as string);
                      }
                    : undefined
                }
                className={breadcrumbs[0].appRoute && breadcrumbs.length === 2 ? classes.breadcrumbLink : undefined}
              >
                {breadcrumbs[0].label}
              </Typography>
              {breadcrumbs && breadcrumbs[0] && breadcrumbs[0].helpMessage && (
                <Tooltip title={breadcrumbs[0].helpMessage}>
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              )}
            </Box>
          )}

          {breadcrumbs && breadcrumbs[1] && (
            <Box display="flex" alignItems="center">
              <Typography variant="h6" id={breadcrumbs[1].label} color="secondary">
                {breadcrumbs[1].label}
              </Typography>
              {breadcrumbs && breadcrumbs[1] && breadcrumbs[1].helpMessage && (
                <Tooltip title={breadcrumbs[1].helpMessage}>
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              )}
            </Box>
          )}
        </Breadcrumbs>
      </Grid>
      {rightChild}
    </Grid>
  );
};
