import { Permission } from '@idearoom/types';
import { User } from '../types/User';
import { isIdeaRoomUser } from './userUtils';
import { GroupMember } from '../types/GroupMember';

// Filter to unique permissions
export const filterUserPermissions = (permissions: string[] | undefined): string[] =>
  permissions && permissions.length > 0
    ? permissions.filter((value, index, self) => self.indexOf(value) === index)
    : [];

export const hasAdminPermissions = (permissions: string[] | undefined): boolean =>
  (permissions && (permissions.includes(Permission.Admin) || permissions.includes(Permission.SuperUser))) || false;

export const canUsePayments = (
  user: User | GroupMember,
  groupId: string,
  paymentIntegrationStatus: string | undefined,
): boolean => {
  const isIdeaRoom = isIdeaRoomUser(user);
  const isAdmin = hasAdminPermissions(user.permissions);
  const isPaymentIntegrationEnabled =
    paymentIntegrationStatus !== undefined && ['connected', 'deactivated'].includes(paymentIntegrationStatus);

  return (
    isIdeaRoom ||
    ([
      'DakotaStorageBuildings',
      'McGeeMetals',
      'NewEnglandOutdoorsSheds&Gazebos',
      'ShedHappens',
      'StrongShed',
      'FancyNerdSheds',
      'FancyNerdCarports',
      'AtomicBuildings',
      'MetalCarports-Eagle',
      'CountryTymeSheds',
    ].some((group) => group === groupId) &&
      // Only Admins can see Payments until payments are enabled
      (isAdmin || isPaymentIntegrationEnabled))
  );
};
