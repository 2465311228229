import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  styled,
  Theme,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Circle, Edit } from '@mui/icons-material';
import { MainMenuItems } from '../constants/MainMenuItems';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  menuItemText: {
    width: 'auto',
    textWrap: 'nowrap',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

interface Props {
  id?: MainMenuItems | string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ideaRoomOnly?: boolean;
  selected?: boolean;
  edited?: boolean;
}

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: '20px',
  alignItems: 'center',
  alignSelf: 'center',
});

export const SecondaryMenuItem: React.FC<Props> = ({
  id,
  label,
  onClick,
  ideaRoomOnly,
  selected = false,
  edited = false,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ListItem disablePadding sx={{ px: '6px' }}>
      <ListItemButton
        id={id}
        onClick={(e) => onClick?.(e)}
        sx={{
          p: '0px',
          height: '32px',
          width: '100%',
          backgroundColor: selected ? '#EBEFF1' : 'transparent',
          borderRadius: '4px',
        }}
      >
        <Box
          display="flex"
          sx={{
            height: '32px',
            pr: '10px',
            pl: edited ? '18.5px' : '20px',
            minWidth: '0px',
            flex: 1,
            justifyContent: 'start',
          }}
        >
          <ListItemIcon sx={{ m: '0px', mr: edited ? '9.5px' : '8px' }}>
            {edited ? (
              <Edit
                sx={{
                  color: theme.palette.secondary.main,
                  height: '20px',
                  width: '20px',
                }}
              />
            ) : (
              <Circle sx={{ color: '#323B4B', opacity: 0.4, height: '16px', width: '16px' }} />
            )}
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemText}
            primary={label}
            sx={{ pl: '0px', my: '0px', alignSelf: 'center' }}
            slotProps={{
              primary: {
                fontSize: '14px',
                color: edited ? theme.palette.secondary.main : '#323B4B',
              },
            }}
          />
        </Box>
        {ideaRoomOnly && (
          <ListItemIcon sx={{ m: '0px' }}>
            <IdeaRoomOnlyIndicator disableMargin />
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  );
};
