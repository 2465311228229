import React, { useState } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { AppState } from '../../types/AppState';
import { useAppSelector } from '../../hooks';
import { unknownGroup } from '../../constants/Group';
import { Page } from '../library/Page';
import { SitesCard } from './SitesCard';

export const Sites: React.FC = () => {
  const configurators = useAppSelector(
    (state: AppState) => (state.currentUser.group || unknownGroup).configurators || [],
  );

  const [initializing, setInitializing] = useState(
    Object.fromEntries(configurators.map(({ clientId }) => [clientId, true])),
  );

  return (
    <Page header="Sites">
      <Grid container gap="30px">
        {configurators.map((configurator) => (
          <Grid
            key={configurator.clientId}
            sx={{ display: 'flex', flex: 1, maxWidth: '550px', minWidth: '421px', height: '262px' }}
          >
            <SitesCard
              configurator={configurator}
              initializing={!!Object.values(initializing).reduce((a, b) => a && b, true)}
              setInitializing={setInitializing}
            />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};
