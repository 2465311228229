import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  IconButton,
  useMediaQuery,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WebIcon from '@mui/icons-material/Web';
import SettingsIcon from '@mui/icons-material/Settings';
import { mdiCashMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import { navigate } from 'hookrouter';
import React from 'react';
import { AnyAction } from 'redux';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, DoneAll, OpenInNew } from '@mui/icons-material';
import menuIntegrationsIcon from './menuIntegrationsIcon.svg';
import menuPaymentsIcon from './menuPaymentsIcon.svg';
import menuOrdersIcon from './menuOrdersIcon.svg';
import { AppRoutes } from '../../constants/AppRoutes';
import { DockedDrawerWidth } from '../../constants/DockedDrawerWidth';
import { menuItems } from '../../constants/map-needed/MenuItems';
import { MainMenuItems } from '../../constants/MainMenuItems';
import { setMainMenuItem } from '../../middleware/menuThunk';
import { unknownGroup } from '../../constants/Group';
import { unknownUser } from '../../types/User';
import { resetSearch } from '../../ducks/search';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isIdeaRoomGroup as isIdeaRoomGroupFunc } from '../../utils/userUtils';
import { MenuStatus } from '../../constants/Viewer';
import { UserMenuItem } from './UserMenuItem';
import { IdeaRoomOnlyIndicator } from '../IdeaRoomOnlyIndicator';
import { setMenuStatus, setSelectedClientId as setSelectedClientIdFunc } from '../../ducks/viewerSlice';
import { MenuIcon } from '../MenuIcon';
import { saveUserPreferences } from '../../ducks/currentUserSlice';
import { UserPreference } from '../../constants/User';
import { viewSite } from '../../utils/configuratorUtils';
import { GettingStartedSteps } from '../../constants/gettingStartedConfig';
import { useCompleteSetupStepMutation, useGetGroupSetupStepsQuery } from '../../services/groupApi';

const getMenuItemIcon = (menuItem: MainMenuItems) => {
  switch (menuItem) {
    case MainMenuItems.Home: {
      return <DoneAll />;
    }
    case MainMenuItems.Team:
    case MainMenuItems.Groups: {
      return <GroupIcon />;
    }
    case MainMenuItems.Leads: {
      return <Icon path={mdiCashMultiple} size="24px" />;
    }
    case MainMenuItems.Orders: {
      return <img src={menuOrdersIcon} width="24px" alt="Orders" />;
    }
    case MainMenuItems.Analyze: {
      return <SignalCellularAltIcon />;
    }
    case MainMenuItems.Integrations: {
      return <img src={menuIntegrationsIcon} width="24px" alt="Integrations" />;
    }
    case MainMenuItems.Dealers: {
      return <StoreIcon />;
    }
    case MainMenuItems.Payments: {
      return <img src={menuPaymentsIcon} width="24px" alt="Payments" />;
    }
    case MainMenuItems.Pricing: {
      return <Icon path={mdiCashMultiple} size="24px" />;
    }
    case MainMenuItems.Reports: {
      return <AssessmentIcon />;
    }
    case MainMenuItems.Settings: {
      return <SettingsIcon />;
    }
    case MainMenuItems.Sites:
    case MainMenuItems.ClientData: {
      return <WebIcon />;
    }
    default: {
      return null;
    }
  }
};

const useStyles = makeStyles<Theme, { menuStatus: MenuStatus; mobileView: boolean }>((theme: Theme) => ({
  root: {
    width: DockedDrawerWidth,
  },
  currentItem: {
    backgroundColor: '#EEEEF2',
    borderRadius: '4px',
  },
  topSpacer: { backgroundColor: '#E0E0E0', height: '3vh', width: '100%', marginBottom: '30px' },
  // Align the button on the right side of the drawer
  menuClose: { position: 'absolute', right: '0px', top: '0px' },
  drawer: {
    flexShrink: 0,
    position: 'fixed',
    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: ({ menuStatus }) => `${menuStatus === MenuStatus.Closed ? 0 : 260}px`,
  },
  drawerPaper: {
    overflow: 'hidden',
  },
  menuItemText: {
    width: ({ menuStatus }) => (menuStatus !== MenuStatus.Closed ? 'auto' : '0px'),
    textWrap: 'nowrap',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
}));

export const Menu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    group = unknownGroup,
    user = unknownUser,
    availableDealers = [],
    preferences: { [UserPreference.ProfilePreferences]: otherProfilePreferences = {} } = {},
  } = useAppSelector((state) => state.currentUser);
  const viewerState = useAppSelector((state) => state.viewer);
  const { defaultClientId = '', selectedClientId, menuStatus } = viewerState;
  const { mainMenuItem } = useAppSelector((state) => state.menu);
  const { tourIsActive } = useAppSelector((state) => state.gettingStarted);

  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles({ menuStatus, mobileView });

  const [completeSetupStep] = useCompleteSetupStepMutation();

  let groupConfigurators = group.configurators || [];
  const userDealers = user.dealers || [];

  if (userDealers.length && groupConfigurators.length) {
    if (userDealers.length === 1) {
      groupConfigurators = groupConfigurators.map((config: any) => {
        const dealer = availableDealers.find(
          (deal) => deal.clientId === `${config.key}-${config.vendor}` && userDealers.includes(deal.key),
        );
        return {
          ...config,
          url: dealer ? `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}` : config.url,
        };
      });
    } else {
      // Loop through each dealer and add the dealer key to the configurator url and set the name as the dealer name
      groupConfigurators = groupConfigurators.reduce((acc: any[], config: any) => {
        const dealers = availableDealers.filter(
          (dealer) => dealer.clientId === `${config.key}-${config.vendor}` && userDealers.includes(dealer.key),
        );
        return [
          ...acc,
          ...(dealers && dealers.length
            ? dealers.map((dealer) => ({
                ...config,
                url: `${config.url}${config.url.includes('?') ? '&' : '?'}dealer=${dealer.key}`,
                name: dealer.name,
              }))
            : [config]),
        ];
      }, []);
    }
  }

  const isIdearoomGroup = isIdeaRoomGroupFunc(group.groupId);
  const selectedGroupId = group.groupId;

  const isDayOneClient = groupConfigurators?.[0]?.vendorData?.vendor?.maxioSubscriptionId;
  const { data: { steps: gettingStartedSteps = [] } = {} } = useGetGroupSetupStepsQuery(
    {
      clientId: selectedClientId || '',
      groupId: group.groupId,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: false },
  );
  const shouldCompleteConfiguratorStep =
    isDayOneClient &&
    gettingStartedSteps.find((step) => step.key === GettingStartedSteps.TRY_NEW_SITE)?.completed === false;

  const shouldCompleteLeadsStep =
    isDayOneClient &&
    gettingStartedSteps.find((step) => step.key === GettingStartedSteps.VIEW_LEADS_LIST)?.completed === false;

  const dispatchAll = (actions: AnyAction[]): void => {
    actions.forEach((action) => dispatch(action));
  };

  const setSelectedClientId = (clientId: string): void => {
    dispatch(setSelectedClientIdFunc(clientId));
  };

  const closeMenu = (): void => {
    dispatch(setMenuStatus(MenuStatus.Closed));
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.ProfilePreferences,
        preferences: {
          ...otherProfilePreferences,
          menuStatus: MenuStatus.Closed,
        },
      }),
    );
  };

  const handleChangeMenuItemClicked = (menuItem: MainMenuItems): void => {
    let route = '';
    switch (menuItem) {
      case MainMenuItems.Home: {
        route = AppRoutes.Home;
        break;
      }
      case MainMenuItems.Groups: {
        route = AppRoutes.Groups;
        break;
      }
      case MainMenuItems.Leads: {
        if (shouldCompleteLeadsStep) {
          completeSetupStep({ groupId: selectedGroupId, setupStepId: GettingStartedSteps.VIEW_LEADS_LIST });
        }
        route = AppRoutes.Leads;
        break;
      }
      case MainMenuItems.Orders: {
        route = AppRoutes.Orders;
        break;
      }
      case MainMenuItems.Analyze: {
        route = AppRoutes.Analyze;
        break;
      }
      case MainMenuItems.Dealers: {
        route = AppRoutes.Dealers;
        break;
      }
      case MainMenuItems.Integrations: {
        route = AppRoutes.Integrations;
        break;
      }
      case MainMenuItems.Team: {
        route = AppRoutes.Team;
        break;
      }
      case MainMenuItems.Payments: {
        route = AppRoutes.Payments;
        break;
      }
      case MainMenuItems.Pricing: {
        route = AppRoutes.Pricing;
        break;
      }
      case MainMenuItems.Reports: {
        route = AppRoutes.Reports;
        break;
      }
      case MainMenuItems.Settings: {
        route = AppRoutes.Settings;
        break;
      }
      case MainMenuItems.Sites: {
        route = AppRoutes.Sites;
        break;
      }
      case MainMenuItems.ClientData: {
        route = `${AppRoutes.ClientData}/${selectedGroupId}`;
        break;
      }
      default:
        throw new Error(`menuItem not supported: ${menuItem}`);
    }
    if (!selectedClientId) {
      setSelectedClientId(defaultClientId);
    }
    dispatch(setMainMenuItem(menuItem));
    dispatch(resetSearch());
    navigate(route);
  };

  return (
    <Drawer
      variant={mobileView ? 'temporary' : 'permanent'}
      open={menuStatus !== MenuStatus.Closed}
      onClose={closeMenu}
      anchor="left"
      className={classes.drawer}
      PaperProps={{
        className: `${classes.drawer} ${classes.drawerPaper}`,
      }}
    >
      <Typography component="div" className={classes.topSpacer} />

      {!mobileView && (
        <IconButton onClick={closeMenu} className={classes.menuClose}>
          <ChevronLeft />
        </IconButton>
      )}

      <UserMenuItem />

      <Divider />

      <List id="menu-list-items-container">
        {Object.entries(menuItems)
          .filter(([_, menuItem]) =>
            menuItem.visible(user, isIdearoomGroup, groupConfigurators, selectedGroupId, viewerState),
          )
          .map(([key, menuItem]) => (
            <Box
              id={`${menuItem.key}-menu-item`}
              key={menuItem.key}
              sx={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}
            >
              <ListItemButton
                className={mainMenuItem === key ? classes.currentItem : undefined}
                onClick={(): void => handleChangeMenuItemClicked(key as MainMenuItems)}
              >
                <ListItemIcon>{getMenuItemIcon(key as MainMenuItems)}</ListItemIcon>

                <ListItemText className={classes.menuItemText} primary={t(menuItem.i18nKey())} />
                {menuItem.customRightIcon && <MenuIcon iconKey={menuItem.customRightIcon(viewerState)} />}
                {menuItem.ideaRoomOnly(user, selectedGroupId, viewerState) && <IdeaRoomOnlyIndicator disableMargin />}
              </ListItemButton>
              {menuItem.showDivider && <Divider sx={{ marginLeft: '72px', marginRight: '16px' }} />}
            </Box>
          ))}

        <Divider sx={{ marginLeft: '72px', marginRight: '16px' }} />

        {groupConfigurators
          .filter(({ url }) => url)
          .map(({ vendor, key, url, name }) => (
            <ListItemButton
              className="group-configurator-btn"
              key={`${key}-${vendor}-btn`}
              onClick={() =>
                viewSite(url, {
                  tourIsActive,
                  shouldCompleteConfiguratorStep,
                  selectedGroupId: group.groupId,
                  dispatch,
                })
              }
            >
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText className={classes.menuItemText} primary={name} />
            </ListItemButton>
          ))}
      </List>
    </Drawer>
  );
};
