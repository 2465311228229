import React, { useLayoutEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { Integration, IntegrationName, IntegrationStatus } from '@idearoom/types';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IntegrationOptions } from '../types/DialogState';
import { FormData as AdyenFormData, IntegrationsAdyenForm } from './IntegrationsDialogAdyenForm';
import {
  FormData as GoogleAnalyticsFormData,
  IntegrationsGoogleAnalyticsForm,
} from './IntegrationsDialogGoogleAnalyticsForm';
import { FormData as PixelFormData, IntegrationsPixelForm } from './IntegrationsDialogPixelForm';
import { IntegrationsFormFields } from '../constants/FormFields';
import { AppState } from '../types/AppState';
import { Forms } from '../constants/Forms';
import { putIntegration } from '../ducks/integrationSlice';

const getFormData = (appState: AppState, integration: Integration) => {
  switch (integration.name) {
    case IntegrationName.Adyen: {
      const formSelector = formValueSelector(Forms.IntegrationsAdyenForm);
      const formAddress1 = formSelector(appState, IntegrationsFormFields.Address1);
      const formAddress2 = formSelector(appState, IntegrationsFormFields.Address2);
      const formCity = formSelector(appState, IntegrationsFormFields.City);
      const formCountry = formSelector(appState, IntegrationsFormFields.Country);
      const formPhone = formSelector(appState, IntegrationsFormFields.Phone);
      const formPostalCode = formSelector(appState, IntegrationsFormFields.PostalCode);
      const formState = formSelector(appState, IntegrationsFormFields.State);
      const { address, phoneNumber } = integration.data?.store || {};

      const formData = {
        [IntegrationsFormFields.Address1]: formAddress1 || address?.address1 || '',
        [IntegrationsFormFields.Address2]: formAddress2 || address?.address2 || '',
        [IntegrationsFormFields.City]: formCity || address?.city || '',
        [IntegrationsFormFields.Country]: formCountry || address?.country || '',
        [IntegrationsFormFields.Phone]: formPhone || phoneNumber || '',
        [IntegrationsFormFields.PostalCode]: formPostalCode || address?.postalCode || '',
        [IntegrationsFormFields.State]: formState || address?.state || '',
      } as AdyenFormData;
      return formData;
    }
    case IntegrationName.GoogleAnalytics: {
      const formSelector = formValueSelector(Forms.IntegrationsGoogleAnalyticsForm);
      const googleAnalyticsId = formSelector(appState, IntegrationsFormFields.GoogleAnalyticsId);

      const formData = {
        [IntegrationsFormFields.GoogleAnalyticsId]: googleAnalyticsId || integration.data?.analyticsTracker || '',
      } as GoogleAnalyticsFormData;
      return formData;
    }
    case IntegrationName.Pixel: {
      const formSelector = formValueSelector(Forms.IntegrationsFacebookPixelForm);
      const pixelId = formSelector(appState, IntegrationsFormFields.FacebookPixelId);

      const formData = {
        [IntegrationsFormFields.FacebookPixelId]: pixelId || integration.data?.facebookPixelId || '',
      } as PixelFormData;
      return formData;
    }
    default:
      return undefined;
  }
};

const IntegrationsForm: React.FC<{
  integration?: Integration;
  formData: AdyenFormData | GoogleAnalyticsFormData | PixelFormData | undefined;
}> = ({ integration, formData }) => {
  const appState = useAppSelector((state: AppState) => state);
  if (!integration) return <div />;

  switch (integration.name) {
    case IntegrationName.Adyen: {
      const initialValues = formData || getFormData(appState, integration);
      return <IntegrationsAdyenForm initialValues={initialValues as AdyenFormData} />;
    }
    case IntegrationName.GoogleAnalytics: {
      const initialValues = formData || getFormData(appState, integration);
      return <IntegrationsGoogleAnalyticsForm initialValues={initialValues as GoogleAnalyticsFormData} />;
    }
    case IntegrationName.Pixel: {
      const initialValues = formData || getFormData(appState, integration);
      return <IntegrationsPixelForm initialValues={initialValues as PixelFormData} />;
    }
    default:
      return <div />;
  }
};

const DeactivatableIntegrations = [IntegrationName.Adyen];

export const IntegrationsDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined);
  const [okText, setOkText] = useState<string | undefined>(undefined);
  const [setupInstructionsText, setSetupInstructionsText] = useState<string | undefined>(undefined);
  const [deactivateButtonVisible, isDeactivateButtonVisible] = useState<boolean>();
  const { options = {} } = useAppSelector((state) => state.dialog);
  const { updatingIntegration } = useAppSelector((state) => state.integration);
  const { integration, formData } = options as IntegrationOptions;
  const onClickOk = () => {
    dispatch(putIntegration({ integration }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClickOk();
    }
  };

  useLayoutEffect(() => {
    if (integration) {
      const isAlreadySetup = [IntegrationStatus.Connected, IntegrationStatus.Deactivated].includes(integration.status);
      if (isAlreadySetup) {
        isDeactivateButtonVisible(
          DeactivatableIntegrations.includes(integration.name) && integration.status === IntegrationStatus.Connected,
        );
      } else {
        isDeactivateButtonVisible(false);
      }

      setOkText(t(I18nKeys.DialogSaveButton));

      setDialogTitle(
        t(I18nKeys.IntegrationsDialogTitle, {
          integration: t(I18nKeys.IntegrationsName, { context: integration.name }),
          context: isAlreadySetup ? 'settings' : 'setup',
        }),
      );

      setSetupInstructionsText(
        t(I18nKeys.IntegrationsDialogSetupInstructions, { context: integration.name, defaultValue: undefined }),
      );
    }
  }, [setDialogTitle, setOkText, integration, t, isDeactivateButtonVisible]);

  return (
    <Dialog dialogKey={Dialogs.Integrations} onKeyDown={handleKeyDown}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {setupInstructionsText && (
          <Typography
            component="span"
            dangerouslySetInnerHTML={{
              __html: setupInstructionsText,
            }}
          />
        )}
        <IntegrationsForm integration={integration} formData={formData} />
      </DialogContent>
      <DialogActions>
        {deactivateButtonVisible && (
          <>
            <Button
              onClick={(): void => {
                dispatch(closeDialogFunc());
                dispatch(
                  openDialog({
                    dialog: Dialogs.IntegrationsDeactivate,
                    options: { integration, formData },
                  }),
                );
              }}
              sx={{
                backgroundColor: (theme) => `${theme.palette.error.dark} !important`,
              }}
              variant="contained"
            >
              {t(I18nKeys.DialogDeactivateButton)}
            </Button>
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        {okText && (
          <Button loading={updatingIntegration} variant="contained" onClick={onClickOk}>
            {okText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
