import React from 'react';
import { AnyAction } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { ImpersonationDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { I18nKeys } from '../../constants/I18nKeys';
import { InputField } from './InputField';
import { useAppDispatch } from '../../hooks';

export interface FormData {
  [ImpersonationDialogFormFields.ValidationCode]: string;
}

type FormProps = InjectedFormProps<FormData>;

const ImpersonationFormComponent: React.FC<FormProps> = ({ handleSubmit }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.Impersonation}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoComplete="off"
        style={{ width: '100%' }}
        component={InputField}
        label={t(I18nKeys.ImpersonationFieldValidationCode)}
        name={ImpersonationDialogFormFields.ValidationCode}
      />
    </Form>
  );
};

export const ImpersonationForm = reduxForm<FormData>({ form: Forms.Impersonation })(ImpersonationFormComponent);
