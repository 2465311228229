import { Dispatch } from 'redux';
import { AppState } from '../types/AppState';
import { MainMenuItems } from '../constants/MainMenuItems';
import { setShowPublishBar, setVariant } from '../ducks/menuSlice';
import { MenuVariant } from '../constants/Menu';
import { mainMenu } from '../constants/MenuItems';
import { ClientDataTableChanges } from '../types/ClientData';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SalesViewUILayout } from '../constants/Viewer';

export const setPublishBar = (state: AppState, dispatch: Dispatch<any>) => {
  const {
    clientData: { clientId },
    menu: { mainMenuItem },
    currentUser: {
      preferences: {
        [UserPreference.ProfilePreferences]: {
          [PreferencesFormFields.Layout]: layout = SalesViewUILayout.MapNeeded,
        } = {},
      } = {},
    },
  } = state;

  // UI Layout Remove Me
  const showPublishBar =
    (mainMenuItem === MainMenuItems.Sites && (!!clientId || layout !== SalesViewUILayout.Horizon)) ||
    mainMenuItem === MainMenuItems.Pricing;
  dispatch(setShowPublishBar(showPublishBar));
};

export const setMenuVariant = (state: AppState, dispatch: Dispatch<any>) => {
  const {
    clientData: { clientId },
    menu: { mainMenuItem },
    currentUser: {
      preferences: {
        [UserPreference.ProfilePreferences]: {
          [PreferencesFormFields.Layout]: layout = SalesViewUILayout.MapNeeded,
        } = {},
      } = {},
    },
  } = state;

  // UI Layout Remove Me
  if (layout !== SalesViewUILayout.Horizon) return;

  const variant = mainMenuItem === MainMenuItems.Sites && !!clientId ? MenuVariant.MINI : MenuVariant.STANDARD;
  dispatch(setVariant(variant));
};

export const getSecondaryMenu = (mainMenuItem: MainMenuItems | undefined) =>
  mainMenu.flat().find(({ id }) => id === mainMenuItem)?.secondaryMenu || [];

export const getChangedSecondaryMenuSections = (
  mainMenuItem: MainMenuItems | undefined,
  changesSummary: ClientDataTableChanges[] | undefined,
) => {
  const secondaryMenu = getSecondaryMenu(mainMenuItem);
  const [{ changes = [] } = {}] = changesSummary || [];

  return secondaryMenu.map(({ items, ...otherProps }) => ({
    ...otherProps,
    items: items
      .slice(0)
      .filter(({ fields = [] }) => fields.some((field) => changes.some(({ column }) => column === field))),
  }));
};
