/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Grid2 as Grid,
  IconButton,
  ImageListItemBar,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  styled,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { WrappedFieldProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from './SelectField';
import { I18nKeys } from '../../constants/I18nKeys';

type Props = WrappedFieldProps &
  SelectProps & {
    loading?: boolean;
    initializing?: boolean;
    images?: string[];
    onDelete?: (field: string, image: string) => void;
    onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

const ImagePreview = styled(Box)(() => ({
  height: '115px',
  textAlign: 'center',
  alignContent: 'center',
}));

const Image = styled('img')(() => ({
  height: '79px',
}));

export const ImageSelectField: React.FC<Props> = ({ images, onDelete, onUpload, ...props }: Props) => {
  const {
    input: { name, value, onChange },
  } = props;
  const { t } = useTranslation();

  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <SelectField
      {...props}
      height="148px"
      onChange={(val: SelectChangeEvent<unknown>) => {
        if (typeof val === 'string' && val === 'new') return;
        onChange(val);
      }}
      renderValue={() =>
        value ? (
          <ImagePreview>
            <Image src={value} style={{ height: '79px' }} alt={name} />
          </ImagePreview>
        ) : (
          <ImagePreview>{t(I18nKeys.SitesLogosNoneSelected)}</ImagePreview>
        )
      }
    >
      <MenuItem key="none" value="" divider={!!images?.length}>
        <Grid>{t(I18nKeys.SitesLogosNoneSelected)}</Grid>
      </MenuItem>
      {images?.map((image = '') => {
        const imageName = image.split('/').pop();
        return (
          <MenuItem key={image} value={image} divider>
            <Grid container justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <Grid container size={{ xs: 11 }} justifyContent="center" alignItems="center">
                <Box sx={{ justifyItems: 'center' }}>
                  <Image src={image} alt={imageName} loading="lazy" />
                  <ImageListItemBar title={imageName} position="below" />
                </Box>
              </Grid>
              {onDelete && (
                <Grid size={{ xs: 1 }} justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete?.(name, image);
                    }}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </MenuItem>
        );
      })}
      {onUpload && (
        <MenuItem
          key="new"
          value="new"
          sx={{ p: '0px' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Grid container justifyContent="center" alignItems="center" size="grow">
            <label
              htmlFor={`file-upload-${name}`}
              style={{ width: '100%', padding: '6px 16px' }}
              onClick={(e) => e.stopPropagation()}
              role="presentation"
            >
              {t(I18nKeys.SitesLogosUploadImageOption)}
              <input
                id={`file-upload-${name}`}
                ref={inputRef}
                name={name}
                type="file"
                accept="image/*,.svg"
                style={{ display: 'none' }}
                onChange={onUpload}
              />
            </label>
          </Grid>
        </MenuItem>
      )}
    </SelectField>
  );
};
