import { Box, Typography } from '@mui/material';
import React from 'react';
import { formValueSelector } from 'redux-form';
import { WatermarkPosition } from '@idearoom/types';
import { styled } from '@mui/material/styles';
import { Forms } from '../../constants/Forms';
import { useAppSelector } from '../../hooks';
import { ColorsAndLogosFormFields } from '../../constants/FormFields';

const WatermarkOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  perspective: '1000px',
});

const WatermarkText = styled(Typography)({
  position: 'absolute',
  color: 'black',
  opacity: '0.1',
  top: '50%',
  left: '50%',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
});

const WatermarkImage = styled('img')({
  position: 'absolute',
  top: '50%',
  width: '35%',
  opacity: '0.6',
});

export const WatermarkPreview: React.FC = () => {
  const watermark = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.Watermark),
  );
  const watermarkText = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.WatermarkText),
  );
  const watermarkPosition = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.WatermarkPosition),
  );

  if (
    watermarkPosition === WatermarkPosition.None ||
    ([WatermarkPosition.CenterText, WatermarkPosition.CenterTextNotRotated].includes(watermarkPosition)
      ? !watermarkText
      : !watermark)
  )
    return null;

  if ([WatermarkPosition.CenterText, WatermarkPosition.CenterTextNotRotated].includes(watermarkPosition)) {
    return (
      <WatermarkOverlay>
        <WatermarkText
          sx={{
            transform: `translate(-50%, -50%) ${
              watermarkPosition === WatermarkPosition.CenterText ? ' rotate(-20deg)' : ''
            }`,
          }}
        >
          {watermarkText}
        </WatermarkText>
      </WatermarkOverlay>
    );
  }

  return (
    <WatermarkOverlay>
      <WatermarkImage
        src={watermark}
        alt="Watermark"
        sx={{
          ...(watermarkPosition === WatermarkPosition.Left ? { left: 0, pl: '5%' } : {}),
          ...(watermarkPosition === WatermarkPosition.Right ? { right: 0, pr: '5%' } : {}),
          ...([WatermarkPosition.Center, WatermarkPosition.CenterNotRotated].includes(watermarkPosition)
            ? {
                left: '50%',
                transform: `translate(-50%, -50%) ${
                  watermarkPosition === WatermarkPosition.CenterNotRotated ? '' : 'rotate(-20deg)'
                }`,
              }
            : {}),
          ...(watermarkPosition === WatermarkPosition.GroundEdges
            ? {
                left: '30%',
                top: '70%',
                transform: 'translate(-50%, -50%) rotateX(75deg) rotateZ(50deg)',
              }
            : {}),
        }}
      />
      {watermarkPosition === WatermarkPosition.GroundEdges && (
        <WatermarkImage
          src={watermark}
          alt="Watermark"
          sx={{
            ...(watermarkPosition === WatermarkPosition.GroundEdges
              ? {
                  left: '70%',
                  top: '70%',
                  transform: 'translate(-50%, -50%) rotateX(75deg) rotateZ(-50deg)',
                }
              : {}),
          }}
        />
      )}
    </WatermarkOverlay>
  );
};
