/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextFieldProps, FilledInputProps, InputLabelProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InputField } from './InputField';
import { AppState } from '../../types/AppState';
import { useAppSelector } from '../../hooks';
import { getCurrencySymbol } from '../../utils/pricingUtils';

type Props = WrappedFieldProps &
  TextFieldProps & {
    hideHelperText?: boolean;
    errorWithoutTouch?: boolean;
    slotProps?: {
      input?: Partial<FilledInputProps>;
      inputLabel?: Partial<InputLabelProps>;
    };
  };

interface CustomProps {
  onChange: (event: {
    target: { value: string; name: string };
    stopPropagation: () => void; // Required for Redux Form
    preventDefault: () => void; // Required for Redux Form
  }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange } = props;
  const {
    viewer: { currency },
  } = useAppSelector((state: AppState) => state);

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
          stopPropagation: () => false,
          preventDefault: () => false,
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={getCurrencySymbol(currency)}
    />
  );
});

export const PriceField: React.FC<Props> = (props: Props) => (
  <InputField
    slotProps={{
      input: {
        inputComponent: NumericFormatCustom as any,
      },
    }}
    {...props}
  />
);
