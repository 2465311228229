/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, CircularProgress, Skeleton, SelectProps, SelectChangeEvent } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { styled } from '@mui/material/styles';
import { SelectField as SalesViewSelectField } from '../library/SelectField';

type Props = WrappedFieldProps &
  SelectProps & {
    loading?: boolean;
    initializing?: boolean;
    height?: string;
  };

const LoadingOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  textAlign: 'center',
  padding: '25px 12px 0px',
}));

export const SelectField: React.FC<Props> = ({
  input,
  children,
  meta: { touched, error },
  disabled,
  loading,
  initializing,
  height,
  ...customProps
}: Props): JSX.Element =>
  initializing ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={height || '56px'}
      sx={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
    />
  ) : (
    <Box>
      <SalesViewSelectField
        {...customProps}
        {...input}
        helperText={touched && error}
        error={!!(touched && error)}
        disabled={disabled || loading}
        height={height}
        onChange={(event: SelectChangeEvent<unknown>): void => input.onChange(event.target.value)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>): void => input.onBlur(event.target.value)}
      >
        {children}
      </SalesViewSelectField>
      {loading && (
        <LoadingOverlay>
          <CircularProgress size={20} sx={{ color: '#323B4B', opacity: 0.6 }} />
        </LoadingOverlay>
      )}
    </Box>
  );
