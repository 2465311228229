import { createTheme, ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import { HookRouter, navigate, useRoutes } from 'hookrouter';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { Permission } from '@idearoom/types';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license';
import { ForgotPassword } from './components/ForgotPassword';
import { ForgotPassword as MapNeededForgotPassword } from './components/map-needed/ForgotPassword';
import { SalesView } from './components/SalesView';
import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';
import { AppRoutes } from './constants/AppRoutes';
import { theme } from './theme';
import { AppState } from './types/AppState';
import './toast-styles.css';
import { Theme } from './types/ViewerState';
import { validateColorInput } from './utils/vendorDataUtils';
import { unknownUser } from './types/User';
import { AuthStatus } from './constants/AuthStatus';
import { config } from './config/config';
import { hasAdminPermissions } from './utils/permissionUtils';
import { mapClientIdToProduct } from './utils/clientIdUtils';
import { viewingDealer } from './utils/supplierUtils';
import { ClientSignUp } from './components/ClientSignUp';
import { UserPreference } from './constants/User';
import { PreferencesFormFields } from './constants/FormFields';
import { SalesViewUILayout } from './constants/Viewer';
import { SignIn as MapNeededSignIn } from './components/map-needed/SignIn';
import { MainMenu } from './components/MainMenu';
import { Menu as MapNeededMenu } from './components/map-needed/Menu';
import { SecondaryMenu } from './components/SecondaryMenu';
import { PublishBar } from './components/PublishBar';
import { PublishBar as MapNeededPublishBar } from './components/map-needed/PublishBar';
import { LogoBar } from './components/LogoBar';
import { LogoBar as MapNeededLogoBar } from './components/map-needed/LogoBar';

LicenseInfo.setLicenseKey(
  '6400888be63dd3f402e0b944984214fcTz0xMDc1NDMsRT0xNzcwNTk1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

declare global {
  interface Window {
    heap: any;
    chmln: any;
  }
}

const INTERCOM_WIDGET_PADDING = 60;

// UI Layout Remove Me
const routes = (layout: SalesViewUILayout): HookRouter.RouteObject =>
  ({
    [AppRoutes.ForgotPassword]: (): JSX.Element =>
      layout !== SalesViewUILayout.Horizon ? <MapNeededForgotPassword /> : <ForgotPassword />,
    [`${AppRoutes.Portal}*`]: (): JSX.Element => <SalesView />,
    [AppRoutes.SignIn]: (): JSX.Element => (layout !== SalesViewUILayout.Horizon ? <MapNeededSignIn /> : <SignIn />),
    [AppRoutes.SignUp]: (): JSX.Element => <SignUp />,
    [`${AppRoutes.ClientSignUp}*`]: (): JSX.Element => <ClientSignUp />,
  } as HookRouter.RouteObject);

interface StateProps {
  layout: SalesViewUILayout;
  selectedTextColor: string | undefined;
  contactBarColor: string | undefined;
  contactBarTextColor: string | undefined;
  authStatus: AuthStatus;
  sub: string | undefined;
  userHash: string | undefined;
  email: string;
  name: string;
  group: { groupName: string } | undefined;
  permissions: string[] | undefined;
  selectedClientId: string | undefined;
  loading: boolean;
}

type Props = StateProps;

const AppComponent: React.FC<Props> = ({
  layout,
  selectedTextColor,
  contactBarColor,
  contactBarTextColor,
  authStatus,
  sub,
  userHash,
  email,
  name,
  group,
  permissions,
  selectedClientId,
  loading,
}: StateProps) => {
  useEffect(() => {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: config.intercom.APP_ID || 'e567lh01',
      vertical_padding: INTERCOM_WIDGET_PADDING,
      ...(authStatus === AuthStatus.SignedIn && sub
        ? {
            user_hash: userHash,
            email,
            name,
            user_name: name,
            conversation_company: group?.groupName,
            admin: hasAdminPermissions(permissions),
            manager: permissions?.includes(Permission.Manager) || false,
            sales: permissions?.includes(Permission.Sales) || false,
            configuratorEditor: hasAdminPermissions(permissions),
          }
        : {}),
    });

    if (window.heap && config.environment.STAGE === 'production') {
      if (authStatus === AuthStatus.SignedIn) {
        window.heap.identify(email);
        window.heap.addUserProperties({
          sub,
          name,
          admin: hasAdminPermissions(permissions),
          manager: permissions?.includes(Permission.Manager) || false,
          sales: permissions?.includes(Permission.Sales) || false,
        });
        window.heap.clearEventProperties();
        window.heap.addEventProperties({
          group: group?.groupName,
        });
      } else {
        window.heap.clearEventProperties();
      }
    }

    if (window.chmln && sub) {
      // eslint-disable-next-line no-nested-ternary
      const role = hasAdminPermissions(permissions)
        ? 'admin'
        : permissions?.includes(Permission.Manager)
        ? 'manager'
        : 'user';

      const client = selectedClientId
        ? {
            industry: mapClientIdToProduct(selectedClientId),
            clientId: selectedClientId,
            supplier: !viewingDealer(selectedClientId),
          }
        : {};

      // Chameleon - Identify Users. See chameleon.io
      window.chmln.identify(sub, {
        email,
        name,
        role,
        company: {
          uid: group?.groupName,
          name: group?.groupName,
        },
        ...client,
      });

      window.chmln.on('app:navigate', (opts: any) => {
        navigate(opts.to);
      });
    }
  }, [authStatus, sub, userHash, email, name, group, permissions]);

  const appTheme = createTheme(theme(layout, selectedTextColor, contactBarColor, contactBarTextColor));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <div style={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          {useRoutes(routes(layout))}
          {!loading && layout !== SalesViewUILayout.Horizon && [<MapNeededLogoBar />, <MapNeededMenu />]}
          {!loading && layout === SalesViewUILayout.Horizon && [<LogoBar />, <MainMenu />, <SecondaryMenu />]}
          {!loading && layout !== SalesViewUILayout.Horizon && <MapNeededPublishBar />}
          {!loading && layout === SalesViewUILayout.Horizon && <PublishBar />}
        </div>
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          style={{ paddingBottom: `${INTERCOM_WIDGET_PADDING}px` }}
          closeButton={false}
          hideProgressBar
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = ({
  viewer: { theme: vendorTheme = {}, selectedTabId = undefined, selectedClientId = undefined },
  currentUser: {
    loading,
    authStatus,
    group,
    user: { sub, userHash, email, name, permissions } = unknownUser,
    preferences,
  },
}: AppState): StateProps => {
  const { selectedTextColor = '', contactBarColor = '', contactBarTextColor = '' } = vendorTheme as Theme;
  return {
    // UI Layout Remove Me
    layout:
      preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout] || SalesViewUILayout.MapNeeded,
    selectedTextColor: (validateColorInput(selectedTextColor) && selectedTextColor) || undefined,
    contactBarColor: (validateColorInput(contactBarColor) && contactBarColor) || undefined,
    contactBarTextColor: (validateColorInput(contactBarTextColor) && contactBarTextColor) || undefined,
    authStatus,
    sub,
    userHash,
    email,
    name,
    group,
    permissions,
    selectedClientId: selectedTabId || selectedClientId,
    loading,
  };
};

export const App = connect(mapStateToProps)(AppComponent);
