/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import DropZone, { FileRejection, DropzoneProps, ErrorCode } from 'react-dropzone';
import { WrappedFieldProps } from 'redux-form';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloudUpload } from '@mui/icons-material';
import { I18nKeys } from '../../constants/I18nKeys';
import { SceneEnvironmentIconPreview } from '../SceneEnvironmentIconPreview';
import { InputField } from '../library/InputField';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

interface OwnProps {
  label: string;
}

type Props = WrappedFieldProps & DropzoneProps & OwnProps;

export const ImageDropField: React.FC<Props> = ({
  input: { onChange, value },
  meta: { error, touched },
  label,
  ...props
}) => {
  const { t } = useTranslation();
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const handleOnDrop = async (newImageFile: File[]) => {
    if (newImageFile.length > 0) {
      try {
        const base64 = await toBase64(newImageFile[0]);
        onChange(base64);
        setUploadError(undefined);
      } catch (e) {
        setUploadError(I18nKeys.SceneEnvironmentFileUnknownError);
      }
    }
  };

  const handleOnDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      switch (fileRejections[0].errors[0].code) {
        case ErrorCode.FileTooLarge:
          setUploadError(I18nKeys.SceneEnvironmentFileTooLarge);
          break;
        case ErrorCode.FileInvalidType:
          setUploadError(I18nKeys.SceneEnvironmentFileInvalidType);
          break;
        default:
          setUploadError(I18nKeys.SceneEnvironmentFileUnknownError);
          break;
      }
    }
  };

  const errorText = ((touched && error) || uploadError) && ((uploadError && t(uploadError)) || error);

  return (
    <div style={{ height: '78px' }} className="preview-container">
      <DropZone
        accept={{
          'image/jpeg': [],
          'image/png': [],
        }}
        onDrop={handleOnDrop}
        onDropRejected={handleOnDropRejected}
        multiple={false}
        {...props}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <InputField
              error={!!errorText}
              helperText={errorText}
              label={label}
              placeholder={!value ? t(I18nKeys.SceneEnvironmentFileDrop) : ''}
              fullWidth
              slotProps={{
                input: {
                  sx: {
                    height: '78px',
                  },
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {value ? (
                        <SceneEnvironmentIconPreview url={value} />
                      ) : (
                        <CloudUpload sx={{ color: '#323B4B', opacity: 0.7 }} />
                      )}
                    </InputAdornment>
                  ),
                },
              }}
            />
            <p />
          </div>
        )}
      </DropZone>
    </div>
  );
};
