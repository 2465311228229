import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector, submit } from 'redux-form';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Forms } from '../constants/Forms';
import { ClientDataPreviewForm } from './ClientDataPreviewForm';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { BRANCH_LABELS } from '../constants/ClientData';
import { ClientDataType } from '../constants/ClientDataType';
import { Configurator } from '../types/Configurator';
import { mapClientIdToConfiguratorAndVendor, mapConfiguratorToClientId } from '../utils/clientIdUtils';
import {
  useGetClientDataBranchesQuery,
  useSaveBranchMetadataMutation,
  useGetVendorDataQuery,
} from '../services/clientDataApi';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { ClientDataPreviewFields } from '../constants/FormFields';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { getConfiguratorPreviewUrl } from '../utils/clientDataUtils';
import { unknownGroup } from '../constants/Group';
import { SelectField } from './library/SelectField';
import { compoundCaseToTitleCase } from '../utils/stringUtils';

const BranchSelector: React.FC<{
  dataType: ClientDataType;
  selected: ClientDataBranch;
  onSelect: { (newBrach: ClientDataBranch): void };
  currentDataType: ClientDataType;
  currentDataBranch: ClientDataBranch;
  clientId: string;
  groupId: string;
}> = ({ dataType, selected, onSelect, currentDataType, currentDataBranch, clientId, groupId }) => {
  const fixedBranch = dataType === currentDataType ? currentDataBranch : null;
  const { data = [{ branchType: fixedBranch || selected }], isLoading } = useGetClientDataBranchesQuery(
    { dataType, clientId, groupId },
    { refetchOnMountOrArgChange: true },
  );
  const { t } = useTranslation();

  const branches = data.map(({ branchType }) => branchType);
  return (
    <Grid size={{ xs: 4, md: 4 }}>
      <SelectField
        id={`${dataType}-select-label`}
        label={`${compoundCaseToTitleCase(dataType)} Data Set`}
        onChange={(event) => onSelect(event.target.value as ClientDataBranch)}
        disabled={!!fixedBranch || isLoading}
        value={fixedBranch || selected}
      >
        {branches.map((branch) => (
          <MenuItem key={branch} value={branch}>
            {t(BRANCH_LABELS[branch])}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  vendorSelector: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const ClientDataPreviewDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [configuratorUrl, setConfiguratorUrl] = useState('');

  const clientDataBranch = useAppSelector((state) => state?.clientData.clientDataBranch || ClientDataBranch.Main);
  const clientDataType = useAppSelector((state) => state?.clientData.clientDataType);
  const clientId = useAppSelector((state) => state?.clientData.clientId);
  const { group: { groupId } = unknownGroup, groups = [] } = useAppSelector((state) => state?.currentUser);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const [saveBranchMetadata] = useSaveBranchMetadataMutation();

  const currentBranchDescription = activeBranches.find((branch) => branch.branchType === clientDataBranch)?.description;

  const configurators = groups
    .filter((group) => (group.configurators || []).length > 0)
    .reduce<Configurator[]>((acc, group) => [...acc, ...(group.configurators || [])], []);
  const shedsConfigurators = Array.from(
    new Set(
      configurators
        .filter((configurator) => configurator.key === 'shedview')
        .map((config) => mapConfiguratorToClientId(config)),
    ),
  );
  const carportsConfigurators = Array.from(
    new Set(
      configurators
        .filter((configurator) => configurator.key === 'carportview')
        .map((config) => mapConfiguratorToClientId(config)),
    ),
  );

  const [clientBranch, setClientBranch] = useState(
    clientDataType === ClientDataType.Vendor ? clientDataBranch : ClientDataBranch.Main,
  );
  const [structureBranch, setStructureBranch] = useState(
    clientDataType === ClientDataType.Supplier ? clientDataBranch : ClientDataBranch.Main,
  );
  const [systemBranch, setSystemBranch] = useState(
    clientDataType === ClientDataType.Reference ? clientDataBranch : ClientDataBranch.Main,
  );
  const [selectedClientId, setSelectedClientId] = useState(
    clientDataType === ClientDataType.Reference ? shedsConfigurators[0] : clientId,
  );

  const { currentData: { locale = '', productionURL = '' } = {}, isFetching: isLoadingVendorData } =
    useGetVendorDataQuery(
      { clientId: selectedClientId },
      {
        skip: !selectedClientId,
      },
    );

  const formDataDescription = useAppSelector((state) =>
    formValueSelector(Forms.ClientDataPreview)(state, ClientDataPreviewFields.Description),
  );

  // Resets selected clientId and branches whenever we change datatype or clientId
  useEffect(() => {
    setSelectedClientId(clientDataType === ClientDataType.Reference ? shedsConfigurators[0] : clientId);
    setClientBranch(clientDataType === ClientDataType.Vendor ? clientDataBranch : ClientDataBranch.Main);
    setStructureBranch(clientDataType === ClientDataType.Supplier ? clientDataBranch : ClientDataBranch.Main);
    setSystemBranch(clientDataType === ClientDataType.Reference ? clientDataBranch : ClientDataBranch.Main);
  }, [clientId, clientDataType, clientDataBranch]);

  useEffect(() => {
    if (selectedClientId && !isLoadingVendorData) {
      const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(selectedClientId);
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
      setConfiguratorUrl(url);
    }
  }, [selectedClientId, isLoadingVendorData]);

  const saveBranchDescription = () => {
    if (formDataDescription !== currentBranchDescription) {
      saveBranchMetadata({
        branch: clientDataBranch,
        dataType: clientDataType,
        clientId,
        description: formDataDescription,
        groupId,
      });
    }
  };

  return (
    <Dialog dialogKey={Dialogs.ClientDataPreview} maxWidth="sm" onClosed={saveBranchDescription}>
      <DialogContent>
        <ClientDataPreviewForm
          initialValues={{
            [ClientDataPreviewFields.Description]: currentBranchDescription,
          }}
          onSubmit={async () => {
            const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientBranch, structureBranch, systemBranch);
            window.open(previewUrl, '_blank', 'noopener noreferrer');
            dispatch(closeDialogFunc());
            saveBranchDescription();
          }}
        />
        <Accordion
          variant="outlined"
          sx={{ borderRadius: '8px !important', minWidth: '500px' }}
          defaultExpanded={clientDataType === ClientDataType.Reference}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: '600 !important' }}>Data Set Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing="16px">
              {clientDataType === ClientDataType.Reference && (
                <Grid className={classes.vendorSelector} size={{ xs: 12, md: 12 }}>
                  <Typography>Vendor</Typography>
                  <Select
                    onChange={(event) => setSelectedClientId(event.target.value)}
                    value={selectedClientId}
                    fullWidth
                  >
                    <ListSubheader>Sheds</ListSubheader>
                    {shedsConfigurators.map((configurator) => (
                      <MenuItem key={configurator} value={configurator}>
                        {configurator}
                      </MenuItem>
                    ))}
                    <ListSubheader>Carports</ListSubheader>
                    {carportsConfigurators.map((configurator) => (
                      <MenuItem key={configurator} value={configurator}>
                        {configurator}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              <BranchSelector
                dataType={ClientDataType.Vendor}
                selected={clientBranch}
                onSelect={setClientBranch}
                currentDataType={clientDataType}
                currentDataBranch={clientDataBranch}
                clientId={selectedClientId}
                groupId={groupId}
              />
              <BranchSelector
                dataType={ClientDataType.Supplier}
                selected={structureBranch}
                onSelect={setStructureBranch}
                currentDataType={clientDataType}
                currentDataBranch={clientDataBranch}
                clientId={selectedClientId}
                groupId={groupId}
              />
              <BranchSelector
                dataType={ClientDataType.Reference}
                selected={systemBranch}
                onSelect={setSystemBranch}
                currentDataType={clientDataType}
                currentDataBranch={clientDataBranch}
                clientId={mapConfiguratorToClientId({ key: 'idearoom', vendor: ClientDataType.Reference })}
                groupId={groupId}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            saveBranchDescription();
            dispatch(closeDialogFunc());
          }}
          variant="outlined"
          id="site-data-preview-dialog-cancel-button"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(submit(Forms.ClientDataPreview));
          }}
          disabled={!configuratorUrl}
          variant="contained"
          id="site-data-preview-dialog-preview-button"
        >
          {t(I18nKeys.PreviewButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
