import React, { useMemo } from 'react';
import { InputAdornment, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GppMaybe, GppGood } from '@mui/icons-material';
import domainLoading from '../../images/domainLoading.svg';
import { BusinessInfoFormFields } from '../../constants/FormFields';
import { useDomainRepo } from '../../hooks/useDomainRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { useAppDispatch } from '../../hooks';
import { DomainStatus } from '../../types/DomainVerification';
import { getDomainFromEmail } from '../../utils/domainUtils';
import { I18nKeys } from '../../constants/I18nKeys';
import { Dialogs } from '../../constants/Dialogs';
import { openDialog } from '../../ducks/dialogSlice';

export const VerifyDomainStatus: React.FC<{ name: BusinessInfoFormFields }> = ({ name }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientEmailDomain: { status },
    isFetchingClientEmailDomain,
  } = useDomainRepo();
  const {
    selectedTableData: [
      { emailAddress, quoteEmailFromAddressSame, quoteEmailFromAddress } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const { domain } = getDomainFromEmail(quoteEmailFromAddressSame ? `${emailAddress}` : `${quoteEmailFromAddress}`);

  const { icon, tooltipText } = useMemo(() => {
    if (isFetchingClientEmailDomain)
      return {
        icon: <img src={domainLoading} alt="loading" />,
        tooltipText: t(I18nKeys.SitesEmailDomainIconLoadingHoverText, { domain }),
      };

    let statusIcon = <GppMaybe color="error" />;
    let statusTooltipText = '';
    switch (status) {
      case DomainStatus.Verified:
      case DomainStatus.VerifiedIdeaRoomDomain:
        statusIcon = (
          <IconButton
            color="secondary"
            aria-label="verified"
            sx={{ p: '0px' }}
            onClick={() => {
              dispatch(openDialog({ dialog: Dialogs.EmailDomain, options: { emailDomain: domain } }));
            }}
          >
            <GppGood />
          </IconButton>
        );
        statusTooltipText = t(I18nKeys.SitesEmailDomainIconVerifiedHoverText, { domain });
        break;
      case DomainStatus.Unverifiable:
        statusTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiableHoverText, { domain });
        break;
      case DomainStatus.UnverifiableCustomSmtpHost:
        statusIcon = <GppMaybe color="warning" />;
        statusTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiableCustomSmtpHostHoverText, { domain });
        break;
      case DomainStatus.Unverified:
      case DomainStatus.DoesNotExist:
      default:
        statusIcon = <GppMaybe color="error" />;
        statusTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiedHoverText, { domain });
        break;
    }

    return { icon: statusIcon, tooltipText: statusTooltipText };
  }, [isFetchingClientEmailDomain, domain, status, dispatch, t]);

  // This field does not need to be verified
  if (
    (name === BusinessInfoFormFields.EmailAddress && !quoteEmailFromAddressSame) ||
    (name === BusinessInfoFormFields.QuoteEmailFromAddress && !!quoteEmailFromAddressSame)
  ) {
    return null;
  }

  return (
    <Tooltip title={tooltipText}>
      <InputAdornment position="end">{icon}</InputAdornment>
    </Tooltip>
  );
};
