import { DialogTitle, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/system';
import { I18nKeys } from '../constants/I18nKeys';
import { config } from '../config/config';
import { Environment } from '../constants/Environment';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    color: `${theme.palette.error.main} !important`,
    textAlign: 'center',
  },
  dialogTitleStripes: {
    width: 'auto',
    height: '15px',
    marginBottom: '4px',
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.main, 0.1)} 22.22%, ${
      theme.palette.error.main
    } 22.22%, ${theme.palette.error.main} 50%, ${alpha(theme.palette.error.main, 0.1)} 50%, ${alpha(
      theme.palette.error.main,
      0.1,
    )} 72.22%, ${theme.palette.error.main} 72.22%, ${theme.palette.error.main} 100%)`,
    backgroundSize: '25.46px 25.46px',
    '&$animated': {
      animation: '$moveStripes 0.5s infinite linear',
    },
  },
  animated: {},
  '@keyframes moveStripes': {
    '0%': { backgroundPositionX: '0px' },
    '100%': { backgroundPositionX: '25.46px' },
  },
}));

export const ClientDataPublishDialogStripes: React.FC<{ isPublishing?: boolean }> = ({ isPublishing }) => {
  const environment = config.environment.STAGE || 'development';
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogTitle className={environment === Environment.Production ? classes.dialogTitle : undefined}>
      {environment === Environment.Production && (
        <div className={`${classes.dialogTitleStripes} ${isPublishing ? classes.animated : ''}`} />
      )}
      {t(I18nKeys.ClientDataPublishDialogTitle, { context: environment })}
    </DialogTitle>
  );
};
