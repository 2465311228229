import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { InputField } from './InputField';
import { I18nDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { SALESVIEW } from '../../constants/App';
import { AppState } from '../../types/AppState';
import { I18nKeys } from '../../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

interface StateProps {
  formValues: FormData | any;
}

export interface FormData {
  [I18nDialogFormFields.ClientId]: string;
  [I18nDialogFormFields.Language]: string;
  [I18nDialogFormFields.Key]: string;
  [I18nDialogFormFields.Value]: string;
  [I18nDialogFormFields.Override]: string;
}

type FormDispatchProps = {
  onSubmit(data: FormData): Promise<AnyAction>;
};

type FormProps = StateProps & FormDispatchProps & InjectedFormProps<FormData>;

const I18nFormComponent: React.FC<FormProps> = ({
  error,
  formValues: { [I18nDialogFormFields.ClientId]: clientId = '' } = {},
  handleSubmit,
  onSubmit,
}: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {clientId !== SALESVIEW && (
        <Field
          autoComplete="off"
          className={classes.field}
          component={InputField}
          disabled
          label={t(I18nKeys.I18nFormOriginalTextField)}
          name={I18nDialogFormFields.Value}
          variant="filled"
        />
      )}
      <Field
        autoComplete="off"
        autoFocus
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.I18nFormOverrideTextField)}
        name={I18nDialogFormFields.Override}
        variant="filled"
      />
      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.I18n}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = (state: AppState): StateProps => {
  const formValues = getFormValues(Forms.I18n)(state) as FormData;

  return { formValues };
};

export const I18nForm = reduxForm<FormData>({ form: Forms.I18n })(
  connect(mapStateToProps, mapDispatchToProps)(I18nFormComponent),
);
