import React from 'react';
import { AnyAction } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Forms } from '../../constants/Forms';
import { I18nKeys } from '../../constants/I18nKeys';
import { InputField } from './InputField';
import { useAppDispatch } from '../../hooks';
import { ClientDataNoteDialogFormFields } from '../../constants/FormFields';

export interface FormData {
  [ClientDataNoteDialogFormFields.Note]: string;
}

type FormProps = InjectedFormProps<FormData>;

const ClientDataNoteFormComponent: React.FC<FormProps> = ({ handleSubmit }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.ClientDataNote}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoFocus
        autoComplete="off"
        style={{ width: '100%', minWidth: '300px' }}
        component={InputField}
        label={t(I18nKeys.ClientDataNoteField)}
        name={ClientDataNoteDialogFormFields.Note}
        multiline
        rows={6}
        variant="filled"
      />
    </Form>
  );
};

export const ClientDataNoteForm = reduxForm<FormData>({ form: Forms.ClientDataNote })(ClientDataNoteFormComponent);
