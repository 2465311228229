import { MenuItem as MuiMenuItem, Box, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Check, Menu, Search } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { resetClientIds } from '../ducks/viewerSlice';
import { fetchGroups } from '../ducks/groups';
import { GroupData } from '../types/Group';
import { SearchInput } from './SearchInput';
import { onTableMenuKeyboardShortcut } from '../utils/clientDataKeyboardShortcutHandlerUtils';
import { AnchorMenu } from './AnchorMenu';
import { fuzzyMatchIncludes } from '../utils/stringUtils';
import { changeGroup } from '../ducks/currentUserSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { setPricingBranches } from '../ducks/pricingSlice';
import { isColorLight } from '../utils/colorUtils';

const useStyles = makeStyles((theme: Theme) => ({
  button: { textTransform: 'none', width: '100%', display: 'flex', color: theme.palette.text.primary },
  buttonText: { flex: 1, marginLeft: '8px' },
  user: {
    padding: '5px 16px',
  },
  groupsList: {
    maxHeight: '500px',
    overflow: 'overlay',
  },
  search: {
    padding: '16px',
  },
}));

const emptyGroupList: GroupData[] = [];

export const GroupsButton: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [matchingGroups, setMatchingGroups] = React.useState<GroupData[]>([]);
  const [matchingGroupOptions, setMatchingGroupOptions] = React.useState<{ key: string; value: string }[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const resetSearch = React.useCallback(() => setSearchValue(''), []);

  const [checkedIndex, setCheckedIndex] = React.useState<number>(0);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const { logoBarColor = '#FFFFFF' } = useAppSelector((state) => state.viewer?.theme) || {};
  const { groupId = '' } = useAppSelector((state) => state.currentUser.group) || {};
  const groups = useAppSelector((state) => state.currentUser.groups) || emptyGroupList;

  useEffect(() => {
    if (groupId) {
      setCheckedIndex(matchingGroupOptions.findIndex((group) => group.key === groupId));
    }
  }, [groupId, groups, matchingGroups]);

  useEffect(() => {
    let displayedGroups;
    if (searchValue.length < 2) {
      displayedGroups = groups;
    } else {
      displayedGroups = groups.filter((group) => {
        const { groupName: filterName, groupId: filterId, configurators = [] } = group;
        return [filterName, filterId, ...configurators.map(({ vendor = '' }) => vendor)].some(
          (v) => v && fuzzyMatchIncludes(v, searchValue),
        );
      });
    }

    setMatchingGroups(displayedGroups);
    setMatchingGroupOptions(
      displayedGroups.map((group) => ({ key: group.groupId || '', value: group.groupName || '' })),
    );
    setSelectedIndex(0);
  }, [searchValue, groups, setMatchingGroups]);

  const getChildNodeById = (parentEl: HTMLElement | null, id: string): HTMLElement | undefined =>
    Array.from(parentEl?.children || []).find((node) => node?.id === id) as HTMLElement | undefined;

  useEffect(() => {
    if (anchorEl) searchRef.current?.focus();
  }, [anchorEl]);

  const menuListRef = React.useRef<HTMLUListElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    resetSearch();
  };

  const focusMenuItem = (index: number) => {
    setSelectedIndex(index);
  };

  const clickMenuItem = () => {
    const id = matchingGroups[selectedIndex]?.groupId;
    if (id) {
      const child = getChildNodeById(menuListRef?.current as HTMLUListElement, id);
      child?.click();
    }
  };

  const handleGroupMenuItemClick = (selectedGroupId: string): void => {
    handleClose();
    dispatch(resetClientIds());
    dispatch(setPricingBranches(ClientDataBranch.Main));
    dispatch(changeGroup(selectedGroupId));
    dispatch(fetchGroups());
  };

  return (
    <Box>
      <IconButton
        sx={{ height: '24px', width: '24px', color: isColorLight(logoBarColor) ? '#323B4B' : '#FFFFFF', opacity: 0.8 }}
        onClick={handleClick}
      >
        <Menu />
      </IconButton>
      <AnchorMenu
        menuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        maxMenuHeight="500px"
        menuAnchorEl={anchorEl}
        listRef={menuListRef}
        onClose={handleClose}
        options={matchingGroupOptions}
        checkedOptions={[matchingGroupOptions[checkedIndex]?.key]}
        selectedOptions={[matchingGroupOptions[selectedIndex]?.key]}
        checkedIcon={<Check />}
        onClick={(id): void => handleGroupMenuItemClick(id)}
        onKeyDown={(i: number) => (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) =>
          onTableMenuKeyboardShortcut(e, { currentIndex: i, focusMenuItem, clickMenuItem })}
        searchInput={
          groups &&
          groups.length > 10 && (
            <MuiMenuItem
              key="group-search"
              className={classes.search}
              onKeyDown={(e): void => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            >
              <SearchInput
                ref={searchRef}
                autoComplete="off"
                searchTerm={searchValue}
                startAdornment={<Search />}
                onClearClick={resetSearch}
                onChange={setSearchValue}
                size="small"
                onKeyDown={(e) =>
                  onTableMenuKeyboardShortcut(e, { currentIndex: selectedIndex, focusMenuItem, clickMenuItem })
                }
              />
            </MuiMenuItem>
          )
        }
      />
    </Box>
  );
};
