import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { EventCategory, EventName } from '../analytics/AnalyticsTypes';
import { googleAnalyticsInstance } from '../analytics/googleAnalyticsInstance';
import { ForgotPasswordActionTypes } from '../ducks/forgotPassword';
import { GroupActionTypes } from '../ducks/group';
import { GroupsActionTypes } from '../ducks/groups';
import { AppState } from '../types/AppState';
import { changeGroup, signedIn, signedInSuccess, updateCurrentUser } from '../ducks/currentUserSlice';
import { removeDealerSuccess, saveDealerSuccess } from '../ducks/dealersSlice';
import { setMainMenuItem } from '../ducks/menuSlice';

export const googleAnalyticsMiddleware =
  (middlewareApi: MiddlewareAPI<Dispatch<AnyAction>, AppState>) =>
  (next: any) =>
  (action: any): any => {
    switch (action.type) {
      case signedInSuccess.type: {
        const {
          payload: { username, groupId },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.SignIn, {
          event_category: EventCategory.User,
          event_label: groupId,
          value: username,
        });

        if (googleAnalyticsInstance.standardEventExtraProps) {
          googleAnalyticsInstance.standardEventExtraProps = {
            ...googleAnalyticsInstance.standardEventExtraProps,
            username,
            vendorKey: groupId,
          };
        } else {
          console.error(
            `googleAnalyticsMiddleware - received action "${signedIn.type}", but googleAnalyticsInstance.standardEventExtraProps not set. ` +
              `Call googleAnalyticsInstance.initializeStandardExtraProps to set this information first.`,
          );
        }
        break;
      }

      case changeGroup.type: {
        const {
          payload: { groupId },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Change, {
          event_category: EventCategory.Navigation,
          event_label: 'group',
          value: groupId,
        });

        if (googleAnalyticsInstance.standardEventExtraProps) {
          googleAnalyticsInstance.standardEventExtraProps = {
            ...googleAnalyticsInstance.standardEventExtraProps,
            vendorKey: groupId,
          };
        } else {
          console.error(
            `googleAnalyticsMiddleware - received action "${changeGroup.type}", but googleAnalyticsInstance.standardEventExtraProps not set. ` +
              `Call googleAnalyticsInstance.initializeStandardExtraProps to set this information first.`,
          );
        }
        break;
      }

      // Menu EventCategory events
      case setMainMenuItem.type: {
        const {
          payload: { menuItem },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Click, {
          event_category: EventCategory.Menu,
          event_label: menuItem,
        });
        break;
      }

      // User EventCategory events
      case GroupActionTypes.ADD_MEMBER_SUCCESS: {
        const {
          payload: { member },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Add, {
          event_category: EventCategory.User,
          event_label: member.username,
          value: member,
        });
        break;
      }
      case GroupActionTypes.EDIT_MEMBER_SUCCESS: {
        const {
          payload: { member },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Update, {
          event_category: EventCategory.User,
          event_label: member.username,
          value: member,
        });
        break;
      }
      case updateCurrentUser.type: {
        const { payload: user } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Update, {
          event_category: EventCategory.User,
          event_label: user.username,
          value: user,
        });
        break;
      }
      case GroupActionTypes.REMOVE_MEMBER_SUCCESS: {
        const {
          payload: { member },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Delete, {
          event_category: EventCategory.User,
          event_label: member.username,
          value: member,
        });
        break;
      }
      case ForgotPasswordActionTypes.SET_FORGOT_PASSWORD_STATE: {
        const {
          payload: { email },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Forgot, {
          event_category: EventCategory.User,
          event_label: email,
        });
        break;
      }

      // Configurator EventCategory events
      case GroupActionTypes.ADD_CONFIGURATOR_TO_GROUP_SUCCESS: {
        const {
          payload: { configurator },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Add, {
          event_category: EventCategory.Configurator,
          event_label: configurator.key,
          value: configurator,
        });
        break;
      }
      case GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP_SUCCESS: {
        const {
          payload: { configurator },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Delete, {
          event_category: EventCategory.Configurator,
          event_label: configurator.key,
          value: configurator,
        });
        break;
      }

      // Group EventCategory events
      case GroupsActionTypes.REMOVE_GROUP_SUCCESS: {
        const {
          payload: { group },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Delete, {
          event_category: EventCategory.Group,
          event_label: group.groupId,
          value: group,
        });
        break;
      }

      // Dealer EventCategory events
      case saveDealerSuccess.type: {
        const {
          payload: { dealer },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Update, {
          event_category: EventCategory.Dealer,
          event_label: dealer.key,
          value: dealer,
        });
        break;
      }
      case removeDealerSuccess.type: {
        const {
          payload: { dealer },
        } = action;
        googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Delete, {
          event_category: EventCategory.Dealer,
          event_label: dealer.key,
          value: dealer,
        });
        break;
      }

      // TODO: Add new event categories using new Dolt method
      // Pricing EventCategory events
      // case PricingAdjustmentActionTypes.SET_PRICING_SELECTION: {
      //   const {
      //     payload: { pricingSelection },
      //   } = action;
      //   const { clientId } = state.clientData;

      //   if (pricingSelection && pricingSelection === PricingSurchargeCalculationType.LineItem) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjChangeApplyLineItem, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the "Change prices of line items" radio button is clicked',
      //     });
      //   }
      //   if (
      //     pricingSelection &&
      //     pricingSelection === PricingSurchargeCalculationType.Surcharge &&
      //     clientId &&
      //     !isCarportView(clientId)
      //   ) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjApplySurcharge, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the "Apply a surcharge or discount" radio button is clicked on a Shed site',
      //     });
      //   }
      //   break;
      // }
      // case PricingAdjustmentActionTypes.SAVE_SURCHARGE: {
      //   const {
      //     payload: { clientId },
      //   } = action;
      //   googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Save, {
      //     event_category: EventCategory.PricingAdjustment,
      //     event_label: clientId,
      //   });
      //   break;
      // }
      // case PricingAdjustmentActionTypes.REVERT_SURCHARGE: {
      //   const {
      //     payload: { clientId },
      //   } = action;
      //   googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Revert, {
      //     event_category: EventCategory.PricingAdjustment,
      //     event_label: clientId,
      //   });
      //   break;
      // }
      // case PricingAdjustmentActionTypes.PUBLISH_SURCHARGE: {
      //   const {
      //     payload: { clientId },
      //   } = action;
      //   googleAnalyticsInstance.sendUnstructuredEvent('event', EventName.Publish, {
      //     event_category: EventCategory.PricingAdjustment,
      //     event_label: clientId,
      //   });
      //   break;
      // }
      // case PricingAdjustmentActionTypes.PUBLISH_SURCHARGE_SUCCESS: {
      //   const {
      //     payload: { surcharge },
      //   } = action;
      //   const { clientId } = state.clientData;

      //   // Occurs when the "Base price only" adjustment is published
      //   if (surcharge.categories.includes(PricingSurchargeCategory.BasePrice) && clientId && !isCarportView(clientId)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjSurchargeCalcBasePricePublished, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the "Base price only" adjustment is published',
      //     });
      //   }
      //   // Occurs when the "Subtotal" adjustment is published
      //   if (surcharge.categories.includes(PricingSurchargeCategory.Subtotal) && clientId && !isCarportView(clientId)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjSurchargeCalcSubtotalPublished, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the "Subtotal" adjustment is published',
      //     });
      //   }
      //   // Occurs when any "Vary Surcharges By" option is published
      //   if (surcharge.rules.length > 0) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjSurchargeVarySurcharge, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when any "Vary Surcharges By" option is published',
      //     });
      //   }
      //   // Occurs when an "Add Surcharge" option is published (or more than one adjustment is published together)
      //   if (surcharge.rules.length > 1) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjSurchargeAddSurcharge, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription:
      //         'Occurs when an "Add Surcharge" option is published (or more than one adjustment is published together)',
      //     });
      //   }
      //   // Occurs when the Base Price is included in the published surcharge
      //   if (surcharge.categories.includes(PricingSurchargeCategory.BasePrice)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjCalcIncBasePrice, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the Base Price is included in the published surcharge',
      //     });
      //   }
      //   // Occurs when the Structure is included in the published surcharge
      //   if (surcharge.categories.includes(PricingSurchargeCategory.Structure)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjCalcIncStructure, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the Structure is included in the published surcharge',
      //     });
      //   }
      //   // Occurs when the Doors & Windows is included in the published surcharge
      //   if (surcharge.categories.includes(PricingSurchargeCategory.DoorsAndWindows)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjCalcIncDoorsWindows, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the Doors & Windows is included in the published surcharge',
      //     });
      //   }
      //   // Occurs when the Addons is included in the published surcharge
      //   if (surcharge.categories.includes(PricingSurchargeCategory.AddOns)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjCalcIncAddons, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the Addons is included in the published surcharge',
      //     });
      //   }
      //   // Occurs when the Services is included in the published surcharge
      //   if (surcharge.categories.includes(PricingSurchargeCategory.Services)) {
      //     googleAnalyticsInstance.sendStandardEvent(EventKey.PricingAdjCalcIncServices, {
      //       eventCategory: EventCategory.Feature,
      //       eventDescription: 'Occurs when the Services is included in the published surcharge',
      //     });
      //   }
      //   break;
      // }

      default:
        break;
    }

    next(action);
  };
