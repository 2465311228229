/* eslint-disable react/no-unstable-nested-components */
import { Box, Card, CardActions, CardContent, CardHeader, MenuItem, Hidden } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useLayoutEffect } from 'react';
import AutoSizer, { Size } from 'react-virtualized/dist/commonjs/AutoSizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useTranslation } from 'react-i18next';
import JoyRide, { ACTIONS, CallBackProps, Placement, STATUS } from 'react-joyride';
import {
  fetchOrderOwnerOptions,
  fetchOrders,
  setDetailsState,
  setOrderDealer,
  setOrderOwner,
  setOrderStatus,
  setSortProperties,
} from '../ducks/orders';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { Order } from '../types/Order';
import { OrderDealer } from '../types/OrderDealer';
import { OrderOwner } from '../types/OrderOwner';
import { OrderStatus } from '../types/OrderStatus';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { isCurrentUserManager, isCurrentUserGroupAdmin, isIdeaRoomGroup } from '../utils/userUtils';
import {
  getOrderDealerFromOrder,
  getOrderOwnerFromOrder,
  getOrderStatusFromOrder,
  getOrderStatusLabelFromOrder,
  mapSubmitStatusToLabel,
} from '../utils/orderUtils';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { sortRows } from '../utils/sortUtils';
import { hyphenateString, stringToLowerIncludes } from '../utils/stringUtils';
import { NewWindowLink } from './NewWindowLink';
import { OrderDetailsDialog } from './OrderDetailsDialog';
import { OrderDetailsDialog as MapNeededOrderDetailsDialog } from './map-needed/OrderDetailsDialog';
import { OrderFilterMenu } from './OrderFilterMenu';
import { OrderDealerSelector } from './OrderDealerSelector';
import { OrderOwnerSelector } from './OrderOwnerSelector';
import { OrderStatusSelector } from './OrderStatusSelector';
import { Table } from './Table';
import { TableControls } from './TableControls';
import { unknownUser } from '../types/User';
import { getSiteFromClientId } from '../utils/clientIdUtils';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { ColumnFilterMenu } from './ColumnFilterMenu';
import { Columns, SalesViewUILayout } from '../constants/Viewer';
import { formatPrice } from '../utils/pricingUtils';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { getEnabledOnProperty, anyHasSmartBuildIntegrationEnabled } from '../utils/vendorDataUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppSelector, useAppDispatch } from '../hooks';
import { exportToCSV } from '../utils/fileUtils';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { JoyrideTooltip } from './JoyrideTooltip';
import { setRunLeadsTour } from '../ducks/gettingStartedSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  orderCards: {
    height: '100%',
  },
  orderCard: {
    minHeight: 150,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  orderCardHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: '0',
  },
  orderCardHeaderContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  orderCardHeaderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  orderCardContent: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  orderCardContentLine: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const OrderReport: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );
  const { currency, visibleColumns } = useAppSelector((state: AppState) => state?.viewer);
  const { searchTerm } = useAppSelector((state: AppState) => state?.search);
  const { loading, orders, orderOwnerOptions } = useAppSelector((state: AppState) => state?.orders);
  const { members } = useAppSelector((state: AppState) => state?.group.group || unknownGroup);
  const { group: { configurators = [], groupId } = unknownGroup, availableDealers: orderDealerOptions = [] } =
    useAppSelector((state: AppState) => state?.currentUser);

  const isIdearoomGroup = useAppSelector(({ currentUser: { group = unknownGroup } }: AppState) =>
    isIdeaRoomGroup(group.groupId),
  );
  const dealersEnabled = useAppSelector(({ currentUser: { group = unknownGroup, availableDealers = [] } }: AppState) =>
    group.configurators
      ? group.configurators.some((c) =>
          getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
        ) && availableDealers.length > 0
      : false,
  );
  const managerUser = useAppSelector(({ currentUser: { user = unknownUser } }: AppState) => isCurrentUserManager(user));
  const adminUser = useAppSelector(({ currentUser: { user = unknownUser, group = unknownGroup } }: AppState) =>
    isCurrentUserGroupAdmin(user, group, group),
  );

  const runLeadsTour = useAppSelector((state) => state?.gettingStarted.runLeadsTour);

  const [filteredOrders, setFilteredOrders] = React.useState<Order[]>(orders);
  const [sortState, setSortState] = React.useState<SortProperty[]>([]);

  const getListItemSize = (): number => {
    let size = 0;
    size += visibleColumns.includes(Columns.Name) ? 48 : 0;
    size += visibleColumns.includes(Columns.Email) ? 24 : 0;
    size += visibleColumns.includes(Columns.Phone) || visibleColumns.includes(Columns.Created) ? 24 : 0;
    size +=
      visibleColumns.includes(Columns.Zip) ||
      visibleColumns.includes(Columns.City) ||
      visibleColumns.includes(Columns.State)
        ? 24
        : 0;
    size += visibleColumns.includes(Columns.Style) || visibleColumns.includes(Columns.Estimate) ? 24 : 0;
    size += visibleColumns.includes(Columns.Size) || visibleColumns.includes(Columns.Reference) ? 24 : 0;
    size += configurators && configurators.length > 1 && visibleColumns.includes(Columns.Site) ? 24 : 0;
    size +=
      (dealersEnabled && (adminUser || orderDealerOptions.length > 1) && visibleColumns.includes(Columns.Dealer)) ||
      ((adminUser || managerUser) && visibleColumns.includes(Columns.Owner)) ||
      visibleColumns.includes(Columns.Status)
        ? 48
        : 0;
    size += 6; // padding
    return size;
  };

  const scrollToJoyride = (): void => {
    const targetElement = document.querySelector('#table-header-status');
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }, 300); // Give time for tooltip to be rendered
    }
  };

  // Effect that filters orders by searchTerm anytime either orders or searchTerm change
  useEffect(() => {
    if (runLeadsTour) {
      scrollToJoyride();
    }

    // don't bother searching until there's at least 3 characters
    if (searchTerm.length < 3) {
      if (filteredOrders.length !== orders.length) {
        setFilteredOrders(orders);
      }
      return;
    }

    // Filtering the columns to search on based on their visibility
    // Filtering row values based on what is shown and not keys or values
    const searchCriteria = [
      ...(visibleColumns.includes(Columns.Created)
        ? [
            (order: Order): boolean =>
              order.date !== undefined && stringToLowerIncludes(moment(order.date).format('MMM DD'), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Name)
        ? [(order: Order): boolean => stringToLowerIncludes(order.customerName, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Email)
        ? [(order: Order): boolean => stringToLowerIncludes(order.customerEmail, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Phone)
        ? [
            (order: Order): boolean => stringToLowerIncludes(order.customerPhone, searchTerm),
            (order: Order): boolean => stringToLowerIncludes(formatPhoneNumber(order.customerPhone), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.City)
        ? [(order: Order): boolean => stringToLowerIncludes(order.city, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Zip)
        ? [(order: Order): boolean => stringToLowerIncludes(order.zipCode, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.State)
        ? [(order: Order): boolean => stringToLowerIncludes(order.state, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Style)
        ? [(order: Order): boolean => stringToLowerIncludes(order.buildingStyle, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.SubmitStatus)
        ? [
            (order: Order): boolean => stringToLowerIncludes(order.submitStatus, searchTerm),
            (order: Order): boolean => stringToLowerIncludes(mapSubmitStatusToLabel(order.submitStatus), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Size)
        ? [(order: Order): boolean => stringToLowerIncludes(order.buildingSize, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Estimate)
        ? [(order: Order): boolean => stringToLowerIncludes(order.totalPrice.toString(), searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Reference)
        ? [(order: Order): boolean => stringToLowerIncludes(order.versionedEmailId, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Site)
        ? [
            (order: Order): boolean =>
              stringToLowerIncludes(getSiteFromClientId(order.clientId, configurators), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Dealer)
        ? [
            (order: Order): boolean => {
              const dealer = getOrderDealerFromOrder(order);
              return stringToLowerIncludes(dealer.name || dealer.key, searchTerm);
            },
          ]
        : []),
      ...(visibleColumns.includes(Columns.Owner)
        ? [
            (order: Order): boolean => {
              const owner = getOrderOwnerFromOrder(order, members);
              return stringToLowerIncludes(owner.name || owner.email, searchTerm);
            },
          ]
        : []),
      ...(visibleColumns.includes(Columns.Status)
        ? [(order: Order): boolean => stringToLowerIncludes(getOrderStatusLabelFromOrder(order), searchTerm)]
        : []),
      (order: Order): boolean => stringToLowerIncludes(order.ipAddress, searchTerm),
      (order: Order): boolean => stringToLowerIncludes(order.versionedEmailId, searchTerm.replace('#', '')),
      (order: Order): boolean => stringToLowerIncludes(hyphenateString(order.versionedEmailId), searchTerm),
    ];

    setFilteredOrders(orders.filter((order) => searchCriteria.some((test) => test(order))));
  }, [orders, configurators, filteredOrders.length, visibleColumns, searchTerm]);

  // Effect that adds the Export button to secondaryActions menu before the browser paints
  useLayoutEffect(() => {
    if (adminUser) {
      dispatch(
        setSecondaryActions([
          <MenuItem
            key="export"
            onClick={() => {
              const headers = [
                t(I18nKeys.TableHeaderCreated),
                t(I18nKeys.TableHeaderName),
                t(I18nKeys.TableHeaderEmail),
                t(I18nKeys.TableHeaderPhone),
                t(I18nKeys.TableHeaderCity),
                t(I18nKeys.TableHeaderDeliveryZip),
                t(I18nKeys.TableHeaderBuildingStyle),
                t(I18nKeys.TableHeaderBuildingSize),
                t(I18nKeys.TableHeaderEstimate),
                t(I18nKeys.TableHeaderReferenceNumber),
                t(I18nKeys.TableHeaderSubmitStatus),
                t(I18nKeys.TableHeaderDesign),
                t(I18nKeys.TableHeaderDealer),
                t(I18nKeys.TableHeaderDealerEmail),
                t(I18nKeys.TableHeaderOwner),
                t(I18nKeys.TableHeaderOwnerEmail),
                t(I18nKeys.TableHeaderSite),
                t(I18nKeys.TableHeaderStatus),
              ];
              const rows = filteredOrders.map((order) => [
                moment(order.date).format('MM/DD/YYYY'),
                order.customerName,
                order.customerEmail,
                order.customerPhone,
                order.city,
                order.zipCode,
                order.buildingStyle,
                order.buildingSize,
                order.totalPrice?.toString() || '',
                order.versionedEmailId,
                mapSubmitStatusToLabel(order.submitStatus),
                order.orderDealerLink,
                order.dealerName,
                order.dealerEmail,
                order.ownerName,
                order.ownerEmail,
                getSiteFromClientId(order.clientId, configurators) || '',
                getOrderStatusLabelFromOrder(order),
              ]);

              exportToCSV(t(I18nKeys.LeadsExportCSVTitle), headers, rows);
            }}
          >
            {t(I18nKeys.ExportButton)}
          </MenuItem>,
        ]),
      );
    }
  }, [adminUser, dispatch, t, filteredOrders, configurators]);

  // Effect that performs cleanup on the secondaryActions menu when view is unloaded
  useLayoutEffect(
    () => (): void => {
      dispatch(setSecondaryActions([]));
    },
    [dispatch],
  );

  const handleSortChanged = (sortProperties: SortProperty[]): void => {
    setSortState(sortProperties);
    dispatch(setSortProperties(sortProperties));
    sortRows(filteredOrders, sortProperties);
  };

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchOrderOwnerOptions(groupId));
  }, [groupId, dispatch]);

  const sortableProperties: SortableProperty[] = [
    { i18nKey: I18nKeys.TableHeaderCreated, property: 'date' },
    { i18nKey: I18nKeys.TableHeaderName, property: 'customerName' },
    { i18nKey: I18nKeys.TableHeaderEmail, property: 'customerEmail' },
    { i18nKey: I18nKeys.TableHeaderPhone, property: 'customerPhone' },
    { i18nKey: I18nKeys.TableHeaderSite, property: 'clientId' },
    { i18nKey: I18nKeys.TableHeaderDealer, property: 'dealerName', defaultValue: t(I18nKeys.DefaultDealerName) },
    { i18nKey: I18nKeys.TableHeaderOwner, property: 'ownerName', defaultValue: t(I18nKeys.DefaultOwnerName) },
    {
      i18nKey: I18nKeys.TableHeaderStatus,
      property: 'orderStatusName',
      defaultValue: t(I18nKeys.DefaultStatusName),
    },
    {
      i18nKey: I18nKeys.TableHeaderSubmitStatus,
      property: 'submitStatus',
    },
    { i18nKey: I18nKeys.TableHeaderDeliveryZip, property: 'zipCode' },
    { i18nKey: I18nKeys.TableHeaderBuildingStyle, property: 'buildingStyle' },
    { i18nKey: I18nKeys.TableHeaderBuildingSize, property: 'buildingSize' },
    { i18nKey: I18nKeys.TableHeaderEstimate, property: 'totalPrice' },
    { i18nKey: I18nKeys.TableHeaderReferenceNumber, property: 'versionedEmailId' },
  ];

  const LeadCard = ({ index, style }: ListChildComponentProps): JSX.Element => {
    const order = filteredOrders[index];
    return (
      <div style={style}>
        <Card
          className={classes.orderCard}
          key={order.hash}
          onClick={(): void => {
            dispatch(setDetailsState(order));
            dispatch(openDialog({ dialog: Dialogs.OrderDetails }));
          }}
        >
          {visibleColumns.includes(Columns.Name) && (
            <CardHeader
              classes={{
                root: classes.orderCardHeader,
                content: classes.orderCardHeaderContent,
                title: classes.orderCardHeaderTitle,
              }}
              title={order.customerName}
              action={
                visibleColumns.includes(Columns.Design) && <NewWindowLink link={order.orderDealerLink || order.link} />
              }
            />
          )}
          <CardContent className={classes.orderCardContent}>
            {visibleColumns.includes(Columns.Email) && (
              <Box className={classes.orderCardContentLine}>{order.customerEmail}</Box>
            )}

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Phone) && <Box>{formatPhoneNumber(order.customerPhone)}</Box>}
              {visibleColumns.includes(Columns.Created) && <Box>{moment(order.date).format('MMM DD')}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.City) && <Box>{order.city}</Box>}
              {visibleColumns.includes(Columns.City) && <Box>{order.state}</Box>}
              {visibleColumns.includes(Columns.Zip) && <Box>{order.zipCode}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Style) && <Box>{order.buildingStyle}</Box>}
              {visibleColumns.includes(Columns.Estimate) && <Box>{formatPrice(order.totalPrice, currency, 2)}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Size) && <Box>{order.buildingSize}</Box>}
              {visibleColumns.includes(Columns.Reference) && <Box>{hyphenateString(order.versionedEmailId)}</Box>}
            </Box>

            {configurators && configurators.length > 1 && visibleColumns.includes(Columns.Site) && (
              <Box className={classes.orderCardContentLine}>{getSiteFromClientId(order.clientId, configurators)}</Box>
            )}
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="space-between" width="100%">
              {dealersEnabled &&
                (adminUser || orderDealerOptions.length > 1) &&
                visibleColumns.includes(Columns.Dealer) && (
                  <Box>
                    {!adminUser && !managerUser ? (
                      <span>{getOrderDealerFromOrder(order).name}</span>
                    ) : (
                      <OrderDealerSelector
                        readonly={isIdearoomGroup}
                        dealer={getOrderDealerFromOrder(order)}
                        options={orderDealerOptions}
                        onOrderDealerChange={(orderDealer: OrderDealer): void => {
                          dispatch(
                            setOrderDealer(
                              order.clientId,
                              order.uuid,
                              getOrderStatusLabelFromOrder(order),
                              orderDealer,
                            ),
                          );
                        }}
                      />
                    )}
                  </Box>
                )}
              {(adminUser || managerUser) && visibleColumns.includes(Columns.Owner) && (
                <Box>
                  <OrderOwnerSelector
                    readonly={isIdearoomGroup}
                    owner={getOrderOwnerFromOrder(order, members)}
                    options={orderOwnerOptions}
                    onOrderOwnerChange={(orderOwner: OrderOwner): void => {
                      dispatch(
                        setOrderOwner(order.clientId, order.uuid, getOrderStatusLabelFromOrder(order), orderOwner),
                      );
                    }}
                  />
                </Box>
              )}
              {visibleColumns.includes(Columns.Status) && (
                <Box>
                  <OrderStatusSelector
                    readonly={isIdearoomGroup}
                    status={getOrderStatusFromOrder(order)}
                    onOrderStatusChange={(orderStatus: OrderStatus): void => {
                      dispatch(setOrderStatus(order.clientId, order.uuid, orderStatus));
                    }}
                  />
                </Box>
              )}
            </Box>
          </CardActions>
        </Card>
      </div>
    );
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    if (action === ACTIONS.CLOSE || status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      if (status === STATUS.FINISHED) {
        dispatch(setRunLeadsTour(false));
      }
    }
  };

  return (
    <div className={classes.root}>
      {layout !== SalesViewUILayout.Horizon ? <MapNeededOrderDetailsDialog /> : <OrderDetailsDialog />}
      <TableControls
        filterRows={<OrderFilterMenu count={filteredOrders.length} />}
        filterColumns={<ColumnFilterMenu />}
        sortableProperties={sortableProperties}
        sortProperties={sortState}
        onSort={handleSortChanged}
      />
      <Hidden smDown>
        {!loading && (
          <JoyRide
            hideBackButton
            hideCloseButton
            run={runLeadsTour}
            steps={[
              {
                disableOverlay: true,
                placement: 'top' as Placement,
                spotlightClicks: true,
                target: `#${I18nKeys.TableHeaderStatus}`,
                content:
                  'Update the Status and Owner as your sales team works the lead. Click on the row to see the building.',
                disableBeacon: true,
              },
            ]}
            tooltipComponent={JoyrideTooltip}
            scrollOffset={100}
            callback={handleJoyrideCallback}
            locale={{
              close: 'Got it',
            }}
            styles={{
              options: {
                zIndex: 1300,
              },
            }}
          />
        )}

        <Table
          headers={[
            {
              i18nKey: I18nKeys.TableHeaderCreated,
              property: 'date',
              hidden: !visibleColumns.includes(Columns.Created),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{moment(value).format('MMM DD')}</span>,
            },
            {
              i18nKey: I18nKeys.TableHeaderName,
              property: 'customerName',
              hidden: !visibleColumns.includes(Columns.Name),
            },
            {
              i18nKey: I18nKeys.TableHeaderEmail,
              property: 'customerEmail',
              hidden: !visibleColumns.includes(Columns.Email),
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderPhone,
              property: 'customerPhone',
              hidden: !visibleColumns.includes(Columns.Phone),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{formatPhoneNumber(value)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderCity,
              property: 'city',
              hidden: !visibleColumns.includes(Columns.City),
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderState,
              property: 'state',
              hidden: !visibleColumns.includes(Columns.State),
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '125px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDeliveryZip,
              property: 'zipCode',
              hidden: !visibleColumns.includes(Columns.Zip),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderBuildingStyle,
              property: 'buildingStyle',
              hidden: !visibleColumns.includes(Columns.Style),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderBuildingSize,
              property: 'buildingSize',
              hidden: !visibleColumns.includes(Columns.Size),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderEstimate,
              property: 'totalPrice',
              hidden: !visibleColumns.includes(Columns.Estimate),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{formatPrice(value, currency, 2)}</span>,
              cellStyle: {
                whiteSpace: 'nowrap',
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'right',
              },
            },
            {
              i18nKey: I18nKeys.TableHeaderReferenceNumber,
              property: 'versionedEmailId',
              hidden: !visibleColumns.includes(Columns.Reference),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{hyphenateString(value)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderSite,
              property: 'clientId',
              hidden: !(configurators && configurators.length > 1) || !visibleColumns.includes(Columns.Site),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{getSiteFromClientId(value, configurators)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDealer,
              property: 'dealerName',
              hidden:
                !dealersEnabled ||
                (!adminUser && orderDealerOptions.length < 2) ||
                !visibleColumns.includes(Columns.Dealer),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element =>
                !adminUser && !managerUser ? (
                  <span>{getOrderDealerFromOrder(row).name}</span>
                ) : (
                  <OrderDealerSelector
                    readonly={isIdearoomGroup}
                    dealer={getOrderDealerFromOrder(row)}
                    options={orderDealerOptions.filter((dealer) => dealer.clientId === row.clientId)}
                    onOrderDealerChange={(orderDealer: OrderDealer): void => {
                      dispatch(setOrderDealer(row.clientId, row.uuid, getOrderStatusLabelFromOrder(row), orderDealer));
                    }}
                  />
                ),
            },
            {
              i18nKey: I18nKeys.TableHeaderOwner,
              property: 'ownerName',
              hidden: (!adminUser && !managerUser) || !visibleColumns.includes(Columns.Owner),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element => (
                <OrderOwnerSelector
                  readonly={isIdearoomGroup}
                  owner={getOrderOwnerFromOrder(row, members)}
                  options={orderOwnerOptions}
                  onOrderOwnerChange={(orderOwner: OrderOwner): void => {
                    dispatch(setOrderOwner(row.clientId, row.uuid, getOrderStatusLabelFromOrder(row), orderOwner));
                  }}
                />
              ),
            },
            {
              i18nKey: I18nKeys.TableHeaderStatus,
              property: 'orderStatusName',
              hidden: !visibleColumns.includes(Columns.Status),
              CellComponent: ({ row }: any): JSX.Element => (
                <OrderStatusSelector
                  readonly={isIdearoomGroup}
                  status={getOrderStatusFromOrder(row)}
                  onOrderStatusChange={(orderStatus: OrderStatus): void => {
                    dispatch(setOrderStatus(row.clientId, row.uuid, orderStatus));
                  }}
                />
              ),
            },
            {
              i18nKey: I18nKeys.TableHeaderSubmitStatus,
              property: 'submitStatus',
              hidden: !visibleColumns.includes(Columns.SubmitStatus),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element => <span>{mapSubmitStatusToLabel(row?.submitStatus)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDesign,
              property: 'link',
              hidden: !visibleColumns.includes(Columns.Design),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row, value }: any): JSX.Element => (
                <NewWindowLink link={row.orderDealerLink || value} />
              ),
            },
            {
              i18nKey: I18nKeys.TableHeaderSmartBuild,
              property: 'smartbuildJobId',
              hidden: !anyHasSmartBuildIntegrationEnabled(configurators),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element => (
                <NewWindowLink
                  link={
                    row.smartbuildJobId
                      ? `https://postframesolver.azurewebsites.net/Framer/Edit/${row.smartbuildJobId}`
                      : ''
                  }
                />
              ),
            },
          ]}
          sortableProperties={sortableProperties}
          sortProperties={sortState}
          onSort={handleSortChanged}
          rows={filteredOrders}
          loading={loading}
          handleRowClick={(row): void => {
            dispatch(setDetailsState(row));
            dispatch(openDialog({ dialog: Dialogs.OrderDetails }));
          }}
        />
      </Hidden>

      <Hidden smUp>
        <Box style={{ flexDirection: 'column', height: '100%' }}>
          <div className={classes.orderCards} style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ height, width }: Size): JSX.Element => (
                <FixedSizeList
                  style={{ paddingBottom: '70px' }}
                  height={height}
                  width={width}
                  itemCount={filteredOrders.length}
                  itemSize={getListItemSize()}
                >
                  {LeadCard}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Hidden>
    </div>
  );
};
