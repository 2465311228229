import { Theme, Container, useMediaQuery, Stack } from '@mui/material';
import { HookRouter, navigate, useInterceptor, useRoutes, usePath } from 'hookrouter';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Permission } from '@idearoom/types';
import { AppRoutes, RouteKeys } from '../constants/AppRoutes';
import { AuthStatus } from '../constants/AuthStatus';
import { setSearchTerm } from '../ducks/search';
import { Group } from '../types/Group';
import { unknownGroup } from '../constants/Group';
import { unknownUser, User } from '../types/User';
import {
  isCurrentUserAdmin,
  isCurrentUserAdminOrManager,
  isCurrentUserSuperUser,
  isIdeaRoomGroup,
  isIdeaRoomUser,
} from '../utils/userUtils';
import { UserDialog } from './UserDialog';
import { UserDialog as MapNeededUserDialog } from './map-needed/UserDialog';
import { ConfiguratorDialog } from './ConfiguratorDialog';
import { ConfirmDialog } from './ConfirmationDialog';
import { ConfirmDialog as MapNeededConfirmDialog } from './map-needed/ConfirmationDialog';
import { NotificationDialog } from './NotificationDialog';
import { NotificationDialog as MapNeededNotificationDialog } from './map-needed/NotificationDialog';
import { Dealers } from './Dealers';
import { Group as GroupComponent } from './Group';
import { Groups } from './Groups';
import { OrderReport } from './OrderReport';
import { Profile } from './Profile';
import { Profile as MapNeededProfile } from './map-needed/Profile';
import { Users } from './Users';
import { Settings } from './Settings';
import { SceneEnvironmentDialog } from './SceneEnvironmentDialog';
import { SceneEnvironmentDialog as MapNeededSceneEnvironmentDialog } from './map-needed/SceneEnvironmentDialog';
import { WhatsNewDialog } from './WhatsNewDialog';
import { WhatsNewPreviewDialog } from './WhatsNewPreviewDialog';
import { WhatsNewDialog as MapNeededWhatsNewDialog } from './map-needed/WhatsNewDialog';
import { WhatsNewPreviewDialog as MapNeededWhatsNewPreviewDialog } from './map-needed/WhatsNewPreviewDialog';
import { Tour } from './Tour';
import { Pricing } from './Pricing';
import { Usage } from './Usage';
import { Sites } from './sites/Sites';
import { Sites as MapNeededSites } from './map-needed/Sites';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { AllowedHTMLTagsDialog } from './AllowedHTMLTagsDialog';
import { AllowedHTMLTagsDialog as MapNeededAllowedHTMLTagsDialog } from './map-needed/AllowedHTMLTagsDialog';
import { I18nDialog } from './I18nDialog';
import { I18nDialog as MapNeededI18nDialog } from './map-needed/I18nDialog';
import { LanguageDialog } from './LanguageDialog';
import { LanguageDialog as MapNeededLanguageDialog } from './map-needed/LanguageDialog';
import { ClientData } from './ClientData';
import { ImpersonationDialog } from './ImpersonationDialog';
import { ImpersonationDialog as MapNeededImpersonationDialog } from './map-needed/ImpersonationDialog';
import { Reports } from './Reports';
import { Payments } from './Payments';
import {
  mainMenuWidth,
  MenuStatus,
  publishBarHeight,
  SalesViewUILayout,
  secondaryMenuWidth,
} from '../constants/Viewer';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getBreadcrumbsHeight, getLogoBarHeight } from '../utils/viewerUtils';
import { AppState } from '../types/AppState';
import { LoadingDialog } from './LoadingDialog';
import { LoadingDialog as MapNeededLoadingDialog } from './map-needed/LoadingDialog';
import { Integrations } from './Integrations';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { PricingTab } from '../constants/Pricing';
import { hasAdminPermissions } from '../utils/permissionUtils';
import { GettingStarted } from './GettingStarted';
import { Orders } from './Orders';
import { TopBarBreadcrumbs } from './TopBarBreadcrumbs';
import { MenuVariant } from '../constants/Menu';
import { BusinessInfo } from './sites/BusinessInfo';
import { VendorDataCategory } from '../constants/VendorData';
import { ColorsAndLogos } from './sites/ColorsAndLogos';
import { Documents } from './sites/Documents';
import { ControlsAndFeatures } from './sites/ControlsAndFeatures';
import { Visibility } from './sites/Visibility';
import { Floorplan } from './sites/Floorplan';
import { LeadCapture } from './sites/LeadCapture';
import { BannersAndMessages } from './sites/BannersAndMessages';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SiteDetailPublishResultDialog } from './SiteDetailPublishResultDialog';
import { EmailDomainDialog } from './EmailDomainDialog';
import { Loading } from './Loading';
import { SitesRevertDialog } from './sites/SitesRevertDialog';

const useStyles = makeStyles<
  Theme,
  {
    layout: SalesViewUILayout;
    menuStatus: MenuStatus;
    showPublishBar: boolean;
    mobileView: boolean;
    path: string;
    variant: MenuVariant;
  }
>((theme: Theme) => ({
  main: {
    flexGrow: 1,
    marginTop: ({ layout, mobileView, path }): string => `${getLogoBarHeight({ layout, mobileView, path })}px`,
    marginBottom: ({ layout, showPublishBar }): string =>
      `${showPublishBar ? (layout !== SalesViewUILayout.Horizon && 64) || publishBarHeight : 0}px`,
    marginLeft: ({ layout, menuStatus, mobileView, variant }): string =>
      mobileView || menuStatus === MenuStatus.Closed
        ? `0px`
        : `${layout !== SalesViewUILayout.Horizon ? 260 : mainMenuWidth[variant] + secondaryMenuWidth[variant]}px`,
    transition: theme.transitions.create(['margin-left', 'margin-bottom', 'max-height'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: 'auto',
    maxHeight: ({ layout, showPublishBar, mobileView, path }): string =>
      `calc(100vh - ${getLogoBarHeight({ layout, mobileView, path })}px - ${
        showPublishBar ? (layout !== SalesViewUILayout.Horizon && 64) || publishBarHeight : 0
      }px)`,
  },
  root: {
    display: 'flex',
    float: 'left',
    height: ({ layout, path }) =>
      `calc(100% - ${layout !== SalesViewUILayout.Horizon ? 0 : getBreadcrumbsHeight({ path })}px)`,
    width: '100%',
    padding: 0,
    maxWidth: 'none',
  },
}));

const routes = (
  layout: SalesViewUILayout,
  user: User = unknownUser,
  userGroup: Group = unknownGroup,
): HookRouter.RouteObject => {
  const routesObject = {
    '/profile': () =>
      // UI Layout Remove Me
      layout !== SalesViewUILayout.Horizon ? (
        <MapNeededProfile key={RouteKeys.ProfileKey} />
      ) : (
        <Profile key={RouteKeys.ProfileKey} />
      ),
    '/home': () => <GettingStarted key={RouteKeys.HomeKey} />,
    '/orders': () => <Orders key={RouteKeys.OrdersKey} />,
    '/leads': () => <OrderReport key={RouteKeys.LeadsKey} />,
    '/payments': () => <Payments key={RouteKeys.PaymentsKey} />,
    '/integrations': () => <Integrations key={RouteKeys.IntegrationsKey} />,
  } as HookRouter.RouteObject;

  const { configurators } = userGroup;
  if (
    configurators &&
    configurators.some((c) =>
      getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
    ) &&
    hasAdminPermissions(user.permissions)
  ) {
    routesObject['/dealers'] = (): JSX.Element => <Dealers key={RouteKeys.DealersKey} />;
  }

  if (isIdeaRoomGroup(userGroup.groupId)) {
    // Only IdeaRoom members can access the group page.
    routesObject['/groups'] = (): JSX.Element => <Groups key={RouteKeys.GroupsKey} />;
    routesObject['/groups/:groupId'] = ({ groupId }: HookRouter.QueryParams): JSX.Element => (
      <GroupComponent key={RouteKeys.GroupKey} groupId={groupId} />
    );
    routesObject['/reports'] = (): JSX.Element => <Reports key={RouteKeys.ReportsKey} />;
  }

  if (isIdeaRoomUser(user) || isCurrentUserSuperUser(user)) {
    routesObject['/data'] = (): JSX.Element => <ClientData key={RouteKeys.ClientDataKey} />;
    routesObject['/data/*'] = (): JSX.Element => <ClientData key={RouteKeys.ClientDataKey} />;
  }

  if (hasAdminPermissions(user.permissions) || user.permissions.includes(Permission.Manager)) {
    // Only admins and managers can access the users page.
    routesObject['/team'] = (): JSX.Element => <Users key={RouteKeys.TeamKey} groupId={userGroup.groupId} />;
    routesObject['/home'] = (): JSX.Element => <GettingStarted key={RouteKeys.HomeKey} />;
  }

  if (hasAdminPermissions(user.permissions)) {
    // Only admins can access the usage, pricing, and sites pages.
    routesObject['/analyze'] = (): JSX.Element => <Usage key={RouteKeys.AnalyzeKey} />;
    routesObject[`/pricing`] = (): JSX.Element => <Pricing key={RouteKeys.PricingKey} />;
    Object.values(PricingTab).forEach((tab) => {
      routesObject[`/pricing/${tab}`] = (): JSX.Element => <Pricing key={RouteKeys.PricingKey} />;
    });

    if (layout === SalesViewUILayout.Horizon) {
      routesObject['/sites'] = (): JSX.Element => <Sites key={RouteKeys.SitesKey} />;
      routesObject[`/sites/:clientId/${VendorDataCategory.BusinessInfo}`] = ({ clientId }): JSX.Element => (
        <BusinessInfo key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.ColorsAndLogos}`] = ({ clientId }): JSX.Element => (
        <ColorsAndLogos key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.LeadCapture}`] = ({ clientId }): JSX.Element => (
        <LeadCapture key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.BannersAndMessages}`] = ({ clientId }): JSX.Element => (
        <BannersAndMessages key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.Documents}`] = ({ clientId }): JSX.Element => (
        <Documents key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.Floorplan}`] = ({ clientId }): JSX.Element => (
        <Floorplan key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.ControlsAndFeatures}`] = ({ clientId }): JSX.Element => (
        <ControlsAndFeatures key={RouteKeys.SitesKey} clientId={clientId} />
      );
      routesObject[`/sites/:clientId/${VendorDataCategory.Visibility}`] = ({ clientId }): JSX.Element => (
        <Visibility key={RouteKeys.SitesKey} clientId={clientId} />
      );
    } else {
      routesObject['/sites'] = (): JSX.Element => <MapNeededSites key={RouteKeys.SitesKey} />;
    }

    routesObject['/settings'] = (): JSX.Element => <Settings key={RouteKeys.SettingsKey} groupId={userGroup.groupId} />;
  }

  return routesObject;
};

export const SalesView: React.FC = () => {
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const path = usePath();

  const { variant, showPublishBar } = useAppSelector((state: AppState) => state.menu);
  const { loading, authStatus, user, group } = useAppSelector((state: AppState) => state.currentUser);
  const { menuStatus } = useAppSelector((state: AppState) => state.viewer);
  // UI Layout Remove Me
  const layout = useAppSelector(
    (state: AppState) =>
      state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout] ||
      SalesViewUILayout.MapNeeded,
  );

  const classes = useStyles({ layout, menuStatus, showPublishBar, mobileView, path, variant });
  if (!loading && authStatus !== AuthStatus.SignedIn) {
    navigate(AppRoutes.SignIn);
  }

  const dispatch = useAppDispatch();
  const route = useRoutes(routes(layout, user, group));

  const routeChangedInterceptor = (currentPath: string, nextPath: string): string => {
    // clear search on route changes
    dispatch(setSearchTerm(''));
    dispatch(setSecondaryActions([]));
    return nextPath;
  };

  useInterceptor(routeChangedInterceptor);

  if (loading) return <Loading />;
  return (
    <main className={classes.main}>
      <Stack sx={{ height: '100%' }}>
        {layout === SalesViewUILayout.Horizon && <TopBarBreadcrumbs />}
        <Container className={classes.root}>
          {route}
          {layout !== SalesViewUILayout.Horizon ? <MapNeededConfirmDialog /> : <ConfirmDialog />}
          {layout !== SalesViewUILayout.Horizon ? <MapNeededNotificationDialog /> : <NotificationDialog />}
          {layout !== SalesViewUILayout.Horizon ? <MapNeededLoadingDialog /> : <LoadingDialog />}
          {layout !== SalesViewUILayout.Horizon ? <MapNeededLanguageDialog /> : <LanguageDialog />}
          <Tour />

          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout === SalesViewUILayout.Horizon && <WhatsNewDialog />}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout !== SalesViewUILayout.Horizon && <MapNeededWhatsNewDialog />}

          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout === SalesViewUILayout.Horizon && <WhatsNewPreviewDialog />}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout !== SalesViewUILayout.Horizon && <MapNeededWhatsNewPreviewDialog />}

          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout !== SalesViewUILayout.Horizon && <MapNeededSceneEnvironmentDialog />}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout === SalesViewUILayout.Horizon && <SceneEnvironmentDialog />}

          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && layout !== SalesViewUILayout.Horizon && (
            <MapNeededAllowedHTMLTagsDialog />
          )}
          {(isCurrentUserAdmin(user) || isIdeaRoomUser(user)) && layout === SalesViewUILayout.Horizon && (
            <AllowedHTMLTagsDialog />
          )}

          {(isCurrentUserAdminOrManager(user) || isIdeaRoomUser(user)) &&
            [RouteKeys.GroupKey, RouteKeys.TeamKey].includes(route?.key) &&
            layout === SalesViewUILayout.Horizon && <UserDialog />}
          {(isCurrentUserAdminOrManager(user) || isIdeaRoomUser(user)) &&
            [RouteKeys.GroupKey, RouteKeys.TeamKey].includes(route?.key) &&
            layout !== SalesViewUILayout.Horizon && <MapNeededUserDialog />}

          {isIdeaRoomUser(user) && [RouteKeys.GroupKey, RouteKeys.GroupsKey].includes(route?.key) && (
            <ConfiguratorDialog />
          )}

          {isIdeaRoomUser(user) &&
            [RouteKeys.GroupKey, RouteKeys.TeamKey].includes(route?.key) &&
            layout === SalesViewUILayout.Horizon && <ImpersonationDialog />}
          {isIdeaRoomUser(user) &&
            [RouteKeys.GroupKey, RouteKeys.TeamKey].includes(route?.key) &&
            layout !== SalesViewUILayout.Horizon && <MapNeededImpersonationDialog />}

          {isIdeaRoomUser(user) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout === SalesViewUILayout.Horizon && <I18nDialog />}
          {isIdeaRoomUser(user) &&
            route &&
            route.key === RouteKeys.SettingsKey &&
            layout !== SalesViewUILayout.Horizon && <MapNeededI18nDialog />}

          {route?.key === RouteKeys.SitesKey && layout === SalesViewUILayout.Horizon && (
            <SiteDetailPublishResultDialog />
          )}
          {route?.key === RouteKeys.SitesKey && layout === SalesViewUILayout.Horizon && <EmailDomainDialog />}
          {route?.key === RouteKeys.SitesKey && layout === SalesViewUILayout.Horizon && <SitesRevertDialog />}
        </Container>
      </Stack>
    </main>
  );
};
