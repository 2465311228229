import React from 'react';
import { Card } from '@mui/material';
import { LeadsPopupDelay } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { LeadCaptureForm } from './LeadCaptureForm';
import { LeadCaptureFormFields } from '../../constants/FormFields';
import { I18nKeys } from '../../constants/I18nKeys';

export const LeadCapture: React.FC<{ clientId: string }> = () => {
  const { t } = useTranslation();
  const {
    selectedTableData: [
      {
        leadsEnableEmailPopup,
        leadsPopupDelay,
        leadsPopupTitle,
        leadsPopupCallToActionText,
        leadsPopupMessage,
        leadsPopupCollectEmail,
        leadsPopupCollectPhone,
        leadsPopupCollectName,
        leadsPopupCollectZipCode,
        leadsRequireForDesign,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  return (
    <SitesPage header="Lead Capture">
      <Card sx={{ p: '24px' }} elevation={0}>
        <LeadCaptureForm
          initialValues={{
            [LeadCaptureFormFields.LeadsEnableEmailPopup]: !!leadsEnableEmailPopup,
            [LeadCaptureFormFields.LeadsPopupDelay]: leadsPopupDelay || LeadsPopupDelay.AfterThirtySeconds,
            [LeadCaptureFormFields.LeadsPopupTitle]: leadsPopupTitle || t(I18nKeys.LeadsPopupDefaultTitle),
            [LeadCaptureFormFields.LeadsPopupCallToActionText]:
              leadsPopupCallToActionText || t(I18nKeys.LeadsPopupDefaultCallToActionText),
            [LeadCaptureFormFields.LeadsPopupMessage]: leadsPopupMessage || t(I18nKeys.LeadsPopupDefaultMessage),
            [LeadCaptureFormFields.LeadsPopupCollectEmail]: !!leadsPopupCollectEmail,
            [LeadCaptureFormFields.LeadsPopupCollectPhone]: !!leadsPopupCollectPhone,
            [LeadCaptureFormFields.LeadsPopupCollectName]: !!leadsPopupCollectName,
            [LeadCaptureFormFields.LeadsPopupCollectZipCode]: !!leadsPopupCollectZipCode,
            [LeadCaptureFormFields.LeadsRequireForDesign]: leadsRequireForDesign ? 1 : 0,
          }}
        />
      </Card>
    </SitesPage>
  );
};
