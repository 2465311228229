import React from 'react';
import { Card } from '@mui/material';
import { FloorplanLabelingMethod } from '@idearoom/types';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { FloorplanFormFields } from '../../constants/FormFields';
import { FloorplanForm } from './FloorplanForm';

export const Floorplan: React.FC<{ clientId: string }> = () => {
  const {
    selectedTableData: [
      {
        useFloorPlanGridDimensions,
        floorplanLabelingMethod,
        floorplanShowSideTexts,
        useFloorPlanGridDimensionsLegend,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  return (
    <SitesPage header="Floorplan">
      <Card sx={{ p: '24px' }} elevation={0}>
        <FloorplanForm
          initialValues={{
            [FloorplanFormFields.UseFloorPlanGridDimensions]: useFloorPlanGridDimensions ? 1 : 0,
            [FloorplanFormFields.FloorplanLabelingMethod]:
              floorplanLabelingMethod || FloorplanLabelingMethod.LabelsInLegend,
            [FloorplanFormFields.FloorplanShowSideTexts]:
              floorplanShowSideTexts === undefined || floorplanShowSideTexts === null ? true : !!floorplanShowSideTexts,
            [FloorplanFormFields.UseFloorPlanGridDimensionsLegend]: !!useFloorPlanGridDimensionsLegend,
          }}
        />
      </Card>
    </SitesPage>
  );
};
