import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  styled,
  Theme,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { MenuVariant } from '../constants/Menu';
import { MenuIcon, MenuIconKey } from './MenuIcon';
import { useAppSelector } from '../hooks';
import { unknownUser } from '../types/User';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  menuItemText: {
    width: 'auto',
    textWrap: 'nowrap',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '14px',
    color: '#323B4B',
  },
}));

interface Props {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  variant?: MenuVariant;
}

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: '20px',
  alignItems: 'center',
  alignSelf: 'center',
});

export const UserMenuItem: React.FC<Props> = ({ onClick, variant = MenuVariant.STANDARD }: Props) => {
  const classes = useStyles();

  const { email = '', firstName = '', lastName = '' } = useAppSelector((state) => state.currentUser.user) || {};
  const userInitials = useAppSelector(
    ({ currentUser: { user: { firstName: first = '', lastName: last = '' } = unknownUser } }) =>
      `${first.charAt(0)}${last.charAt(0)}`,
  );

  return (
    <ListItem
      disablePadding
      sx={{
        height: '44px',
        borderRight: '1px solid #DEDEDE',
      }}
    >
      <Box
        sx={{
          width: '100%',
          justifyItems: 'center',
        }}
      >
        <ListItemButton
          onClick={(e) => onClick?.(e)}
          sx={{
            p: '0px',
            height: '44px',
            ...(variant === MenuVariant.STANDARD
              ? { pr: '16px', width: '100%' }
              : { border: 'none', borderRadius: '100px', height: '42px', width: '42px' }),
          }}
        >
          <Box
            display="flex"
            sx={{
              p: '9px 10px',
              pl: '10px',
              height: '44px',
              minWidth: '0px',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <ListItemIcon sx={{ m: '0px', mr: variant === MenuVariant.STANDARD ? '12px' : '0px' }}>
              <Avatar
                className={classes.avatar}
                sx={{ backgroundColor: variant === MenuVariant.STANDARD ? '#F2F4F5' : '#FFFFFF' }}
              >
                {userInitials}
              </Avatar>
            </ListItemIcon>

            {variant === MenuVariant.STANDARD && (
              <ListItemText
                className={classes.menuItemText}
                primary={[firstName, lastName].join(' ') || email}
                sx={{ alignSelf: 'center' }}
                primaryTypographyProps={{
                  fontWeight: '500',
                  color: '#323B4B',
                  sx: { opacity: 0.8 },
                }}
              />
            )}
          </Box>
          {variant === MenuVariant.STANDARD && (
            <ListItemIcon sx={{ m: '0px' }}>
              <MenuIcon iconKey={MenuIconKey.ARROW_DOWN} />
            </ListItemIcon>
          )}
        </ListItemButton>
      </Box>
    </ListItem>
  );
};
