/* eslint-disable react-hooks/rules-of-hooks */

import { OnlyExistingKeys } from '../types/Repo';
import { useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { useGetPaymentsQuery } from '../services/leadApi';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { PaymentOrder } from '../types/PaymentOrder';
import { MUIDataGridKey } from '../constants/MUIDataGrid';

const EMPTY_PAYMENTS_REFERENCE: Readonly<PaymentOrder[]> = [];

type UsePaymentRepoReturn<UsePaymentsType> = OnlyExistingKeys<{
  payments: UsePaymentsType extends true ? PaymentOrder[] : never;
  isLoadingPayments: UsePaymentsType extends true ? boolean : never;
}>;

export const usePaymentRepo = <UsePaymentsType extends boolean = false>({
  usePayments,
}: {
  usePayments?: UsePaymentsType;
} = {}): UsePaymentRepoReturn<UsePaymentsType> => {
  const { groupId = '', configurators: groupConfigurators = [] } = useAppSelector(
    (state) => state.currentUser.group || unknownGroup,
  );
  const { systemGroupOrderConfigurators = [] } = useAppSelector((state) => state.systemGroupOrderConfigurators);
  const { dateRangeFilter: { startDate = undefined, endDate = undefined } = {} } = useAppSelector(
    (state) => state.muiDataGrid[MUIDataGridKey.Payments] || state.muiDataGrid[MUIDataGridKey.Default],
  );

  const result: any = {};
  if (usePayments) {
    const configurators = (
      isIdeaRoomGroup(groupId)
        ? systemGroupOrderConfigurators
        : groupConfigurators.map((c) => ({ configurator: c.key, vendor: c.vendor }))
    )
      .map((c) => `${c.configurator}-${c.vendor}`)
      .join(',');
    const paymentRequest = {
      options: {
        queryParams: {
          configurators,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        },
      },
    };

    const skip = !configurators.length;
    const { data: payments = EMPTY_PAYMENTS_REFERENCE, isFetching: isLoadingLeads } = useGetPaymentsQuery(
      { paymentRequest },
      {
        skip,
        refetchOnMountOrArgChange: true,
      },
    );
    result.payments = payments;
    result.isLoadingPayments = isLoadingLeads;
  }

  return result;
};
