import React from 'react';
import { TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { InputField } from './redux-form/InputField';
import { InputField as MapNeededInputField } from './map-needed/InputField';
import { PreferencesFormFields, WhatsNewDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppSelector } from '../hooks';
import { UserPreference } from '../constants/User';
import { SalesViewUILayout } from '../constants/Viewer';
import { Form } from './redux-form/Form';

export interface FormData {
  [WhatsNewDialogFormFields.Id]: number;
  [WhatsNewDialogFormFields.Date]: string;
  [WhatsNewDialogFormFields.Title]: string;
  [WhatsNewDialogFormFields.Message]: string;
  [WhatsNewDialogFormFields.Readonly]: boolean;
}

type FormDispatchProps = {
  onSubmit(data: FormData): Promise<AnyAction>;
};

type FormProps = FormDispatchProps & InjectedFormProps<FormData>;

type DateFieldProps = {
  autoFocus?: boolean;
  disabled: boolean;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
};

// see https://material-ui-pickers.dev/guides/form-integration#redux-form-integration
const DateField = (props: DateFieldProps): JSX.Element => {
  const {
    disabled,
    meta: { submitting, error, touched },
    input: { name, onChange, value },
    autoFocus,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      {/* ^^ Required until https://github.com/mui/mui-x/issues/4942 is fixed */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          autoFocus={autoFocus}
          disabled={disabled || submitting}
          label={t(I18nKeys.WhatsNewDisplayStarting)}
          value={value}
          onChange={(date: any): void => onChange(date)}
          renderInput={(params): JSX.Element => (
            <TextField variant="standard" name={name} error={error && touched} {...params} /> // eslint-disable-line react/jsx-props-no-spreading
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

const WhatsNewFormComponent: React.FC<FormProps> = ({ error, handleSubmit, onSubmit, initialValues }: FormProps) => {
  const { t } = useTranslation();

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field disabled={initialValues.READONLY} name={WhatsNewDialogFormFields.Date} autoFocus component={DateField} />

      <Field
        autoComplete="off"
        // UI Layout Remove Me
        component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
        disabled={initialValues.READONLY}
        label={t(I18nKeys.WhatsNewFieldTitle)}
        name={WhatsNewDialogFormFields.Title}
      />

      <Field
        autoComplete="off"
        // UI Layout Remove Me
        component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
        disabled={initialValues.READONLY}
        label={t(I18nKeys.WhatsNewFieldMessage)}
        name={WhatsNewDialogFormFields.Message}
        multiline
        rows={5}
      />

      {!!error && <Typography sx={{ color: 'red !important' }}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.WhatsNew}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

export const WhatsNewForm = reduxForm<FormData>({ form: Forms.WhatsNew })(
  connect(undefined, mapDispatchToProps)(WhatsNewFormComponent),
);
