import React, { useCallback } from 'react';
import { Grid2 as Grid, FormControl, Collapse } from '@mui/material';
import { Field, formValueSelector } from 'redux-form';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { BannersAndMessagesFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { Header } from '../library/Header';
import { InputField } from '../redux-form/InputField';
import { SitesDivider } from './SitesDivider';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { sanitizeInput } from '../../utils/inputUtils';

type Props = {
  initializing: boolean;
};

export const InfoPanelSection: React.FC<Props> = ({ initializing }) => {
  const dispatch = useAppDispatch();

  const showInformationalPanel = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, BannersAndMessagesFormFields.ShowInformationalPanel),
  );

  const onChange = useCallback(
    (column: string, value: string | boolean | null) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Grid container size={12} spacing="24px">
      <Grid container size={12} spacing="16px">
        <Header
          initializing={initializing}
          header="Info Panel"
          subheader="If visible, shows as the first configuration panel."
        />
        <Grid key={BannersAndMessagesFormFields.ShowInformationalPanel} size={12}>
          <FormControl fullWidth>
            <Field
              name={BannersAndMessagesFormFields.ShowInformationalPanel}
              component={LabeledCheckboxField}
              label="Show Info Panel"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BannersAndMessagesFormFields.ShowInformationalPanel, !!event.target.checked)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Collapse in={showInformationalPanel && !initializing} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <Grid container size={12} spacing="24px" sx={{ pl: '36px' }}>
            <Grid key={BannersAndMessagesFormFields.InformationPanelTitle} size={{ xs: 12, lg: 6 }}>
              <FormControl fullWidth>
                <Field
                  name={BannersAndMessagesFormFields.InformationPanelTitle}
                  label="Title"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(BannersAndMessagesFormFields.InformationPanelTitle, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Grid>
            <Grid key={BannersAndMessagesFormFields.InformationPanelSubtitle} size={{ xs: 12, lg: 6 }}>
              <FormControl fullWidth>
                <Field
                  name={BannersAndMessagesFormFields.InformationPanelSubtitle}
                  label="Subtitle"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(BannersAndMessagesFormFields.InformationPanelSubtitle, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Grid>
            <Grid key={BannersAndMessagesFormFields.InformationPanelMessage} size={12}>
              <FormControl fullWidth>
                <Field
                  name={BannersAndMessagesFormFields.InformationPanelMessage}
                  label=""
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(BannersAndMessagesFormFields.InformationPanelMessage, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                  multiline
                  rows={2.3}
                  height="71px"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <SitesDivider initializing={initializing} />
    </Grid>
  );
};
