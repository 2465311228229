import React from 'react';
import { Paper as MuiPaper, styled, Typography, Box } from '@mui/material';

const Paper = styled(MuiPaper)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  height: 'fit-content',
  backgroundColor: '#F2F4F5',
  borderRadius: '0px',
}));

type Props = React.PropsWithChildren<{
  header?: string;
  subheader?: string;
}>;

export const Page: React.FC<Props> = ({ header, subheader, children }) => (
  <Paper elevation={0} sx={{ py: '20px', px: '24px' }}>
    <Typography fontSize="26px" fontWeight={800} color="#323B4B">
      {header}
    </Typography>
    {subheader && (
      <Typography fontSize="16px" color="#323B4B" sx={{ mt: '10px' }}>
        {subheader}
      </Typography>
    )}
    <Box sx={{ mt: '24px' }}>{children}</Box>
  </Paper>
);
