import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import { EmailFormat, SendEmailsTo } from '@idearoom/types';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { DocumentsFormFields } from '../../constants/FormFields';
import { DocumentsForm } from './DocumentsForm';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getLogosAndWatermarks } from '../../ducks/vendorDataSlice';

export const Documents: React.FC<{ clientId: string }> = () => {
  const dispatch = useAppDispatch();

  const clientId = useAppSelector((state) => state.clientData.clientId);
  const {
    selectedTableData: [
      {
        checkoutEmailFormat,
        quoteEmailFormat,
        saveEmailFormat,
        printEmailFormat,
        sendEmailsTo,
        emailLogoUrl,
        showLogoWatermarkUnderEstimate,
        emailFloorPlan,
        useFloorplanFullPage,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  useEffect(() => {
    if (clientId) {
      dispatch(getLogosAndWatermarks());
    }
  }, [dispatch, clientId]);

  return (
    <SitesPage header="Documents">
      <Card sx={{ p: '24px' }} elevation={0}>
        <DocumentsForm
          initialValues={{
            [DocumentsFormFields.CheckoutEmailFormat]: checkoutEmailFormat || EmailFormat.Summary,
            [DocumentsFormFields.QuoteEmailFormat]: quoteEmailFormat || EmailFormat.Summary,
            [DocumentsFormFields.SaveEmailFormat]: saveEmailFormat || EmailFormat.Summary,
            [DocumentsFormFields.PrintEmailFormat]: printEmailFormat || EmailFormat.Summary,
            [DocumentsFormFields.SendEmailsTo]: sendEmailsTo || SendEmailsTo.Default,
            [DocumentsFormFields.EmailLogoUrl]: emailLogoUrl || '',
            [DocumentsFormFields.ShowLogoWatermarkUnderEstimate]: !!showLogoWatermarkUnderEstimate,
            [DocumentsFormFields.EmailFloorPlan]: !!emailFloorPlan,
            [DocumentsFormFields.UseFloorplanFullPage]: !!useFloorplanFullPage,
          }}
        />
      </Card>
    </SitesPage>
  );
};
