import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reset, submit, getFormValues } from 'redux-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { WhatsNewDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { closeDialog as closeDialogFunc, openDialog } from '../../ducks/dialogSlice';
import { AppState } from '../../types/AppState';
import { Dialog } from './Dialog';
import { LoadingButton } from '../LoadingButton';
import { WhatsNew } from '../../types/WhatsNew';
import { WhatsNewForm, FormData } from './WhatsNewForm';
import { sanitizeInput } from '../../utils/inputUtils';
import { I18nKeys } from '../../constants/I18nKeys';
import { setWhatsNewPreview } from '../../ducks/settings';

interface StateProps {
  clientId: string;
  initialValues: FormData;
  submitting: boolean;
  values: {
    DATE: string;
    ID: number;
    MESSAGE: string;
    TITLE: string;
    READONLY: boolean;
  };
}

interface DispatchProps {
  saveWhatsNew(): void;
  closeDialog(): void;
  // maybe we want a pick here?
  previewWhatsNew(whatsNew: Partial<WhatsNew>): void;
}

type Props = StateProps & DispatchProps;

const Spacer = styled('div')({
  flexGrow: 1,
});

const Warning = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const WhatsNewDialogComponent: React.FC<Props> = ({
  clientId,
  previewWhatsNew,
  saveWhatsNew,
  closeDialog,
  initialValues,
  submitting,
  values,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog dialogKey={Dialogs.WhatsNew}>
      <DialogTitle>
        {`${initialValues.ID ? t(I18nKeys.DialogTypeEdit) : t(I18nKeys.DialogTypeAdd)} ${t(I18nKeys.WhatsNew)}`}
      </DialogTitle>
      <DialogContent>
        {initialValues.READONLY && <Warning>{t(I18nKeys.WhatsNewReadOnlyWarning)}</Warning>}
        <WhatsNewForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            previewWhatsNew({
              clientId,
              date: values.DATE,
              readonly: values.READONLY,
              id: values.ID,
              title: sanitizeInput(values.TITLE),
              message: sanitizeInput(values.MESSAGE),
              updating: false,
            });
          }}
          color="primary"
        >
          {t(I18nKeys.WhatsNewPreview)}
        </Button>
        <Spacer />
        {initialValues.READONLY ? (
          <LoadingButton loading={submitting} onClick={closeDialog} color="primary">
            {t(I18nKeys.DialogDoneButton)}
          </LoadingButton>
        ) : (
          <>
            <LoadingButton loading={submitting} onClick={closeDialog} color="primary">
              {t(I18nKeys.DialogCancelButton)}
            </LoadingButton>
            <LoadingButton loading={submitting} onClick={(): void => saveWhatsNew()} color="primary">
              {t(I18nKeys.DialogSaveButton)}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { whatsNewDialog } = state.settings;

  return {
    clientId: whatsNewDialog.clientId as string,
    initialValues: {
      [WhatsNewDialogFormFields.Id]: whatsNewDialog.id,
      [WhatsNewDialogFormFields.ClientId]: whatsNewDialog.clientId,
      [WhatsNewDialogFormFields.Date]: whatsNewDialog.date,
      [WhatsNewDialogFormFields.Title]: whatsNewDialog.title,
      [WhatsNewDialogFormFields.Message]: whatsNewDialog.message,
      [WhatsNewDialogFormFields.Readonly]: whatsNewDialog.readonly,
    } as FormData,
    submitting: whatsNewDialog.updating,
    values: getFormValues(Forms.WhatsNew)(state) as FormData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  previewWhatsNew: (whatsNew: WhatsNew): void => {
    dispatch(setWhatsNewPreview([whatsNew]));
    dispatch(openDialog({ dialog: Dialogs.WhatsNewPreview }));
  },
  saveWhatsNew: (): void => {
    dispatch(submit(Forms.WhatsNew));
  },
  closeDialog: (): void => {
    dispatch(reset(Forms.WhatsNew));
    dispatch(closeDialogFunc());
  },
});

export const WhatsNewDialog = connect(mapStateToProps, mapDispatchToProps)(WhatsNewDialogComponent);
