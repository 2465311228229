import { DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import React from 'react';
import { reset, submit } from 'redux-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { LanguageDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { LanguageForm, FormData } from './LanguageForm';
import { i18n } from '../../i18n';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch } from '../../hooks';

export const LanguageDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues = {
    [LanguageDialogFormFields.Language]: i18n.languages.includes(i18n.language) ? i18n.language : undefined,
  } as FormData;

  const submitLanguage = (): void => {
    dispatch(submit(Forms.Language));
  };

  const closeDialog = (): void => {
    dispatch(reset(Forms.Language));
    dispatch(closeDialogFunc());
  };

  return (
    <Dialog dialogKey={Dialogs.Language}>
      <DialogTitle>{t(I18nKeys.LanguageDialogTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: t(I18nKeys.LanguageDialogMessage),
          }}
        />
        <LanguageForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={(): void => submitLanguage()} color="primary">
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
