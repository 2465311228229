import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, Form, FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { FormControl, IconButton, InputAdornment, Grid2 as Grid, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { passwordValidation, required } from '../utils/reduxFormUtils';
import { InputField } from './redux-form/InputField';
import { Button } from './library/Button';
import { Header } from './library/Header';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginBottom: '1rem',
  },
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
}));

interface FormData {
  [PasswordFormFields.CurrentPassword]: string;
  [PasswordFormFields.NewPassword]: string;
  [PasswordFormFields.ConfirmNewPassword]: string;
}

interface DispatchProps {
  onSubmit(data: FormData): Promise<void>;
}

type Props = DispatchProps & InjectedFormProps<FormData>;

const PasswordFormComponent: React.FC<Props> = ({ error, handleSubmit, onSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="24px">
        <Header subheader="After a successful password update, you will be redirected to the sign in page where you can sign in with your new password." />
        <Grid size={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <Field
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                validate={passwordValidation}
                variant="filled"
                name={PasswordFormFields.CurrentPassword}
                component={InputField}
                label={`${t(I18nKeys.PasswordCurrentPassword)}*`}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              autoComplete="new-password"
              type={showNewPassword ? 'text' : 'password'}
              validate={passwordValidation}
              variant="filled"
              name={PasswordFormFields.NewPassword}
              component={InputField}
              label={`${t(I18nKeys.PasswordNewPassword)}*`}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl fullWidth>
            <Field
              autoComplete="new-password"
              type={showConfirmPassword ? 'text' : 'password'}
              validate={required}
              variant="filled"
              name={PasswordFormFields.ConfirmNewPassword}
              component={InputField}
              label={`${t(I18nKeys.FieldConfirmNewPassword)}*`}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>
        </Grid>

        {!!error && <Typography className={classes.error}>{error}</Typography>}

        <Button
          key="submit-password-form-btn"
          type="submit"
          variant="outlined"
          loading={submitting}
          disabled={submitting}
        >
          {t(I18nKeys.ProfileUpdatePassword)}
        </Button>
      </Grid>
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Password}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

const validate = ({
  [PasswordFormFields.NewPassword]: password,
  [PasswordFormFields.ConfirmNewPassword]: confirmPassword,
}: FormData): FormErrors<FormData, string> => {
  if (password && confirmPassword && password !== confirmPassword) {
    return { [PasswordFormFields.ConfirmNewPassword]: i18n.t(I18nKeys.PasswordPasswordsDontMatchError) };
  }

  return {};
};

export const PasswordForm = reduxForm<FormData>({ form: Forms.Password, validate })(
  connect(undefined, mapDispatchToProps)(PasswordFormComponent),
);
