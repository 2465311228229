import { IntegrationStatus } from '@idearoom/types';
import { MenuIconKey } from '../../components/MenuIcon';
import { ConfiguratorEnabledOnProps } from '../../types/Configurator';
import { User } from '../../types/User';
import { ViewerState } from '../../types/ViewerState';
import {
  isCurrentUserAdmin,
  isCurrentUserManager,
  isCurrentUserSuperUser,
  isIdeaRoomUser,
} from '../../utils/userUtils';
import { getEnabledOnProperty } from '../../utils/vendorDataUtils';
import { I18nKeys } from '../I18nKeys';
import { canUsePayments } from '../../utils/permissionUtils';
import { MainMenuItems } from '../MainMenuItems';

type MenuItem = {
  [item in MainMenuItems]?: {
    visible: (
      user: User,
      isIdearoomGroup: boolean,
      configurators: any[],
      selectedGroupId: string,
      viewerState: ViewerState,
    ) => boolean;
    key: string;
    showDivider?: boolean;
    i18nKey: () => string;
    ideaRoomOnly: (user: User, selectedGroupId: string, viewerState: ViewerState) => boolean;
    customRightIcon?: (viewerState: ViewerState) => MenuIconKey | undefined;
  };
};

export const menuItems: MenuItem = {
  [MainMenuItems.Home]: {
    visible: (_user: User, isIdearoomGroup: boolean, configurators: any[]) =>
      !isIdearoomGroup && configurators && configurators[0]?.vendorData?.vendor?.maxioSubscriptionId,
    key: 'Getting-Started',
    i18nKey: () => I18nKeys.MenuGettingStartedOption,
    ideaRoomOnly: () => false,
    showDivider: true,
  },
  [MainMenuItems.Groups]: {
    visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
    key: 'Groups',
    i18nKey: () => I18nKeys.MenuGroupsOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Leads]: {
    visible: (_user: User, isIdearoomGroup: boolean) => !isIdearoomGroup,
    key: 'Leads',
    i18nKey: () => I18nKeys.MenuLeadsOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Orders]: {
    visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
    key: 'Orders',
    i18nKey: () => I18nKeys.MenuOrdersOption,
    ideaRoomOnly: () => true,
  },
  [MainMenuItems.Payments]: {
    visible: (
      user: User,
      isIdearoomGroup: boolean,
      _configurators: any[],
      selectedGroupId: string,
      viewerState: ViewerState,
    ) => !isIdearoomGroup && canUsePayments(user, selectedGroupId, viewerState.paymentIntegrationStatus?.status),
    key: 'Payments',
    i18nKey: () => I18nKeys.MenuPaymentsOption,
    ideaRoomOnly: (user, selectedGroupId, viewerState) =>
      !canUsePayments(user, selectedGroupId, viewerState.paymentIntegrationStatus?.status),
    showDivider: true,
    customRightIcon: (viewerState: ViewerState) => {
      switch (viewerState.paymentIntegrationStatus?.status) {
        case IntegrationStatus.Disconnected:
          return MenuIconKey.NEW;
        case IntegrationStatus.Pending:
          return MenuIconKey.ATTENTION;
        case IntegrationStatus.Failed:
          return MenuIconKey.WARNING;
        default:
          return undefined;
      }
    },
  },
  [MainMenuItems.Analyze]: {
    visible: (user: User, isIdearoomGroup: boolean) =>
      !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Usage',
    i18nKey: () => I18nKeys.MenuUsageOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Dealers]: {
    visible: (user: User, _: boolean, configurators: any[]) =>
      configurators.some((c) =>
        getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      ) &&
      (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Dealers',
    i18nKey: () => I18nKeys.MenuDealersOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Team]: {
    visible: (user: User) => isIdeaRoomUser(user) || isCurrentUserAdmin(user) || isCurrentUserManager(user),
    key: 'Users',
    i18nKey: () => I18nKeys.MenuUsersOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Pricing]: {
    visible: (user: User, isIdearoomGroup: boolean, configurators: any[]) =>
      !isIdearoomGroup &&
      (isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
      configurators.some((c) => getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true)),
    key: 'Pricing',
    i18nKey: () => I18nKeys.MenuPricingOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Sites]: {
    visible: (user: User, isIdearoomGroup: boolean) =>
      !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
    key: 'Sites',
    i18nKey: () => I18nKeys.MenuSitesOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.ClientData]: {
    visible: (user: User) => isIdeaRoomUser(user) || isCurrentUserSuperUser(user),
    key: 'Client-Data',
    i18nKey: () => I18nKeys.MenuClientDataOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Integrations]: {
    visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
    key: 'Integrations',
    i18nKey: () => I18nKeys.MenuIntegrationsOption,
    ideaRoomOnly: () => true,
  },
  [MainMenuItems.Reports]: {
    visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
    key: 'Reports',
    i18nKey: () => I18nKeys.MenuReportsOption,
    ideaRoomOnly: () => false,
  },
  [MainMenuItems.Settings]: {
    visible: (user: User, _: boolean, _configurators: any[], selectedGroupId: string) =>
      /* TODO: Add back in commented line after soft release */
      /* {(isIdeaRoomUser(user) || isCurrentUserAdmin(user)) && ( */
      isIdeaRoomUser(user) ||
      (['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId) && isCurrentUserAdmin(user)),
    key: 'Settings',
    i18nKey: () => I18nKeys.MenuSettingsOption,
    ideaRoomOnly: (user: User, selectedGroupId: string) =>
      isIdeaRoomUser(user) && !['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId),
  },
};
