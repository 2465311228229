import React from 'react';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../../constants/Dialogs';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch } from '../../hooks';
import greenCheckConnected from '../../images/greenCheckConnected.svg';

const useStyles = makeStyles(() => ({
  dialogContent: {
    maxWidth: '354px',
    display: 'flex',
  },
  dialogContentImg: {
    width: '36px',
    height: '36px',
  },
  dialogContentText: {
    marginLeft: '16px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    flexGrow: '1',
  },
}));

export const AdyenPaymentsNotificationDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Dialog dialogKey={Dialogs.AdyenPaymentsNotification}>
      <DialogContent className={classes.dialogContent}>
        <img className={classes.dialogContentImg} alt="Connected" src={greenCheckConnected} />
        <Typography
          className={classes.dialogContentText}
          component="span"
          dangerouslySetInnerHTML={{
            __html: t(I18nKeys.AdyenNotificationDialogContent),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
