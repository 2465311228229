/* eslint-disable no-param-reassign */
import { navigate } from 'hookrouter';
import { createAppAsyncThunk } from './thunks';
import { MainMenuItems } from '../constants/MainMenuItems';
import { AppRoutes, MenuItemRouteMap } from '../constants/AppRoutes';
import { setMenuVariant, setPublishBar } from '../utils/menuUtils';
import { resetSearch } from '../ducks/search';
import { unknownGroup } from '../constants/Group';
import { setSelectedClientId } from '../ducks/viewerSlice';
import { setClientDataType, setClientId } from '../ducks/clientDataSlice';
import { getPathPart, setQueryParam } from '../utils/urlUtils';
import { VendorDataCategory } from '../constants/VendorData';
import { setSecondaryMenuItem as setSecondaryMenuItemFunc } from '../ducks/menuSlice';
import { Group } from '../types/Group';
import { isIdeaRoomGroup as isIdeaRoomGroupFunc } from '../utils/userUtils';
import { Configurator, ConfiguratorEnabledOnProps } from '../types/Configurator';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SalesViewUILayout } from '../constants/Viewer';
import { userApi } from '../services/userApi';
import { ClientDataType } from '../constants/ClientDataType';

export const setMainMenuItem = createAppAsyncThunk<MainMenuItems | undefined, MainMenuItems | undefined>(
  'menu/setMainMenuItem',
  async (mainMenuItem, { dispatch, getState }) => {
    if (!mainMenuItem) return mainMenuItem;

    const originalState = getState();
    const {
      viewer: { defaultClientId },
      clientData: { clientId },
      // UI Layout Remove Me
      currentUser: {
        group: { groupId } = unknownGroup,
        preferences: {
          [UserPreference.ProfilePreferences]: {
            [PreferencesFormFields.Layout]: layout = SalesViewUILayout.MapNeeded,
          } = {},
        } = {},
      },
    } = originalState;
    const state = {
      ...originalState,
      menu: {
        ...originalState.menu,
        mainMenuItem,
      },
      clientData: {
        ...originalState.clientData,
        clientId: '',
      },
    };

    setPublishBar(state, dispatch);
    setMenuVariant(state, dispatch);

    dispatch(resetSearch());

    // UI Layout Remove Me
    if ((mainMenuItem !== MainMenuItems.Sites || layout !== SalesViewUILayout.Horizon) && !clientId)
      dispatch(setSelectedClientId(defaultClientId));

    let route = MenuItemRouteMap[mainMenuItem];
    if (mainMenuItem === MainMenuItems.ClientData) route = `${route}/${groupId}`;
    navigate(route);
    return mainMenuItem;
  },
);

export const setSubMenuItem = createAppAsyncThunk<string | undefined, string | undefined>(
  'menu/setSubMenuItem',
  async (subMenuItem, { dispatch, getState }) => {
    const originalState = getState();
    const {
      menu: { mainMenuItem },
    } = originalState;
    let {
      menu: { secondaryMenuItem },
    } = originalState;
    const state = {
      ...originalState,
      menu: {
        ...originalState.menu,
        subMenuItem,
      },
      clientData: {
        ...originalState.clientData,
        ...(mainMenuItem === MainMenuItems.Sites ? { clientId: subMenuItem } : {}),
      },
    };

    if (!subMenuItem || !mainMenuItem) return subMenuItem;
    setPublishBar(state, dispatch);
    setMenuVariant(state, dispatch);

    dispatch(resetSearch());

    if (mainMenuItem === MainMenuItems.Sites) {
      dispatch(setClientId(subMenuItem));
      dispatch(setSelectedClientId(subMenuItem));
      dispatch(setClientDataType(ClientDataType.Vendor));
      secondaryMenuItem = secondaryMenuItem || VendorDataCategory.BusinessInfo;
      dispatch(setSecondaryMenuItemFunc(secondaryMenuItem));
    }

    let route = `${MenuItemRouteMap[mainMenuItem]}/${subMenuItem}`;
    if (secondaryMenuItem) route = `${route}/${secondaryMenuItem}`;
    if ((Object.values(MainMenuItems) as string[]).includes(subMenuItem)) {
      route = MenuItemRouteMap[subMenuItem as MainMenuItems];
    }

    navigate(route);
    return subMenuItem;
  },
);

export const setSecondaryMenuItem = createAppAsyncThunk<string | undefined, string | undefined>(
  'menu/setSecondaryMenuItem',
  async (secondaryMenuItem, { dispatch, getState }) => {
    const originalState = getState();
    const {
      menu: { mainMenuItem, subMenuItem },
    } = originalState;

    if (!subMenuItem || !mainMenuItem || !secondaryMenuItem) return secondaryMenuItem;

    dispatch(resetSearch());

    const route = `${MenuItemRouteMap[mainMenuItem]}/${subMenuItem}/${secondaryMenuItem}`;
    navigate(route);

    return secondaryMenuItem;
  },
);

export const setInitialMenuState = createAppAsyncThunk<
  {
    mainMenuItem: MainMenuItems | undefined;
    subMenuItem: string | undefined;
    secondaryMenuItem: string | undefined;
  },
  {
    newClient?: boolean;
    group: Group;
  }
>(
  'menu/setInitialMenuState',
  async ({ newClient = false, group: { groupId, configurators = [] } }, { dispatch, getState }) => {
    const originalState = getState();

    const {
      preferences: {
        [UserPreference.ProfilePreferences]: {
          [PreferencesFormFields.Layout]: layout = SalesViewUILayout.MapNeeded,
        } = {},
      } = {},
    } =
      (await dispatch(
        userApi.endpoints.getCurrentUserPreferences.initiate(undefined, {
          subscriptionOptions: { refetchOnFocus: false },
        }),
      ).unwrap()) || {};

    const isIdeaRoomGroup = isIdeaRoomGroupFunc(groupId);
    let mainMenuItem: MainMenuItems | undefined;
    let subMenuItem: string | undefined;
    let secondaryMenuItem: string | undefined;
    let clientId = '';

    if (isIdeaRoomGroup) {
      navigate(AppRoutes.Groups);
      return { mainMenuItem: MainMenuItems.Groups, subMenuItem: undefined, secondaryMenuItem: undefined };
    }

    const currentPath = window.location.pathname;
    if (!currentPath || !currentPath.startsWith(AppRoutes.Portal)) {
      mainMenuItem = newClient ? MainMenuItems.Home : MainMenuItems.Leads;
      navigate(newClient ? AppRoutes.Home : AppRoutes.Leads);

      // Set a uuid query param if it exists in session storage
      setQueryParam('uuid');
    }

    // Selects the menu item based on the current URL path
    if (
      currentPath.startsWith(AppRoutes.Leads) ||
      currentPath.startsWith(AppRoutes.Groups) ||
      (currentPath.startsWith(AppRoutes.Pricing) &&
        !configurators?.some((config: Configurator) =>
          getEnabledOnProperty(config.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true),
        )) ||
      currentPath.replace(/\/$/, '') === AppRoutes.Portal
    ) {
      if (layout !== SalesViewUILayout.Horizon) {
        mainMenuItem = MainMenuItems.Leads;
      } else {
        mainMenuItem = MainMenuItems.Sales;
        subMenuItem = MainMenuItems.Leads;
      }
      navigate(AppRoutes.Leads);
    }
    if (currentPath.startsWith(AppRoutes.Pricing)) {
      mainMenuItem = MainMenuItems.Pricing;
    }
    if (currentPath.startsWith(AppRoutes.Team)) {
      mainMenuItem = MainMenuItems.Team;
    }
    if (currentPath.startsWith(AppRoutes.Dealers)) {
      mainMenuItem = MainMenuItems.Dealers;
    }
    if (currentPath.startsWith(AppRoutes.Sites)) {
      mainMenuItem = MainMenuItems.Sites;
      clientId = configurators?.find(({ clientId: id }) => id === getPathPart(3))?.clientId || '';
      dispatch(setClientId(clientId));
      dispatch(setClientDataType(ClientDataType.Vendor));
      // UI Layout Remove Me
      if (clientId && layout === SalesViewUILayout.Horizon) {
        subMenuItem = clientId;
        secondaryMenuItem = getPathPart(4) || VendorDataCategory.BusinessInfo;
        navigate(`${AppRoutes.Sites}/${clientId}/${secondaryMenuItem}`);
      } else {
        navigate(AppRoutes.Sites);
      }
    }

    const state = {
      ...originalState,
      clientData: {
        ...originalState.clientData,
        clientId,
      },
      menu: {
        ...originalState.menu,
        mainMenuItem,
        subMenuItem,
        secondaryMenuItem,
      },
      currentUser: {
        ...originalState.currentUser,
        preferences: {
          ...(originalState.currentUser.preferences || {}),
          [UserPreference.ProfilePreferences]: {
            ...(originalState.currentUser.preferences?.[UserPreference.ProfilePreferences] || {}),
            [PreferencesFormFields.Layout]: layout,
          },
        },
      },
    };
    setPublishBar(state, dispatch);
    setMenuVariant(state, dispatch);

    return { mainMenuItem, subMenuItem, secondaryMenuItem };
  },
);
