import React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from './library/Button';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IntegrationDeactivateOptions } from '../types/DialogState';
import { putIntegration } from '../ducks/integrationSlice';

export const IntegrationsDeactivateDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { options } = useAppSelector((state) => state.dialog);
  const { integration, formData } = (options as IntegrationDeactivateOptions) || {};

  const openPreviousDialog = () => {
    dispatch(openDialog({ dialog: Dialogs.Integrations, options: { integration, formData } }));
  };

  return (
    <Dialog dialogKey={Dialogs.IntegrationsDeactivate} onClosed={openPreviousDialog}>
      <DialogContent>
        <Typography
          component="span"
          dangerouslySetInnerHTML={{
            __html: t(I18nKeys.IntegrationsDeactivateMessage, { context: integration ? integration.type : undefined }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
            openPreviousDialog();
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(putIntegration({ integration, enabled: false }));
            dispatch(closeDialogFunc());
          }}
          sx={{
            backgroundColor: (theme) => `${theme.palette.error.dark} !important`,
          }}
          variant="contained"
        >
          {t(I18nKeys.DialogDeactivateButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
