/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { Form as ReduxForm, FormProps } from 'redux-form';

type Props = React.PropsWithChildren<FormProps<FormData, Record<string, unknown>>>;

export const Form: React.FC<Props> = ({ children, ...props }): JSX.Element => (
  <ReduxForm {...props}>
    <Stack direction="column" spacing="16px">
      {children}
    </Stack>
  </ReduxForm>
);
