import React from 'react';
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  DialogActions,
  Card,
  CardHeader,
  CardContent,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dialog } from './library/Dialog';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { WhatsNew } from '../types/WhatsNew';
import { setWhatsNewDialog } from '../ducks/settings';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getDateAsText } from '../utils/dateUtils';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';

export const WhatsNewPreviewDialog: React.FC = () => {
  const { t } = useTranslation();

  const { whatsNewPreviewDialog: whatsNewRecords = [], loading } = useAppSelector((state: AppState) => state?.settings);

  const dispatch = useAppDispatch();
  const closeDialog = (whatsNew: WhatsNew): void => {
    dispatch(closeDialogFunc());
    dispatch(setWhatsNewDialog(whatsNew.clientId, whatsNew));
    dispatch(openDialog({ dialog: Dialogs.WhatsNew }));
  };

  return (
    <Dialog dialogKey={Dialogs.WhatsNewPreview}>
      <DialogTitle>What&apos;s New</DialogTitle>
      {loading && (
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
          </div>
        </DialogContent>
      )}
      {!loading && (
        <DialogContent sx={{ p: '2px !important' }}>
          {whatsNewRecords.map((record) => (
            <Card key={record.id}>
              <CardHeader
                sx={{ pb: '0px' }}
                slotProps={{ title: { variant: 'h6' } }}
                title={`${record.title}: ${getDateAsText(record.date)}`}
              />
              <CardContent sx={{ py: '0px' }}>
                <Box
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  dangerouslySetInnerHTML={{
                    __html: record.message,
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={(): void => closeDialog(whatsNewRecords[0])} variant="contained">
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
