import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  AssessmentOutlined,
  GroupOutlined,
  HelpOutlineOutlined,
  SettingsOutlined,
  SignalCellularAlt,
  StoreOutlined,
  WebOutlined,
  ArrowDropDown,
} from '@mui/icons-material';
import { Theme } from '@mui/material';
import newIcon from '../images/new.svg';
import waitingIcon from '../images/waiting.svg';
import warningIcon from '../images/warning.svg';
import { useAppSelector } from '../hooks';

const useStyles = makeStyles<Theme, { highlighted: boolean }>((theme: Theme) => ({
  primary: {
    color: ({ highlighted }) => (highlighted ? theme.palette.primary.main : '#323B4B'),
    opacity: 0.8,
  },
  icon: {
    width: '24px',
    height: '24px',
  },
}));

export enum MenuIconKey {
  ANALYZE = 'analyze',
  ARROW_DOWN = 'arrow-down',
  ATTENTION = 'attention',
  DEALERS = 'dealers',
  HELP = 'help',
  HOME = 'home',
  INTEGRATIONS = 'integrations',
  NEW = 'new',
  REPORTS = 'reports',
  SALES = 'sales',
  SETTINGS = 'settings',
  SITES = 'sites',
  TEAM = 'team',
  WARNING = 'warning',
}

export const MenuIcon: React.FC<{
  iconKey?: MenuIconKey;
  highlighted?: boolean;
}> = ({ iconKey, highlighted = false }) => {
  const { menuIconSvgs } = useAppSelector((state) => state.menu);
  const classes = useStyles({ highlighted });

  switch (iconKey) {
    case MenuIconKey.ANALYZE:
      return <SignalCellularAlt className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.ARROW_DOWN:
      return <ArrowDropDown className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.ATTENTION:
      return <img alt="Attention" className={classes.icon} src={waitingIcon} />;
    case MenuIconKey.DEALERS:
      return <StoreOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.HELP:
      return <HelpOutlineOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.HOME:
      return (
        <img
          alt="Home"
          className={`${classes.primary} ${classes.icon}`}
          src={highlighted ? menuIconSvgs?.[iconKey]?.[1] : menuIconSvgs?.[iconKey]?.[0]}
        />
      );
    case MenuIconKey.INTEGRATIONS:
      return (
        <img
          alt="Integrations"
          className={`${classes.primary} ${classes.icon}`}
          src={highlighted ? menuIconSvgs?.[iconKey]?.[1] : menuIconSvgs?.[iconKey]?.[0]}
        />
      );
    case MenuIconKey.NEW:
      return <img alt="New" className={classes.icon} src={newIcon} />;
    case MenuIconKey.REPORTS:
      return <AssessmentOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.SALES:
      return (
        <img
          alt="Sales"
          className={`${classes.primary} ${classes.icon}`}
          src={highlighted ? menuIconSvgs?.[iconKey]?.[1] : menuIconSvgs?.[iconKey]?.[0]}
        />
      );
    case MenuIconKey.SETTINGS:
      return <SettingsOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.SITES:
      return <WebOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.TEAM:
      return <GroupOutlined className={`${classes.primary} ${classes.icon}`} />;
    case MenuIconKey.WARNING:
      return <img alt="Warning" className={classes.icon} src={warningIcon} />;
    default:
      return null;
  }
};
