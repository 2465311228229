import {
  Box,
  Card,
  CardMedia,
  DialogActions,
  DialogContent,
  Divider,
  Grid2 as Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, styled } from '@mui/styles';
import { CloudDownload } from '@mui/icons-material';
import { getSiteFromClientId } from '../utils/clientIdUtils';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { hyphenateString } from '../utils/stringUtils';
import { formatPrice } from '../utils/pricingUtils';
import { NewWindowLink } from './NewWindowLink';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getOrderOwnerFromOrder } from '../utils/orderUtils';
import { unknownGroup } from '../constants/Group';
import { anyHasSmartBuildIntegrationEnabled } from '../utils/vendorDataUtils';
import { Button } from './library/Button';
import { Dialog } from './library/Dialog';
import { Dialogs } from '../constants/Dialogs';
import { removeQueryParam } from '../utils/urlUtils';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { deleteLead, setDetailsState } from '../ducks/orders';
import { isCurrentUserGroupAdmin } from '../utils/userUtils';
import { unknownUser } from '../types/User';

const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    position: 'relative',
    background: theme.palette.grey[200],
    padding: '8px',
  },
  image: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '370px',
  },
  link: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  wrapItem: {
    overflowWrap: 'break-word',
  },
}));

const LeadDetailSection = styled(DialogContent)({
  padding: '24px',
});

const LeadDetailRow = styled(Grid)({
  justifyContent: 'flex-start',
  alignItems: 'baseline',
});

export const OrderDetailsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { currency } = useAppSelector((state) => state?.viewer);
  const { members } = useAppSelector((state) => state?.group.group || unknownGroup);
  const { group: { configurators = [] } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const { detailsState: order } = useAppSelector((state) => state?.orders);
  const admin = useAppSelector(({ currentUser: { user = unknownUser, group = unknownGroup } }) =>
    isCurrentUserGroupAdmin(user, group, group),
  );

  const onClose = useCallback(() => {
    dispatch(closeDialog());
    dispatch(setDetailsState(undefined));
    removeQueryParam('uuid');
  }, [dispatch]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!order) return <></>;

  const {
    hash,
    customerName,
    customerEmail,
    customerPhone,
    date,
    clientId,
    dealerEmail: initialDealerEmail,
    dealerName: initialDealerName,
    orderDealerKey,
    orderDealerEmail,
    orderDealerName,
    orderDealerLink,
    orderStatusName,
    orderStatusI18nKey,
    ipAddress,
    link: initialLink,
    buildingImage,
    floorplanImage,
    totalPrice,
    buildingStyle,
    versionedEmailId,
    smartbuildJobId,
  } = order;

  const { name: ownerName, email: ownerEmail } = getOrderOwnerFromOrder(order, members);

  let link = initialLink;
  let dealerEmail = initialDealerEmail;
  let dealerName = initialDealerName;
  if (orderDealerKey) {
    link = orderDealerLink;
    dealerEmail = orderDealerEmail;
    dealerName = orderDealerName;
  }

  return (
    <Dialog dialogKey={Dialogs.OrderDetails} onClosed={onClose} maxWidth="xs" scroll="body" fullWidth>
      <Stack spacing="24px">
        <LeadDetailSection>
          <Stack spacing="16px">
            {customerName && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogName)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography className={classes.wrapItem} variant="body2">
                    {customerName}
                  </Typography>
                </Grid>
              </LeadDetailRow>
            )}
            <LeadDetailRow container>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.FieldEmail)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Link className={classes.wrapItem} href={`mailto:${customerEmail}`} variant="body2">
                  {customerEmail}
                </Link>
              </Grid>
            </LeadDetailRow>
            {customerPhone && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.FieldPhone)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Link className={classes.wrapItem} href={`tel:${formatPhoneNumber(customerPhone)}`} variant="body2">
                    {formatPhoneNumber(customerPhone)}
                  </Link>
                </Grid>
              </LeadDetailRow>
            )}
            <LeadDetailRow container>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogDate)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {`${moment(date).format('MMM DD, h:mm A')} from ${ipAddress}`}
                </Typography>
              </Grid>
            </LeadDetailRow>
            {clientId && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogSite)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography className={classes.wrapItem} variant="body2">
                    {getSiteFromClientId(clientId, configurators)}
                  </Typography>
                </Grid>
              </LeadDetailRow>
            )}
          </Stack>
        </LeadDetailSection>
        <Divider />
        {(dealerName || dealerEmail) && (
          <>
            <LeadDetailSection>
              <Stack spacing="16px">
                <LeadDetailRow container>
                  <Grid size={{ xs: 4 }}>
                    <Typography color="textSecondary" variant="body2">
                      {t(I18nKeys.OrderDetailsDialogDealer)}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 8 }}>
                    {dealerName && (
                      <Typography className={classes.wrapItem} variant="body2">
                        {dealerName}
                      </Typography>
                    )}
                    {dealerEmail && (
                      <Link className={classes.wrapItem} href={`mailto:${dealerEmail}`} variant="body2">
                        {dealerEmail}
                      </Link>
                    )}
                  </Grid>
                </LeadDetailRow>
              </Stack>
            </LeadDetailSection>
            <Divider />
          </>
        )}
        <LeadDetailSection>
          <Stack spacing="16px">
            <LeadDetailRow container>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogOwner)}
                </Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography className={classes.wrapItem} variant="body2">
                  {ownerName || 'Not Assigned'}
                </Typography>
                {ownerEmail && (
                  <Grid size="grow">
                    <Link className={classes.wrapItem} href={`mailto:${ownerEmail}`} variant="body2">
                      {ownerEmail}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </LeadDetailRow>
            <LeadDetailRow container>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogStatus)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {(orderStatusI18nKey && t(orderStatusI18nKey)) || orderStatusName || 'New Lead'}
                </Typography>
              </Grid>
            </LeadDetailRow>
          </Stack>
        </LeadDetailSection>
        <Divider />
        <LeadDetailSection>
          <Stack spacing="16px">
            {hash && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogHash)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography className={classes.wrapItem} variant="body2">
                    {hash}
                  </Typography>
                </Grid>
              </LeadDetailRow>
            )}
            <LeadDetailRow container>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogReference)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {hyphenateString(versionedEmailId)}
                </Typography>
              </Grid>
            </LeadDetailRow>
            {smartbuildJobId && anyHasSmartBuildIntegrationEnabled(configurators) && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogSmartBuild)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Link
                    href={`https://postframesolver.azurewebsites.net/Framer/Edit/${smartbuildJobId}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Typography className={classes.wrapItem} variant="body2">
                      {smartbuildJobId}
                    </Typography>
                  </Link>
                </Grid>
              </LeadDetailRow>
            )}
            {totalPrice && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogAmount)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography className={classes.wrapItem} variant="body2">
                    {formatPrice(totalPrice, currency)}
                  </Typography>
                </Grid>
              </LeadDetailRow>
            )}
            {buildingStyle && (
              <LeadDetailRow container>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogStyle)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography className={classes.wrapItem} variant="body2">
                    {buildingStyle}
                  </Typography>
                </Grid>
              </LeadDetailRow>
            )}
          </Stack>
        </LeadDetailSection>
        <DialogContent>
          <Card className={classes.imageContainer} elevation={0}>
            <CardMedia component="img" image={buildingImage} alt="building" className={classes.image} />
            <NewWindowLink link={link} className={classes.link} />
          </Card>
          <Card className={classes.imageContainer} elevation={0}>
            <CardMedia component="img" image={floorplanImage} alt="floorplan" className={classes.image} />
            <NewWindowLink link={floorplanImage} className={classes.link} icon={CloudDownload} />
          </Card>
        </DialogContent>
      </Stack>
      <DialogActions>
        {admin && (
          <Button
            onClick={() => {
              dispatch(
                openConfirmationDialog(
                  t(I18nKeys.LeadConfirmDeleteTitle),
                  t(I18nKeys.LeadConfirmDeleteMessage),
                  undefined,
                  [{ label: t(I18nKeys.DialogDeleteButton), actions: [deleteLead()] }],
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
            }}
            variant="outlined"
          >
            {t(I18nKeys.DialogDeleteButton)}
          </Button>
        )}
        <Box flexGrow={1} />
        <Button onClick={onClose} variant="contained">
          {t(I18nKeys.DialogCloseButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
