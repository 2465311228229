/* eslint-disable react/jsx-props-no-spreading */
import { Typography, TypographyProps, List, ListItem, Stack } from '@mui/material';
import React, { forwardRef } from 'react';

type Props = TypographyProps & {
  listStyleType?: string; // Ex: disc, circle, auto
  spacing?: string;
  columns?: number;
  items: {
    label: React.ReactNode;
    content?: React.ReactNode;
  }[];
};

export const ContentList = forwardRef<HTMLDivElement, Props>(
  ({ listStyleType = 'auto', spacing = '16px', columns = 1, items, ...props }, ref) => (
    <Typography {...props} sx={{ p: '1px', pl: '20px', ...props.sx }} ref={ref}>
      <List
        sx={{
          listStyleType,
          display: 'grid',
          rowGap: spacing,
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
        }}
        disablePadding
      >
        {items.map(({ label, content }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={i} spacing={spacing}>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              {label}
            </ListItem>
            {content}
          </Stack>
        ))}
      </List>
    </Typography>
  ),
);
