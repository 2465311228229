import React, { useCallback } from 'react';
import { Grid2 as Grid, Stack, FormControl, Collapse } from '@mui/material';
import { Field, formValueSelector } from 'redux-form';
import { PromotionalMessageType } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { BannersAndMessagesFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { Header } from '../library/Header';
import { RadioField } from '../redux-form/RadioField';
import { RadioGroupField } from '../redux-form/RadioGroupField';
import { I18nKeys } from '../../constants/I18nKeys';
import { InputField } from '../redux-form/InputField';
import { sanitizeInput } from '../../utils/inputUtils';

type Props = {
  initializing: boolean;
};

export const InstallationMessagingSection: React.FC<Props> = ({ initializing }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const installationMessageType = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, BannersAndMessagesFormFields.InstallationMessageType),
  );

  const onChange = useCallback(
    (column: string, value: string | boolean | null) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Grid container size={12} spacing="24px">
      <Grid container size={12} spacing="16px">
        <Header
          initializing={initializing}
          header="Installation Messaging"
          subheader="Tell the buyer what's required for site preparation and delivery readiness."
        />
        <Grid container size={12} spacing="8px">
          <FormControl fullWidth>
            <Field
              name={BannersAndMessagesFormFields.InstallationMessageType}
              component={RadioGroupField}
              onChange={(value: any) => {
                onChange(BannersAndMessagesFormFields.InstallationMessageType, value);
              }}
            >
              <RadioField
                initializing={initializing}
                label={t(I18nKeys.SitesInstallationMessageStandard)}
                value={PromotionalMessageType.Standard}
                color="secondary"
                sx={{ py: '4px' }}
              />
            </Field>
          </FormControl>
          <FormControl fullWidth>
            <Stack rowGap="16px">
              <Collapse
                in={installationMessageType === PromotionalMessageType.Standard || initializing}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%', pl: '36px' }}
              >
                <FormControl fullWidth>
                  <Field
                    name={BannersAndMessagesFormFields.StandardInstallationMessage}
                    label="Message"
                    variant="filled"
                    component={InputField}
                    initializing={initializing}
                    disabled
                    multiline
                    rows={4}
                    height="125px"
                  />
                </FormControl>
              </Collapse>
              <FormControl fullWidth>
                <Field
                  name={BannersAndMessagesFormFields.InstallationMessageType}
                  component={RadioGroupField}
                  onChange={(value: any) => {
                    onChange(BannersAndMessagesFormFields.InstallationMessageType, value);
                  }}
                >
                  <RadioField
                    initializing={initializing}
                    label={t(I18nKeys.SitesInstallationMessageCustom)}
                    value={PromotionalMessageType.Custom}
                    color="secondary"
                    sx={{ py: '4px' }}
                  />
                </Field>
              </FormControl>
              <Collapse
                in={installationMessageType === PromotionalMessageType.Custom && !initializing}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%', pl: '36px' }}
              >
                <FormControl fullWidth>
                  <Field
                    name={BannersAndMessagesFormFields.InstallationMessage}
                    label="Message"
                    variant="filled"
                    component={InputField}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(BannersAndMessagesFormFields.InstallationMessage, sanitizeInput(event.target.value));
                    }}
                    initializing={initializing}
                    multiline
                    rows={4}
                    height="125px"
                  />
                </FormControl>
              </Collapse>
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
