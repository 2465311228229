import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, FormControl, Grid2 as Grid, MenuItem } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, Field, formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { UILayoutVersion, WatermarkPosition } from '@idearoom/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { ColorsAndLogosFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { SelectField } from '../redux-form/SelectField';
import { ColorField } from '../redux-form/ColorField';
import { colorFormatter, colorValidation } from '../../utils/reduxFormUtils';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { ImageSelectField } from '../redux-form/ImageSelectField';
import { deleteFileFromS3, uploadLogoImage, uploadWatermarkImage } from '../../ducks/vendorDataSlice';
import { useGetClientDataTableDataQuery, useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { unknownGroup } from '../../constants/Group';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { I18nKeys } from '../../constants/I18nKeys';
import { openConfirmationDialog } from '../../ducks/confirmation';
import { openDialog } from '../../ducks/dialogSlice';
import { openNotificationDialog } from '../../ducks/notificationDialog';
import { Dialogs } from '../../constants/Dialogs';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { sanitizeColor } from '../../utils/sitesUtils';
import { InputField } from '../redux-form/InputField';
import { SitesPreview } from './SitesPreview';

export interface FormData {
  [ColorsAndLogosFormFields.Layout]: string | number | boolean;
  [ColorsAndLogosFormFields.LogoBarColor]: string | number | boolean;
  [ColorsAndLogosFormFields.LogoBarTextColor]: string | number | boolean;
  [ColorsAndLogosFormFields.ContactBarColor]: string | number | boolean;
  [ColorsAndLogosFormFields.ContactBarTextColor]: string | number | boolean;
  [ColorsAndLogosFormFields.SelectedTextColor]: string | number | boolean;
  [ColorsAndLogosFormFields.DefaultSceneEnvironment]: string;
  [ColorsAndLogosFormFields.LogoUrl]: string | number | boolean;
  [ColorsAndLogosFormFields.Watermark]: string | number | boolean;
  [ColorsAndLogosFormFields.ShowLogoOnMobile]: boolean;
  [ColorsAndLogosFormFields.WatermarkPosition]: string | number | boolean;
  [ColorsAndLogosFormFields.WatermarkText]: string | number | boolean;
}

const ColorsAndLogosFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { clientId, clientDataType, selectedTable, isCreatingBranch } = useAppSelector((state) => state.clientData);
  const groupId = useAppSelector((state) => (state.currentUser.group || unknownGroup).groupId);
  const sceneEnvironments = useAppSelector((state) => state.settings.sceneEnvironment);
  const loadingSceneEnvironment = useAppSelector((state) => state.settings.loadingSceneEnvironment);
  const { imageProcessing, vendorLogos, vendorWatermarks } = useAppSelector((state) => state.vendorData);
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [initializing, setInitializing] = useState(true);

  const {
    currentData: [{ logoUrl: publishedLogoUrl, watermark: publishedWatermark } = {} as Record<string, string>] = [],
  } = useGetClientDataTableDataQuery(
    {
      dataType: clientDataType,
      clientId,
      groupId,
      branch: ClientDataBranch.Main,
      table: selectedTable,
    },
    {
      skip: !clientId,
      refetchOnMountOrArgChange: true,
    },
  );

  const watermarkPosition = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.WatermarkPosition),
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData ||
        isUninitializedSelectedTableData ||
        isCreatingBranch ||
        isDeletingBranch ||
        loadingSceneEnvironment,
    );
  }, [
    isInitializingSelectedTableData,
    isUninitializedSelectedTableData,
    isCreatingBranch,
    isDeletingBranch,
    loadingSceneEnvironment,
  ]);

  const onChange = useCallback(
    (column: string, event: React.ChangeEvent<HTMLInputElement> | string | boolean) => {
      const value = typeof event !== 'object' ? event : event?.target?.value || '';
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  const onColorChange = useCallback(
    (column: string, value: string) => {
      const color = sanitizeColor(value);
      if (color) onChange(column, color);
    },
    [onChange],
  );

  const onImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files, name } = e.target;
      const file = files?.[0];
      if (!file) return;
      if (name === ColorsAndLogosFormFields.LogoUrl) dispatch(uploadLogoImage({ image: file }));
      if (name === ColorsAndLogosFormFields.Watermark) dispatch(uploadWatermarkImage({ image: file }));
    },
    [dispatch],
  );

  const onImageDelete = useCallback(
    (field: string, image: string) => {
      const publishedImage = field === ColorsAndLogosFormFields.LogoUrl ? publishedLogoUrl : publishedWatermark;
      if (image !== publishedImage) {
        dispatch(
          openConfirmationDialog(
            t(I18nKeys.SitesLogosDeleteLogoConfirmTitle),
            t(I18nKeys.SitesLogosDeleteLogoConfirmMessage),
            undefined,
            [
              {
                actions: [deleteFileFromS3({ path: image })],
              },
            ],
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      } else {
        dispatch(openNotificationDialog('', t(I18nKeys.SitesLogosDeletePublishImageWarning)));
        dispatch(openDialog({ dialog: Dialogs.Notification }));
      }
    },
    [dispatch, t, publishedLogoUrl, publishedWatermark],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid id="sites-colors-and-scene-container" container spacing="24px">
        <Grid container size={{ xs: 12, lg: 6 }} spacing="24px">
          <Grid key={ColorsAndLogosFormFields.Layout} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.Layout}
                label="Control layout"
                variant="filled"
                component={SelectField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ColorsAndLogosFormFields.Layout, event)
                }
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(UILayoutVersion).map((layout) => (
                  <MenuItem key={layout} value={layout}>
                    {t(`sites-layout-${layout}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.LogoBarColor} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.LogoBarColor}
                label="Logo bar"
                variant="filled"
                component={ColorField}
                onChangeComplete={(value: string) => onColorChange(ColorsAndLogosFormFields.LogoBarColor, value)}
                validate={colorValidation}
                format={colorFormatter}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.LogoBarTextColor} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.LogoBarTextColor}
                label="Logo bar text"
                variant="filled"
                component={ColorField}
                onChangeComplete={(value: string) => onColorChange(ColorsAndLogosFormFields.LogoBarTextColor, value)}
                validate={colorValidation}
                format={colorFormatter}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.ContactBarColor} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.ContactBarColor}
                label="Contact bar"
                variant="filled"
                component={ColorField}
                onChangeComplete={(value: string) => onColorChange(ColorsAndLogosFormFields.ContactBarColor, value)}
                validate={colorValidation}
                format={colorFormatter}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.ContactBarTextColor} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.ContactBarTextColor}
                label="Contact bar text"
                variant="filled"
                component={ColorField}
                onChangeComplete={(value: string) => onColorChange(ColorsAndLogosFormFields.ContactBarTextColor, value)}
                validate={colorValidation}
                format={colorFormatter}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.SelectedTextColor} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.SelectedTextColor}
                label="Buttons"
                variant="filled"
                component={ColorField}
                onChangeComplete={(value: string) => onColorChange(ColorsAndLogosFormFields.SelectedTextColor, value)}
                validate={colorValidation}
                format={colorFormatter}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }} spacing="24px">
          <Grid size={12}>
            <SitesPreview initializing={initializing} />
          </Grid>
          <Grid key={ColorsAndLogosFormFields.DefaultSceneEnvironment} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.DefaultSceneEnvironment}
                label="Background"
                variant="filled"
                component={SelectField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ColorsAndLogosFormFields.DefaultSceneEnvironment, event)
                }
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                disabled={sceneEnvironments.length === 0}
                initializing={initializing}
              >
                {sceneEnvironments.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }} spacing="24px">
          <Grid key={ColorsAndLogosFormFields.LogoUrl} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.LogoUrl}
                label="Logo"
                variant="filled"
                component={ImageSelectField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ColorsAndLogosFormFields.LogoUrl, event)
                }
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                images={vendorLogos}
                onDelete={onImageDelete}
                onUpload={onImageUpload}
                loading={imageProcessing}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid
            key={ColorsAndLogosFormFields.ShowLogoOnMobile}
            size={12}
            sx={{ height: '56px', alignContent: 'center' }}
          >
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.ShowLogoOnMobile}
                component={LabeledCheckboxField}
                label="Show logo on mobile"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ColorsAndLogosFormFields.ShowLogoOnMobile, !!event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }} spacing="24px">
          <Grid key={ColorsAndLogosFormFields.Watermark} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.Watermark}
                label="Watermark image"
                variant="filled"
                component={ImageSelectField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ColorsAndLogosFormFields.Watermark, event)
                }
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                disabled={[WatermarkPosition.CenterText, WatermarkPosition.CenterTextNotRotated].includes(
                  watermarkPosition,
                )}
                images={vendorWatermarks}
                onDelete={onImageDelete}
                onUpload={onImageUpload}
                loading={imageProcessing}
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Grid key={ColorsAndLogosFormFields.WatermarkPosition} size={12}>
            <FormControl fullWidth>
              <Field
                name={ColorsAndLogosFormFields.WatermarkPosition}
                label="Watermark style"
                variant="filled"
                component={SelectField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = typeof event !== 'object' ? event : event?.target?.value || '';
                  if (
                    ([WatermarkPosition.CenterText, WatermarkPosition.CenterTextNotRotated] as string[]).includes(value)
                  ) {
                    onChange(ColorsAndLogosFormFields.Watermark, '');
                  }
                  onChange(ColorsAndLogosFormFields.WatermarkPosition, value);
                }}
                margin="none"
                slotProps={{
                  select: {
                    autoWidth: false,
                  },
                }}
                initializing={initializing}
              >
                {Object.values(WatermarkPosition).map((position) => (
                  <MenuItem key={position} value={position}>
                    {t(`sites-logos-configurator-watermark-style-${position}`)}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Collapse
            in={
              [WatermarkPosition.CenterText, WatermarkPosition.CenterTextNotRotated].includes(watermarkPosition) &&
              !initializing
            }
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Grid key={ColorsAndLogosFormFields.WatermarkText} size={12}>
              <FormControl fullWidth>
                <Field
                  name={ColorsAndLogosFormFields.WatermarkText}
                  label="Watermark text"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(ColorsAndLogosFormFields.WatermarkText, event.target.value)
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Form>
  );
};

export const ColorsAndLogosForm = reduxForm<FormData>({
  form: Forms.ColorsAndLogos,
  enableReinitialize: true,
})(ColorsAndLogosFormComponent);
