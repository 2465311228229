import React, { useEffect, useMemo, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Card, Stack, styled, Skeleton, Box, darken, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Button as SalesViewButton } from '../library/Button';
import { Configurator } from '../../types/Configurator';
import {
  useGetClientDataBranchChangesSummaryQuery,
  useGetClientDataBranchesQuery,
  useGetClientVersionsQuery,
} from '../../services/clientDataApi';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { unknownGroup } from '../../constants/Group';
import { AppState } from '../../types/AppState';
import { getChangedSecondaryMenuSections } from '../../utils/menuUtils';
import { ClientDataType } from '../../constants/ClientDataType';
import { setSubMenuItem } from '../../middleware/menuThunk';
import { viewSite } from '../../utils/configuratorUtils';
import { ContentList } from '../library/ContentList';
import { publishMessagePrefix } from '../../constants/VendorData';
import { useGetGroupSetupStepsQuery } from '../../services/groupApi';
import { GettingStartedSteps } from '../../constants/gettingStartedConfig';

const useStyles = makeStyles({
  header: {
    color: '#323B4B',
    fontSize: '26px',
    fontWeight: 800,
    lineHeight: '100%',
  },
  subheader: {
    color: '#000000',
    fontSize: '14px',
    opacity: 0.5,
    lineHeight: '140%',
  },
  banner: {
    width: '100%',
    height: '32px',
    transform: 'none',
    WebkitTransform: 'none',
    borderRadius: '15px 15px 0px 0px',
  },
});

const Button = styled(SalesViewButton)({
  borderRadius: '100px',
});

type Props = {
  configurator: Configurator;
  initializing: boolean;
  setInitializing: (
    initializing:
      | { [clientId: string]: boolean }
      | ((prevInitializing: { [clientId: string]: boolean }) => { [clientId: string]: boolean }),
  ) => void;
};

export const SitesCard: React.FC<Props> = ({
  configurator: { clientId = '', name, url, vendorData: { vendor: { selectedTextColor = '#D9D9D9' } = {} } = {} },
  initializing,
  setInitializing,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const cardRef = useRef<HTMLDivElement>(null);

  const { groupId, configurators = [] } = useAppSelector((state: AppState) => state.currentUser.group || unknownGroup);
  const { tourIsActive } = useAppSelector((state) => state.gettingStarted);
  const viewerState = useAppSelector((state) => state.viewer);
  const { selectedClientId } = viewerState;
  const mainMenuItem = useAppSelector((state) => state.menu.mainMenuItem);

  const {
    currentData: activeBranches,
    isUninitialized: isUninitializedActiveBranches,
    isLoading: isLoadingActiveBranches,
  } = useGetClientDataBranchesQuery(
    { dataType: ClientDataType.Vendor, clientId, groupId },
    {
      skip: !clientId,
      refetchOnMountOrArgChange: true,
    },
  );

  const branch = activeBranches?.some(
    ({ branchType }) => !isLoadingActiveBranches && branchType === ClientDataBranch.SiteDetail,
  )
    ? ClientDataBranch.SiteDetail
    : ClientDataBranch.Main;

  const { currentData: changesSummary, isLoading: isLoadingChangesSummary } = useGetClientDataBranchChangesSummaryQuery(
    { dataType: ClientDataType.Vendor, clientId, groupId, branch },
    {
      skip: !clientId || branch === ClientDataBranch.Main,
      refetchOnMountOrArgChange: true,
    },
  );
  const { currentData: branchDiffMerges, isLoading: isLoadingBranchDiffMerges } = useGetClientVersionsQuery(
    {
      dataType: ClientDataType.Vendor,
      clientId,
      groupId,
      messagePrefix: publishMessagePrefix,
      limit: 1,
    },
    {
      skip: !clientId || branch === ClientDataBranch.SiteDetail,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: { steps: gettingStartedSteps = [] } = {} } = useGetGroupSetupStepsQuery(
    {
      clientId: selectedClientId || '',
      groupId,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: false },
  );
  const isDayOneClient = configurators?.[0]?.vendorData?.vendor?.maxioSubscriptionId;
  const shouldCompleteConfiguratorStep =
    isDayOneClient &&
    gettingStartedSteps.find((step) => step.key === GettingStartedSteps.TRY_NEW_SITE)?.completed === false;

  useEffect(() => {
    setInitializing((prevInitializing) => ({
      ...prevInitializing,
      [clientId]:
        isUninitializedActiveBranches ||
        (!activeBranches && isLoadingActiveBranches) ||
        isLoadingChangesSummary ||
        isLoadingBranchDiffMerges,
    }));
  }, [
    clientId,
    setInitializing,
    isUninitializedActiveBranches,
    activeBranches,
    isLoadingActiveBranches,
    isLoadingChangesSummary,
    isLoadingBranchDiffMerges,
  ]);

  const changedMenuSections = useMemo(
    () =>
      getChangedSecondaryMenuSections(mainMenuItem, changesSummary)
        .map(({ items }) => items)
        .flat(),
    [mainMenuItem, changesSummary],
  );
  const lastPublished = useMemo(() => {
    const [{ date = '' } = {}] = branchDiffMerges || [];
    if (!date) return '';
    return `Last published ${moment(date).calendar({
      sameDay: '[today]',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: 'MMMM Do',
    })}`;
  }, [branchDiffMerges]);

  return (
    <Card
      ref={cardRef}
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', borderRadius: '15px' }}
      elevation={1}
    >
      {!initializing && (
        <Box
          className={classes.banner}
          sx={{
            background: `linear-gradient(0deg, ${darken(selectedTextColor, 0.1)} 0%, ${selectedTextColor} 100%)`,
          }}
        />
      )}
      {initializing && <Skeleton className={classes.banner} component="div" />}
      <Stack sx={{ p: '25px', flex: 1, justifyContent: 'space-between' }}>
        <Stack spacing="12px">
          {initializing && (
            <Skeleton
              variant="rectangular"
              sx={{
                height: '26px',
                borderRadius: '2px',
              }}
            />
          )}
          {!initializing && <Typography className={classes.header}>{name}</Typography>}
          <Stack spacing="7px">
            {initializing ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  height: '19.6px',
                  borderRadius: '2px',
                }}
              />
            ) : (
              <Typography className={classes.subheader}>
                {branch === ClientDataBranch.SiteDetail ? 'Edited, but not yet published' : lastPublished}
              </Typography>
            )}
            <Slide
              direction="right"
              in={branch === ClientDataBranch.SiteDetail && !!changedMenuSections.length && !initializing}
              container={cardRef.current}
              unmountOnExit
            >
              <ContentList
                listStyleType="disc"
                className={classes.subheader}
                spacing="0px"
                columns={changedMenuSections.length > 3 ? 2 : 1}
                items={changedMenuSections
                  .slice(0, 4)
                  .map(({ label }, i) =>
                    changedMenuSections.length > 4 && i === 3
                      ? { label: `+ ${changedMenuSections.length - 3} additional categories` }
                      : { label: label(t) },
                  )}
              />
            </Slide>
          </Stack>
        </Stack>
        <Stack direction="row" gap="11px">
          <Box position="relative" width="fit-content">
            <Button
              className="group-configurator-btn"
              variant="outlined"
              sx={{ visibility: initializing ? 'hidden' : 'visible' }}
              onClick={(): Window | null =>
                viewSite(url, {
                  tourIsActive,
                  shouldCompleteConfiguratorStep,
                  selectedGroupId: groupId,
                  dispatch,
                })
              }
            >
              View Site
            </Button>
            {initializing && (
              <Skeleton
                variant="rectangular"
                sx={{
                  height: '44px',
                  borderRadius: '100px',
                }}
              />
            )}
          </Box>
          <Box position="relative" width="fit-content">
            <Button
              variant="outlined"
              sx={{ visibility: initializing ? 'hidden' : 'visible' }}
              onClick={() => {
                dispatch(setSubMenuItem(clientId));
              }}
            >
              Edit
            </Button>
            {initializing && (
              <Skeleton
                variant="rectangular"
                sx={{
                  height: '44px',
                  borderRadius: '100px',
                }}
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};
