import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch } from '../hooks';
import supportIcon from '../images/contactSupport.svg';

export const PricingContactSupportDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showIntercomDialog = () => {
    window.Intercom('show');
    dispatch(closeDialogFunc());
  };

  return (
    <Dialog dialogKey={Dialogs.PricingContactSupport}>
      <DialogTitle>{t(I18nKeys.PricingBaseContactSupportDialogTitle)}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing="16px" alignItems="center">
          <Button
            sx={{
              height: '60px',
              width: '60px',
              padding: '12px',
              backgroundColor: 'rgb(51, 51, 51)',
              borderRadius: '50%',
            }}
            onClick={showIntercomDialog}
          >
            <img style={{ height: '100%' }} alt="Support" src={supportIcon} />
          </Button>
          <Typography role="button" onClick={showIntercomDialog} onKeyDown={showIntercomDialog} tabIndex={0}>
            {t(I18nKeys.PricingBaseContactSupportMessage)}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={showIntercomDialog} variant="contained">
          {t(I18nKeys.DialogContactSupportButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCloseButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
