import { FormHelperText, Grid2 as Grid, InputAdornment, IconButton, Collapse } from '@mui/material';
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { Form } from './redux-form/Form';
import { DealerFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { DepositAmount } from '../types/DepositAmount';
import { isCarportView } from '../utils/clientIdUtils';
import { required } from '../utils/reduxFormUtils';
import { DepositAmounts } from './DepositAmounts';
import { InputField } from './redux-form/InputField';
import { unknownGroup } from '../constants/Group';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setLoading } from '../ducks/dealersSlice';
import { unknownDealer } from '../types/Dealer';
import { LabeledCheckboxField } from './redux-form/LabeledCheckboxField';

export interface FormData {
  [DealerFormFields.ClientId]: string;
  [DealerFormFields.DealerName]: string;
  [DealerFormFields.City]: string;
  [DealerFormFields.State]: string;
  [DealerFormFields.ZipCode]: string;
  [DealerFormFields.Key]: string;
  [DealerFormFields.Id]: number;
  [DealerFormFields.PhoneNumber]: string;
  [DealerFormFields.CustomLogoUrl]: string;
  [DealerFormFields.DealerUrl]: string;
  [DealerFormFields.HomeLinkUrl]: string;
  [DealerFormFields.EmailAddress]: string;
  [DealerFormFields.QuoteEmailReplyToSame]: boolean;
  [DealerFormFields.QuoteEmailReplyToAddress]: string;
  [DealerFormFields.QuoteEmailCopySame]: boolean;
  [DealerFormFields.QuoteEmailCopyAddress]: string;
  [DealerFormFields.DepositAmounts]: DepositAmount[];
  [DealerFormFields.Integration]: string;
  [DealerFormFields.ContactBarHtml]: string;
  [DealerFormFields.EmailLogoUrl]: string;
  [DealerFormFields.IntegrationsKeySame]: boolean;
  [DealerFormFields.IntegrationsKey]: string;
}

const DealerFormComponent: React.FC<InjectedFormProps<FormData>> = ({
  handleSubmit,
  initialValues,
  change,
  error,
}: InjectedFormProps<FormData>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { dealerURL: initialDealerUrl = '', homeLinkUrl: initialHomeLinkUrl = '' } = useAppSelector(
    (state) => state.dealers.dialogDealer || unknownDealer,
  );
  const replyToSame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? dialogDealer.emailAddress === dialogDealer?.quoteEmailReplyToAddress : true,
  );
  const copySame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? dialogDealer.emailAddress === dialogDealer?.quoteEmailCopyAddress : true,
  );
  const integrationsKeySame = useAppSelector(({ dealers: { dialogDealer } }) =>
    dialogDealer ? !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey : true,
  );
  const configuratorLink = useAppSelector(
    ({ dealers: { dialogClientId }, currentUser: { group: { configurators = [] } = unknownGroup } }) => {
      const selectedConfigurator = configurators.find((config) => dialogClientId === `${config.key}-${config.vendor}`);
      return selectedConfigurator ? selectedConfigurator.url || '' : '';
    },
  );

  const [replyToSameChecked, setReplyToSameChecked] = React.useState<boolean>(replyToSame);
  const [copySameChecked, setCopySameChecked] = React.useState<boolean>(copySame);
  const [integrationsSameChecked, setIntegrationsSameChecked] = React.useState<boolean>(integrationsKeySame);
  const [dealerUrl, setDealerUrl] = React.useState(initialDealerUrl);

  useEffect(() => setReplyToSameChecked(replyToSame), [replyToSame]);
  useEffect(() => setReplyToSameChecked(copySame), [copySame]);
  useEffect(() => setIntegrationsSameChecked(integrationsKeySame), [integrationsKeySame]);
  useEffect(() => setDealerUrl(initialDealerUrl), [initialDealerUrl]);

  const showCarportViewFields = isCarportView(initialValues[DealerFormFields.ClientId] || '');

  const handleDealerIdChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const url = `${configuratorLink}${configuratorLink.includes('?') ? '&' : '?'}dealer=${event.target.value}`;
    change(DealerFormFields.DealerUrl, url);
    setDealerUrl(url);
    // If the home link URL hasn't been changed, update to new dealer ID
    if (!initialHomeLinkUrl || initialHomeLinkUrl === initialDealerUrl) {
      change(DealerFormFields.HomeLinkUrl, url);
    }
  };

  const handleReplyToSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setReplyToSameChecked(event.target.checked);
  };

  const handleCopySameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCopySameChecked(event.target.checked);
  };

  const handleIntegrationsSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIntegrationsSameChecked(event.target.checked);
  };

  const handleDealerUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDealerUrl(event.target.value);
  };

  return (
    <Form
      onSubmit={handleSubmit((values: FormData): Promise<AnyAction> => {
        dispatch(setLoading(true));
        return new Promise(
          (resolve, reject): AnyAction =>
            // eslint-disable-next-line no-promise-executor-return
            dispatch({
              type: `${Forms.Dealer}_SUBMIT`,
              values,
              resolve,
              reject,
            }),
        );
      })}
      onReset={() => {
        setReplyToSameChecked(true);
        setCopySameChecked(true);
        setIntegrationsSameChecked(true);
      }}
    >
      <Grid container direction="column" spacing="16px">
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            autoFocus
            component={InputField}
            required
            label={t(I18nKeys.DealerDialogDealerNameField)}
            name={DealerFormFields.DealerName}
            validate={required}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            required
            label={t(I18nKeys.DealerDialogDealerIdField)}
            validate={required}
            name={DealerFormFields.Key}
            onChange={handleDealerIdChanged}
          />
          <FormHelperText>{t(I18nKeys.DealerDialogDealerIdFieldHelpText)}</FormHelperText>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            props={{ disabled: true }}
            label={t(I18nKeys.DealerDialogDealerSiteLinkField)}
            name={DealerFormFields.DealerUrl}
            onChange={handleDealerUrlChange}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!dealerUrl}
                      edge="end"
                      sx={{ p: '16px' }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
                        event.stopPropagation();
                        window.open(dealerUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <OpenInNew sx={{ color: '#323B4B', opacity: 0.7 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        <Grid container spacing="16px">
          <Grid size={{ sm: 7, xs: 12 }}>
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogDealerCityField)}
              name={DealerFormFields.City}
            />
          </Grid>
          <Grid size={{ sm: 2, xs: 6 }}>
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogDealerStateField)}
              name={DealerFormFields.State}
            />
          </Grid>
          <Grid size={{ sm: 3, xs: 6 }}>
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogDealerZipField)}
              name={DealerFormFields.ZipCode}
            />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Field
            autoComplete="off"
            component={InputField}
            label={t(I18nKeys.FieldPhone)}
            name={DealerFormFields.PhoneNumber}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Field
              autoComplete="off"
              component={InputField}
              label={t(I18nKeys.DealerDialogDealerEmailField)}
              name={DealerFormFields.EmailAddress}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Field
              id={DealerFormFields.QuoteEmailReplyToSame}
              name={DealerFormFields.QuoteEmailReplyToSame}
              component={LabeledCheckboxField}
              label={t(I18nKeys.DealerDialogEmailReplyToSameCheckbox)}
              onChange={handleReplyToSameChange}
            />
          </Grid>
          <Collapse in={!replyToSameChecked} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <Grid size={{ xs: 12 }} sx={{ pl: '36px' }}>
              <Field
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogEmailReplyToSameField)}
                name={DealerFormFields.QuoteEmailReplyToAddress}
              />
            </Grid>
          </Collapse>
          <Grid size={{ xs: 12 }}>
            <Field
              id={DealerFormFields.QuoteEmailCopySame}
              name={DealerFormFields.QuoteEmailCopySame}
              component={LabeledCheckboxField}
              label={t(I18nKeys.DealerDialogEmailCopySameCheckbox)}
              onChange={handleCopySameChange}
            />
          </Grid>
          <Collapse in={!copySameChecked} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <Grid size={{ xs: 12 }} sx={{ pl: '36px' }}>
              <Field
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogEmailCopySameField)}
                name={DealerFormFields.QuoteEmailCopyAddress}
              />
            </Grid>
          </Collapse>
          <Grid size={{ xs: 12 }}>
            <Field
              id={DealerFormFields.IntegrationsKeySame}
              name={DealerFormFields.IntegrationsKeySame}
              component={LabeledCheckboxField}
              label={t(I18nKeys.DealerDialogCustomDealerIdCheckbox)}
              onChange={handleIntegrationsSameChange}
            />
          </Grid>
          <Collapse in={!integrationsSameChecked} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <Grid size={{ xs: 12 }} sx={{ pl: '36px' }}>
              <Field
                autoComplete="off"
                component={InputField}
                label={t(I18nKeys.DealerDialogCustomDealerIdField)}
                name={DealerFormFields.IntegrationsKey}
              />
            </Grid>
          </Collapse>
        </Grid>
        {showCarportViewFields && (
          <Grid size={{ xs: 12 }}>
            <FieldArray name={DealerFormFields.DepositAmounts} component={DepositAmounts} />
          </Grid>
        )}
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
    </Form>
  );
};

export const DealerForm = reduxForm<FormData>({ form: Forms.Dealer, enableReinitialize: true })(DealerFormComponent);
