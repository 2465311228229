import React, { useLayoutEffect, useEffect, useCallback, useMemo } from 'react';
import { GridCheckIcon, GridColDef, useGridApiRef, ValueOptions } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useOrderRepo } from '../hooks/useOrderRepo';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { formatPrice } from '../utils/pricingUtils';
import { hyphenateString } from '../utils/stringUtils';
import {
  getApplyQuickFilterFnVersionedEmailId,
  getOrdersCreatedFormatter,
  getValueOrEmpty,
} from '../utils/MUIDataGridUtils';

import { setSearchHidden } from '../ducks/search';
import { isCurrentUserAdmin, isIdeaRoomUser } from '../utils/userUtils';
import { MuiDataGrid } from './MUIDataGrid';
import { I18nKeys } from '../constants/I18nKeys';
import { NewWindowLink } from './NewWindowLink';
import { OrderOwnerSelector } from './OrderOwnerSelector';
import { OrderOwner } from '../types/OrderOwner';
import { getOrderOwnerFromOrder, getOrderStatusLabelFromOrder } from '../utils/orderUtils';
import { fetchOrderOwnerOptions, setOrderOwner } from '../ducks/orders';
import { AppDispatch } from '../middleware/thunks';
import { unknownGroup } from '../constants/Group';
import { GroupMember } from '../types/GroupMember';

const getColumnExtraProps = (
  t: Function,
  dispatch: AppDispatch,
  members?: GroupMember[],
  orderOwnerOptions: OrderOwner[] = [],
  getBuildingStyleOptions?: () => ValueOptions[],
  getBuildingSizeOptions?: () => ValueOptions[],
): GridColDef[] => [
  {
    field: 'date',
    headerName: t(I18nKeys.OrderGridHeaderDate),
    valueFormatter: getOrdersCreatedFormatter,
    type: 'dateTime',
    minWidth: 88,
    filterable: false,
  },
  {
    field: 'customerName',
    headerName: t(I18nKeys.OrderGridHeaderName),
    type: 'string',
    minWidth: 144,
  },
  {
    field: 'customerEmail',
    headerName: t(I18nKeys.OrderGridHeaderEmail),
    type: 'string',
    minWidth: 176,
  },
  {
    field: 'customerPhone',
    headerName: t(I18nKeys.OrderGridHeaderPhone),
    valueFormatter: formatPhoneNumber,
    type: 'string',
    minWidth: 120,
  },
  {
    field: 'address',
    headerName: t(I18nKeys.OrderGridHeaderAddress),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.shippingAddress?.address1),
    minWidth: 144,
  },
  {
    field: 'city',
    headerName: t(I18nKeys.OrderGridHeaderCity),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.shippingAddress?.city),
    minWidth: 88,
  },
  {
    field: 'state',
    headerName: t(I18nKeys.OrderGridHeaderState),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.shippingAddress?.state),
    minWidth: 80,
  },
  {
    field: 'postalCode',
    headerName: t(I18nKeys.OrderGridHeaderPostalCode),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.shippingAddress?.zip),
    minWidth: 78,
  },
  {
    field: 'buildingStyle',
    headerName: t(I18nKeys.OrderGridHeaderBuildingStyle),
    type: 'singleSelect',
    minWidth: 126,
    valueOptions: getBuildingStyleOptions?.() || [],
  },
  {
    field: 'buildingSize',
    headerName: t(I18nKeys.OrderGridHeaderBuildingSize),
    type: 'singleSelect',
    valueOptions: getBuildingSizeOptions?.() || [],
  },
  {
    field: 'totalPrice',
    headerName: t(I18nKeys.OrderGridHeaderOrderAmount),
    valueFormatter: (value, row) => formatPrice(value, row?.transaction?.currency || 'USD', 2),
    type: 'number',
    minWidth: 88,
  },
  {
    field: 'paidAmount',
    headerName: t(I18nKeys.OrderGridHeaderPaidAmount),
    valueFormatter: (value, row) => formatPrice(value, row?.transaction?.currency || 'USD', 2),
    type: 'number',
    minWidth: 88,
  },
  {
    field: 'paidInFull',
    headerName: t(I18nKeys.OrderGridHeaderPaidInFull),
    valueGetter: (value, row) => row.totalPrice && row.paidAmount >= row.totalPrice,
    renderCell: (params) => {
      if (params.value === true) {
        return <GridCheckIcon />;
      }
      return null;
    },
    type: 'boolean',
    minWidth: 64,
  },
  {
    field: 'Owner',
    headerName: t(I18nKeys.OrderGridHeaderOwner),
    type: 'singleSelect',
    minWidth: 152,
    valueGetter: (value, row) => getOrderOwnerFromOrder(row, members).name,
    renderCell: (params) => (
      <Box>
        <OrderOwnerSelector
          readonly={false}
          owner={getOrderOwnerFromOrder(params.row, members)}
          options={orderOwnerOptions}
          onOrderOwnerChange={(orderOwner: OrderOwner): void => {
            dispatch(
              setOrderOwner(params.row.clientId, params.row.uuid, getOrderStatusLabelFromOrder(params.row), orderOwner),
            );
          }}
        />
      </Box>
    ),
  },
  {
    field: 'versionedEmailId',
    headerName: t(I18nKeys.OrderGridHeaderOrderNumber),
    type: 'string',
    minWidth: 160,
    valueFormatter: (value) => hyphenateString(value),
    getApplyQuickFilterFn: getApplyQuickFilterFnVersionedEmailId,
  },
  {
    field: 'design',
    headerName: t(I18nKeys.OrderGridHeaderDesign),
    valueGetter: (value, row) => row?.orderDealerLink || row?.link,
    renderCell: (params) => <NewWindowLink link={params.value} />,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#F5F5F5',
    '& .MuiDataGrid-footerContainer': {
      background: '#F5F5F5',
    },
  },
  chip: {
    padding: '4px 12px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 12px',
    gap: '8px',
    borderRadius: '16px',
    opacity: '0px',
    background: 'red',
  },
  chipText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
}));

export const Orders: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const { user, group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const { orders, isLoadingOrders } = useOrderRepo({ useOrders: true });
  const { members } = useAppSelector((state) => state?.group.group || unknownGroup);
  const { orderOwnerOptions } = useAppSelector((state) => state?.orders);

  const getBuildingStyleOptions = useCallback(() => Array.from(new Set(orders.map((o) => o.buildingStyle))), [orders]);
  const getBuildingSizeOptions = useCallback(() => Array.from(new Set(orders.map((o) => o.buildingSize))), [orders]);

  const columns = useMemo(
    () => getColumnExtraProps(t, dispatch, members, orderOwnerOptions, getBuildingStyleOptions, getBuildingSizeOptions),
    [t, dispatch, members, orderOwnerOptions, getBuildingStyleOptions, getBuildingSizeOptions],
  );

  useEffect(() => {
    dispatch(fetchOrderOwnerOptions(groupId));
  }, [groupId, dispatch]);

  const handleExport = useCallback(() => {
    apiRef?.current?.exportDataAsCsv();
  }, [apiRef]);

  useLayoutEffect(() => {
    dispatch(setSearchHidden(false));
    if (isIdeaRoomUser() || isCurrentUserAdmin(user)) {
      dispatch(
        setSecondaryActions([
          <IconButton color="secondary" size="small" onClick={handleExport}>
            <FileDownloadOutlinedIcon />
          </IconButton>,
        ]),
      );
    }
  }, [dispatch, user, handleExport]);

  return (
    <div className={classes.root}>
      <MuiDataGrid
        loading={isLoadingOrders}
        rows={orders}
        columns={columns}
        apiRef={apiRef}
        enableDateFilter
        getRowId={(row) => row.hash}
        handleSearch
        autoHeight
        initialState={{
          columns: {
            columnVisibilityModel: {
              customerPhone: false,
              address: false,
              city: false,
              state: false,
              postalCode: false,
              buildingStyle: false,
              buildingSize: false,
            },
          },
        }}
        countParams={{
          countLabel: I18nKeys.OrderGridPaymentCount,
          filteredLabel: I18nKeys.OrderGridFilteredPaymentCount,
        }}
      />
    </div>
  );
};
