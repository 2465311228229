import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../hooks';
import ideaRoomIconSrc from '../images/ideaRoomIcon.svg';
import { AppState } from '../types/AppState';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import { SalesViewUILayout } from '../constants/Viewer';

export const Logo: React.FC = () => {
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const logoUrl = useAppSelector((state) => state.viewer?.theme?.logoUrl || ideaRoomIconSrc);

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state: AppState) =>
      state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  // UI Layout Remove Me
  if (layout !== SalesViewUILayout.Horizon && logoUrl)
    return <img style={{ height: mobileView ? '48px' : '70px' }} alt="logo" src={logoUrl} />;

  return logoUrl ? (
    <img style={{ height: mobileView ? '36px' : '50px' }} alt="logo" src={logoUrl} />
  ) : (
    <CircularProgress size={24} color="primary" />
  );
};
