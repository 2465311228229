import { DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  statusIcon: {
    width: '30px',
    height: '30px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
}));

export const ClientDataCantGenerateIconsDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Dialog dialogKey={Dialogs.ClientDataCantGenerateIconsDialog} maxWidth="xs">
      <DialogContent>
        <Stack direction="row" spacing="16px">
          <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />
          <Typography>
            <Trans i18nKey={I18nKeys.ClientDataCantGenerateIconsError as string} />
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          variant="contained"
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
