import React from 'react';
import { AnyAction } from 'redux';
import { Field, InjectedFormProps, Validator, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Stack, styled, Typography } from '@mui/material';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PricingBaseAddSizeFormFields } from '../constants/FormFields';
import { Form } from './redux-form/Form';

export interface FormData {
  [PricingBaseAddSizeFormFields.Width]: number;
  [PricingBaseAddSizeFormFields.Length]: number;
}

interface CustomProps {
  hasPriceWithSize: boolean;
  hasPriceWithZero: boolean;
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const StyledInputField = styled(InputField)({
  width: '90px',
});

const Label = styled(Typography)({
  fontWeight: 'bold',
  alignSelf: 'end',
  paddingBottom: '8px',
});

const hasPriceWithSizeValidator: Validator = (value: string | number, allValues: FormData, props: FormProps) => {
  if (props.hasPriceWithSize || value === 0) {
    return 'error';
  }
  return undefined;
};

const PricingBaseAddSizeFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  hasPriceWithSize,
  hasPriceWithZero,
}: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { useMetricUnits } = useAppSelector((state) => state?.viewer);

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.PricingBaseAddSize}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  const parseMeasurement = (val: string) => {
    if (Number.isNaN(val)) {
      return 0;
    }
    return Number(val);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing="16px">
        <Field
          autoFocus
          type="number"
          autoComplete="off"
          validate={hasPriceWithSizeValidator}
          parse={parseMeasurement}
          component={StyledInputField}
          hideHelperText
          errorWithoutTouch
          label={t(I18nKeys.PricingBaseAddSizeDialogWidth)}
          name={PricingBaseAddSizeFormFields.Width}
          variant="filled"
          normalize={(value: any, previousValue: any, allValues: any[]) => (value < 0 ? previousValue : value)}
        />
        <Label>
          {t(useMetricUnits ? I18nKeys.PricingbaseaddSizeFormMetricUnit : I18nKeys.PricingbaseaddSizeFormImperialUnit)}
        </Label>
        <Label>×</Label>
        <Field
          autoComplete="off"
          type="number"
          validate={hasPriceWithSizeValidator}
          parse={parseMeasurement}
          hideHelperText
          errorWithoutTouch
          component={StyledInputField}
          label={t(I18nKeys.PricingBaseAddSizeDialogLength)}
          name={PricingBaseAddSizeFormFields.Length}
          variant="filled"
          normalize={(value: any, previousValue: any, allValues: any[]) => (value < 0 ? previousValue : value)}
        />
        <Label>
          {t(useMetricUnits ? I18nKeys.PricingbaseaddSizeFormMetricUnit : I18nKeys.PricingbaseaddSizeFormImperialUnit)}
        </Label>
      </Stack>

      {hasPriceWithSize && <FormHelperText error>{t(I18nKeys.PricingbaseaddSizeFormErrorSizeExists)}</FormHelperText>}
      {hasPriceWithZero && <FormHelperText error>{t(I18nKeys.PricingbaseaddSizeFormErrorZero)}</FormHelperText>}
    </Form>
  );
};

export const PricingBaseAddSizeForm = reduxForm<FormData, CustomProps>({
  form: Forms.PricingBaseAddSize,
})(PricingBaseAddSizeFormComponent);
