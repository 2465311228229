import { DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useGetClientPublishedVersionsQuery } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import {
  clientHasDatasetsToUpdate,
  setClientDataNewSupplierUpdatesDialogSkip,
  shouldSkipClientDataNewSupplierUpdatesDialog,
} from '../utils/clientDataUtils';
import { unknownGroup } from '../constants/Group';
import { Button } from './library/Button';

export const ClientDataNewSupplierUpdatesDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientDataType, clientDataBranch, clientId } = useAppSelector((state) => state.clientData);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const { currentData: clientPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, groupId, dataType: clientDataType },
    {
      skip:
        !clientId ||
        !groupId ||
        !clientDataType ||
        clientDataType !== ClientDataType.Vendor ||
        clientDataBranch !== ClientDataBranch.Main,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );
  const openDialogKey = useAppSelector((state) => state.dialog.key);

  useEffect(() => {
    if (
      !openDialogKey &&
      clientHasDatasetsToUpdate(clientPublishedVersions) &&
      !shouldSkipClientDataNewSupplierUpdatesDialog(clientId, clientPublishedVersions)
    ) {
      setClientDataNewSupplierUpdatesDialogSkip(clientId, clientPublishedVersions);
      dispatch(openDialog({ dialog: Dialogs.ClientDataNewSupplierUpdates }));
    }
  }, [clientPublishedVersions]);

  return (
    <Dialog dialogKey={Dialogs.ClientDataNewSupplierUpdates} maxWidth="xs">
      <DialogContent>
        <Typography>
          <Trans i18nKey={I18nKeys.ClientDataNewSupplierUpdatesDialogText as string} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeDialogFunc());
          }}
          variant="contained"
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
