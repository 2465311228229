import React from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './library/Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SetupPaymentIntegrationOptions } from '../types/DialogState';
import { startPaymentIntegration } from '../ducks/integrationSlice';
import { ContentList } from './library/ContentList';
import { Button } from './library/Button';

export const SetupPaymentIntegrationConfirmationDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { legalEntityName, shopperStatement } = options as SetupPaymentIntegrationOptions;
  const openPreviousDialog = () => {
    dispatch(openDialog({ dialog: Dialogs.SetupPaymentIntegration, options: { legalEntityName, shopperStatement } }));
  };

  const onContinue = () => {
    dispatch(startPaymentIntegration({ legalEntityName, shopperStatement }));
    dispatch(
      openDialog({
        dialog: Dialogs.Loading,
        options: {
          title: t(I18nKeys.SetupPaymentIntegrationLoadingTitle),
          content: t(I18nKeys.SetupPaymentIntegrationLoadingContent),
        },
      }),
    );
  };

  return (
    <Dialog dialogKey={Dialogs.SetupPaymentIntegrationConfirmation} onClosed={openPreviousDialog}>
      <DialogTitle>{t(I18nKeys.SetupPaymentIntegrationConfirmationDialogTitle)}</DialogTitle>
      <DialogContent>
        <ContentList
          listStyleType="disc"
          spacing="0px"
          items={[
            {
              label: (
                <Box>
                  <Typography display="inline">{`${t(I18nKeys.SetupPaymentIntegrationDialogLegalName)}: `}</Typography>
                  <Typography fontWeight="bold" display="inline">
                    {legalEntityName}
                  </Typography>
                </Box>
              ),
            },
            {
              label: (
                <Box>
                  <Typography display="inline">{`${t(
                    I18nKeys.SetupPaymentIntegrationDialogShopperStatement,
                  )}: `}</Typography>
                  <Typography fontWeight="bold" display="inline">
                    {shopperStatement}
                  </Typography>
                </Box>
              ),
            },
          ]}
        />
        <Typography>{t(I18nKeys.SetupPaymentIntegrationConfirmationDialogDisclaimer)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
            openPreviousDialog();
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button variant="contained" onClick={onContinue}>
          {t(I18nKeys.DialogContinueButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
