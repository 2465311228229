import React, { useCallback } from 'react';
import { Grid2 as Grid, Stack, FormControl, Collapse, Box } from '@mui/material';
import { change, Field, formValueSelector } from 'redux-form';
import { PromotionalMessageType } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { BannersAndMessagesFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { Header } from '../library/Header';
import { RadioField } from '../redux-form/RadioField';
import { RadioGroupField } from '../redux-form/RadioGroupField';
import { I18nKeys } from '../../constants/I18nKeys';
import { InputField } from '../redux-form/InputField';
import { sanitizeInput } from '../../utils/inputUtils';
import { Button } from '../library/Button';
import { mobileMessageValidation } from '../../utils/reduxFormUtils';
import { SitesDivider } from './SitesDivider';

type Props = {
  initializing: boolean;
};

export const TopBannerSection: React.FC<Props> = ({ initializing }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const promoBannerType = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, BannersAndMessagesFormFields.PromoBannerType),
  );
  const desktopPromo2 = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, BannersAndMessagesFormFields.DesktopPromo2),
  );
  const mobilePromo2 = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, BannersAndMessagesFormFields.MobilePromo2),
  );

  const onChange = useCallback(
    (column: string, value: string | boolean | null) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Grid container size={12} spacing="24px">
      <Grid container size={12} spacing="16px">
        <Header
          initializing={initializing}
          header="Top Banner"
          subheader="Sits at the top of your site. It can rotate 1 additional message when customized."
        />
        <Grid container size={12} spacing="8px">
          <FormControl fullWidth>
            <Field
              name={BannersAndMessagesFormFields.PromoBannerType}
              component={RadioGroupField}
              onChange={(value: any) => {
                onChange(BannersAndMessagesFormFields.PromoBannerType, value);
              }}
            >
              <RadioField
                initializing={initializing}
                label={t(I18nKeys.SitesInstallationMessageStandard)}
                value={PromotionalMessageType.Standard}
                color="secondary"
                sx={{ py: '4px' }}
              />
            </Field>
          </FormControl>
          <FormControl fullWidth>
            <Stack rowGap="16px">
              <Collapse
                in={promoBannerType === PromotionalMessageType.Standard || initializing}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%', pl: '36px' }}
              >
                <Stack rowGap="24px">
                  <FormControl fullWidth>
                    <Field
                      name={BannersAndMessagesFormFields.StandardDesktopPromo}
                      label="Desktop message"
                      variant="filled"
                      component={InputField}
                      initializing={initializing}
                      disabled
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      name={BannersAndMessagesFormFields.StandardMobilePromo}
                      label="Mobile message"
                      variant="filled"
                      component={InputField}
                      initializing={initializing}
                      disabled
                    />
                  </FormControl>
                </Stack>
              </Collapse>
              <FormControl fullWidth>
                <Field
                  name={BannersAndMessagesFormFields.PromoBannerType}
                  component={RadioGroupField}
                  onChange={(value: any) => {
                    onChange(BannersAndMessagesFormFields.PromoBannerType, value);
                  }}
                >
                  <RadioField
                    initializing={initializing}
                    label={t(I18nKeys.SitesInstallationMessageCustom)}
                    value={PromotionalMessageType.Custom}
                    color="secondary"
                    sx={{ py: '4px' }}
                  />
                </Field>
              </FormControl>
              <Collapse
                in={promoBannerType === PromotionalMessageType.Custom && !initializing}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%', pl: '36px' }}
              >
                <Stack rowGap="24px">
                  <FormControl fullWidth>
                    <Field
                      name={BannersAndMessagesFormFields.DesktopPromo1}
                      label="Desktop message"
                      variant="filled"
                      component={InputField}
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(BannersAndMessagesFormFields.DesktopPromo1, sanitizeInput(event.target.value));
                      }}
                      initializing={initializing}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      name={BannersAndMessagesFormFields.MobilePromo1}
                      label="Mobile message"
                      variant="filled"
                      component={InputField}
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(BannersAndMessagesFormFields.MobilePromo1, sanitizeInput(event.target.value))
                      }
                      initializing={initializing}
                      validate={mobileMessageValidation}
                    />
                  </FormControl>
                  <Collapse in={desktopPromo2 !== null || mobilePromo2 !== null} timeout="auto" unmountOnExit>
                    <Stack rowGap="24px">
                      <FormControl fullWidth>
                        <Field
                          name={BannersAndMessagesFormFields.DesktopPromo2}
                          label="Desktop message"
                          variant="filled"
                          component={InputField}
                          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(BannersAndMessagesFormFields.DesktopPromo2, sanitizeInput(event.target.value))
                          }
                          initializing={initializing}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          name={BannersAndMessagesFormFields.MobilePromo2}
                          label="Mobile message"
                          variant="filled"
                          component={InputField}
                          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(BannersAndMessagesFormFields.MobilePromo2, sanitizeInput(event.target.value))
                          }
                          initializing={initializing}
                          validate={mobileMessageValidation}
                        />
                      </FormControl>
                    </Stack>
                  </Collapse>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const newValue = desktopPromo2 !== null || mobilePromo2 !== null ? null : '';
                        dispatch(
                          change(Forms.BannersAndMessages, BannersAndMessagesFormFields.DesktopPromo2, newValue),
                        );
                        dispatch(change(Forms.BannersAndMessages, BannersAndMessagesFormFields.MobilePromo2, newValue));
                        onChange(BannersAndMessagesFormFields.DesktopPromo2, newValue);
                        onChange(BannersAndMessagesFormFields.MobilePromo2, newValue);
                      }}
                    >
                      {desktopPromo2 !== null || mobilePromo2 !== null
                        ? t(I18nKeys.SitesPromotionalBannerRemoveButton)
                        : t(I18nKeys.SitesPromotionalBannerAddButton)}
                    </Button>
                  </Box>
                </Stack>
              </Collapse>
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
      <SitesDivider initializing={initializing} />
    </Grid>
  );
};
