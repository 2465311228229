import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PreferencesFormFields, SignUpFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { passwordValidation, required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { InputField } from './redux-form/InputField';
import { InputField as MapNeededInputField } from './map-needed/InputField';
import { IdeaRoomLogo } from './IdeaRoomLogo';
import { useAppSelector } from '../hooks';
import { UserPreference } from '../constants/User';
import { SalesViewUILayout } from '../constants/Viewer';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  image: {
    marginBottom: '20px',
  },
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: theme.spacing(3, 3),
  },
  field: {
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
}));

export interface FormData {
  [SignUpFormFields.FirstName]: string;
  [SignUpFormFields.LastName]: string;
  [SignUpFormFields.NewPassword]: string;
  [SignUpFormFields.ConfirmNewPassword]: string;
}

interface DispatchProps {
  onSubmit(data: FormData): Promise<any>;
}

type Props = DispatchProps & InjectedFormProps<FormData>;

const SignUpFormComponent: React.FC<Props> = ({ error, handleSubmit, onSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // UI Layout Remove Me
  const layout = useAppSelector(
    (state) => state?.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Paper className={classes.paper}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <IdeaRoomLogo className={classes.image} height="100px" />

        <Typography component="p">You need to sign up before continuing</Typography>

        <Field
          autoComplete="given-name"
          validate={required}
          className={classes.field}
          name={SignUpFormFields.FirstName}
          // UI Layout Remove Me
          component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
          label={`${t(I18nKeys.FieldFirstName)}*`}
        />

        <Field
          autoComplete="family-name"
          validate={required}
          className={classes.field}
          name={SignUpFormFields.LastName}
          // UI Layout Remove Me
          component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
          label={`${t(I18nKeys.FieldLastName)}*`}
        />

        <Field
          autoComplete="new-password"
          type={showNewPassword ? 'text' : 'password'}
          validate={passwordValidation}
          className={classes.field}
          name={SignUpFormFields.NewPassword}
          // UI Layout Remove Me
          component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
          label={`${t(I18nKeys.FieldPassword)}*`}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />

        <Field
          autoComplete="new-password"
          type={showConfirmPassword ? 'text' : 'password'}
          validate={required}
          className={classes.field}
          name={SignUpFormFields.ConfirmNewPassword}
          // UI Layout Remove Me
          component={layout !== SalesViewUILayout.Horizon ? MapNeededInputField : InputField}
          label={`${t(I18nKeys.FieldConfirmNewPassword)}*`}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />

        {!!error && <Typography className={classes.error}>{error}</Typography>}

        <LoadingButton
          className={classes.button}
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          loading={submitting}
          disabled={submitting}
        >
          {t(I18nKeys.SignUpSubmitButton)}
        </LoadingButton>
      </Form>
    </Paper>
  );
};

const validate = ({
  [SignUpFormFields.NewPassword]: password,
  [SignUpFormFields.ConfirmNewPassword]: confirmPassword,
}: FormData): any => {
  if (password && confirmPassword && password !== confirmPassword) {
    return { [SignUpFormFields.ConfirmNewPassword]: `Password confirmation doesn't match new password` };
  }

  return {};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.SignUp}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

export const SignUpForm = reduxForm<FormData>({ form: Forms.SignUp, validate })(
  connect(undefined, mapDispatchToProps)(SignUpFormComponent),
);
