import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';

export type ClientEmailDomainResponse = {
  status: string;
  records?: {
    [key: string]: {
      host: string;
      type: string;
      expectedValue: string;
      currentValue: string;
      priority?: string;
      verified: boolean;
    };
  };
};

export type CreateMailgunDomainResponse = {
  success: boolean;
};

export const domainApi = createApi({
  reducerPath: 'domainApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal',
  }),
  endpoints: (builder) => ({
    /**
     * Gets the client's email domain information
     *
     * @param domain
     * @returns client email domain information
     */
    getClientEmailDomain: builder.query<
      ClientEmailDomainResponse,
      { emailAddress: string | undefined; clientId: string }
    >({
      query: ({ emailAddress, clientId }) => ({
        url: `/sending-domain`,
        method: 'get',
        params: {
          emailAddress,
        },
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Creates a Mailgun domain for the client domain
     *
     * @param domain
     * @returns Mailgun domain creation response
     */
    createMailgunDomain: builder.mutation<CreateMailgunDomainResponse, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/sending-domain`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Get an existing certificate by arn
     *
     * @param arn
     * @returns certificate
     */
    getCertificate: builder.query<{ certificate: string }, { arn: string }>({
      query: ({ arn }) => ({
        url: `/domain/certificate`,
        method: 'get',
        init: {
          headers: getRequestHeader({ arn }),
        },
      }),
    }),

    /**
     * Get an existing certificate by domain
     *
     * @param domain
     * @returns certificate
     */
    getCertificateByDomain: builder.query<{ certificate: string }, { domain: string }>({
      query: ({ domain }) => ({
        url: `/domain/certificate/${domain}`,
        method: 'get',
      }),
    }),

    /**
     * Request a new certificate by domain
     *
     * @param domain
     * @returns certificate arn
     */
    requestCertificateByDomain: builder.mutation<{ arn: string }, { domain: string }>({
      query: ({ domain }) => ({
        url: `/domain/certificate/${domain}`,
        method: 'post',
      }),
    }),

    /**
     * Create a new distribution by domain
     *
     * @param domain
     * @returns distribution
     */
    createDistributionByDomain: builder.mutation<{ distribution: string }, { domain: string }>({
      query: ({ domain }) => ({
        url: `/domain/distribution/${domain}`,
        method: 'post',
      }),
    }),
  }),
});

export const {
  useGetClientEmailDomainQuery,
  useCreateMailgunDomainMutation,
  useGetCertificateQuery,
  useGetCertificateByDomainQuery,
  useRequestCertificateByDomainMutation,
} = domainApi;
