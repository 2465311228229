/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dispatch } from 'redux';
import { Placement, Step } from 'react-joyride';
import { navigate } from 'hookrouter';
import { AppRoutes } from './AppRoutes';
import { setRunLeadsTour, setRunSitesTour, setSitesTourKey } from '../ducks/gettingStartedSlice';
import { useCompleteSetupStepMutation } from '../services/groupApi';
import { setMainMenuItem } from '../ducks/menuSlice';
import { MainMenuItems } from './MainMenuItems';

export enum GettingStartedSteps {
  TRY_NEW_SITE = 'try-new-site',
  SET_SITE_LOGO = 'set-site-logo',
  SET_SITE_COLORS = 'set-site-colors',
  CREATE_FIRST_BUILDING = 'create-first-building',
  VIEW_LEADS_LIST = 'view-leads-list',
  SET_UP_PAYMENTS = 'set-up-payments',
}

interface TourConfig {
  id: string;
  title: string;
  description: string;
  tourSteps: Step[];
}

export const getGettingStartedSteps = ({
  groupId,
  supplierName,
  dispatch,
  completeSetupStep,
}: {
  groupId: string;
  supplierName: string;
  dispatch: Dispatch<any>;
  completeSetupStep: ReturnType<typeof useCompleteSetupStepMutation>[0];
}): TourConfig[] => [
  {
    id: GettingStartedSteps.TRY_NEW_SITE,
    title: 'Try out your new site',
    description: supplierName
      ? `We already created it for you, using the catalog from ${supplierName}.`
      : 'We already created it for you, using your catalog.',
    tourSteps: [
      {
        placement: 'top' as Placement,
        spotlightClicks: true,
        target: '#menu-list-items-container div.group-configurator-btn',
        content:
          'View the live version of your 3D designer in a new window at any time by clicking at the bottom of this navigation panel.',
        disableBeacon: true,
      },
    ],
  },
  {
    id: GettingStartedSteps.SET_SITE_LOGO,
    title: 'Set your site logo',
    description: 'Customers will see your brand image on the site.',
    tourSteps: [
      {
        locale: {
          close: 'Go',
        },
        placement: 'right' as Placement,
        target: '#Sites-menu-item',
        content: (
          <>
            Settings for the look and behavior of your 3D designer site can be changed on the <strong>Sites</strong>{' '}
            tab.
          </>
        ),
        disableBeacon: true,
        data: {
          onClick: () => {
            dispatch(setRunSitesTour(true));
            dispatch(setSitesTourKey(GettingStartedSteps.SET_SITE_LOGO));
            dispatch(setMainMenuItem(MainMenuItems.Sites));
            navigate(AppRoutes.Sites);
          },
        },
      },
    ],
  },
  {
    id: GettingStartedSteps.SET_SITE_COLORS,
    title: 'Set your site colors',
    description: 'Choose colors to match with your site’s brand.',
    tourSteps: [
      {
        locale: {
          close: 'Go',
        },
        placement: 'right' as Placement,
        target: '#Sites-menu-item',
        content: (
          <>
            Settings for the look and behavior of your 3D designer site can be changed on the <strong>Sites</strong>{' '}
            tab.
          </>
        ),
        disableBeacon: true,
        data: {
          onClick: () => {
            dispatch(setRunSitesTour(true));
            dispatch(setSitesTourKey(GettingStartedSteps.SET_SITE_COLORS));
            dispatch(setMainMenuItem(MainMenuItems.Sites));
            navigate(AppRoutes.Sites);
          },
        },
      },
    ],
  },
  {
    id: GettingStartedSteps.CREATE_FIRST_BUILDING,
    title: 'Create your first test building',
    description: 'Configure a building and save it.',
    tourSteps: [
      {
        placement: 'top' as Placement,
        spotlightClicks: true,
        target: '#menu-list-items-container div.group-configurator-btn',
        content:
          'Use your 3D designer to create a building and save it (but don’t order it yet). Then we can see the new lead show up in SalesView.',
        disableBeacon: true,
      },
    ],
  },
  {
    id: GettingStartedSteps.VIEW_LEADS_LIST,
    title: 'Check out your leads list',
    description: 'You just made a lead.',
    tourSteps: [
      {
        locale: {
          close: 'Go',
        },
        placement: 'right' as Placement,
        target: '#Leads-menu-item',
        content:
          'The Leads list shows all buyers who have saved a building. Later, if they check out, an Order is created.',
        disableBeacon: true,
        data: {
          onClick: () => {
            dispatch(setRunLeadsTour(true));
            completeSetupStep({ groupId, setupStepId: GettingStartedSteps.VIEW_LEADS_LIST });
            dispatch(setMainMenuItem(MainMenuItems.Leads));
            navigate(AppRoutes.Leads);
          },
        },
      },
    ],
  },
  {
    id: GettingStartedSteps.SET_UP_PAYMENTS,
    title: 'Set up payments',
    description: 'Receive payments for your deposits with card or bank transfer from buyers.',
    tourSteps: [
      {
        locale: {
          close: 'Go',
        },
        placement: 'right' as Placement,
        target: '#Payments-menu-item',
        content: 'Payments setup can be completed on the Payments tab.',
        disableBeacon: true,
        data: {
          onClick: () => {
            dispatch(setMainMenuItem(MainMenuItems.Payments));
            navigate(AppRoutes.Payments);
          },
        },
      },
    ],
  },
];

interface SitesTourField {
  sectionKey: string;
  tourSteps: Step[];
}

export const sitesTourMap: Partial<Record<GettingStartedSteps, SitesTourField>> = {
  [GettingStartedSteps.SET_SITE_LOGO]: {
    sectionKey: 'logosAndWatermarks',
    tourSteps: [
      {
        placement: 'right' as Placement,
        spotlightClicks: true,
        target: '#logoUrl-select',
        content: (
          <>
            Save your logo from your existing website, then click here to upload it.
            <br />
            <a
              href="https://idearoom.atlassian.net/wiki/x/AYBqdQ?atlOrigin=eyJpIjoiODYyNDU0MmI5NWEwNDVmYTg5OWUzNWZmMmJlYmM0YTEiLCJwIjoiYyJ9"
              target="_blank"
              rel="noreferrer"
            >
              Learn How
            </a>
          </>
        ),
        disableBeacon: true,
      },
      {
        floaterProps: {
          hideArrow: true,
        },
        placement: 'right' as Placement,
        spotlightClicks: true,
        target: '#publish-bar-id',
        content: (
          <>
            After you make a change, you can <strong>Preview</strong> to see how it will look on your 3D designer.
            Changes aren’t shown publicly until you Publish.
          </>
        ),
        disableBeacon: true,
      },
    ],
  },
  [GettingStartedSteps.SET_SITE_COLORS]: {
    sectionKey: 'colorsAndScene',
    tourSteps: [
      {
        disableOverlay: true,
        placement: 'top' as Placement,
        spotlightClicks: true,
        target: '#sites-colors-and-scene-container .color-picker:first-of-type',
        content: (
          <>
            Select colors that match or go well with your brand colors. Enter the hex codes for the colors here.
            <br />
            <a
              href="https://idearoom.atlassian.net/wiki/x/aYBqdQ?atlOrigin=eyJpIjoiZDFmNzdiYmVkMzI3NDllNmI2OWNlMThmNzllNmNlYzIiLCJwIjoiYyJ9"
              target="_blank"
              rel="noreferrer"
            >
              Learn How
            </a>
          </>
        ),
        disableBeacon: true,
      },
    ],
  },
};
