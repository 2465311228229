/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography, Stack, BoxProps } from '@mui/material';
import React from 'react';
import { ContentSkeleton } from './ContentSkeleton';
import { IdeaRoomOnlyIndicator } from '../IdeaRoomOnlyIndicator';

export const Header: React.FC<
  BoxProps & { initializing?: boolean; header?: string; subheader?: string; ideaRoomOnly?: boolean }
> = ({ initializing, header, subheader, ideaRoomOnly, onClick, ...props }) => (
  <Box sx={{ width: '100%' }} {...props} onClick={onClick}>
    <Stack direction="column" spacing="8px">
      {header && (
        <ContentSkeleton initializing={initializing}>
          <Stack direction="row" alignItems="center" spacing="6px" sx={{ width: 'fit-content' }}>
            {ideaRoomOnly && <IdeaRoomOnlyIndicator height="19.6px" disableMargin sx={{ pt: '0.6px' }} />}
            <Typography fontWeight={500} sx={{ width: 'fit-content' }}>
              {header}
            </Typography>
          </Stack>
        </ContentSkeleton>
      )}
      {subheader && (
        <ContentSkeleton initializing={initializing}>
          <Stack direction="row" alignItems="center" spacing="6px" sx={{ width: 'fit-content' }}>
            {ideaRoomOnly && <IdeaRoomOnlyIndicator height="19.6px" disableMargin sx={{ pt: '0.6px' }} />}
            <Typography
              sx={{
                width: 'fit-content',
                fontSize: '14px',
                opacity: 0.7,
                ...(onClick
                  ? {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      opacity: 0.6,
                    }
                  : {}),
              }}
            >
              {subheader}
            </Typography>
          </Stack>
        </ContentSkeleton>
      )}
    </Stack>
  </Box>
);
