/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuState } from '../types/MenuState';
import { MainMenuItems } from '../constants/MainMenuItems';
import { MenuIconKey } from '../components/MenuIcon';
import { MenuVariant } from '../constants/Menu';
import {
  setInitialMenuState,
  setMainMenuItem as setMainMenuItemThunk,
  setSecondaryMenuItem as setSecondaryMenuItemThunk,
  setSubMenuItem as setSubMenuItemThunk,
} from '../middleware/menuThunk';

export const initialState: MenuState = {
  mainMenuItem: undefined,
  subMenuItem: undefined,
  secondaryMenuItem: undefined,
  variant: MenuVariant.STANDARD,
  showPublishBar: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMainMenuItem(state, action: PayloadAction<MainMenuItems | undefined>) {
      state.mainMenuItem = action.payload;
    },
    setSubMenuItem(state, action: PayloadAction<string | undefined>) {
      state.subMenuItem = action.payload;
    },
    setSecondaryMenuItem(state, action: PayloadAction<string | undefined>) {
      state.secondaryMenuItem = action.payload;
    },
    setMenuIconSvgs(state, action: PayloadAction<{ [key in MenuIconKey]?: string[] } | undefined>) {
      state.menuIconSvgs = action.payload;
    },
    setVariant(state, action: PayloadAction<MenuVariant>) {
      state.variant = action.payload;
    },
    setShowPublishBar(state, action: PayloadAction<boolean>) {
      state.showPublishBar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMainMenuItemThunk.fulfilled, (state, action) => {
      state.mainMenuItem = action.payload;
      state.subMenuItem = action.payload === MainMenuItems.Sales ? MainMenuItems.Leads : undefined;
      state.secondaryMenuItem = undefined;
    });
    builder.addCase(setSubMenuItemThunk.fulfilled, (state, action) => {
      state.subMenuItem = action.payload;
    });
    builder.addCase(setSecondaryMenuItemThunk.fulfilled, (state, action) => {
      state.secondaryMenuItem = action.payload;
    });
    builder.addCase(setInitialMenuState.fulfilled, (state, action) => {
      state.mainMenuItem = action.payload.mainMenuItem;
      state.subMenuItem = action.payload.subMenuItem;
      state.secondaryMenuItem = action.payload.secondaryMenuItem;
    });
  },
});

export const { setMainMenuItem, setSubMenuItem, setSecondaryMenuItem, setMenuIconSvgs, setVariant, setShowPublishBar } =
  menuSlice.actions;
