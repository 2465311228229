/* eslint-disable react/jsx-props-no-spreading */
import { alpha, Box, Divider as MuiDivider, Skeleton, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { formValueSelector } from 'redux-form';
import { Forms } from '../../constants/Forms';
import { useAppSelector } from '../../hooks';
import { ColorsAndLogosFormFields } from '../../constants/FormFields';
import { ContentSkeleton } from '../library/ContentSkeleton';

const Label = styled(Typography)({
  position: 'relative',
  textTransform: 'none',
  fontSize: '11px',
  fontWeight: 400,
  color: '#FFFFFF',
  justifySelf: 'center',
});

const Menu = styled(Box)({
  width: '137px',
  borderLeft: `1px solid ${alpha('#000000', 0.12)}`,
});

const Section = styled(Stack)({
  padding: '10px',
});

const Divider = styled(MuiDivider)({
  margin: '0px 10px',
});

const Text = styled(Skeleton)({
  height: '6px',
  borderRadius: '8px',
  backgroundColor: alpha('#000000', 0.12),
});

const MenuButton = styled(Skeleton)({
  height: '23px',
  width: '55px',
  borderRadius: '20px',
  transform: 'none',
});

export const MenuPreview: React.FC<{ initializing?: boolean }> = ({ initializing = false }) => {
  const selectedTextColor = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.SelectedTextColor),
  );

  return (
    <Menu>
      <Section direction="column" spacing="9px">
        <Text variant="rectangular" animation={initializing ? undefined : false} width="47px" />
        <Text variant="rectangular" animation={initializing ? undefined : false} width="31px" />
        <Stack direction="row" spacing="8px" sx={{ ml: '-1px !important', mr: '-1px !important', py: '4px' }}>
          <Box position="relative" height="23px" width="55px">
            <MenuButton
              animation={initializing ? undefined : false}
              sx={{ position: 'absolute', backgroundColor: initializing ? undefined : selectedTextColor }}
            />
            <MenuButton
              animation={false}
              width="53px"
              height="21px"
              sx={{ position: 'absolute', top: '1px', left: '1px', backgroundColor: '#FFFFFF' }}
            />
            <ContentSkeleton initializing={initializing} sx={{ justifyItems: 'center', pt: '3px' }}>
              <Label sx={{ color: selectedTextColor }}>Button</Label>
            </ContentSkeleton>
          </Box>
          <Box position="relative" height="23px" width="55px">
            <MenuButton
              animation={initializing ? undefined : false}
              sx={{ position: 'absolute', backgroundColor: initializing ? undefined : selectedTextColor }}
            />
            {!initializing && (
              <Box sx={{ justifyItems: 'center', pt: '3px' }}>
                <Label>Button</Label>
              </Box>
            )}
          </Box>
        </Stack>
        <Text variant="rectangular" animation={initializing ? undefined : false} width="47px" />
        <Text variant="rectangular" animation={initializing ? undefined : false} width="47px" />
      </Section>
      <Divider />
      <Section direction="column" spacing="9px">
        <Text variant="rectangular" animation={initializing ? undefined : false} width="47px" />
        <Text variant="rectangular" animation={initializing ? undefined : false} width="111px" />
        <Text
          variant="rectangular"
          animation={initializing ? undefined : false}
          width="111px"
          sx={{ mb: '6px !important' }}
        />
      </Section>
      <Divider />
      <Section direction="column" spacing="9px">
        <Text variant="rectangular" animation={initializing ? undefined : false} width="47px" />
        <Text variant="rectangular" animation={initializing ? undefined : false} width="111px" />
      </Section>
    </Menu>
  );
};
