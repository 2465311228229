import { DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import React from 'react';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button } from './library/Button';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { AppState } from '../types/AppState';
import { Dialog } from './library/Dialog';
import { ImpersonationForm } from './ImpersonationForm';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { validateImpersonation } from '../ducks/currentUserSlice';

export const ImpersonationDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { loading = false, user: { name = 'this user' } = {} } = useAppSelector(
    (state: AppState) => state.currentUser.impersonation || {},
  );

  return (
    <Dialog dialogKey={Dialogs.Impersonation}>
      <DialogTitle>{t(I18nKeys.ImpersonationDialogTitle, { name })}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: t(I18nKeys.ImpersonationDialogMessage, { name }),
          }}
        />
        <ImpersonationForm />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(reset(Forms.Impersonation));
            dispatch(closeDialogFunc());
          }}
          variant="outlined"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          loading={loading}
          onClick={(): void => {
            dispatch(validateImpersonation());
            dispatch(submit(Forms.Impersonation));
          }}
          variant="contained"
        >
          {t(I18nKeys.DialogImpersonateButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
