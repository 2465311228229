import React, { useLayoutEffect } from 'react';
import { Page } from '../library/Page';
import { useAppDispatch } from '../../hooks';
import { setSearchHidden } from '../../ducks/search';
import { setClientDataType, setSelectedTable } from '../../ducks/clientDataSlice';
import { SITE_DETAIL_TABLE } from '../../constants/ClientData';
import { ClientDataType } from '../../constants/ClientDataType';

type Props = React.PropsWithChildren<{
  header?: string;
  subheader?: string;
}>;

export const SitesPage: React.FC<Props> = ({ header, subheader, children }) => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
    dispatch(setClientDataType(ClientDataType.Vendor));
    dispatch(setSelectedTable(SITE_DETAIL_TABLE));
  });

  return (
    <Page header={header} subheader={subheader}>
      {children}
    </Page>
  );
};
