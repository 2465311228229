import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import { BusinessInfoForm } from './BusinessInfoForm';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { BusinessInfoFormFields } from '../../constants/FormFields';
import { useDomainRepo } from '../../hooks/useDomainRepo';
import { getDomainFromEmail } from '../../utils/domainUtils';

export const BusinessInfo: React.FC<{ clientId: string }> = () => {
  const { refetchClientEmailDomain } = useDomainRepo();
  const {
    selectedTableData: [
      {
        name,
        address,
        city,
        state,
        zipCode,
        phoneNumber,
        companyFax,
        homeLinkUrl,
        emailAddress,
        quoteEmailFromAddressSame,
        quoteEmailFromAddress,
        quoteEmailCopyAddressSame,
        quoteEmailCopyAddress,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  useEffect(() => {
    const quoteAddress = quoteEmailFromAddressSame ? emailAddress : quoteEmailFromAddress;
    const quoteEmailFromAddressDomain = getDomainFromEmail(`${quoteAddress}`);
    if (!quoteEmailFromAddressDomain.error) {
      refetchClientEmailDomain();
    }
  }, [quoteEmailFromAddressSame, emailAddress, quoteEmailFromAddress, refetchClientEmailDomain]);

  return (
    <SitesPage header="Business Info">
      <Card sx={{ p: '24px' }} elevation={0}>
        <BusinessInfoForm
          initialValues={{
            [BusinessInfoFormFields.Name]: name || '',
            [BusinessInfoFormFields.Address]: address || '',
            [BusinessInfoFormFields.City]: city || '',
            [BusinessInfoFormFields.State]: state || '',
            [BusinessInfoFormFields.ZipCode]: zipCode || '',
            [BusinessInfoFormFields.PhoneNumber]: phoneNumber || '',
            [BusinessInfoFormFields.CompanyFax]: companyFax || '',
            [BusinessInfoFormFields.HomeLinkUrl]: homeLinkUrl || '',
            [BusinessInfoFormFields.EmailAddress]: emailAddress || '',
            [BusinessInfoFormFields.QuoteEmailFromAddressSame]:
              !!quoteEmailFromAddressSame || !quoteEmailFromAddress || quoteEmailFromAddress === emailAddress,
            [BusinessInfoFormFields.QuoteEmailFromAddress]: quoteEmailFromAddress || emailAddress || '',
            [BusinessInfoFormFields.QuoteEmailCopyAddressSame]:
              !!quoteEmailCopyAddressSame || !quoteEmailCopyAddress || quoteEmailCopyAddress === emailAddress,
            [BusinessInfoFormFields.QuoteEmailCopyAddress]: quoteEmailCopyAddress || emailAddress || '',
          }}
        />
      </Card>
    </SitesPage>
  );
};
