import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { signOut, signIn, confirmSignIn } from '@aws-amplify/auth';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import { IntegrationStatus } from '@idearoom/types';
import { AppState } from '../types/AppState';
import { config as configuration } from '../config/config';
import {
  changeGroup,
  fetchUserPreferences,
  fetchUserPreferencesComplete,
  impersonationFailed,
  initiateImpersonation,
  recordUserEvent,
  saveUserPreferences,
  saveUserPreferencesComplete,
  sendOtp,
  setCurrentUserAuthStatus,
  setCurrentUserData,
  setUserPreferences,
  signOutAction,
  signedIn,
  signedInFailed,
  signedInSuccess,
  verifyOtp,
} from '../ducks/currentUserSlice';
import {
  getTheme,
  openTour,
  setDefaultClientId,
  setGroupFilters,
  setMenuStatus,
  setPaymentIntegrationStatus,
  setSelectedClientId,
  setSelectedTabId,
  setTourSteps,
} from '../ducks/viewerSlice';
import { Dealer } from '../types/Dealer';
import { GroupData } from '../types/Group';
import { groupApi } from '../services/groupApi';
import { SystemGroups } from '../constants/SystemGroups';
import { getGroupIdFromUrl } from '../utils/clientDataUtils';
import { AppRoutes } from '../constants/AppRoutes';
import { LocalStorage } from '../constants/LocalStorage';
import { AuthStatus } from '../constants/AuthStatus';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import { getMenuIconSvgs, getTourSteps } from '../utils/viewerUtils';
import { Dialogs } from '../constants/Dialogs';
import { userApi } from '../services/userApi';
import { MUIDataGridPreferences, unknownUser } from '../types/User';
import { unknownGroup } from '../constants/Group';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { clientDataApi } from '../services/clientDataApi';
import { getConfigWithVendor } from '../utils/configuratorUtils';
import { dealerApi } from '../services/dealerApi';
import { Configurator } from '../types/Configurator';
import { referenceConfigurator } from '../constants/Configurator';
import { resetSearch } from '../ducks/search';
import { SessionStorage } from '../constants/SessionStorage';
import { defaultErrorMessage } from '../constants/Error';
import { extractErrorProps } from '../utils/errorUtils';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { UserPreference } from '../constants/User';
import { EventName } from '../analytics/AnalyticsTypes';
import { integrationApi } from '../services/integrationApi';
import { MenuStatus } from '../constants/Viewer';
import { updateDataGridState } from '../ducks/muiDataGridSlice';
import { setDateFilter, setDateRange } from '../ducks/orders';
import { canUsePayments } from '../utils/permissionUtils';
import { setMenuIconSvgs } from '../ducks/menuSlice';
import { setInitialMenuState } from './menuThunk';
import { getDefaultOrderFilter } from '../utils/orderUtils';

/* global localStorage */

const clearUserLocalStorageItems = () => {
  [
    LocalStorage.LastPricingBaseMergeCommit,
    LocalStorage.LastPricingComponentMergeCommit,
    LocalStorage.LastPricingSizeBasedMergeCommit,
    LocalStorage.HaveShownPricingBaseDeletingPriceDialog,
  ].forEach((key) => localStorage.removeItem(key));
};

export const userListener = createListenerMiddleware<AppState>();

const fetchCurrentUserData = async (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  newClient?: boolean,
  paymentIntegrationStatus?: string,
): Promise<void> => {
  const availableDealers: Dealer[] = [];
  try {
    const { groups } = await dispatch(
      groupApi.endpoints.getUserGroups.initiate(undefined, {
        forceRefetch: true,
        subscriptionOptions: { refetchOnFocus: false },
      }),
    ).unwrap();

    // If user is not in any groups, throw an error
    if (!groups) {
      throw new Error('User not associated with any groups.');
    }

    // Check that the user is a member of one of the groups or in the IdeaRoom group, if so,
    // use that group.
    const groupIdIsValid = (groupId: string | undefined): boolean =>
      groupId !== undefined &&
      (groups.some((group: GroupData) => group.groupId === groupId) ||
        groups.some((group: GroupData) => group.groupId === SystemGroups.IdeaRoom));

    // Get the group from the uri path. /portal/data/:groupId/...
    const pathGroupId = window.location.pathname.startsWith(AppRoutes.ClientData) ? getGroupIdFromUrl() : undefined;
    // Get the previously selected group from local storage.
    const previouslySelectedGroupId = localStorage.getItem(LocalStorage.SelectedGroupId);

    let selectedGroupId: string | undefined;
    if (pathGroupId && groupIdIsValid(pathGroupId)) {
      selectedGroupId = pathGroupId;
    } else if (previouslySelectedGroupId && groupIdIsValid(previouslySelectedGroupId)) {
      selectedGroupId = previouslySelectedGroupId;
    } else {
      // If the user is not a member of the group, default to the first group
      // in their list of groups. If the user is in the IdeaRoom group, default to it.
      selectedGroupId = groups.some((group: GroupData) => group.groupId === SystemGroups.IdeaRoom)
        ? SystemGroups.IdeaRoom
        : groups[0].groupId || '';
    }
    if (selectedGroupId) {
      localStorage.setItem(LocalStorage.SelectedGroupId, selectedGroupId);
    }

    const tourIndex = localStorage.getItem(LocalStorage.TourIndex) || undefined;
    if (tourIndex === undefined) {
      dispatch(setTourSteps(getTourSteps(groups)));
      dispatch(openTour());
    }

    let user;
    let group;

    try {
      ({ user = unknownUser, group = unknownGroup } = await dispatch(
        userApi.endpoints.getCurrentUserData.initiate(
          {
            groupId: selectedGroupId,
          },
          { forceRefetch: true, subscriptionOptions: { refetchOnFocus: false } },
        ),
      ).unwrap());
    } catch (e) {
      localStorage.removeItem(LocalStorage.SelectedGroupId);
      throw e;
    }

    dispatch(fetchUserPreferences());

    dispatch(setCurrentUserAuthStatus(AuthStatus.SignedIn));
    dispatch(
      setInitialMenuState({
        newClient,
        group,
      }),
    );

    let defaultClientId;
    if (group) {
      const { configurators = [] } = group;
      const [firstConfig] = configurators;
      defaultClientId = mapConfiguratorToClientId(firstConfig);

      const configuratorsWithVendor = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const config of configurators) {
        const clientId = mapConfiguratorToClientId(config);

        // eslint-disable-next-line no-await-in-loop
        const vendor = await dispatch(
          clientDataApi.endpoints.getVendorData.initiate(
            { clientId },
            { forceRefetch: true, subscriptionOptions: { refetchOnFocus: false } },
          ),
        ).unwrap();

        // eslint-disable-next-line no-await-in-loop
        const updatedConfig = (await getConfigWithVendor(config, vendor)) as Configurator;
        configuratorsWithVendor.push(updatedConfig);
      }

      // Create highlighted versions of svgs for the vendor's theme
      const [{ vendorData: { vendor: { selectedTextColor: primaryColor = '#323B4B' } = {} } = {} } = {}] =
        configuratorsWithVendor;
      dispatch(setMenuIconSvgs(await getMenuIconSvgs(primaryColor)));

      // Sort them alphabetically
      configuratorsWithVendor.sort((a: Configurator, b: Configurator) => ((a.name || '') > (b.name || '') ? 1 : -1));
      const clientIds = configuratorsWithVendor.map((configurator: Configurator) =>
        mapConfiguratorToClientId(configurator),
      );

      const { dealers = [] } = await dispatch(
        dealerApi.endpoints.getDealersByClientIds.initiate(
          {
            clientIds,
            groupId: selectedGroupId,
          },
          {
            forceRefetch: true,
            subscriptionOptions: { refetchOnFocus: false },
          },
        ),
      ).unwrap();

      availableDealers.push(
        ...dealers.filter((dealer) => dealer).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      );

      if (group.groupId === 'IdeaRoom') {
        configuratorsWithVendor.push(referenceConfigurator);
        dispatch(setSelectedClientId(referenceConfigurator.clientId));
        dispatch(setDefaultClientId(referenceConfigurator.clientId));
      } else if (!paymentIntegrationStatus || canUsePayments(user, group.groupId, paymentIntegrationStatus)) {
        dispatch(
          integrationApi.endpoints.getPaymentIntegration.initiate(
            { groupId: group.groupId, clientIds },
            {
              forceRefetch: true,
              subscriptionOptions: { refetchOnFocus: false },
            },
          ),
        )
          .unwrap()
          .then((integrationStatus) => {
            dispatch(setPaymentIntegrationStatus(integrationStatus));
          })
          .catch(() => {
            dispatch(setPaymentIntegrationStatus({ status: IntegrationStatus.Disconnected }));
          });
      }

      group = { ...group, configurators: configuratorsWithVendor };
    }

    const { groups: allGroups = [] } = await dispatch(
      groupApi.endpoints.getUserGroups.initiate(undefined, {
        forceRefetch: true,
        subscriptionOptions: { refetchOnFocus: false },
      }),
    ).unwrap();

    dispatch(setCurrentUserData({ user, group, groups: allGroups, availableDealers }));
    dispatch(setSelectedClientId(defaultClientId));
    dispatch(setDefaultClientId(defaultClientId));
    dispatch(getTheme());
    dispatch(signedInSuccess({ username: user.username, groupId: group.groupId }));
  } catch (error) {
    const { message } = error as Error;
    toast.error(message);

    dispatch(signedInFailed());
    dispatch(signOutAction());
  }
};

const recordEvent = async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>, action: AnyAction) => {
  try {
    const { type } = action;
    const { groupId, clientId, eventCategory, eventType: actionEventType } = action.payload;
    let eventType = actionEventType || type;
    if (configuration.environment.STAGE === 'production') {
      switch (type) {
        case signedInSuccess.type:
          eventType = EventName.SignIn; // eslint-disable-line no-param-reassign
          break;
        case changeGroup.type:
          eventType = EventName.Change; // eslint-disable-line no-param-reassign
          break;
        default:
          break;
      }

      dispatch(userApi.endpoints.addUserEvent.initiate({ groupId, clientId, eventCategory, eventType }));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    toast.error(errorMessage);
  }
};

userListener.startListening({
  actionCreator: signedIn,
  effect: async (action, { dispatch, getState }) => {
    const {
      signUp: { subscriptionId = '' },
      viewer: { paymentIntegrationStatus: { status: paymentIntegrationStatus } = {} },
    } = getState();

    fetchCurrentUserData(dispatch, !!subscriptionId, paymentIntegrationStatus);
  },
});

userListener.startListening({
  actionCreator: signedInSuccess,
  effect: async (action, { dispatch }) => {
    try {
      recordEvent(dispatch, action);
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: changeGroup,
  effect: async (action, { dispatch, getState }) => {
    const {
      currentUser: { impersonation: { user: impersonatedUser } = {} },
      viewer: { paymentIntegrationStatus: { status: paymentIntegrationStatus } = {} },
    } = getState();
    try {
      const groupId = action.payload;
      const availableDealers: Dealer[] = [];

      localStorage.setItem(LocalStorage.SelectedGroupId, groupId);

      let user;
      let group;

      try {
        ({ user = unknownUser, group = unknownGroup } = await dispatch(
          userApi.endpoints.getCurrentUserData.initiate(
            {
              groupId,
            },
            { forceRefetch: !!impersonatedUser, subscriptionOptions: { refetchOnFocus: false } },
          ),
        ).unwrap());
      } catch (e) {
        localStorage.removeItem(LocalStorage.SelectedGroupId);
        throw e;
      }

      let defaultClientId;
      if (group) {
        // If on the client data page, update the GroupId in the URL
        if (window.location.pathname.startsWith(AppRoutes.ClientData)) {
          navigate(`${AppRoutes.ClientData}/${groupId}`);
        }

        const { configurators = [] } = group;
        const [firstConfig] = configurators;
        defaultClientId = mapConfiguratorToClientId(firstConfig);

        const configuratorsWithVendor = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const config of configurators) {
          const clientId = mapConfiguratorToClientId(config);

          // eslint-disable-next-line no-await-in-loop
          const vendor = await dispatch(
            clientDataApi.endpoints.getVendorData.initiate(
              { clientId },
              { forceRefetch: !!impersonatedUser, subscriptionOptions: { refetchOnFocus: false } },
            ),
          ).unwrap();

          // eslint-disable-next-line no-await-in-loop
          const updatedConfig = await getConfigWithVendor(config, vendor);
          configuratorsWithVendor.push(updatedConfig);
        }

        // Create highlighted versions of svgs for the vendor's theme
        const [{ vendorData: { vendor: { selectedTextColor: primaryColor = '#323B4B' } = {} } = {} } = {}] =
          configuratorsWithVendor;
        dispatch(setMenuIconSvgs(await getMenuIconSvgs(primaryColor)));

        // Sort them alphabetically
        configuratorsWithVendor.sort((a: Configurator, b: Configurator) => ((a.name || '') > (b.name || '') ? 1 : -1));
        const clientIds = configuratorsWithVendor.map((configurator: Configurator) =>
          mapConfiguratorToClientId(configurator),
        );

        const { dealers = [] } = await dispatch(
          dealerApi.endpoints.getDealersByClientIds.initiate(
            {
              clientIds,
              groupId,
            },
            {
              forceRefetch: !!impersonatedUser,
              subscriptionOptions: { refetchOnFocus: false },
            },
          ),
        ).unwrap();

        availableDealers.push(
          ...dealers
            .filter((dealer) => dealer)
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        );

        if (group.groupId === 'IdeaRoom') {
          configuratorsWithVendor.push(referenceConfigurator);
          dispatch(setSelectedClientId(referenceConfigurator.clientId));
          dispatch(setDefaultClientId(referenceConfigurator.clientId));
        } else if (!paymentIntegrationStatus || canUsePayments(user, group.groupId, paymentIntegrationStatus)) {
          dispatch(
            integrationApi.endpoints.getPaymentIntegration.initiate(
              { groupId: group.groupId, clientIds },
              {
                forceRefetch: true,
                subscriptionOptions: { refetchOnFocus: false },
              },
            ),
          )
            .unwrap()
            .then((integrationStatus) => {
              dispatch(setPaymentIntegrationStatus(integrationStatus));
            })
            .catch(() => {
              dispatch(setPaymentIntegrationStatus({ status: IntegrationStatus.Disconnected }));
            });
        }

        group = { ...group, configurators: configuratorsWithVendor };
      }

      const { groups = [] } = await dispatch(
        groupApi.endpoints.getUserGroups.initiate(undefined, {
          forceRefetch: !!impersonatedUser,
          subscriptionOptions: { refetchOnFocus: false },
        }),
      ).unwrap();

      dispatch(resetSearch());

      dispatch(
        setInitialMenuState({
          group,
        }),
      );

      dispatch(setCurrentUserData({ user, group, groups, availableDealers }));
      dispatch(setSelectedClientId(defaultClientId));
      dispatch(setSelectedTabId(undefined));
      dispatch(setDefaultClientId(defaultClientId));
      dispatch(getTheme());
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
});

userListener.startListening({
  actionCreator: changeGroup,
  effect: async (action, { dispatch }) => {
    try {
      const groupId = action.payload;
      recordEvent(dispatch, { ...action, payload: { groupId } });
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: signOutAction,
  effect: async () => {
    try {
      await signOut();
      clearUserLocalStorageItems();

      navigate(AppRoutes.SignIn);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
});

userListener.startListening({
  actionCreator: sendOtp,
  effect: async (action) => {
    try {
      const email = action.payload;

      const { nextStep } = await signIn({
        username: email,
        options: { authFlowType: 'USER_AUTH', preferredChallenge: 'EMAIL_OTP' },
      });

      if (nextStep.signInStep !== 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE') {
        console.log('Invalid sign in step:', nextStep.signInStep); // eslint-disable-line no-console
        throw new Error('Error sending OTP');
      }
    } catch (error) {
      const { message } = error as Error;
      // Don't show error if user does not exist. This can expose a vulnerability where an attacker can check if a user exists.
      if (message !== 'User does not exist.') {
        toast.error(message);
      }
    }
  },
});

userListener.startListening({
  actionCreator: verifyOtp,
  effect: async (action, { dispatch }) => {
    try {
      const otp = action.payload;

      const { nextStep } = await confirmSignIn({ challengeResponse: otp });

      if (nextStep.signInStep === 'DONE') {
        dispatch(closeDialog());
        dispatch(signedIn());
      } else {
        throw new Error('Invalid sign in step');
      }
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
});

userListener.startListening({
  actionCreator: initiateImpersonation,
  effect: async (action, { dispatch, getState }) => {
    try {
      const userToImpersonate = action.payload;
      const { username: usernameToImpersonate } = userToImpersonate;

      const state = getState();

      const {
        currentUser: { user: { username = '' } = {} },
      } = state;

      const session =
        (await dispatch(
          userApi.endpoints.initiateImpersonation.initiate({ username, usernameToImpersonate }),
        ).unwrap()) || '';

      // Store session to be used in validation
      sessionStorage.setItem(SessionStorage.ImpersonationSession, session);

      dispatch(openDialog({ dialog: Dialogs.Impersonation }));
    } catch (error) {
      let { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      if (errorMessage === 'User does not exist in user pool') errorMessage = i18n.t(I18nKeys.UserMissingFromUserPool);

      dispatch(impersonationFailed());
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: fetchUserPreferences,
  effect: async (action, { dispatch, getState }) => {
    try {
      let { preferences } =
        (await dispatch(
          userApi.endpoints.getCurrentUserPreferences.initiate(undefined, {
            forceRefetch: true,
            subscriptionOptions: { refetchOnFocus: false },
          }),
        ).unwrap()) || {};

      if (!preferences[UserPreference.ClientDataPreferences]) {
        // Fetch previous client data preferences from local storage
        preferences = {
          ...preferences,
          [UserPreference.ClientDataPreferences]: JSON.parse(
            localStorage.getItem(LocalStorage.ClientDataPreferences) || '{}',
          ),
        };
      }

      if (preferences) {
        const { groupId = '' } = getState().currentUser.group || unknownGroup;
        const { defaultOrderFilter, defaultStartDate, defaultEndDate } = getDefaultOrderFilter(groupId);
        const { date: { filter = defaultOrderFilter, startDate = defaultStartDate, endDate = defaultEndDate } = {} } =
          preferences?.[UserPreference.OrdersPreferences] || {};

        dispatch(setMenuStatus(preferences?.[UserPreference.ProfilePreferences]?.menuStatus || MenuStatus.Open));
        dispatch(setGroupFilters(preferences?.[UserPreference.ProfilePreferences]?.groupFilters || []));
        dispatch(setDateFilter(filter));
        dispatch(setDateRange({ startDate, endDate }));
        dispatch(setUserPreferences(preferences));
      }
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    } finally {
      dispatch(fetchUserPreferencesComplete());
    }
  },
});

userListener.startListening({
  actionCreator: saveUserPreferences,
  effect: async (action, { dispatch, getState }) => {
    try {
      const { userPreference, preferences } = action.payload;
      const state = getState();
      const { currentUser: { preferences: existingPreferences = {} } = {} } = state;

      if (!Object.values(UserPreference).includes(userPreference)) throw new Error('Invalid user preference key');

      if (preferences) {
        const newPreferences = { ...existingPreferences, [userPreference]: preferences };

        const { preferences: updatedPreferences = {} } =
          (await dispatch(userApi.endpoints.saveUserPreferences.initiate({ preferences: newPreferences })).unwrap()) ||
          {};

        dispatch(setUserPreferences(updatedPreferences));
      }
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    } finally {
      dispatch(saveUserPreferencesComplete());
    }
  },
});

userListener.startListening({
  actionCreator: recordUserEvent,
  effect: async (action, { dispatch }) => {
    try {
      recordEvent(dispatch, action);
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: updateDataGridState,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const {
      currentUser: { preferences: { [UserPreference.MUIDataGrid]: muiDataGridPreferences = {} } = {} },
    } = state;
    const { dataGridKey } = action.payload;

    let { columns, pinnedColumns } = muiDataGridPreferences[dataGridKey] || {};
    if ('pinnedColumns' in action.payload) {
      ({ pinnedColumns } = action.payload);
    }
    if ('columns' in action.payload) {
      ({ columns } = action.payload);
    }

    const prefs: MUIDataGridPreferences = {
      ...muiDataGridPreferences,
      [dataGridKey]: {
        pinnedColumns,
        columns,
      },
    };

    dispatch(saveUserPreferences({ userPreference: UserPreference.MUIDataGrid, preferences: prefs }));
  },
});
