import React, { useCallback, useEffect, useState } from 'react';
import { Grid2 as Grid, Stack, FormControl, MenuItem, Collapse } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, formValueSelector, Field } from 'redux-form';
import { LeadsPopupDelay } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { LeadCaptureFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { AppState } from '../../types/AppState';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { Header } from '../library/Header';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { SelectField } from '../redux-form/SelectField';
import { InputField } from '../redux-form/InputField';
import { SitesDivider } from './SitesDivider';
import { RadioField } from '../redux-form/RadioField';
import { RadioGroupField } from '../redux-form/RadioGroupField';
import { openDialog } from '../../ducks/dialogSlice';
import { Dialogs } from '../../constants/Dialogs';
import { sanitizeInput } from '../../utils/inputUtils';

export interface FormData {
  [LeadCaptureFormFields.LeadsEnableEmailPopup]: boolean;
  [LeadCaptureFormFields.LeadsPopupDelay]: string | number | boolean;
  [LeadCaptureFormFields.LeadsPopupTitle]: string | number | boolean;
  [LeadCaptureFormFields.LeadsPopupCallToActionText]: string | number | boolean;
  [LeadCaptureFormFields.LeadsPopupMessage]: string | number | boolean;
  [LeadCaptureFormFields.LeadsPopupCollectEmail]: boolean;
  [LeadCaptureFormFields.LeadsPopupCollectPhone]: boolean;
  [LeadCaptureFormFields.LeadsPopupCollectName]: boolean;
  [LeadCaptureFormFields.LeadsPopupCollectZipCode]: boolean;
  [LeadCaptureFormFields.LeadsRequireForDesign]: number;
}

const LeadCaptureFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state: AppState) => state?.clientData);
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const [initializing, setInitializing] = useState(true);

  const leadsEnableEmailPopup = useAppSelector((state) =>
    formValueSelector(Forms.LeadCapture)(state, LeadCaptureFormFields.LeadsEnableEmailPopup),
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="24px">
        <Grid container size={12} spacing="24px" alignItems="start">
          <Grid size="grow">
            <Header initializing={initializing} subheader="Show windows to collect the buyer's contact information." />
          </Grid>
          <Grid>
            <Header
              initializing={initializing}
              subheader="Some HTML Allowed"
              onClick={() => dispatch(openDialog({ dialog: Dialogs.AllowedHtmlTags }))}
            />
          </Grid>
        </Grid>
        <Grid key={LeadCaptureFormFields.LeadsEnableEmailPopup} size={{ xs: 12, lg: 6 }}>
          <FormControl fullWidth>
            <Field
              name={LeadCaptureFormFields.LeadsEnableEmailPopup}
              component={LabeledCheckboxField}
              label="Collect buyer information before save"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(LeadCaptureFormFields.LeadsEnableEmailPopup, !!event.target.checked)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Collapse in={leadsEnableEmailPopup && !initializing} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <Grid container size={12} spacing="24px" sx={{ pl: '36px' }}>
            <Grid key={LeadCaptureFormFields.LeadsPopupDelay} size={12}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <FormControl fullWidth>
                  <Field
                    name={LeadCaptureFormFields.LeadsPopupDelay}
                    label="Delay"
                    variant="filled"
                    component={SelectField}
                    onChange={(value: any) => onChange(LeadCaptureFormFields.LeadsPopupDelay, value)}
                    margin="none"
                    slotProps={{
                      select: {
                        autoWidth: false,
                      },
                    }}
                    initializing={initializing}
                  >
                    {Object.values(LeadsPopupDelay).map((delay) => (
                      <MenuItem key={delay} value={delay}>
                        {t(`leads-popup-delay-${delay}`)}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
            <Grid key={LeadCaptureFormFields.LeadsPopupTitle} size={{ xs: 12, lg: 6 }}>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupTitle}
                  label="Title"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupTitle, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Grid>
            <Grid key={LeadCaptureFormFields.LeadsPopupCallToActionText} size={{ xs: 12, lg: 6 }}>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupCallToActionText}
                  label="Button text"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupCallToActionText, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Grid>
            <Grid key={LeadCaptureFormFields.LeadsPopupMessage} size={12}>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupMessage}
                  label="Message"
                  variant="filled"
                  component={InputField}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupMessage, sanitizeInput(event.target.value))
                  }
                  initializing={initializing}
                  multiline
                  rows={2}
                  height="79px"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
        <Grid size={12}>
          <SitesDivider initializing={initializing} />
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }}>
          <Stack spacing="16px">
            <Header initializing={initializing} header="Ask for information:" />
            <Stack spacing="8px">
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupCollectEmail}
                  component={LabeledCheckboxField}
                  label="Email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupCollectEmail, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupCollectPhone}
                  component={LabeledCheckboxField}
                  label="Phone"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupCollectPhone, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupCollectName}
                  component={LabeledCheckboxField}
                  label="Name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupCollectName, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={LeadCaptureFormFields.LeadsPopupCollectZipCode}
                  component={LabeledCheckboxField}
                  label="Zip Code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(LeadCaptureFormFields.LeadsPopupCollectZipCode, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }}>
          <Stack spacing="16px">
            <Header initializing={initializing} header="Allow user to continue:" />
            <FormControl fullWidth>
              <Field
                name={LeadCaptureFormFields.LeadsRequireForDesign}
                component={RadioGroupField}
                onChange={(value: any) => onChange(LeadCaptureFormFields.LeadsRequireForDesign, !!Number(value))}
              >
                <Stack rowGap="8px">
                  <RadioField
                    initializing={initializing}
                    value={0}
                    label="Optional"
                    color="secondary"
                    sx={{ py: '4px' }}
                  />
                  <RadioField
                    initializing={initializing}
                    value={1}
                    label="Required"
                    color="secondary"
                    sx={{ py: '4px' }}
                  />
                </Stack>
              </Field>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export const LeadCaptureForm = reduxForm<FormData>({
  form: Forms.LeadCapture,
  enableReinitialize: true,
})(LeadCaptureFormComponent);
