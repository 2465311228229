import React, { useEffect } from 'react';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Forms } from '../constants/Forms';
import { Form } from './redux-form/Form';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { ClientDataPublishFields } from '../constants/FormFields';
import { MultiselectField } from './redux-form/MultiselectField';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataType } from '../constants/ClientDataType';
import { useGetSupplierVendorsListQuery } from '../services/clientDataApi';
import { getVendorFromClientId } from '../utils/clientIdUtils';
import { useGetClientName } from '../hooks/useGetClientName';
import { unknownGroup } from '../constants/Group';
import { config } from '../config/config';
import { LabeledCheckboxField } from './redux-form/LabeledCheckboxField';
import { Environment } from '../constants/Environment';

export interface FormData {
  [ClientDataPublishFields.Description]: string;
  [ClientDataPublishFields.PublishVendors]: string[];
  [ClientDataPublishFields.PreviewedChanges]: boolean;
  [ClientDataPublishFields.CustomerIsAware]: boolean;
  [ClientDataPublishFields.VerifiedQuotes]: boolean;
}

interface CustomProps {
  onSubmit: { (values: FormData): void };
  isPublishing: boolean;
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const ClientDataPublishFormComponent: React.FC<FormProps> = ({ handleSubmit, onSubmit, isPublishing }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { clientDataType, clientId } = useAppSelector((state) => state?.clientData);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const getClientName = useGetClientName();
  const environment = config.environment.STAGE || 'development';

  const { data: supplierVendorsList = [], isFetching: isLoadingSupplierVendorsList } = useGetSupplierVendorsListQuery(
    { clientId, groupId },
    { skip: clientDataType !== ClientDataType.Supplier, refetchOnFocus: false },
  );

  useEffect(() => {
    const supplierKey = getVendorFromClientId(clientId);
    const vendorWithSameKey = supplierVendorsList.find((vendorKey) => vendorKey === supplierKey);
    if (vendorWithSameKey) {
      dispatch(change(Forms.ClientDataPublish, ClientDataPublishFields.PublishVendors, [vendorWithSameKey]));
    }
  }, [supplierVendorsList]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {environment === Environment.Production && (
        <Stack direction="column" spacing="8px">
          <Field
            disabled={isPublishing}
            name={ClientDataPublishFields.PreviewedChanges}
            component={LabeledCheckboxField}
            label={t(I18nKeys.ClientDataPublishPreviewedChanges)}
          />
          <Field
            disabled={isPublishing}
            name={ClientDataPublishFields.CustomerIsAware}
            component={LabeledCheckboxField}
            label={t(I18nKeys.ClientDataPublishCustomerIsAware)}
          />
          <Field
            disabled={isPublishing}
            name={ClientDataPublishFields.VerifiedQuotes}
            component={LabeledCheckboxField}
            label={t(I18nKeys.ClientDataPublishVerifiedQuotes)}
          />
        </Stack>
      )}

      <Field
        disabled={isPublishing}
        variant="filled"
        autoComplete="off"
        component={InputField}
        label={t(I18nKeys.ClientDataPublishDescriptionField)}
        name={ClientDataPublishFields.Description}
        multiline
        rows={3}
      />
      {clientDataType === ClientDataType.Supplier && (
        <Field
          disabled={isPublishing || isLoadingSupplierVendorsList}
          component={MultiselectField}
          label={t(I18nKeys.ClientDataPublishVendorsList)}
          name={ClientDataPublishFields.PublishVendors}
          options={supplierVendorsList}
          renderValue={(selected: string[]) =>
            selected.length === supplierVendorsList.length
              ? `All Vendors (${selected.length})`
              : selected.map((key) => getClientName(key)).join(', ')
          }
          renderItemValue={(key: string) => getClientName(key)}
        />
      )}
    </Form>
  );
};

export const ClientDataPublishForm = reduxForm<FormData, CustomProps>({
  form: Forms.ClientDataPublish,
})(ClientDataPublishFormComponent);
