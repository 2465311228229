/* eslint-disable react/jsx-props-no-spreading */
import { TooltipProps, Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';

export const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => (
  <MuiTooltip
    {...props}
    slotProps={{
      ...props.slotProps,
      tooltip: {
        sx: {
          border: '1px solid #DEDEDE',
          borderRadius: '32px',
          boxShadow: '0px 0px 4px 2px #FFFFFF99',
          py: '6px',
          px: '24px',
          backgroundColor: '#FFFFFF',
          fontSize: '16px',
          fontWeight: '500',
          color: '#323B4B',
        },
      },
    }}
  >
    {children}
  </MuiTooltip>
);
