/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CheckboxProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { LabeledCheckboxField as SalesViewLabeledCheckboxField } from '../library/LabeledCheckboxField';

type Props = WrappedFieldProps &
  CheckboxProps & {
    label: string;
    initializing?: boolean;
    startAdornment?: React.ReactNode;
    ideaRoomOnly?: boolean;
    beta?: boolean;
  };

export const LabeledCheckboxField: React.FC<Props> = (props: Props) => {
  const { input } = props;
  return <SalesViewLabeledCheckboxField checked={!!input.value} {...props} {...input} />;
};
