import MaterialDialog from '@mui/material/Dialog';
import React from 'react';
import {
  buttonClasses,
  dialogActionsClasses,
  dialogContentClasses,
  dialogContentTextClasses,
  dialogTitleClasses,
  Stack,
} from '@mui/material';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog } from '../../ducks/dialogSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

/**
 * Connect Material dialog to open dialog state to reduce boilerplate.
 */
export const Dialog: React.FC<
  React.PropsWithChildren<{
    id?: string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullWidth?: boolean;
    disableEnforceFocus?: boolean;
    dialogKey: Dialogs;
    onClosed?(): void;
    disableClose?: boolean;
    onCloseAnimationComplete?(): void;
    scroll?: 'body' | 'paper';
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  }>
> = ({
  id,
  children,
  fullWidth,
  maxWidth = 'xs',
  disableEnforceFocus,
  dialogKey,
  onClosed,
  disableClose,
  onCloseAnimationComplete,
  scroll,
  onKeyDown,
}) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(({ dialog: { key } }) => key === dialogKey);

  const handleOnClose = (): void => {
    if (disableClose) return;
    dispatch(closeDialog());

    if (onClosed) {
      onClosed();
    }
  };

  return (
    <MaterialDialog
      id={id}
      key={dialogKey}
      open={open}
      scroll={scroll}
      onClose={handleOnClose}
      fullWidth={fullWidth}
      disableEnforceFocus={disableEnforceFocus}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: '8px',
          p: '24px',
          [`& .${dialogTitleClasses.root}`]: {
            p: '0px',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
            fontWeight: '600',
          },
          [`& .${dialogContentClasses.root}`]: {
            display: 'grid',
            rowGap: '16px',
            p: '0px',
            // Add some extra spacing between actions and content if the last child is a form
            'form:last-child': { mb: '8px' },
            [`& .${dialogContentTextClasses.root}`]: {
              color: '#323B4B',
            },
          },
          [`& .${dialogActionsClasses.root}`]: {
            p: '0px',
            columnGap: '12px',
            [`& .${buttonClasses.root}`]: { margin: '0px' },
          },
        },
      }}
      maxWidth={maxWidth}
      onKeyDown={onKeyDown}
      TransitionProps={{
        onExited: () => {
          if (onCloseAnimationComplete) onCloseAnimationComplete();
        },
      }}
    >
      <Stack direction="column" spacing="16px" sx={{ overflow: 'hidden' }}>
        {children}
      </Stack>
    </MaterialDialog>
  );
};
