import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, FormControl, Grid2 as Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, Field, formValueSelector } from 'redux-form';
import { PricingVisibility } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { VisibilityFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SelectField } from '../redux-form/SelectField';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { PriceField } from '../redux-form/PriceField';
import { isIdeaRoomUser } from '../../utils/userUtils';
import { unknownUser } from '../../types/User';

export interface FormData {
  [VisibilityFormFields.PricingVisibility]: string | number | boolean;
  [VisibilityFormFields.PriceRangePercentage]: string | number | boolean;
  [VisibilityFormFields.PricingEnableClientManaged]: boolean;
  [VisibilityFormFields.PricingEnableClientUpdates]: boolean;
  [VisibilityFormFields.PricingVisibilityStaging]: string | number | boolean;
}

const VisibilityFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state) => state.clientData);
  const ideaRoomUser = useAppSelector((state) => isIdeaRoomUser(state.currentUser.user || unknownUser));
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [initializing, setInitializing] = useState(true);

  const pricingVisibility = useAppSelector((state) =>
    formValueSelector(Forms.Visibility)(state, VisibilityFormFields.PricingVisibility),
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean, percentage = false) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value, percentage }]));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="16px">
        <Grid container spacing="24px" size={12}>
          <Grid container spacing="24px" size={{ xs: 12, lg: 6 }}>
            <Grid key={VisibilityFormFields.PricingVisibility} size={12}>
              <FormControl fullWidth>
                <Field
                  name={VisibilityFormFields.PricingVisibility}
                  label="Pricing visibility"
                  variant="filled"
                  component={SelectField}
                  onChange={(value: any) => onChange(VisibilityFormFields.PricingVisibility, value)}
                  margin="none"
                  slotProps={{
                    select: {
                      autoWidth: false,
                    },
                  }}
                  initializing={initializing}
                >
                  {Object.values(PricingVisibility).map((visibility) => (
                    <MenuItem key={visibility} value={visibility}>
                      {t(`sites-pricing-visibility-${visibility}`)}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
            <Collapse
              in={
                [
                  PricingVisibility.ProtectedRange,
                  PricingVisibility.SummarizedRange,
                  PricingVisibility.SummarizedRangeHideLineItems,
                ].includes(pricingVisibility) && !initializing
              }
              timeout="auto"
              unmountOnExit
              sx={{ width: '100%' }}
            >
              <Grid key={VisibilityFormFields.PriceRangePercentage} size={12}>
                <FormControl fullWidth>
                  <Field
                    name={VisibilityFormFields.PriceRangePercentage}
                    label="Price range percentage"
                    variant="filled"
                    component={PriceField}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(VisibilityFormFields.PriceRangePercentage, event.target.value, true)
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                    initializing={initializing}
                  />
                </FormControl>
              </Grid>
            </Collapse>
          </Grid>
          {ideaRoomUser && (
            <Grid key={VisibilityFormFields.PricingVisibilityStaging} size={{ xs: 12, lg: 6 }}>
              <FormControl fullWidth>
                <Field
                  name={VisibilityFormFields.PricingVisibilityStaging}
                  label="Pricing visibility on preview"
                  variant="filled"
                  component={SelectField}
                  onChange={(value: any) => onChange(VisibilityFormFields.PricingVisibilityStaging, value)}
                  margin="none"
                  initializing={initializing}
                >
                  {Object.values(PricingVisibility)
                    .filter(
                      (visibility) =>
                        ![
                          PricingVisibility.ProtectedRange,
                          PricingVisibility.SummarizedRange,
                          PricingVisibility.SummarizedRangeHideLineItems,
                        ].includes(visibility),
                    )
                    .map((visibility) => (
                      <MenuItem key={visibility} value={visibility}>
                        {t(`sites-pricing-visibility-${visibility}`)}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {ideaRoomUser && (
          <Grid container size={{ xs: 12, lg: 6 }}>
            <Stack spacing="16px" sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <Field
                  name={VisibilityFormFields.PricingEnableClientManaged}
                  component={LabeledCheckboxField}
                  label="Seller-managed pricing"
                  ideaRoomOnly
                  beta
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(VisibilityFormFields.PricingEnableClientManaged, !!event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  name={VisibilityFormFields.PricingEnableClientUpdates}
                  component={LabeledCheckboxField}
                  label="Seller-managed component pricing"
                  ideaRoomOnly
                  beta
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(VisibilityFormFields.PricingEnableClientUpdates, event.target.checked)
                  }
                  initializing={initializing}
                />
              </FormControl>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export const VisibilityForm = reduxForm<FormData>({
  form: Forms.Visibility,
  enableReinitialize: true,
})(VisibilityFormComponent);
