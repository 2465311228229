import { MainMenuItems } from './MainMenuItems';

export enum AppRoutes {
  ClientData = '/portal/data',
  ClientSignUp = '/client/sign-up',
  ForgotPassword = '/password/new',
  Home = '/portal/home',
  Groups = '/portal/groups',
  Leads = '/portal/leads',
  Orders = '/portal/orders',
  Dealers = '/portal/dealers',
  Integrations = '/portal/integrations',
  Portal = '/portal',
  Payments = '/portal/payments',
  Pricing = '/portal/pricing',
  Profile = '/portal/profile',
  Analyze = '/portal/analyze',
  Team = '/portal/team',
  Reports = '/portal/reports',
  Settings = '/portal/settings',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Sites = '/portal/sites',
}

export const anonymousRoutes = [AppRoutes.ClientSignUp, AppRoutes.ForgotPassword, AppRoutes.SignUp];

export const routesWithConfirmation = [AppRoutes.Pricing, AppRoutes.Sites];

export enum AppRoutesWithConfirmation {
  Pricing = '/portal/pricing',
  Sites = '/portal/sites',
}

export enum RouteKeys {
  ClientDataKey = 'client-data',
  DealersKey = 'dealers',
  HomeKey = 'home',
  GroupKey = 'group',
  GroupsKey = 'groups',
  IntegrationsKey = 'integrations',
  LeadsKey = 'leads',
  OrdersKey = 'orders',
  PaymentsKey = 'payments',
  PricingKey = 'pricing',
  ProfileKey = 'profile',
  ReportsKey = 'reports',
  SettingsKey = 'settings',
  SitesKey = 'sites',
  AnalyzeKey = 'analyze',
  TeamKey = 'team',
}

export const MenuItemRouteMap: { [key in MainMenuItems]: AppRoutes | string } = {
  [MainMenuItems.ClientData]: AppRoutes.ClientData,
  [MainMenuItems.Dealers]: AppRoutes.Dealers,
  [MainMenuItems.Groups]: AppRoutes.Groups,
  [MainMenuItems.Home]: AppRoutes.Home,
  [MainMenuItems.Integrations]: AppRoutes.Integrations,
  [MainMenuItems.Leads]: AppRoutes.Leads,
  [MainMenuItems.Orders]: AppRoutes.Orders,
  [MainMenuItems.Analyze]: AppRoutes.Analyze,
  [MainMenuItems.Payments]: AppRoutes.Payments,
  [MainMenuItems.Pricing]: AppRoutes.Pricing,
  [MainMenuItems.Reports]: AppRoutes.Reports,
  [MainMenuItems.Settings]: AppRoutes.Settings,
  [MainMenuItems.Sites]: AppRoutes.Sites,
  [MainMenuItems.Sales]: AppRoutes.Leads,
  [MainMenuItems.Team]: AppRoutes.Team,
  [MainMenuItems.Help]: '',
};
