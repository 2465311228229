import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { reset, submit } from 'redux-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { Forms } from '../../constants/Forms';
import { closeDialog, closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ClientDataCreateBranchForm, FormData } from './ClientDataCreateBranchForm';
import { LoadingButton } from '../LoadingButton';
import { useAddBranchMutation } from '../../services/clientDataApi';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { ClientDataCreateBranchFields } from '../../constants/FormFields';
import { setClientDataBranch } from '../../ducks/clientDataSlice';
import { BRANCH_LABELS, KeyboardKeys } from '../../constants/ClientData';
import { unknownGroup } from '../../constants/Group';
import { ClientDataCreateBranchDialogOptions } from '../../types/DialogState';

export const ClientDataCreateBranchDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientDataType, clientId } = useAppSelector((state) => state?.clientData);
  const { key: dialogKey, options } = useAppSelector((state) => state?.dialog);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const branch = (options as ClientDataCreateBranchDialogOptions)?.branch || ClientDataBranch.Hotfix;

  const [addBranch, { isLoading }] = useAddBranchMutation({
    fixedCacheKey: 'revert',
  });

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === KeyboardKeys.Enter && dialogKey === Dialogs.ClientDataCreateBranch) {
        dispatch(submit(Forms.ClientDataCreateBranch));
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Dialog dialogKey={Dialogs.ClientDataCreateBranch} maxWidth="sm">
      <DialogTitle>{t(I18nKeys.ClientDataCreateBranchDialogTitle, { branch: t(BRANCH_LABELS[branch]) })}</DialogTitle>
      <DialogContent>
        <ClientDataCreateBranchForm
          branch={branch}
          isSubmitting={isLoading}
          onSubmit={(formData: FormData) => {
            addBranch({
              branch,
              dataType: clientDataType,
              clientId,
              groupId,
              description: formData[ClientDataCreateBranchFields.Description],
            })
              .unwrap()
              .then(() => {
                dispatch(setClientDataBranch(branch));
                dispatch(closeDialog());
              });
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(reset(Forms.ClientDataCreateBranch));
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            dispatch(submit(Forms.ClientDataCreateBranch));
          }}
          loading={isLoading}
          color="primary"
        >
          {t(I18nKeys.DialogCreateButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
