export enum Forms {
  BannersAndMessages = 'BANNERS_AND_MESSAGES',
  BulkUser = 'BULK_USER',
  BusinessInfo = 'BUSINESS_INFO',
  ClientDataNote = 'CLIENT_DATA_NOTE',
  ClientDataCreateBranch = 'CLIENT_DATA_CREATE_BRANCH',
  ClientDataPublish = 'CLIENT_DATA_PUBLISH',
  ClientDataPreview = 'CLIENT_DATA_PREVIEW',
  ClientSignUp = 'CLIENT_SIGN_UP',
  Configurator = 'CONFIGURATOR',
  ControlsAndFeatures = 'CONTROLS_AND_FEATURES',
  ColorsAndLogos = 'COLORS_AND_LOGOS',
  Dealer = 'DEALER',
  DealerAdvanced = 'DEALER_ADVANCED',
  Documents = 'DOCUMENTS',
  ForgotPassword = 'FORGOT_PASSWORD',
  Floorplan = 'FLOORPLAN',
  Impersonation = 'IMPERSONATION',
  IntegrationsAdyenForm = 'INTEGRATION_ADYEN_FORM',
  IntegrationsFacebookPixelForm = 'INTEGRATION_FACEBOOK_PIXEL_FORM',
  IntegrationsGoogleAnalyticsForm = 'INTEGRATION_GOOGLE_ANALYTICS_FORM',
  I18n = 'I18N',
  LeadCapture = 'LEAD_CAPTURE',
  Password = 'PASSWORD',
  Preview = 'PREVIEW',
  PricingBaseAddSize = 'PRICING_BASE_ADD_SIZE',
  PricingSheetName = 'PRICING_SHEET_NAME',
  PricingComponentEdit = 'PRICING_COMPONENT_EDIT',
  Profile = 'PROFILE',
  Preferences = 'PREFERENCES',
  Publish = 'PUBLISH',
  Language = 'LANGUAGE',
  SceneEnvironment = 'SCENE_ENVIRONMENT',
  SetupPaymentIntegration = 'SETUP_PAYMENT_INTEGRATION',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  User = 'USER',
  Visibility = 'VISIBILITY',
  WhatsNew = 'WHATS_NEW',
}
