import { Alert, DialogActions, DialogContent, Link, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BRANCH_LABELS, MergeStatus } from '../../constants/ClientData';
import { mapClientIdToConfiguratorAndVendor } from '../../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../../utils/vendorUtils';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { config } from '../../config/config';

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: { padding: '0px 8px 8px 8px' },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statusIcon: {
    width: '30px',
    height: '30px',
    marginRight: '15px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
  successColor: {
    color: theme.palette.success.dark,
  },
  infoColor: {
    color: 'rgba(160, 160, 160, 1)',
  },
}));

const ProductionLinkUrl: React.FC<{ url?: string; children?: React.ReactNode }> = ({ url, children }) => (
  <Link href={url} target="_blank" rel="noreferrer noopener" underline="hover">
    {children}
  </Link>
);

export const SiteDetailPublishResultDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.clientData);
  const { publishMergeResult } = useAppSelector((state) => state.vendorData);
  const {
    data,
    error,
    isSuccess: isPublishSuccess,
  } = publishMergeResult || { data: undefined, error: undefined, isSuccess: false };

  const { configurator = '', vendor = '' } = clientId ? mapClientIdToConfiguratorAndVendor(clientId) : {};
  const [productionUrl, setProductionUrl] = useState('');
  const { vendorData: { locale = '', productionURL: vendorUrl = '' } = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });

  useEffect(() => {
    if (clientId !== undefined && clientId !== '' && data && !isLoadingVendorData) {
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, vendorUrl);
      const configuratorUrl = `${url}${url.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2`;
      setProductionUrl(configuratorUrl);
    }
  }, [clientId, data, isLoadingVendorData]);

  const additionalBranchesMergesWithConflicts =
    data?.additionalBranchMerges?.filter(({ status }) => status === MergeStatus.Conflicts) || [];

  const mergeSuccessful = isPublishSuccess && data && data.mainMerge.status === MergeStatus.Succeed;
  const errorMessage = error && 'data' in error ? error.data.replace(URL_REGEX, '') : '';
  const errorCellLink = error && 'data' in error ? (error.data.match(URL_REGEX) || [])[0] : undefined;
  const environment = config.environment.STAGE || 'development';

  return (
    <Dialog dialogKey={Dialogs.SiteDetailPublishResult} maxWidth="lg">
      <DialogContent className={classes.dialogContent}>
        {mergeSuccessful && <CheckCircleIcon className={`${classes.statusIcon} ${classes.successColor}`} />}
        {!mergeSuccessful && <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />}
        <div>
          {mergeSuccessful && (
            <>
              <Typography>
                <Trans
                  i18nKey={I18nKeys.ClientDataPublishSuccess as string}
                  components={{ a: <ProductionLinkUrl url={productionUrl} /> }}
                  context={environment}
                />
              </Typography>
              {data &&
                data.additionalBranchMerges?.length &&
                (additionalBranchesMergesWithConflicts.length > 0 ? (
                  <Typography>
                    <Trans
                      i18nKey={I18nKeys.ClientDataPublishSuccessConflicts as string}
                      values={{
                        branches: additionalBranchesMergesWithConflicts.map(({ branch }) => t(BRANCH_LABELS[branch])),
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography>
                    <Trans i18nKey={I18nKeys.ClientDataPublishSuccessNoConflicts as string} />
                  </Typography>
                ))}
            </>
          )}
          {!mergeSuccessful && (
            <>
              {error && (
                <Alert severity="error" icon={false}>
                  <code>{errorMessage}</code>
                  {errorCellLink && (
                    <>
                      <br />
                      <a href={errorCellLink}>Cell with error</a>
                    </>
                  )}
                </Alert>
              )}
              {data && data.mainMerge.status === MergeStatus.Conflicts && (
                <Typography>
                  <Trans i18nKey={I18nKeys.ClientDataPublishUnresolvedMergeConflicts as string} />
                </Typography>
              )}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {additionalBranchesMergesWithConflicts.length > 0 && (
          <Button color={mergeSuccessful ? 'success' : 'error'}>{t(I18nKeys.ClientDataPublishResolveNowButton)}</Button>
        )}
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color={mergeSuccessful ? 'success' : 'error'}
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
