import { DialogTitle, DialogContent, DialogActions, Typography, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog } from './library/Dialog';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog } from '../ducks/dialogSlice';
import { AppState } from '../types/AppState';
import { SelectedFilterValues } from '../types/CustomFilter';
import { SearchInput } from './SearchInput';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { resetFilter, setFilter, setGroupFilters } from '../ducks/viewerSlice';
import { Button } from './library/Button';
import { LabeledCheckboxField } from './library/LabeledCheckboxField';

interface DispatchProps {
  handleFilterSelectionChange(selectedFilterValues: SelectedFilterValues[]): void;
  applyDialogFilter(onFilterFunction?: any): void;
  closeFilterDialog(): void;
}

interface FilterSelectionType {
  key: string;
  name: string;
  color?: string;
}

interface StateProps {
  filterType: string;
  availableValues: FilterSelectionType[];
  selectedFilterValues: any[];
}

interface OwnProps {
  onApply?(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterSelectionDialogComponent: React.FC<Props> = ({
  filterType,
  availableValues,
  selectedFilterValues,
  handleFilterSelectionChange,
  applyDialogFilter,
  closeFilterDialog,
  onApply,
}: Props) => {
  const { t } = useTranslation();
  const [filteredAvailableValues, setFilteredAvailableValues] = React.useState<FilterSelectionType[]>(availableValues);
  const [searchTerm, setDialogSearchTerm] = React.useState<string>('');

  useEffect(() => {
    // don't bother searching until there's at least 2 characters
    if (searchTerm.length < 2) {
      setFilteredAvailableValues(availableValues);
      return;
    }

    const tests = [
      (availableValue: FilterSelectionType): boolean => stringToLowerIncludes(availableValue.name, searchTerm),
      (availableValue: FilterSelectionType): boolean => stringToLowerIncludes(availableValue.key, searchTerm),
    ];

    setFilteredAvailableValues(availableValues.filter((availableValue) => tests.some((test) => test(availableValue))));
  }, [availableValues, searchTerm]);

  const resetSearchTerm = (): void => setDialogSearchTerm('');

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.target;
    if (selectedFilterValues.some((selectedValue) => selectedValue.key === value)) {
      handleFilterSelectionChange(
        selectedFilterValues.some((selectedValue) => selectedValue.key === 'all')
          ? selectedFilterValues.filter((selectedValue) => selectedValue.key !== value && selectedValue.key !== 'all')
          : selectedFilterValues.filter((selectedValue) => selectedValue.key !== value),
      );
    } else {
      handleFilterSelectionChange([...selectedFilterValues, { key: value, name }]);
    }
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.target;
    handleFilterSelectionChange(
      selectedFilterValues.some((selectedValue) => selectedValue.key === value)
        ? []
        : [
            ...availableValues.map((availableValue) => ({ key: availableValue.key, name: availableValue.name })),
            { key: value, name },
          ],
    );
  };

  return (
    <Dialog dialogKey={Dialogs.FilterSelection} onClosed={(): void => closeFilterDialog()}>
      <DialogTitle>{`${t(I18nKeys.FilterTitlePrefix)} ${filterType}`}</DialogTitle>
      <DialogContent>
        <Stack spacing="16px" sx={{ mb: '8px' }}>
          {availableValues.length > 10 && (
            <SearchInput
              searchTerm={searchTerm}
              startAdornment={<SearchIcon />}
              variant="outlined"
              onClearClick={resetSearchTerm}
              onChange={setDialogSearchTerm}
            />
          )}
          <LabeledCheckboxField
            checked={selectedFilterValues.some((selectedValue) => selectedValue.key === 'all')}
            name="all"
            value="all"
            onChange={handleSelectAllChange}
            label={t(I18nKeys.FilterSelectAll)}
          />
          {filteredAvailableValues.map((filter: any) => (
            <LabeledCheckboxField
              checked={selectedFilterValues.some((selectedValue) => selectedValue.key === filter.key)}
              name={filter.name}
              value={filter.key}
              onChange={handleSelectionChange}
              key={filter.key}
              label={
                (filter.color && (
                  <Stack direction="row" spacing="8px">
                    <TurnedInIcon key={`${filter.name}-icon`} style={{ color: filter.color }} />
                    <Typography>{` ${filter.name}`}</Typography>
                  </Stack>
                )) ||
                filter.name
              }
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={(): void => closeFilterDialog()} variant="outlined">
          {t(I18nKeys.DialogCloseButton)}
        </Button>
        <Button
          onClick={(): void => {
            applyDialogFilter(onApply);
          }}
          variant="contained"
        >
          {t(I18nKeys.DialogFilterButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleFilterSelectionChange: (selectedFilterValues: SelectedFilterValues[]): void => {
    dispatch(setFilter(selectedFilterValues));
  },
  applyDialogFilter: (onFilterFunction?: any): void => {
    dispatch(setGroupFilters());
    if (onFilterFunction) {
      dispatch(onFilterFunction());
    }
    dispatch(closeDialog());
  },
  closeFilterDialog: (): void => {
    dispatch(closeDialog());
    dispatch(resetFilter());
  },
});

const mapStateToProps = ({
  viewer: { filterType, availableFilterValues, selectedFilterValues },
}: AppState): StateProps => {
  const availableValues: any = availableFilterValues;

  return {
    filterType,
    availableValues: availableValues
      .map((value: any) => {
        const val = typeof value === 'string' ? { key: value, name: value } : value;
        const { vendor, key, email, name, color } = val;
        return {
          key: vendor || key || email || name,
          name: name || email || vendor || key,
          color,
        };
      })
      .sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      }),
    selectedFilterValues,
  };
};

export const FilterSelectionDialog = connect(mapStateToProps, mapDispatchToProps)(FilterSelectionDialogComponent);
