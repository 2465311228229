import { AppBar, Tabs, Tab, useMediaQuery, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreferencesFormFields, ProfileFormFields } from '../../constants/FormFields';
import { I18nKeys } from '../../constants/I18nKeys';
import { PasswordForm } from './PasswordForm';
import { PreferencesForm } from './PreferencesForm';
import { ProfileForm } from './ProfileForm';
import { useAppSelector } from '../../hooks';
import { UserPreference } from '../../constants/User';
import { unknownUser } from '../../types/User';
import { isIdeaRoomUser } from '../../utils/userUtils';
import { SalesViewUILayout } from '../../constants/Viewer';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: '1rem',
  },
  rootGrid: {
    padding: '3%',
    flex: 1,
  },
}));

enum ProfileComponentTabs {
  General = 'general',
  Preferences = 'preferences',
  Password = 'password',
}

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = React.useState(ProfileComponentTabs.General);

  const {
    user = unknownUser,
    preferences: {
      [UserPreference.ProfilePreferences]: {
        [PreferencesFormFields.Theme]: editorTheme = '',
        [PreferencesFormFields.MiniMap]: editorMiniMap = false,
      } = {},
    } = {},
  } = useAppSelector((state) => state?.currentUser);
  const { firstName = '', lastName = '', email = '', phone = '' } = user;

  const initialProfileValues = {
    [ProfileFormFields.FirstName]: firstName,
    [ProfileFormFields.LastName]: lastName,
    [ProfileFormFields.Email]: email,
    [ProfileFormFields.PhoneNumber]: phone,
  };

  const initialPreferencesValues = {
    [PreferencesFormFields.Layout]: SalesViewUILayout.MapNeeded,
    [PreferencesFormFields.Theme]: editorTheme,
    [PreferencesFormFields.MiniMap]: editorMiniMap,
  };

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  const tabs = [
    {
      label: t(I18nKeys.ProfileProfileTab),
      value: ProfileComponentTabs.General,
      id: 'profile-tab',
      hidden: false,
    },
    {
      label: t(I18nKeys.ProfilePreferencesTab),
      value: ProfileComponentTabs.Preferences,
      id: 'preferences-tab',
      hidden: !isIdeaRoomUser(user),
    },
    {
      label: t(I18nKeys.ProfilePasswordTab),
      value: ProfileComponentTabs.Password,
      id: 'password-tab',
      hidden: false,
    },
  ];

  return (
    <div>
      <AppBar position="static" color="inherit">
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: ProfileComponentTabs): void => {
            setTab(newValue);
          }}
          aria-label="settings-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {tabs.map(
            (appBarTab) =>
              !appBarTab.hidden && (
                <Tab
                  key={appBarTab.id}
                  label={appBarTab.label}
                  value={appBarTab.value}
                  id={appBarTab.id}
                  aria-controls={appBarTab.id}
                />
              ),
          )}
        </Tabs>
      </AppBar>
      <Grid container className={classes.rootGrid}>
        {tab === ProfileComponentTabs.General && <ProfileForm initialValues={initialProfileValues} />}
        {tab === ProfileComponentTabs.Preferences && <PreferencesForm initialValues={initialPreferencesValues} />}
        {tab === ProfileComponentTabs.Password && <PasswordForm />}
      </Grid>
    </div>
  );
};
