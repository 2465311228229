import {
  Box,
  Container,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { A } from 'hookrouter';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Form } from './redux-form/Form';
import { AppRoutes } from '../constants/AppRoutes';
import { SignInFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { required } from '../utils/reduxFormUtils';
import { IdeaRoomLogo } from './IdeaRoomLogo';
import { InputField } from './redux-form/InputField';
import { useAppDispatch, useAppSelector } from '../hooks';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { OneTimePasswordDialog } from './OneTimePasswordDialog';
import { Button } from './library/Button';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: theme.spacing(4),
  },
}));

interface DispatchProps {
  onSubmit(data: FormData): Promise<AnyAction>;
}

interface FormData {
  [SignInFormFields.Email]: string;
  [SignInFormFields.Password]: string;
}

type Props = DispatchProps & InjectedFormProps<FormData>;

const extractParamFromQueryString = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const SignInComponent: React.FC<Props> = ({ error, change, handleSubmit, onSubmit, submitting }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { signingIn } = useAppSelector((state) => state.currentUser);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSendOtpClick = () => {
    dispatch(openDialog({ dialog: Dialogs.SendOtp }));
  };

  useEffect(() => {
    // Attempt to pull the email and password from the url params
    const email = extractParamFromQueryString('email');
    const password = extractParamFromQueryString('password');

    // Populate the form with the email and password from the url params if they exist
    if (email) change(SignInFormFields.Email, email);
    if (password) change(SignInFormFields.Password, password);

    // If the email and password are populated, submit the form
    if (email && password && !signingIn) {
      onSubmit({ [SignInFormFields.Email]: email, [SignInFormFields.Password]: password });
    }
  }, []);

  return (
    <Container sx={{ height: '100%', maxWidth: '100% !important', backgroundColor: '#F2F4F5' }}>
      <Paper className={classes.paper} elevation={0}>
        <Stack spacing="24px">
          <Box>
            <IdeaRoomLogo height="100px" />
          </Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Typography component="p">{t(I18nKeys.SignInMessage, 'Sign in with your email and password')}</Typography>

            <Field
              autoComplete="username"
              validate={required}
              name={SignInFormFields.Email}
              component={InputField}
              label={`${t(I18nKeys.FieldEmail, 'Email')}*`}
              disabled={signingIn}
            />

            <Stack spacing="8px">
              <Field
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                validate={required}
                name={SignInFormFields.Password}
                component={InputField}
                label={`${t(I18nKeys.FieldPassword, 'Password')}*`}
                disabled={signingIn}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <A style={{ justifyItems: 'end' }} href={AppRoutes.ForgotPassword}>
                <Typography sx={{ textDecoration: 'underline', opacity: 0.6 }}>
                  {t(I18nKeys.ForgotPasswordLink, 'Forgot your password?')}
                </Typography>
              </A>
            </Stack>

            {!!error && (
              <FormHelperText error sx={{ alignSelf: 'center' }}>
                {error}
              </FormHelperText>
            )}
          </Form>
          <Stack spacing="16px">
            <Box>
              <Button
                variant="contained"
                type="submit"
                loading={submitting || signingIn || false}
                disabled={submitting || signingIn}
                sx={{ width: '262px', backgroundColor: (theme) => `${theme.palette.primary.main} !important` }}
              >
                {t(I18nKeys.SignInButton, 'Sign in')}
              </Button>
            </Box>

            <Divider sx={{ margin: '10px 0', fontFamily: 'Inter, sans-serif' }}>or</Divider>

            <Box>
              <Button
                variant="outlined"
                onClick={handleSendOtpClick}
                loading={submitting || signingIn || false}
                disabled={submitting || signingIn}
                sx={{ width: '262px' }}
              >
                {t(I18nKeys.SendOtpButton, 'Send One Time Password')}
              </Button>
            </Box>
          </Stack>
        </Stack>

        <OneTimePasswordDialog />
      </Paper>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.SignIn}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

export const SignIn = reduxForm<FormData>({ form: Forms.SignIn })(
  connect(undefined, mapDispatchToProps)(SignInComponent),
);
