/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TextFieldProps, FilledInputProps, InputLabelProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { InputField as SalesViewInputField } from '../library/InputField';

type Props = WrappedFieldProps &
  TextFieldProps & {
    hideHelperText?: boolean;
    errorWithoutTouch?: boolean;
    initializing?: boolean;
    height?: string;
    slotProps?: {
      input?: Partial<FilledInputProps>;
      inputLabel?: Partial<InputLabelProps>;
    };
  };

export const InputField = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    input,
    hideHelperText = false,
    errorWithoutTouch = false,
    meta: { touched, error },
    ...customProps
  } = props;

  return (
    <SalesViewInputField
      ref={ref}
      error={!!((touched || errorWithoutTouch) && error)}
      helperText={(touched || errorWithoutTouch) && !hideHelperText && error}
      {...input}
      {...customProps}
    />
  );
});
