/* eslint-disable react/jsx-props-no-spreading */
import { alpha, AppBar, Box, Card, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { formValueSelector } from 'redux-form';
import { Forms } from '../../constants/Forms';
import { useAppSelector } from '../../hooks';
import { ColorsAndLogosFormFields } from '../../constants/FormFields';
import { DEFAULT_SCENE_KEY } from '../../types/SceneEnvironment';
import { WatermarkPreview } from './WatermarkPreview';
import { ContentSkeleton } from '../library/ContentSkeleton';
import { MenuPreview } from './MenuPreview';

const Header = styled(Typography)({
  width: 'fit-content',
  fontWeight: 500,
  color: alpha('#000000', 0.87),
});

const PreviewCard = styled(Card)({
  height: '285px',
  width: '100%',
  border: `1px solid ${alpha('#000000', 0.12)}`,
  display: 'flex',
  flexDirection: 'column',
});

const ContactBar = styled(AppBar)({
  justifyContent: 'center',
  height: '28px',
});

const LogoBar = styled(AppBar)({
  justifyContent: 'center',
  height: '37px',
  padding: '2.5px 16px 2.5px 8px',
  borderBottom: `1px solid ${alpha('#000000', 0.12)}`,
});

const Label = styled(Typography)({
  fontSize: '11px',
  fontWeight: 400,
  color: '#FFFFFF',
});

const Background = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  objectFit: 'cover',
  objectPosition: 'center bottom',
  width: '100%',
  height: '100%',
});

export const SitesPreview: React.FC<{ initializing?: boolean }> = ({ initializing = false }) => {
  const sceneEnvironments = useAppSelector((state) => state.settings.sceneEnvironment);

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const contactBarColor = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.ContactBarColor),
  );
  const contactBarTextColor = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.ContactBarTextColor),
  );
  const logoBarColor = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.LogoBarColor),
  );
  const logoBarTextColor = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.LogoBarTextColor),
  );
  const logoUrl = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.LogoUrl),
  );
  const defaultSceneEnvironment = useAppSelector((state) =>
    formValueSelector(Forms.ColorsAndLogos)(state, ColorsAndLogosFormFields.DefaultSceneEnvironment),
  );

  const sceneEnvironmentUrl = useMemo(() => {
    if (initializing || defaultSceneEnvironment === DEFAULT_SCENE_KEY) return undefined;
    return sceneEnvironments.find((s) => s.key === defaultSceneEnvironment)?.previewUrl;
  }, [defaultSceneEnvironment, sceneEnvironments, initializing]);

  return (
    <Stack spacing="24px" sx={{ mb: mobileView ? '13px' : '0px' }}>
      <Box sx={{ height: '56px', alignContent: 'center', alignSelf: 'center' }}>
        <ContentSkeleton initializing={initializing}>
          <Header>Site preview</Header>
        </ContentSkeleton>
      </Box>
      <PreviewCard elevation={0}>
        <ContentSkeleton variant="rectangular" initializing={initializing} width="100%">
          <ContactBar position="static" elevation={0} sx={{ backgroundColor: contactBarColor }}>
            <Label sx={{ color: contactBarTextColor, alignSelf: 'center' }}>Contact Bar</Label>
          </ContactBar>
        </ContentSkeleton>
        <LogoBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: initializing ? '#FFFFFF' : logoBarColor,
          }}
        >
          <Stack direction="row" alignItems="center">
            {!initializing && logoUrl && <Box component="img" src={logoUrl} alt="Logo" sx={{ height: '23px' }} />}
            <Box flex={1} />
            <ContentSkeleton initializing={initializing}>
              <Label sx={{ color: logoBarTextColor }}>Logo bar</Label>
            </ContentSkeleton>
          </Stack>
        </LogoBar>
        <Box sx={{ flex: 1, display: 'flex' }}>
          <Stack direction="row" sx={{ height: '100%', width: '100%' }}>
            <ContentSkeleton variant="rectangular" initializing={initializing} flex={1} height="100%" width="100%">
              <Box flex={1} position="relative" sx={{ backgroundColor: '#F5F5F5' }}>
                {sceneEnvironmentUrl && <Background src={sceneEnvironmentUrl} alt="Background" />}
                <WatermarkPreview />
                <Label position="relative" sx={{ mt: '57px', color: alpha('#000000', 0.87), justifySelf: 'center' }}>
                  Background
                </Label>
              </Box>
            </ContentSkeleton>
            <MenuPreview initializing={initializing} />
          </Stack>
        </Box>
      </PreviewCard>
    </Stack>
  );
};
