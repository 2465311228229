/* eslint-disable @typescript-eslint/no-empty-function */
import { AppBar, CircularProgress, Stack, styled, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { usePath } from 'hookrouter';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Edit } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { mainMenuWidth, MenuStatus, publishBarHeight, secondaryMenuWidth } from '../constants/Viewer';
import { useSharedDeleteBranchMutation, useSharedPublishClientDataMutation } from '../services/clientDataApi';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { unknownGroup } from '../constants/Group';
import { MenuVariant } from '../constants/Menu';
import { Button } from './library/Button';
import { I18nKeys } from '../constants/I18nKeys';
import {
  onRevert as onRevertFunc,
  onPreview as onPreviewFunc,
  onPublish as onPublishFunc,
} from '../utils/publishBarUtils';
import { AppRoutes } from '../constants/AppRoutes';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { PricingTab } from '../constants/Pricing';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';

const BranchLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.secondary.main,
  alignContent: 'center',
}));

const useStyles = makeStyles<
  Theme,
  { menuStatus: MenuStatus; showPublishBar: boolean; mobileView: boolean; variant: MenuVariant }
>((theme: Theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    height: ({ showPublishBar }) => `${showPublishBar ? publishBarHeight : 0}px`,
    width: ({ menuStatus, mobileView, variant }) =>
      `calc(100% - ${
        mobileView || menuStatus === MenuStatus.Closed ? 0 : mainMenuWidth[variant] + secondaryMenuWidth[variant]
      }px)`,
    transition: theme.transitions.create(['height', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: theme.zIndex.drawer - 1,
    borderTop: '1px solid #D7DBDF',
  },
}));

export const PublishBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const path = usePath();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    selectedTabId,
    selectedClientId,
    menuStatus,
    selectedPricingTabId: pricingTab,
  } = useAppSelector((state) => state.viewer);
  const {
    clientId: clientDataClientId,
    clientDataType,
    clientDataBranch,
    isCreatingBranch,
  } = useAppSelector((state) => state.clientData);
  const { variant, showPublishBar } = useAppSelector((state) => state.menu);
  const { groupId } = useAppSelector((state) => state.currentUser.group || unknownGroup);
  const layout = useAppSelector(
    (state) => state.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  const [publishClientData, { isLoading: isPublishing = false }] = useSharedPublishClientDataMutation();
  const [deleteBranch] = useSharedDeleteBranchMutation();

  const { vendorData, isLoadingVendorData, activeBranches } = useClientDataRepo({
    useVendorData: true,
    useBranches: true,
  });

  const branch = (() => {
    let b: ClientDataBranch = ClientDataBranch.SiteDetail;
    if (path.startsWith(AppRoutes.Pricing)) {
      switch (pricingTab) {
        case PricingTab.Base:
          b = ClientDataBranch.Pricing;
          break;
        case PricingTab.Component:
          b = ClientDataBranch.ClientUpdate;
          break;
        case PricingTab.Surcharge:
          b = ClientDataBranch.PricingSurcharge;
          break;
        case PricingTab.SizeBased:
          b = ClientDataBranch.PricingSizeBased;
          break;
        default:
          break;
      }
    }
    return b;
  })();

  const branchLabel = (() => {
    if (path.startsWith(AppRoutes.Sites)) return 'Site';
    return '';
  })();

  const clientId = path.startsWith(AppRoutes.Sites)
    ? clientDataClientId
    : getClientIdFromClientSupplier(selectedTabId || selectedClientId);
  const disabled =
    (isCreatingBranch || isPublishing || !activeBranches.find((b) => b.branchType === branch)) &&
    (path.startsWith(AppRoutes.Pricing) || clientDataBranch !== branch);

  const { selectedPricingSurcharge, pricingSurchargeVaryConditions } = usePricingRepo({
    useSelectedPricingSurcharge: true,
    usePricingSurchargeVaryConditions: true,
  });

  const classes = useStyles({ menuStatus, showPublishBar, mobileView, variant });

  const onRevert = useCallback(() => {
    onRevertFunc(
      {
        clientId,
        groupId,
        clientDataType,
        pricingTab,
        path,
        activeBranches,
        branch,
        layout,
      },
      deleteBranch,
      dispatch,
      t,
    );
  }, [clientId, groupId, clientDataType, pricingTab, path, activeBranches, branch, layout, deleteBranch, dispatch, t]);

  const onPreview = useCallback(() => {
    onPreviewFunc(
      {
        clientId,
        vendorData,
        clientDataBranch,
        pricingTab,
        selectedPricingSurcharge,
        pricingSurchargeVaryConditions,
        path,
      },
      dispatch,
      t,
    );
  }, [
    clientId,
    vendorData,
    isLoadingVendorData,
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge,
    pricingSurchargeVaryConditions,
    path,
    dispatch,
    t,
  ]);

  const onPublish = useCallback(() => {
    onPublishFunc(
      {
        clientId,
        groupId,
        clientDataType,
        clientDataBranch,
        pricingTab,
        selectedPricingSurcharge,
        pricingSurchargeVaryConditions,
        path,
      },
      publishClientData,
      dispatch,
      t,
    );
  }, [
    clientId,
    groupId,
    clientDataType,
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge,
    pricingSurchargeVaryConditions,
    path,
    publishClientData,
    dispatch,
    t,
  ]);

  return (
    <AppBar id="publish-bar-id" position="fixed" color="inherit" className={classes.appBar} elevation={0}>
      <Toolbar style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Stack direction="row" spacing="40px">
          {branchLabel && (
            <Stack direction="row" spacing="8px" alignItems="center">
              <Edit color="secondary" sx={{ width: '24px', height: '24px', p: '2px' }} />
              <BranchLabel>{branchLabel}</BranchLabel>
            </Stack>
          )}
          <Stack direction="row" spacing="16px">
            <Button
              id={`publish-bar-${t(I18nKeys.RevertButton)}`}
              variant="outlined"
              disabled={disabled}
              color="secondary"
              onClick={onRevert}
              loading={isPublishing}
              loadingIndicator={<CircularProgress size="22px" />}
            >
              {t(I18nKeys.RevertButton)}
            </Button>
            <Button
              id={`publish-bar-${t(I18nKeys.PreviewButton)}`}
              variant="outlined"
              disabled={disabled}
              color="secondary"
              onClick={onPreview}
              loading={isPublishing}
              loadingIndicator={<CircularProgress size="22px" />}
            >
              {t(I18nKeys.PreviewButton)}
            </Button>
            <Button
              id={`publish-bar-${t(I18nKeys.PublishButton)}`}
              variant="contained"
              disabled={disabled}
              color="primary"
              onClick={onPublish}
              loading={isPublishing}
              loadingIndicator={<CircularProgress size="22px" />}
            >
              {t(I18nKeys.PublishButton)}
            </Button>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
