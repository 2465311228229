import { UILayoutVersion } from '@idearoom/types';
import { Vendor } from '../types/VendorData';
import { SiteDetailField } from '../types/ClientData';
import { I18nKeys } from './I18nKeys';

export const environmentToken = '$env';

export enum SiteDetailDataFields {
  /* Basic Information Fields */
  Name = 'name',
  Address = 'address',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  PhoneNumber = 'phoneNumber',
  CompanyFax = 'companyFax',
  HomeLinkUrl = 'homeLinkUrl',
  /* Configurator Settings Fields */
  DisableSharing = 'disableSharing',
  DisableAR = 'disableAR',
  EnableDesignAutoSave = 'enableDesignAutoSave',
  HideCenterComponentsButton = 'hideCenterComponentsButton',
  /* Email and Contact Fields */
  EmailAddress = 'emailAddress',
  QuoteEmailFromAddress = 'quoteEmailFromAddress',
  QuoteEmailFromAddressSame = 'quoteEmailFromAddressSame',
  QuoteEmailCopyAddress = 'quoteEmailCopyAddress',
  QuoteEmailCopyAddressSame = 'quoteEmailCopyAddressSame',
  CheckoutEmailFormat = 'checkoutEmailFormat',
  QuoteEmailFormat = 'quoteEmailFormat',
  SaveEmailFormat = 'saveEmailFormat',
  PrintEmailFormat = 'printEmailFormat',
  SendEmailsTo = 'sendEmailsTo',
  /* Logos Fields */
  LogoUrl = 'logoUrl',
  ShowLogoOnMobile = 'showLogoOnMobile',
  EmailLogoUrl = 'emailLogoUrl',
  Watermark = 'watermark',
  WatermarkText = 'watermarkText',
  WatermarkPosition = 'watermarkPosition',
  ShowLogoWatermarkUnderEstimate = 'showLogoWatermarkUnderEstimate',
  /* Layout and Color Fields */
  Layout = 'layout',
  CaptureInfoWithCapSave = 'captureInfoWithCapSave',
  CapSaveRequirePhone = 'capSaveRequirePhone',
  DefaultSceneEnvironment = 'defaultSceneEnvironment',
  DisableSceneEnvironmentChange = 'disableSceneEnvironmentChange',
  LogoBarColor = 'logoBarColor',
  LogoBarTextColor = 'logoBarTextColor',
  ContactBarColor = 'contactBarColor',
  ContactBarTextColor = 'contactBarTextColor',
  SelectedTextColor = 'selectedTextColor',
  YourEstimateTabColor = 'yourEstimateTabColor',
  YourEstimateTextColor = 'yourEstimateTextColor',
  /* Floorplan Fields */
  EmailFloorPlan = 'emailFloorPlan',
  EmailFloorplanImageScale = 'emailFloorplanImageScale',
  UseFloorPlanGridDimensions = 'useFloorPlanGridDimensions',
  UseFloorPlanGridDimensionsLegend = 'useFloorPlanGridDimensionsLegend',
  UseFloorplanFullPage = 'useFloorplanFullPage',
  FloorplanLabelingMethod = 'floorplanLabelingMethod',
  FloorplanGridStyle = 'floorplanGridStyle',
  FloorplanMeasurementArrowheadStyle = 'floorplanMeasurementArrowheadStyle',
  FloorplanWallThickness = 'floorplanWallThickness',
  FloorplanTextSize = 'floorplanTextSize',
  FloorplanHeight = 'floorplanHeight',
  FloorplanWidth = 'floorplanWidth',
  FloorplanShowSideTexts = 'floorplanShowSideTexts',
  FloorplanShowFlowerbox = 'floorplanShowFlowerbox',
  FloorplanShowPorchBoardDirection = 'floorplanShowPorchBoardDirection',
  FloorplanComponentStyle = 'floorplanComponentStyle',
  FloorplanFrontDirection = 'floorplanFrontDirection',
  /* Information Panel Fields */
  ShowInformationalPanel = 'showInformationalPanel',
  InformationPanelTitle = 'informationPanelTitle',
  InformationPanelSubtitle = 'informationPanelSubtitle',
  InformationPanelMessage = 'informationPanelMessage',
  /* Promotion Panel Fields */
  PromotionalBannerType = 'promoBannerType',
  PromotionalBannerDesktopMessage1 = 'desktopPromo1',
  PromotionalBannerMobileMessage1 = 'mobilePromo1',
  PromotionalBannerDesktopMessage2 = 'desktopPromo2',
  PromotionalBannerMobileMessage2 = 'mobilePromo2',
  DeliveryMessageType = 'deliveryMessageType',
  DeliveryMessage = 'deliveryMessage',
  InstallationMessageType = 'installationMessageType',
  InstallationMessage = 'installationMessage',
  EnableClientManagedLeadsForm = 'enableClientManagedLeadsForm',
  LeadsEnableEmailPopup = 'leadsEnableEmailPopup',
  LeadsRequireForDesign = 'leadsRequireForDesign',
  LeadsPopupDelay = 'leadsPopupDelay',
  LeadsPopupTitle = 'leadsPopupTitle',
  LeadsPopupMessage = 'leadsPopupMessage',
  LeadsPopupCallToActionText = 'leadsPopupCallToActionText',
  LeadsPopupCollectEmail = 'leadsPopupCollectEmail',
  LeadsPopupCollectPhone = 'leadsPopupCollectPhone',
  LeadsPopupCollectName = 'leadsPopupCollectName',
  LeadsPopupCollectZipCode = 'leadsPopupCollectZipCode',
  /* Pricing Fields */
  PricingVisibility = 'pricingVisibility',
  PricingVisibilityStaging = 'pricingVisibilityStaging',
  PriceRangePercentage = 'priceRangePercentage',
  PricingEnableClientManaged = 'pricingEnableClientManaged',
  PricingEnableClientUpdates = 'pricingEnableClientUpdates',
  /* Advanced Fields */
  AuthenticationProvider = 'authenticationProvider',
  SalesViewGroupId = 'salesViewGroupId',
  SupplierDatasetOptOut = 'supplierDatasetOptOut',
}

export enum WatermarkPositions {
  None = 'none',
  Left = 'left',
  Right = 'right',
  CenterNotRotated = 'center-not-rotated',
  Center = 'center',
  GroundEdges = 'ground-edges',
  CenterTextNotRotated = 'center-text-not-rotated',
  CenterText = 'center-text',
}

export enum AuthenticationProvider {
  Email = 'Email',
  Cognito = 'Cognito',
  OIDC = 'OIDC',
}

export enum LeadsPopupDelay {
  NoTimeDelay = 'no-time-delay',
  AfterThirtySeconds = 'after-thirty-seconds',
  AfterOneMinute = 'after-one-minute',
}

export const leadsPopupDelayOptions = [
  {
    key: LeadsPopupDelay.NoTimeDelay,
    label: I18nKeys.LeadsPopupDelayNoTimeDelay,
  },
  {
    key: LeadsPopupDelay.AfterThirtySeconds,
    label: I18nKeys.LeadsPopupDelayAfterThirtySeconds,
  },
  {
    key: LeadsPopupDelay.AfterOneMinute,
    label: I18nKeys.LeadsPopupDelayAfterOneMinute,
  },
];

export const authenticationProviders = [
  {
    key: AuthenticationProvider.Email,
    label: I18nKeys.AuthenticationProviderEmail,
  },
  {
    key: AuthenticationProvider.Cognito,
    label: I18nKeys.AuthenticationProviderCognito,
  },
  {
    key: AuthenticationProvider.OIDC,
    label: I18nKeys.AuthenticationProviderOIDC,
    isIdeaRoomOnly: true,
  },
];

/**
 * Detailed - Full pricing visibility
 * EmailOnly - Pricing is visible only on the email
 * Summarized - Show totals, but hides line item prices
 * SummarizedRange - Show range, but hides line item prices
 * SummarizedHideLineItems - Show totals, but hides line items completely
 * SummarizedRangeHideLineItems - Show range, but hides line items completely
 * SummarizedWithProtectedLineItems - Summarized pricing is shown on the configurator. Full pricing is shown when logged into SalesTool.
 * Protected - Hide totals and line item prices if no sales rep. is logged in
 * ProtectedRange - Pricing is visible as a range, hides line item prices. Full pricing is shown when logged into SalesTool
 * Hidden - Hide totals and line item prices
 * HiddenSiteSummarizedEmail
 * HiddenSiteSummarizedSiteHideLineItemsEmail
 */
export enum PricingVisibility {
  Detailed = 'detailed',
  EmailOnly = 'email-only',
  Summarized = 'summarized',
  SummarizedRange = 'summarized-range',
  SummarizedHideLineItems = 'summarized-hide-line-items',
  SummarizedRangeHideLineItems = 'summarized-range-hide-line-items',
  SummarizedWithProtectedLineItems = 'summarized-with-protected-line-items',
  Protected = 'protected',
  ProtectedRange = 'protected-range',
  Hidden = 'hidden',
  HiddenSiteSummarizedEmail = 'hidden-site-summarized-email',
  HiddenSiteSummarizedSiteHideLineItemsEmail = 'hidden-site-summarized-hide-line-items-email',
  SystemDefined = 'system-defined',
}

export enum SitesMessageType {
  Standard = 'standard',
  Custom = 'custom',
}

export const pricingVisibilityOptions = [
  {
    key: PricingVisibility.SystemDefined,
    value: '',
    label: 'System Defined',
  },
  {
    key: PricingVisibility.Detailed,
    label: 'Detailed',
  },
  {
    key: PricingVisibility.Summarized,
    label: 'Summarized',
  },
  {
    key: PricingVisibility.SummarizedRange,
    label: 'Summarized Range',
  },
  {
    key: PricingVisibility.SummarizedHideLineItems,
    label: 'Summarized (hide line items)',
  },
  {
    key: PricingVisibility.SummarizedRangeHideLineItems,
    label: 'Summarized Range (hide line items)',
  },
  {
    key: PricingVisibility.SummarizedWithProtectedLineItems,
    label: 'Summarized (protected line items)',
  },
  {
    key: PricingVisibility.Protected,
    label: 'Protected',
  },
  {
    key: PricingVisibility.ProtectedRange,
    label: 'Protected Range',
  },
  {
    key: PricingVisibility.Hidden,
    label: 'Hidden',
  },
  {
    key: PricingVisibility.EmailOnly,
    label: 'Email only',
  },
  {
    key: PricingVisibility.HiddenSiteSummarizedEmail,
    label: 'Hidden (site summarized email)',
  },
  {
    key: PricingVisibility.HiddenSiteSummarizedSiteHideLineItemsEmail,
    label: 'Hidden (site summarized hide line items email)',
  },
];

/**
 * Summary (default)
 * Invoice
 */
export enum EmailFormat {
  Summary = 'summary',
  Invoice = 'invoice',
}

export enum SendEmailTo {
  None = 'none',
  Default = 'default',
  SupplierAndDealer = 'supplier-and-dealer',
  DealerAndBuyerOnly = 'dealer-and-buyer-only',
}

export enum FloorplanLabelingMethod {
  LabelsInLegend = 'labels-in-legend',
  LabelsOnDiagram = 'labels-on-diagram',
}

export enum FloorplanGridStyle {
  Solid = 'solid',
  Dotted = 'dotted',
  None = 'none',
}

export enum FloorplanMeasurementArrowheadStyle {
  Oblique = 'oblique',
  OpenArrow = 'open-arrow',
}

export enum FloorplanComponentStyle {
  Classic = 'classic',
  Version2 = 'version-2',
}

export enum FloorplanFrontDirection {
  Bottom = 'bottom',
  Left = 'left',
}

export const sitesText: any = {
  revertConfirmationTitle: I18nKeys.SiteTextRevertConfirmationTitle,
  revertConfirmationBody: I18nKeys.SiteTextRevertConfirmationBody,
  publishConfirmationTitle: I18nKeys.SiteTextPublishConfirmationTitle,
  publishConfirmationBody: I18nKeys.SiteTextPublishConfirmationBody,
  previewInstructions: I18nKeys.SiteTextPreviewInstructions,
  publishInstructions: I18nKeys.SiteTextPublishInstructions,
};

export const colorsFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.LogoBarColor]: {
    i18nKey: I18nKeys.SitesColorsLogoBarColor,
  },
  [SiteDetailDataFields.LogoBarTextColor]: {
    i18nKey: I18nKeys.SitesColorsLogoBarTextColor,
  },
  [SiteDetailDataFields.ContactBarColor]: {
    i18nKey: I18nKeys.SitesColorsContactBarColor,
  },
  [SiteDetailDataFields.ContactBarTextColor]: {
    i18nKey: I18nKeys.SitesColorsContactBarTextColor,
    props: {
      defaultValue: '#FFFFFF',
    },
  },
  [SiteDetailDataFields.SelectedTextColor]: {
    i18nKey: I18nKeys.SitesColorsSelectedTextColor,
  },
  [SiteDetailDataFields.YourEstimateTabColor]: {
    i18nKey: I18nKeys.SitesColorsYourEstimateTabColor,
    hidden: (vendor: Vendor) => vendor[SiteDetailDataFields.Layout] === UILayoutVersion.Modern,
  },
  [SiteDetailDataFields.YourEstimateTextColor]: {
    i18nKey: I18nKeys.SitesColorsYourEstimateTextColor,
    hidden: (vendor: Vendor) => vendor[SiteDetailDataFields.Layout] === UILayoutVersion.Modern,
    props: {
      defaultValue: '#FFFFFF',
    },
  },
};

export const emailFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.EmailAddress]: {
    i18nKey: I18nKeys.SitesEmailContactEmailField,
  },
  [SiteDetailDataFields.QuoteEmailFromAddress]: {
    i18nKey: I18nKeys.SitesEmailEmailReplyToSameField,
  },
  [SiteDetailDataFields.QuoteEmailFromAddressSame]: {
    i18nKey: I18nKeys.SitesEmailEmailReplyToSameCheckbox,
  },
  [SiteDetailDataFields.QuoteEmailCopyAddress]: {
    i18nKey: I18nKeys.SitesEmailEmailCopySameField,
  },
  [SiteDetailDataFields.QuoteEmailCopyAddressSame]: {
    i18nKey: I18nKeys.SitesEmailEmailCopySameCheckbox,
  },
  [SiteDetailDataFields.CheckoutEmailFormat]: {
    i18nKey: I18nKeys.SitesEmailCheckoutEmailFormatField,
  },
  [SiteDetailDataFields.QuoteEmailFormat]: {
    i18nKey: I18nKeys.SitesEmailQuoteEmailFormatField,
  },
  [SiteDetailDataFields.SaveEmailFormat]: {
    i18nKey: I18nKeys.SitesEmailSaveEmailFormatField,
  },
  [SiteDetailDataFields.PrintEmailFormat]: {
    i18nKey: I18nKeys.SitesEmailPrintEmailFormatField,
  },
  [SiteDetailDataFields.SendEmailsTo]: {
    i18nKey: I18nKeys.SitesEmailSendEmailsToField,
  },
};

export const basicInformationFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.Name]: {
    i18nKey: I18nKeys.SitesBasicInformationNameInputField,
  },
  [SiteDetailDataFields.Address]: {
    i18nKey: I18nKeys.SitesBasicInformationAddressInputField,
  },
  [SiteDetailDataFields.City]: {
    i18nKey: I18nKeys.SitesBasicInformationCityInputField,
    props: {
      sm: 6,
    },
  },
  [SiteDetailDataFields.State]: {
    i18nKey: I18nKeys.SitesBasicInformationStateInputField,
    props: {
      sm: 3,
    },
  },
  [SiteDetailDataFields.ZipCode]: {
    i18nKey: I18nKeys.SitesBasicInformationZipCodeInputField,
    props: {
      sm: 3,
    },
  },
  [SiteDetailDataFields.PhoneNumber]: {
    i18nKey: I18nKeys.SitesBasicInformationPhoneNumberInputField,
    props: {
      sm: 6,
    },
  },
  [SiteDetailDataFields.CompanyFax]: {
    i18nKey: I18nKeys.SitesBasicInformationFaxNumberInputField,
    props: {
      sm: 6,
    },
  },
  [SiteDetailDataFields.HomeLinkUrl]: {
    i18nKey: I18nKeys.SitesBasicInformationHomeLinkInputField,
  },
};

export const configuratorSettingsFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.DisableSharing]: {
    i18nKey: I18nKeys.SitesDisableSharing,
    checkbox: true,
  },
  [SiteDetailDataFields.DisableAR]: {
    i18nKey: I18nKeys.SitesDisableAR,
    checkbox: true,
  },
  [SiteDetailDataFields.EnableDesignAutoSave]: {
    i18nKey: I18nKeys.SitesEnableDesignAutoSave,
    checkbox: true,
  },
  [SiteDetailDataFields.HideCenterComponentsButton]: {
    i18nKey: I18nKeys.SitesHideCenterComponentsButton,
    checkbox: true,
  },
};

export const floorplanFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.EmailFloorPlan]: {
    i18nKey: I18nKeys.SitesFloorplanEmailFloorPlanField,
    props: {
      xs: 12,
    },
  },
  [SiteDetailDataFields.EmailFloorplanImageScale]: {
    i18nKey: I18nKeys.SitesFloorplanEmailFloorplanImageScaleField,
    disabled: (vendor: Vendor) => !vendor[SiteDetailDataFields.EmailFloorPlan],
  },
  [SiteDetailDataFields.UseFloorplanFullPage]: {
    i18nKey: I18nKeys.SitesFloorplanUseFloorplanFullPageField,
    disabled: (vendor: Vendor) => !vendor[SiteDetailDataFields.EmailFloorPlan],
  },
  [SiteDetailDataFields.UseFloorPlanGridDimensions]: {
    i18nKey: I18nKeys.SitesFloorplanUseFloorPlanGridDimensionsField,
  },
  [SiteDetailDataFields.UseFloorPlanGridDimensionsLegend]: {
    i18nKey: I18nKeys.SitesFloorplanUseFloorPlanGridDimensionsLegendField,
  },
  [SiteDetailDataFields.FloorplanShowSideTexts]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanShowSideTextsField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: true,
    },
  },
  [SiteDetailDataFields.FloorplanShowFlowerbox]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanShowFlowerboxField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
  },
  [SiteDetailDataFields.FloorplanShowPorchBoardDirection]: {
    i18nKey: I18nKeys.SitesFloorplanShowPorchBoardDirectionField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
  },
  [SiteDetailDataFields.FloorplanLabelingMethod]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanLabelingMethodField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: FloorplanLabelingMethod.LabelsInLegend,
    },
  },
  [SiteDetailDataFields.FloorplanGridStyle]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanGridStyleField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: FloorplanGridStyle.Solid,
    },
  },
  [SiteDetailDataFields.FloorplanMeasurementArrowheadStyle]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanMeasurementArrowheadStyleField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: FloorplanMeasurementArrowheadStyle.Oblique,
    },
  },
  [SiteDetailDataFields.FloorplanComponentStyle]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanComponentStyleField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: FloorplanComponentStyle.Classic,
    },
  },
  [SiteDetailDataFields.FloorplanFrontDirection]: {
    i18nKey: I18nKeys.SitesFloorplanFrontDirectionField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: FloorplanFrontDirection.Left,
    },
  },
  [SiteDetailDataFields.FloorplanWidth]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanWidthField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: 240,
    },
  },
  [SiteDetailDataFields.FloorplanHeight]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanHeightField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: 260,
    },
  },
  [SiteDetailDataFields.FloorplanWallThickness]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanWallThicknessField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: 4,
    },
  },
  [SiteDetailDataFields.FloorplanTextSize]: {
    i18nKey: I18nKeys.SitesFloorplanFloorplanTextSizeField,
    hidden: (_vendor: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      !isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean, selectedGroupId: string) =>
      isIdeaRoomUser && selectedGroupId !== 'WeatherKingPortableBuildings',
    props: {
      defaultValue: 14,
    },
  },
};

export const informationPanelFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.ShowInformationalPanel]: {
    i18nKey: I18nKeys.SitesInformationPanelShowInfoPanel,
  },
  [SiteDetailDataFields.InformationPanelTitle]: {
    i18nKey: I18nKeys.SitesInformationPanelTitle,
    disabled: (vendor: Vendor) => !vendor.showInformationalPanel,
    props: {
      xs: 6,
    },
  },
  [SiteDetailDataFields.InformationPanelSubtitle]: {
    i18nKey: I18nKeys.SitesInformationPanelSubtitle,
    disabled: (vendor: Vendor) => !vendor.showInformationalPanel,
    props: {
      xs: 6,
    },
  },
  [SiteDetailDataFields.InformationPanelMessage]: {
    i18nKey: I18nKeys.SitesInformationPanelMessage,
    disabled: (vendor: Vendor) => !vendor.showInformationalPanel,
  },
};

export const leadsFormPanelFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.EnableClientManagedLeadsForm]: {
    i18nKey: I18nKeys.EnableClientManagedLeadsForm,
    checkbox: true,
    props: {
      xs: 12,
    },
  },
  [SiteDetailDataFields.LeadsEnableEmailPopup]: {
    i18nKey: I18nKeys.SitesLeadsEnableEmailPopup,
    checkbox: true,
    props: {
      xs: 12,
    },
  },
  [SiteDetailDataFields.LeadsPopupDelay]: {
    i18nKey: I18nKeys.SitesLeadsPopupDelay,
    props: {
      xs: 6,
      defaultValue: LeadsPopupDelay.AfterThirtySeconds,
    },
  },
  [SiteDetailDataFields.LeadsPopupTitle]: {
    i18nKey: I18nKeys.SitesLeadsPopupTitle,
    props: {
      xs: 6,
      defaultValue: I18nKeys.LeadsPopupDefaultTitle,
    },
  },
  [SiteDetailDataFields.LeadsPopupCallToActionText]: {
    i18nKey: I18nKeys.SitesLeadsPopupCallToActionText,
    props: {
      xs: 6,
      defaultValue: I18nKeys.LeadsPopupDefaultCallToActionText,
    },
  },
  [SiteDetailDataFields.LeadsPopupMessage]: {
    i18nKey: I18nKeys.SitesLeadsPopupMessage,
    props: {
      xs: 12,
      defaultValue: I18nKeys.LeadsPopupDefaultMessage,
    },
  },
  [SiteDetailDataFields.LeadsRequireForDesign]: {
    i18nKey: I18nKeys.SitesLeadsRequireForDesign,
    checkbox: true,
    props: {
      xs: 6,
    },
  },
  [SiteDetailDataFields.LeadsPopupCollectEmail]: {
    i18nKey: I18nKeys.SitesLeadsPopupCollectEmail,
  },
  [SiteDetailDataFields.LeadsPopupCollectPhone]: {
    i18nKey: I18nKeys.SitesLeadsPopupCollectPhone,
  },
  [SiteDetailDataFields.LeadsPopupCollectName]: {
    i18nKey: I18nKeys.SitesLeadsPopupCollectName,
  },
  [SiteDetailDataFields.LeadsPopupCollectZipCode]: {
    i18nKey: I18nKeys.SitesLeadsPopupCollectZipCode,
  },
};

export const promotionalBannerFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.PromotionalBannerType]: {
    i18nKey: I18nKeys.SitesPromotionalBannerTitle,
    radio: true,
  },
  [SiteDetailDataFields.PromotionalBannerDesktopMessage1]: {
    i18nKey: I18nKeys.SitesPromotionalBannerDesktopMessage,
    props: {
      defaultValue: '',
    },
    disabled: (vendor: Vendor) => vendor[SiteDetailDataFields.PromotionalBannerType] !== SitesMessageType.Custom,
  },
  [SiteDetailDataFields.PromotionalBannerMobileMessage1]: {
    i18nKey: I18nKeys.SitesPromotionalBannerMobileMessage,
    props: {
      defaultValue: '',
    },
    disabled: (vendor: Vendor) => vendor[SiteDetailDataFields.PromotionalBannerType] !== SitesMessageType.Custom,
  },
  [SiteDetailDataFields.PromotionalBannerDesktopMessage2]: {
    i18nKey: I18nKeys.SitesPromotionalBannerDesktopMessage,
    props: {
      defaultValue: '',
    },
    hidden: (vendor: Vendor) =>
      vendor[SiteDetailDataFields.PromotionalBannerType] !== SitesMessageType.Custom ||
      vendor[SiteDetailDataFields.PromotionalBannerDesktopMessage1] === null,
  },
  [SiteDetailDataFields.PromotionalBannerMobileMessage2]: {
    i18nKey: I18nKeys.SitesPromotionalBannerMobileMessage,
    props: {
      defaultValue: '',
    },
    hidden: (vendor: Vendor) =>
      vendor[SiteDetailDataFields.PromotionalBannerType] !== SitesMessageType.Custom ||
      vendor[SiteDetailDataFields.PromotionalBannerDesktopMessage1] === null,
  },
};

export const deliveryMessageFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.DeliveryMessageType]: {
    i18nKey: I18nKeys.SitesDeliveryMessageTitle,
    radio: true,
  },
  [SiteDetailDataFields.DeliveryMessage]: {
    i18nKey: I18nKeys.SitesDeliveryMessageTitle,
    props: {
      defaultValue: '',
    },
    disabled: (vendor: Vendor) => vendor[SiteDetailDataFields.DeliveryMessageType] !== SitesMessageType.Custom,
  },
};

export const installationMessageFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.InstallationMessageType]: {
    i18nKey: I18nKeys.SitesInstallationMessageTitle,
    radio: true,
  },
  [SiteDetailDataFields.InstallationMessage]: {
    i18nKey: I18nKeys.SitesInstallationMessageTitle,
    props: {
      defaultValue: '',
    },
    disabled: (vendor: Vendor) => vendor[SiteDetailDataFields.InstallationMessageType] !== SitesMessageType.Custom,
  },
};

export const advancedSectionFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.AuthenticationProvider]: {
    i18nKey: I18nKeys.SitesAdvancedAuthenticationProviderField,
    props: {
      options: authenticationProviders,
      defaultValue: AuthenticationProvider.Email,
    },
  },
  [SiteDetailDataFields.SalesViewGroupId]: {
    i18nKey: I18nKeys.SitesAdvancedAuthenticationProviderField,
    hidden: () => true,
    props: {
      defaultValue: null,
    },
  },
  [SiteDetailDataFields.SupplierDatasetOptOut]: {
    i18nKey: I18nKeys.SitesAdvancedSupplierDatasetOptOutField,
    switch: true,
    hidden: (vendor: Vendor) => {
      const { vendorKey, supplierKey } = vendor;
      return vendorKey !== supplierKey;
    },
  },
};

export const sitesFieldsMap: Partial<Record<SiteDetailDataFields, SiteDetailField>> = {
  [SiteDetailDataFields.LogoUrl]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorLogoField,
  },
  [SiteDetailDataFields.ShowLogoOnMobile]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorLogoShowOnMobile,
  },
  [SiteDetailDataFields.EmailLogoUrl]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorEmailLogoField,
  },
  [SiteDetailDataFields.Watermark]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorWatermarkField,
  },
  [SiteDetailDataFields.WatermarkText]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorWatermarkTextField,
  },
  [SiteDetailDataFields.WatermarkPosition]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorWatermarkStyleField,
  },
  [SiteDetailDataFields.ShowLogoWatermarkUnderEstimate]: {
    i18nKey: I18nKeys.SitesLogosConfiguratorWatermarkShowBelowEstimate,
  },
  [SiteDetailDataFields.PricingVisibility]: {
    i18nKey: I18nKeys.SitesPricingVisibility,
  },
  [SiteDetailDataFields.PricingVisibilityStaging]: {
    hidden: (_: Vendor, isIdeaRoomUser: boolean) => !isIdeaRoomUser,
    i18nKey: I18nKeys.SitesPricingVisibilityStaging,
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean) => isIdeaRoomUser,
  },
  [SiteDetailDataFields.PriceRangePercentage]: {
    i18nKey: I18nKeys.SitesPriceRangePercentage,
  },
  [SiteDetailDataFields.PricingEnableClientManaged]: {
    hidden: (_: Vendor, isIdeaRoomUser: boolean) => !isIdeaRoomUser,
    i18nKey: I18nKeys.SitesPricingClientManagedPricing,
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean) => isIdeaRoomUser,
  },
  [SiteDetailDataFields.PricingEnableClientUpdates]: {
    hidden: (_: Vendor, isIdeaRoomUser: boolean) => !isIdeaRoomUser,
    i18nKey: I18nKeys.SitesPricingClientUpdates,
    ideaRoomOnly: (_: Vendor, isIdeaRoomUser: boolean) => isIdeaRoomUser,
  },
  [SiteDetailDataFields.Layout]: {
    i18nKey: I18nKeys.SitesSaveAndCheckoutLayoutField,
  },
  [SiteDetailDataFields.CaptureInfoWithCapSave]: {
    i18nKey: I18nKeys.SitesCaptureInfoField,
    hidden: (vendor: Vendor) => {
      const { layout } = vendor;
      return layout !== UILayoutVersion.Modern;
    },
  },
  [SiteDetailDataFields.CapSaveRequirePhone]: {
    i18nKey: I18nKeys.SitesCaptureInfoRequirePhoneField,
    hidden: (vendor: Vendor) => {
      const { layout, captureInfoWithCapSave = true } = vendor;
      return layout !== UILayoutVersion.Modern || !captureInfoWithCapSave;
    },
  },
  [SiteDetailDataFields.DefaultSceneEnvironment]: {
    i18nKey: I18nKeys.SceneEnvironmentSelector,
  },
  [SiteDetailDataFields.DisableSceneEnvironmentChange]: {
    i18nKey: I18nKeys.SceneEnvironmentAllowUserChange,
  },
  ...emailFieldsMap,
  ...floorplanFieldsMap,
  ...informationPanelFieldsMap,
  ...basicInformationFieldsMap,
  ...configuratorSettingsFieldsMap,
  ...colorsFieldsMap,
  ...advancedSectionFieldsMap,
  ...leadsFormPanelFieldsMap,
  ...promotionalBannerFieldsMap,
  ...deliveryMessageFieldsMap,
  ...installationMessageFieldsMap,
};

export enum VendorDataCategory {
  BusinessInfo = 'business-info',
  ColorsAndLogos = 'colors-and-logos',
  LeadCapture = 'lead-capture',
  BannersAndMessages = 'banners-and-messages',
  Documents = 'documents',
  Floorplan = 'floorplan',
  ControlsAndFeatures = 'controls-and-features',
  Visibility = 'visibility',
}

export const publishMessagePrefix = 'Publishing site details for ';
