import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { defaultAllowedHTMLTags } from '../../constants/Input';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch } from '../../hooks';

export const AllowedHTMLTagsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeDialog = (): void => {
    dispatch(closeDialogFunc());
  };

  return (
    <Dialog dialogKey={Dialogs.AllowedHtmlTags}>
      <DialogTitle>{t(I18nKeys.AllowedHTMLDialogTitle)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t(I18nKeys.AllowedHTMLDialogContent)}
          <br />
          <br />
          {defaultAllowedHTMLTags.map((tag) => `“${tag}”`).join(', ')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
