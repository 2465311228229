import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BusinessInfoFormFields } from '../../constants/FormFields';
import { useDomainRepo } from '../../hooks/useDomainRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { Button } from '../library/Button';
import { DomainStatus } from '../../types/DomainVerification';
import { getDomainFromEmail } from '../../utils/domainUtils';
import { I18nKeys } from '../../constants/I18nKeys';
import { Dialogs } from '../../constants/Dialogs';
import { openDialog } from '../../ducks/dialogSlice';
import { useAppDispatch } from '../../hooks';

export const VerifyDomainButton: React.FC<{ name: BusinessInfoFormFields }> = ({ name }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientEmailDomain: { status },
    isFetchingClientEmailDomain,
  } = useDomainRepo();
  const {
    selectedTableData: [
      { emailAddress, quoteEmailFromAddressSame, quoteEmailFromAddress } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const { domain, error } = getDomainFromEmail(
    quoteEmailFromAddressSame ? `${emailAddress}` : `${quoteEmailFromAddress}`,
  );

  // This field does not need to be verified
  if (
    (name === BusinessInfoFormFields.EmailAddress && !quoteEmailFromAddressSame) ||
    (name === BusinessInfoFormFields.QuoteEmailFromAddress && !!quoteEmailFromAddressSame)
  ) {
    return null;
  }

  if (
    isFetchingClientEmailDomain ||
    error ||
    [
      DomainStatus.Verified,
      DomainStatus.VerifiedIdeaRoomDomain,
      DomainStatus.Unverifiable,
      DomainStatus.UnverifiableCustomSmtpHost,
    ].includes(status as DomainStatus)
  ) {
    return null;
  }

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={() => {
          dispatch(openDialog({ dialog: Dialogs.EmailDomain, options: { emailDomain: domain } }));
        }}
      >
        {t(I18nKeys.SitesEmailDomainManagementButton)}
      </Button>
    </Grid>
  );
};
