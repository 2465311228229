/* eslint-disable react/prop-types */
import { Autocomplete, Chip, FormHelperText, Grid2 as Grid, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Permission } from '@idearoom/types';
import { InputField } from './InputField';
import { UserDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { getPermissionLabel } from '../../constants/Permissions';
import { emailValidation as asyncValidate, required } from '../../utils/reduxFormUtils';
import { AppState } from '../../types/AppState';
import { Dealer } from '../../types/Dealer';
import { isCurrentUserManager, isCurrentUserGroupAdmin, isIdeaRoomUser } from '../../utils/userUtils';
import { I18nKeys } from '../../constants/I18nKeys';
import { getDateTimeString } from '../../utils/dateUtils';
import { Configurator } from '../../types/Configurator';
import { RenderSelectField } from './RenderSelectField';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: '15px',
    marginTop: '0px',
    width: '100%',
  },
});

export interface FormData {
  [UserDialogFormFields.FirstName]: string;
  [UserDialogFormFields.LastName]: string;
  [UserDialogFormFields.Email]: string;
  [UserDialogFormFields.PhoneNumber]: string;
  [UserDialogFormFields.Permissions]: string[];
  [UserDialogFormFields.LastLogin]: string;
}

interface FormDispatchProps {
  onSubmit(data: FormData): void;
}

interface StateProps {
  newMember: boolean;
  availableConfigurators: Configurator[];
  availableDealers: Dealer[];
  adminUser: boolean;
  idearoomSuperUser: boolean;
  managerUser: boolean;
  userIsAdmin: boolean;
  userIsSuperUser: boolean;
  lastLogin?: string;
  warningText?: string;
}

type FormProps = FormDispatchProps & StateProps & InjectedFormProps<FormData>;

const UserFormComponent: React.FC<FormProps> = ({
  newMember,
  availableConfigurators,
  availableDealers,
  adminUser,
  idearoomSuperUser,
  managerUser,
  userIsAdmin,
  userIsSuperUser,
  lastLogin,
  warningText,
  error,
  handleSubmit,
  onSubmit,
  change,
}: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getSelectedConfigurators = (configuratorKeys: string[]): Configurator[] | undefined =>
    availableConfigurators.filter((configurator: Configurator) =>
      configuratorKeys.includes(configurator.clientId || ''),
    );

  const getSelectedDealers = (dealerKeys: string[]): Dealer[] | undefined =>
    availableDealers.filter((dealer: Dealer) => dealerKeys.includes(dealer.key));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={!newMember}
        autoFocus={newMember}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        validate={required}
        label={`${t(I18nKeys.FieldEmail)}${newMember ? '*' : ''}`}
        name={UserDialogFormFields.Email}
      />
      {warningText && <FormHelperText>{warningText}</FormHelperText>}

      <Field
        disabled={(!newMember && !adminUser) || !!warningText}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.FieldPhone)}
        name={UserDialogFormFields.PhoneNumber}
      />

      <Field
        autoFocus={!newMember}
        autoComplete="off"
        className={classes.field}
        component={RenderSelectField}
        validate={required}
        label={`${t(I18nKeys.FieldPermissions)}*`}
        name={UserDialogFormFields.Permissions}
        variant="filled"
        slotProps={{
          select: {
            multiple: true,
            displayEmpty: true,
            renderValue: (selectedValues: string[]): JSX.Element => {
              const selectedPermissions = [...selectedValues] as Permission[];

              if (selectedPermissions.length > 0) {
                selectedPermissions.sort();
                return (
                  <div>
                    {(selectedPermissions as Permission[]).map((selectedValue) => (
                      <Chip
                        style={{ margin: '0px 2px' }}
                        key={selectedValue}
                        label={getPermissionLabel(selectedValue, t)}
                      />
                    ))}
                  </div>
                );
              }

              return <div style={{ height: '18px' }} />;
            },
          },
        }}
      >
        {idearoomSuperUser && (
          <MenuItem key="super-user-permission" value={Permission.SuperUser}>
            {getPermissionLabel(Permission.SuperUser, t)}
          </MenuItem>
        )}
        {adminUser && (
          <MenuItem key="admin-permission" value={Permission.Admin}>
            {getPermissionLabel(Permission.Admin, t)}
          </MenuItem>
        )}

        <MenuItem key="sales-tools-permission" value={Permission.Manager}>
          {getPermissionLabel(Permission.Manager, t)}
        </MenuItem>

        <MenuItem key="sales-view-permission" value={Permission.Sales}>
          {getPermissionLabel(Permission.Sales, t)}
        </MenuItem>
      </Field>

      {availableDealers.length > 0 && (
        <Field
          name={UserDialogFormFields.Dealers}
          autoFocus={!newMember}
          className={classes.field}
          label={t(I18nKeys.UserFormFieldDealers)}
          multiple
          validate={!adminUser && managerUser && required}
          disabled={userIsAdmin || userIsSuperUser}
          // eslint-disable-next-line react/no-unstable-nested-components
          component={(props: any): JSX.Element => (
            <Autocomplete
              disabled={userIsAdmin || userIsSuperUser}
              multiple
              autoHighlight
              options={availableDealers}
              value={getSelectedDealers(props.input.value) || null}
              getOptionLabel={(option: Dealer): string => option.name}
              onChange={(event: any, value: Dealer[]): void => {
                change(UserDialogFormFields.Dealers, value ? value.map((val: Dealer) => val.key) : []);
              }}
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              renderInput={(params: any): JSX.Element => (
                <TextField
                  {...params} // eslint-disable-line react/jsx-props-no-spreading
                  disabled={userIsAdmin || userIsSuperUser}
                  required={!adminUser && managerUser}
                  variant="filled"
                  label={t(I18nKeys.UserFormFieldDealers)}
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    },
                  }}
                  error={!!(props.meta.touched && props.meta.error)}
                  helperText={props.meta.touched && props.meta.error}
                />
              )}
            />
          )}
        />
      )}

      {availableConfigurators.length > 1 && (
        <Field
          name={UserDialogFormFields.Configurators}
          autoFocus={!newMember}
          className={classes.field}
          label={t(I18nKeys.UserFormFieldConfigurators)}
          multiple
          validate={!adminUser && managerUser && required}
          disabled={userIsAdmin || userIsSuperUser}
          // eslint-disable-next-line react/no-unstable-nested-components
          component={(props: any): JSX.Element => (
            <Autocomplete
              disabled={userIsAdmin || userIsSuperUser}
              multiple
              autoHighlight
              options={availableConfigurators}
              value={getSelectedConfigurators(props.input.value) || null}
              getOptionLabel={(option: Configurator): string => option.name || option.clientId || ''}
              onChange={(event: any, value: Configurator[] | null): void => {
                change(UserDialogFormFields.Configurators, value ? value.map((val: Configurator) => val.clientId) : []);
              }}
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              renderInput={(params: any): JSX.Element => (
                <TextField
                  {...params} // eslint-disable-line react/jsx-props-no-spreading
                  disabled={userIsAdmin || userIsSuperUser}
                  required={!adminUser && managerUser}
                  variant="filled"
                  label={t(I18nKeys.UserFormFieldConfigurators)}
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    },
                  }}
                  error={!!(props.meta.touched && props.meta.error)}
                  helperText={props.meta.touched && props.meta.error}
                />
              )}
            />
          )}
        />
      )}

      <Grid container spacing={2}>
        <Grid size={{ xs: 6 }}>
          <Field
            disabled={(!newMember && !adminUser) || !!warningText}
            autoComplete="off"
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.FieldFirstName)}
            name={UserDialogFormFields.FirstName}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Field
            disabled={(!newMember && !adminUser) || !!warningText}
            autoComplete="off"
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.FieldLastName)}
            name={UserDialogFormFields.LastName}
          />
        </Grid>
      </Grid>

      {!newMember && (
        <Typography variant="subtitle2" color="text.secondary">
          {t(I18nKeys.UserFormLastLogin)} {lastLogin ? getDateTimeString(lastLogin) : t(I18nKeys.UserFormNeverLoggedIn)}
        </Typography>
      )}

      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.User}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = (state: AppState): StateProps => {
  const {
    group: { newMember, group },
    currentUser: { user, group: usersGroup, availableDealers = [] },
    viewer: { warning: warningText },
  } = state;

  const adminUser = isCurrentUserGroupAdmin(user, usersGroup, group);
  const idearoomSuperUser =
    (isIdeaRoomUser(user) && (user?.email.startsWith('bkelley') || user?.email.startsWith('nperalta'))) || false;
  const managerUser = isCurrentUserManager(user);
  const selector = formValueSelector(Forms.User);
  const selectedPermissions = selector(state, UserDialogFormFields.Permissions);
  const lastLogin = selector(state, UserDialogFormFields.LastLogin);
  const userIsAdmin = selectedPermissions && selectedPermissions.includes(Permission.Admin);
  const userIsSuperUser = selectedPermissions && selectedPermissions.includes(Permission.SuperUser);

  const { configurators: availableConfigurators = [] } = group || { configurators: [] };

  return {
    newMember,
    availableConfigurators,
    availableDealers,
    adminUser,
    idearoomSuperUser,
    managerUser,
    userIsAdmin,
    userIsSuperUser,
    lastLogin,
    warningText,
  };
};

export const UserForm = reduxForm<FormData>({
  form: Forms.User,
  enableReinitialize: true,
  asyncValidate,
  asyncBlurFields: [`${UserDialogFormFields.Email}`],
})(connect(mapStateToProps, mapDispatchToProps)(UserFormComponent));
