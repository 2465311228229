import { makeStyles } from '@mui/styles';
import React from 'react';
import { Theme, Grid2 as Grid, AppBar, Toolbar, useMediaQuery, styled, Box } from '@mui/material';
import { usePath } from 'hookrouter';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';
import { Logo } from './Logo';
import { LogoBarSearch } from './LogoBarSearch';
import { SecondaryActionsMenu } from './SecondaryActionsMenu';
import { MenuStatus, SalesViewUILayout } from '../constants/Viewer';
import { AppRoutes } from '../constants/AppRoutes';
import { getLogoBarHeight } from '../utils/viewerUtils';
import { ClientDataTopBar } from './ClientDataTopBar';
import { setMenuStatus as setMenuStatusFunc } from '../ducks/viewerSlice';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { GroupsButton } from './GroupsButton';

const useStyles = makeStyles<
  Theme,
  {
    logoBarColor: string;
    logoBarTextColor: string;
    menuStatus: MenuStatus;
    mobileView: boolean;
    path: string;
  }
>((theme: Theme) => ({
  appBar: {
    backgroundColor: ({ logoBarColor }) => logoBarColor,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: ({ mobileView, path }) => `${getLogoBarHeight({ layout: SalesViewUILayout.Horizon, mobileView, path })}px`,
    zIndex: ({ mobileView }) => theme.zIndex.drawer + (mobileView ? -1 : 1),
    borderBottom: '1px solid #D7DBDF',
  },
  appBarSection: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
    height: '100%',
    padding: ({ mobileView }) => (mobileView ? '0px 14px' : '8px 14px'),
  },
}));

const Spacer = styled(Box)({
  height: '100%',
  width: '16px',
});

export const LogoBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const path = usePath();

  const secondaryActions = useAppSelector((state: AppState) => state.secondaryActions.secondaryActions);
  const hideSearch = useAppSelector((state: AppState) => state.search.hideSearch);
  const { theme: { logoBarColor = '#FFFFFF', logoBarTextColor = '#000' } = {}, menuStatus } = useAppSelector(
    (state: AppState) => state.viewer,
  );
  const otherProfilePreferences = useAppSelector((state: AppState) => state.currentUser.preferences?.profile) || {};

  const classes = useStyles({ logoBarColor, logoBarTextColor, menuStatus, mobileView, path });

  const setMenuStatus = (status: MenuStatus): void => {
    dispatch(setMenuStatusFunc(status));
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.ProfilePreferences,
        preferences: {
          ...otherProfilePreferences,
          menuStatus: status,
        },
      }),
    );
  };

  let topBarComponent = null;
  if (path.startsWith(AppRoutes.ClientData)) {
    topBarComponent = (
      <ClientDataTopBar
        open={menuStatus !== MenuStatus.Closed}
        onDrawerToggle={() => setMenuStatus(menuStatus !== MenuStatus.Closed ? MenuStatus.Closed : MenuStatus.Open)}
      />
    );
  }

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      {topBarComponent || (
        <Toolbar variant="dense" className={classes.toolbar}>
          <Grid container size="grow" justifyContent="space-between">
            <Grid size="grow" className={classes.appBarSection}>
              <GroupsButton />
              <Spacer />
              <Logo />
            </Grid>

            <Grid className={classes.appBarSection}>
              <LogoBarSearch />
              {secondaryActions.length > 0 &&
                (hideSearch && secondaryActions.length === 1 ? (
                  secondaryActions
                ) : (
                  <SecondaryActionsMenu secondaryActions={secondaryActions} />
                ))}
            </Grid>
          </Grid>
        </Toolbar>
      )}
    </AppBar>
  );
};
