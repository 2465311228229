import React, { useEffect } from 'react';
import {
  buttonBaseClasses,
  DialogActions,
  dialogActionsClasses,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2 as Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../types/DateRange';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { Dialogs } from '../constants/Dialogs';
import { useAppSelector } from '../hooks';
import { InputField } from './library/InputField';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    '& .MuiButtonBase-root': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&.MuiPickersDay-today': {
        border: '1px solid #D7DBDF',
      },
    },
  },
  mobileDatePicker: {
    [`& .${dialogClasses.paper}`]: {
      borderRadius: '8px',
      padding: '24px',
      [`& .${dialogActionsClasses.root}`]: {
        padding: '0px',
        [`& .${buttonBaseClasses.root}`]: {
          height: '44px',
          padding: '8px 32px',
          textTransform: 'none',
          borderRadius: '22px',
          fontWeight: 500,
          borderColor: '#D7DBDF',
          fontSize: '16px',
          color: '#323B4B',
          border: '1px solid #323B4B',
          '&:hover': {
            backgroundColor: '#EBEFF1',
          },
          '&:last-child': {
            marginLeft: '12px',
            color: '#FFFFFF',
            backgroundColor: theme.palette.secondary.main,
            border: 'none',
            '&:hover': {
              opacity: 0.8,
              boxShadow: 'none',
            },
          },
        },
      },
    },
  },
}));

interface Props {
  dateRange: DateRange;
  onApply(dateRange: DateRange): void;
  onCancel(): void;
}

export const DateRangeDialog: React.FC<Props> = ({ dateRange: { startDate, endDate }, onApply, onCancel }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const open = useAppSelector(({ dialog: { key } }) => key === Dialogs.DateRange);

  const [pickerStartDate, setPickerStartDate] = React.useState(startDate);
  const [pickerEndDate, setPickerEndDate] = React.useState(endDate);
  const [isValid, setIsValid] = React.useState(false);

  useEffect(() => {
    if (pickerStartDate && pickerEndDate) {
      setIsValid(pickerStartDate.isValid() && pickerEndDate.isValid());
    } else {
      setIsValid(false);
    }
  }, [pickerStartDate, pickerEndDate]);

  useEffect(() => {
    if (!open) return;
    setPickerStartDate(startDate);
    setPickerEndDate(endDate);
  }, [open]);

  const handleStartDateChange = (newStartDate: any): void => setPickerStartDate(newStartDate);
  const handleEndDateChange = (newEndDate: any): void => setPickerEndDate(newEndDate);

  const handleApplyClick = (): void => {
    if (pickerStartDate && pickerEndDate) {
      onApply({ startDate: pickerStartDate.startOf('day'), endDate: pickerEndDate.endOf('day') });
    }
  };

  return (
    <Dialog dialogKey={Dialogs.DateRange} maxWidth="md" disableEnforceFocus>
      <DialogTitle>{t(I18nKeys.DateRangeDialogTitle)}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container direction="row" spacing="24px" sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <StaticDatePicker
              className={classes.datePicker}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={pickerStartDate}
              disableFuture
              onChange={handleStartDateChange}
              renderInput={(): JSX.Element => <InputField label={t(I18nKeys.DateRangeDialogStartDateLabel)} />}
            />
            <Divider orientation="vertical">{t(I18nKeys.DateRangeDialogDivider)}</Divider>
            <StaticDatePicker
              className={classes.datePicker}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={pickerEndDate}
              disableFuture
              onChange={handleEndDateChange}
              renderInput={(): JSX.Element => <InputField label={t(I18nKeys.DateRangeDialogEndDateLabel)} />}
            />
          </Grid>
          <Grid container direction="column" spacing="16px" sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Grid>
              <MobileDatePicker
                value={pickerStartDate}
                disableFuture
                onChange={handleStartDateChange}
                DialogProps={{ className: classes.mobileDatePicker }}
                renderInput={({ error, inputProps }): JSX.Element => (
                  <InputField label={t(I18nKeys.DateRangeDialogStartDateLabel)} inputProps={inputProps} error={error} />
                )}
              />
            </Grid>
            <Grid>
              <MobileDatePicker
                value={pickerEndDate}
                disableFuture
                onChange={handleEndDateChange}
                DialogProps={{ className: classes.mobileDatePicker }}
                renderInput={({ error, inputProps }): JSX.Element => (
                  <InputField label={t(I18nKeys.DateRangeDialogEndDateLabel)} inputProps={inputProps} error={error} />
                )}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button variant="contained" disabled={!isValid} onClick={handleApplyClick}>
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
