import React, { useEffect, useState } from 'react';
import { Grid2 as Grid, Collapse } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, formValueSelector } from 'redux-form';
import { UILayoutVersion } from '@idearoom/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { BannersAndMessagesFormFields, ColorsAndLogosFormFields } from '../../constants/FormFields';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { AppState } from '../../types/AppState';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { Header } from '../library/Header';
import { openDialog } from '../../ducks/dialogSlice';
import { Dialogs } from '../../constants/Dialogs';
import { TopBannerSection } from './TopBannerSection';
import { InfoPanelSection } from './InfoPanelSection';
import { DeliveryMessagingSection } from './DeliveryMessagingSection';
import { InstallationMessagingSection } from './InstallationMessagingSection';

export interface FormData {
  [ColorsAndLogosFormFields.Layout]: string | number | boolean;
  [BannersAndMessagesFormFields.PromoBannerType]: string | number | boolean;
  [BannersAndMessagesFormFields.StandardDesktopPromo]: string | number | boolean;
  [BannersAndMessagesFormFields.StandardMobilePromo]: string | number | boolean;
  [BannersAndMessagesFormFields.DesktopPromo1]: string | number | boolean;
  [BannersAndMessagesFormFields.DesktopPromo2]: string | number | boolean | null;
  [BannersAndMessagesFormFields.MobilePromo1]: string | number | boolean;
  [BannersAndMessagesFormFields.MobilePromo2]: string | number | boolean | null;
  [BannersAndMessagesFormFields.ShowInformationalPanel]: boolean;
  [BannersAndMessagesFormFields.InformationPanelTitle]: string | number | boolean;
  [BannersAndMessagesFormFields.InformationPanelSubtitle]: string | number | boolean;
  [BannersAndMessagesFormFields.InformationPanelMessage]: string | number | boolean;
  [BannersAndMessagesFormFields.DeliveryMessageType]: string | number | boolean;
  [BannersAndMessagesFormFields.StandardDeliveryMessage]: string | number | boolean;
  [BannersAndMessagesFormFields.DeliveryMessage]: string | number | boolean;
  [BannersAndMessagesFormFields.InstallationMessageType]: string | number | boolean;
  [BannersAndMessagesFormFields.StandardInstallationMessage]: string | number | boolean;
  [BannersAndMessagesFormFields.InstallationMessage]: string | number | boolean;
}

const BannersAndMessagesFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state: AppState) => state?.clientData);
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const [initializing, setInitializing] = useState(true);

  const layout = useAppSelector((state) =>
    formValueSelector(Forms.BannersAndMessages)(state, ColorsAndLogosFormFields.Layout),
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="24px">
        <Grid container size={12} spacing="24px" alignItems="start">
          <Grid size="grow">
            <Header
              initializing={initializing}
              subheader="Shows on your site to highlight important information or promotions."
            />
          </Grid>
          <Grid>
            <Header
              initializing={initializing}
              subheader="Some HTML Allowed"
              onClick={() => dispatch(openDialog({ dialog: Dialogs.AllowedHtmlTags }))}
            />
          </Grid>
        </Grid>
        <Collapse
          in={layout === UILayoutVersion.Mobile && !initializing}
          timeout="auto"
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <TopBannerSection initializing={initializing} />
        </Collapse>
        <InfoPanelSection initializing={initializing} />
        <DeliveryMessagingSection initializing={initializing} />
        <InstallationMessagingSection initializing={initializing} />
      </Grid>
    </Form>
  );
};

export const BannersAndMessagesForm = reduxForm<FormData>({
  form: Forms.BannersAndMessages,
  enableReinitialize: true,
})(BannersAndMessagesFormComponent);
