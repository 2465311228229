import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { I18nKeys } from '../../constants/I18nKeys';
import { Dialogs } from '../../constants/Dialogs';
import { Dialog } from './Dialog';
import { closeDialog } from '../../ducks/dialogSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ColumnDataType } from '../../constants/ClientData';
import { useGetClientDataAllTableMetadataQuery } from '../../services/clientDataApi';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { TableMetadata } from '../../types/ClientData';
import { Loading } from '../Loading';
import { compoundCaseToTitleCase } from '../../utils/stringUtils';
import { LoadingButton } from '../LoadingButton';
import { ClientDataState } from '../../types/ClientDataState';
import { fetchTableDataToGenerateIcons } from '../../middleware/clientDataThunk';
import { unknownGroup } from '../../constants/Group';
import { isCarportView } from '../../utils/clientIdUtils';

const useStyles = makeStyles(() => ({
  dialogContent: { padding: '0px', minWidth: '350px' },
  dialogActions: { padding: '0px 8px 8px 8px' },
}));
const EMPTY_TABLE_METADATA: Readonly<TableMetadata[]> = [];

export const ClientDataLoadOptionsToGenerateIconsDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tablesWithOption, setTablesWithOption] = useState<NonNullable<ClientDataState['optionIconsToGenerate']>>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const {
    clientDataType,
    clientId,
    clientDataBranch = ClientDataBranch.Main,
    loadingTableDataToGenerateIcon,
  } = useAppSelector((state) => state.clientData);
  const dialogKey = useAppSelector((state) => state.dialog.key);
  const groupId = useAppSelector((state) => (state.currentUser.group || unknownGroup).groupId);
  const open = dialogKey === Dialogs.LoadOptionsToGenerateIcons;

  const { data: allTablesMetadata = EMPTY_TABLE_METADATA, isFetching: isLoadingAllTablesMetadata } =
    useGetClientDataAllTableMetadataQuery(
      { dataType: clientDataType, clientId, groupId, branch: clientDataBranch },
      {
        skip: !clientDataType || !clientId || !open,
        refetchOnFocus: false,
      },
    );

  useEffect(() => {
    const options: NonNullable<ClientDataState['optionIconsToGenerate']> = [];
    const tables: string[] = [];
    (allTablesMetadata || []).forEach(({ formattedTableName, metadata, sheds, carports }) => {
      const columns = Object.keys(metadata);
      const column = columns.find((key) => metadata[key].dataType === ColumnDataType.OptionIcon);
      if (column && ((isCarportView(clientId) && carports) || (!isCarportView(clientId) && sheds))) {
        options.push({ table: formattedTableName, column, rows: [] });
        tables.push(formattedTableName);
      }
    });
    setChecked(tables);
    setTablesWithOption(options);
  }, [clientId, allTablesMetadata]);

  const handleToggle = (table: string) => {
    const currentIndex = checked.indexOf(table);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(table);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleGenerate = async () => {
    const options = await dispatch(
      fetchTableDataToGenerateIcons(tablesWithOption.filter((o) => checked.includes(o.table))),
    ).unwrap();

    if (options.length > 0) {
      dispatch(closeDialog());
    } else {
      toast.warn(t(I18nKeys.LoadOptionsToGenerateIconsDialogNoOptionError));
    }
  };

  return (
    <Dialog dialogKey={Dialogs.LoadOptionsToGenerateIcons} scroll="paper">
      <DialogTitle>{t(I18nKeys.LoadOptionsToGenerateIconsDialogTitle)}</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {isLoadingAllTablesMetadata ? (
          <Loading />
        ) : (
          <Stack padding={0}>
            <Typography paddingBlockStart={2} paddingBlockEnd={1} paddingInline={3}>
              <Trans i18nKey={I18nKeys.LoadOptionsToGenerateIconsDialogText as string} />
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {tablesWithOption.map(({ table }) => (
                <ListItem key={table} disableGutters disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      handleToggle(table);
                    }}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox color="primary" checked={checked.includes(table)} />
                    </ListItemIcon>
                    <ListItemText primary={compoundCaseToTitleCase(table)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color="primary">
          {t(I18nKeys.DialogCloseButton)}
        </Button>
        <LoadingButton onClick={handleGenerate} color="primary" loading={loadingTableDataToGenerateIcon}>
          {t(I18nKeys.DialogGenerateButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
