/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid2 as Grid, FormControl, InputAdornment, IconButton, Collapse } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, Field, formValueSelector } from 'redux-form';
import { OpenInNew } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { BusinessInfoFormFields } from '../../constants/FormFields';
import { InputField } from '../redux-form/InputField';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { AppState } from '../../types/AppState';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { useDomainRepo } from '../../hooks/useDomainRepo';
import { VerifyDomainButton } from './VerifyDomainButton';
import { DomainStatus } from '../../types/DomainVerification';
import { getDomainFromEmail } from '../../utils/domainUtils';
import { VerifyDomainStatus } from './VerifyDomainStatus';

export interface FormData {
  [BusinessInfoFormFields.Name]: string | number | boolean;
  [BusinessInfoFormFields.Address]: string | number | boolean;
  [BusinessInfoFormFields.City]: string | number | boolean;
  [BusinessInfoFormFields.State]: string | number | boolean;
  [BusinessInfoFormFields.ZipCode]: string | number | boolean;
  [BusinessInfoFormFields.PhoneNumber]: string | number | boolean;
  [BusinessInfoFormFields.CompanyFax]: string | number | boolean;
  [BusinessInfoFormFields.HomeLinkUrl]: string | number | boolean;
  [BusinessInfoFormFields.EmailAddress]: string | number | boolean;
  [BusinessInfoFormFields.QuoteEmailFromAddressSame]: boolean;
  [BusinessInfoFormFields.QuoteEmailFromAddress]: string | number | boolean;
  [BusinessInfoFormFields.QuoteEmailCopyAddressSame]: boolean;
  [BusinessInfoFormFields.QuoteEmailCopyAddress]: string | number | boolean;
}

const BusinessInfoFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state: AppState) => state?.clientData);
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const {
    clientEmailDomain: { status },
  } = useDomainRepo();
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const [initializing, setInitializing] = useState(true);

  const homeLinkUrl = useAppSelector((state) =>
    formValueSelector(Forms.BusinessInfo)(state, BusinessInfoFormFields.HomeLinkUrl),
  );
  const emailAddress = useAppSelector((state) =>
    formValueSelector(Forms.BusinessInfo)(state, BusinessInfoFormFields.EmailAddress),
  );
  const quoteEmailFromAddressSame = useAppSelector((state) =>
    formValueSelector(Forms.BusinessInfo)(state, BusinessInfoFormFields.QuoteEmailFromAddressSame),
  );
  const quoteEmailFromAddress = useAppSelector((state) =>
    formValueSelector(Forms.BusinessInfo)(state, BusinessInfoFormFields.QuoteEmailFromAddress),
  );
  const quoteEmailCopyAddressSame = useAppSelector((state) =>
    formValueSelector(Forms.BusinessInfo)(state, BusinessInfoFormFields.QuoteEmailCopyAddressSame),
  );
  const { error: emailDomainError } = getDomainFromEmail(
    quoteEmailFromAddressSame ? `${emailAddress}` : `${quoteEmailFromAddress}`,
  );

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="24px">
        <Grid key={BusinessInfoFormFields.Name} size={12}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.Name}
              label="Name"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.Name, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.Address} size={12}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.Address}
              label="Address"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.Address, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.City} size={{ xs: 12, lg: 6 }}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.City}
              label="City"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.City, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.State} size={{ xs: 12, lg: 3 }}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.State}
              label="State"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.State, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.ZipCode} size={{ xs: 12, lg: 3 }}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.ZipCode}
              label="Zip / Postal"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.ZipCode, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.PhoneNumber} size={{ xs: 12, lg: 6 }}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.PhoneNumber}
              label="Phone number"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.PhoneNumber, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.CompanyFax} size={{ xs: 12, lg: 6 }}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.CompanyFax}
              label="Fax number"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.CompanyFax, event.target.value)
              }
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid key={BusinessInfoFormFields.HomeLinkUrl} size={12}>
          <FormControl fullWidth>
            <Field
              name={BusinessInfoFormFields.HomeLinkUrl}
              label="Home Link URL"
              variant="filled"
              component={InputField}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(BusinessInfoFormFields.HomeLinkUrl, event.target.value)
              }
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!homeLinkUrl}
                        sx={{ p: '0px' }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
                          event.stopPropagation();
                          window.open(homeLinkUrl, '_blank', 'noopener noreferrer');
                        }}
                      >
                        <OpenInNew sx={{ color: '#323B4B', opacity: 0.7 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              initializing={initializing}
            />
          </FormControl>
        </Grid>
        <Grid container size={12} alignItems="center" spacing="24px">
          <Grid key={BusinessInfoFormFields.EmailAddress} size={{ xs: 'grow', lg: 6 }}>
            <FormControl fullWidth>
              <Field
                name={BusinessInfoFormFields.EmailAddress}
                label="Contact email"
                variant="filled"
                component={InputField}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(BusinessInfoFormFields.EmailAddress, event.target.value)
                }
                initializing={initializing}
                {...(quoteEmailFromAddressSame
                  ? {
                      error:
                        (
                          [DomainStatus.Unverified, DomainStatus.Unverifiable, DomainStatus.DoesNotExist] as string[]
                        ).includes(status) || emailDomainError,
                      helperText: emailDomainError,
                    }
                  : {})}
                slotProps={{
                  input: {
                    endAdornment: <VerifyDomainStatus name={BusinessInfoFormFields.EmailAddress} />,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <VerifyDomainButton name={BusinessInfoFormFields.EmailAddress} />
        </Grid>
        <Grid container size={12} gap="20px">
          <Grid key={BusinessInfoFormFields.QuoteEmailFromAddressSame} size={12}>
            <FormControl fullWidth>
              <Field
                name={BusinessInfoFormFields.QuoteEmailFromAddressSame}
                component={LabeledCheckboxField}
                label={`"Send copy to" address same as contact email`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(BusinessInfoFormFields.QuoteEmailFromAddressSame, !!event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Collapse
            in={!quoteEmailFromAddressSame && !initializing}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Grid container size={12} alignItems="center" spacing="24px">
              <Grid key={BusinessInfoFormFields.QuoteEmailFromAddress} size={{ xs: 'grow', lg: 6 }} sx={{ pl: '36px' }}>
                <FormControl fullWidth>
                  <Field
                    name={BusinessInfoFormFields.QuoteEmailFromAddress}
                    label="From email address"
                    variant="filled"
                    component={InputField}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(BusinessInfoFormFields.QuoteEmailFromAddress, event.target.value)
                    }
                    initializing={initializing}
                    {...(!quoteEmailFromAddressSame
                      ? {
                          error:
                            (
                              [
                                DomainStatus.Unverified,
                                DomainStatus.Unverifiable,
                                DomainStatus.DoesNotExist,
                              ] as string[]
                            ).includes(status) || emailDomainError,
                          helperText: emailDomainError,
                        }
                      : {})}
                    slotProps={{
                      input: {
                        endAdornment: <VerifyDomainStatus name={BusinessInfoFormFields.QuoteEmailFromAddress} />,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <VerifyDomainButton name={BusinessInfoFormFields.QuoteEmailFromAddress} />
            </Grid>
          </Collapse>
        </Grid>
        <Grid container size={12} gap="20px">
          <Grid key={BusinessInfoFormFields.QuoteEmailCopyAddressSame} size={12}>
            <FormControl fullWidth>
              <Field
                name={BusinessInfoFormFields.QuoteEmailCopyAddressSame}
                component={LabeledCheckboxField}
                label={`"Send copy to" address same as contact email`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(BusinessInfoFormFields.QuoteEmailCopyAddressSame, !!event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
          </Grid>
          <Collapse
            in={!quoteEmailCopyAddressSame && !initializing}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Grid container size={12} alignItems="center" spacing="24px">
              <Grid key={BusinessInfoFormFields.QuoteEmailCopyAddress} size={{ xs: 'grow', lg: 6 }} sx={{ pl: '36px' }}>
                <FormControl fullWidth>
                  <Field
                    name={BusinessInfoFormFields.QuoteEmailCopyAddress}
                    label="Copy email address"
                    variant="filled"
                    component={InputField}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(BusinessInfoFormFields.QuoteEmailCopyAddress, event.target.value)
                    }
                    initializing={initializing}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Form>
  );
};

export const BusinessInfoForm = reduxForm<FormData>({
  form: Forms.BusinessInfo,
  enableReinitialize: true,
})(BusinessInfoFormComponent);
