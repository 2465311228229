/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TextField, TextFieldProps, FilledInputProps, InputLabelProps } from '@mui/material';
import { alpha } from '@mui/system';
import { ContentSkeleton } from './ContentSkeleton';

type Props = TextFieldProps & {
  initializing?: boolean;
  height?: string;
  slotProps?: {
    input?: Partial<FilledInputProps>;
    inputLabel?: Partial<InputLabelProps>;
  };
};

export const InputField = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { rows, multiline, disabled, initializing = false, label, height = '56px', ...customProps } = props;

  return (
    <ContentSkeleton initializing={initializing} variant="input" height={height}>
      <TextField
        ref={ref}
        variant="filled"
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        label={label}
        {...customProps}
        slotProps={{
          ...customProps?.slotProps,
          input: {
            ...customProps?.slotProps?.input,
            sx: {
              pt: label ? '25px' : '9px',
              pb: label ? '8px' : '9px',
              px: '16px',
              ...customProps?.slotProps?.input?.sx,
              backgroundColor: disabled ? '#DFE0E1' : '#F2F4F5',
              color: alpha('#323B4B', disabled ? 0.6 : 1),
              '& input': {
                p: '0px',
              },
              '&.MuiInputBase-adornedStart': {
                pl: '56px',
              },
              '&.MuiInputBase-adornedEnd': {
                pr: '60px',
              },
              '& .MuiInputAdornment-positionEnd': {
                position: 'absolute',
                right: '16px',
                alignSelf: 'anchor-center',
              },
              '& .MuiInputAdornment-positionStart': {
                position: 'absolute',
                left: '16px',
                alignSelf: 'anchor-center',
              },
            },
          },
          inputLabel: {
            sx: {
              opacity: disabled ? 0.6 : 0.7,
              color: disabled ? '#000000' : '#323B4B',
              transform: 'translate(14px, 7px) scale(0.75)',
              ...customProps?.slotProps?.inputLabel?.sx,
            },
            ...customProps?.slotProps?.inputLabel,
          },
        }}
        sx={{ width: '100%', height }}
      />
    </ContentSkeleton>
  );
});
