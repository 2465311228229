import React from 'react';
import { Card } from '@mui/material';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { SitesPage } from './SitesPage';
import { ControlsAndFeaturesFormFields } from '../../constants/FormFields';
import { ControlsAndFeaturesForm } from './ControlsAndFeaturesForm';

export const ControlsAndFeatures: React.FC<{ clientId: string }> = () => {
  const {
    selectedTableData: [
      {
        disableSharing,
        disableAR,
        hideCenterComponentsButton,
        disableSceneEnvironmentChange,
        enableDesignAutoSave,
      } = {} as Record<string, string>,
    ] = [],
  } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  return (
    <SitesPage header="Controls & Features">
      <Card sx={{ p: '24px' }} elevation={0}>
        <ControlsAndFeaturesForm
          initialValues={{
            // Inverting initial values, because labels are positive
            [ControlsAndFeaturesFormFields.DisableSharing]: !disableSharing,
            [ControlsAndFeaturesFormFields.DisableAR]: !disableAR,
            [ControlsAndFeaturesFormFields.HideCenterComponentsButton]: !hideCenterComponentsButton,
            [ControlsAndFeaturesFormFields.DisableSceneEnvironmentChange]: !disableSceneEnvironmentChange,
            [ControlsAndFeaturesFormFields.EnableDesignAutoSave]: !!enableDesignAutoSave,
          }}
        />
      </Card>
    </SitesPage>
  );
};
