import React, { useEffect } from 'react';
import { Button, DialogActions, DialogContent, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../../types/DateRange';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppSelector } from '../../hooks';
import { Dialogs } from '../../constants/Dialogs';
import { Dialog } from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'row',
  },
  dividerContainer: {
    padding: theme.spacing(3),
  },
  dividerItem: {
    height: '75%',
    marginLeft: theme.spacing(1),
  },
  to: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
}));

interface Props {
  dateRange: DateRange;
  onApply(dateRange: DateRange): void;
  onCancel(): void;
}

export const DateRangeDialog: React.FC<Props> = ({ dateRange: { startDate, endDate }, onApply, onCancel }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const open = useAppSelector(({ dialog: { key } }) => key === Dialogs.DateRange);

  const [pickerStartDate, setPickerStartDate] = React.useState(startDate);
  const [pickerEndDate, setPickerEndDate] = React.useState(endDate);
  const [isValid, setIsValid] = React.useState(false);

  useEffect(() => {
    if (pickerStartDate && pickerEndDate) {
      setIsValid(pickerStartDate.isValid() && pickerEndDate.isValid());
    } else {
      setIsValid(false);
    }
  }, [pickerStartDate, pickerEndDate]);

  useEffect(() => {
    if (!open) return;
    setPickerStartDate(startDate);
    setPickerEndDate(endDate);
  }, [open]);

  const handleStartDateChange = (newStartDate: any): void => setPickerStartDate(newStartDate);
  const handleEndDateChange = (newEndDate: any): void => setPickerEndDate(newEndDate);

  const handleApplyClick = (): void => {
    if (pickerStartDate && pickerEndDate) {
      onApply({ startDate: pickerStartDate.startOf('day'), endDate: pickerEndDate.endOf('day') });
    }
  };

  return (
    <Dialog dialogKey={Dialogs.DateRange} maxWidth="md" disableEnforceFocus>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body1">{t(I18nKeys.DateRangeDialogTitle)}</Typography>
            </Grid>
            <Grid>
              <Grid sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pickerStartDate}
                  disableFuture
                  onChange={handleStartDateChange}
                  renderInput={(): JSX.Element => <TextField label={t(I18nKeys.DateRangeDialogStartDateLabel)} />}
                />
                <div className={classes.dividerContainer}>
                  <Typography className={classes.to} variant="body1">
                    {t(I18nKeys.DateRangeDialogDivider)}
                  </Typography>
                  <Divider orientation="vertical" className={classes.dividerItem} />
                </div>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pickerEndDate}
                  disableFuture
                  onChange={handleEndDateChange}
                  renderInput={(): JSX.Element => <TextField label={t(I18nKeys.DateRangeDialogEndDateLabel)} />}
                />
              </Grid>
              <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid>
                  <MobileDatePicker
                    value={pickerStartDate}
                    disableFuture
                    onChange={handleStartDateChange}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps): JSX.Element => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField label={t(I18nKeys.DateRangeDialogStartDateLabel)} {...params} />
                    )}
                  />
                </Grid>
                <Grid style={{ height: '16px' }} />
                <Grid>
                  <MobileDatePicker
                    value={pickerEndDate}
                    disableFuture
                    onChange={handleEndDateChange}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps): JSX.Element => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField label={t(I18nKeys.DateRangeDialogEndDateLabel)} {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t(I18nKeys.DialogCancelButton)}</Button>
        <Button disabled={!isValid} onClick={handleApplyClick}>
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
