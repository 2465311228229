import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { UserDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { unknownGroup } from '../constants/Group';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { unknownGroupMember } from '../types/GroupMember';
import { UserForm, FormData } from './UserForm';
import { BulkUserForm } from './BulkUserForm';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Tabs } from './library/Tabs';
import { ContentList } from './library/ContentList';

// eslint-disable-next-line no-shadow
enum MemberComponentTabs {
  SingleMember = 'SINGLE_MEMBER',
  BulkMembers = 'BULK_MEMBERS',
}

export const UserDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tab, setTab] = React.useState(MemberComponentTabs.SingleMember);

  const { group = unknownGroup, availableDealers = [] } = useAppSelector((state) => state.currentUser);
  const { editingMember, member = unknownGroupMember, newMember } = useAppSelector((state) => state.group);
  const isIdearoomGroup = isIdeaRoomGroup(group.groupId);
  const submitting = editingMember;

  const initialValues = {
    [UserDialogFormFields.NewUser]: newMember,
    [UserDialogFormFields.GroupId]: group.groupId,
    [UserDialogFormFields.FirstName]: member.firstName,
    [UserDialogFormFields.LastName]: member.lastName,
    [UserDialogFormFields.Email]: member.email,
    [UserDialogFormFields.PhoneNumber]: member.phone,
    [UserDialogFormFields.Configurators]: member.configurators || [],
    [UserDialogFormFields.Dealers]:
      newMember && availableDealers.length === 1 ? availableDealers[0].key : member.dealers,
    [UserDialogFormFields.Permissions]: member.permissions,
    [UserDialogFormFields.LastLogin]: member.lastLogin,
  } as FormData;

  const saveUser = (): void => {
    if (tab === MemberComponentTabs.SingleMember) {
      dispatch(submit(Forms.User));
    } else {
      dispatch(submit(Forms.BulkUser));
    }
  };

  const closeDialog = (): void => {
    dispatch(reset(Forms.User));
    dispatch(reset(Forms.BulkUser));
    dispatch(closeDialogFunc());
  };

  const tabs = [
    {
      label: t(I18nKeys.UserDialogTabSingleMember),
      value: MemberComponentTabs.SingleMember,
      ideaRoomOnly: false,
    },
    {
      label: t(I18nKeys.UserDialogTabBulkImport),
      value: MemberComponentTabs.BulkMembers,
      ideaRoomOnly: true,
    },
  ];

  return (
    <Dialog dialogKey={Dialogs.User}>
      <DialogTitle>
        {t(I18nKeys.UserDialogTitle, {
          dialogType: newMember ? t(I18nKeys.DialogTypeAdd) : t(I18nKeys.DialogTypeEdit),
          userType: isIdearoomGroup ? t(I18nKeys.UserDialogUserTypeMember) : t(I18nKeys.UserDialogUserTypeUser),
        })}
      </DialogTitle>
      {isIdearoomGroup && newMember && (
        <Tabs
          tabs={tabs}
          value={tab}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: MemberComponentTabs): void => {
            setTab(newValue);
          }}
        />
      )}
      <DialogContent>
        {tab === MemberComponentTabs.SingleMember && <UserForm initialValues={initialValues} />}
        {tab === MemberComponentTabs.BulkMembers && (
          <>
            <DialogContentText
              dangerouslySetInnerHTML={{
                __html: t(I18nKeys.UserDialogBulkImportHeader),
              }}
            />
            <ContentList
              items={[
                {
                  label: t(I18nKeys.UserDialogBulkImportStep1),
                },
                {
                  label: t(I18nKeys.UserDialogBulkImportStep2),
                },
              ]}
            />

            <BulkUserForm initialValues={initialValues} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button loading={submitting} onClick={closeDialog} variant="outlined">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button loading={submitting} onClick={(): void => saveUser()} variant="contained">
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
