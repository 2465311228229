import { Select, InputAdornment, MenuItem, Box } from '@mui/material';
import React from 'react';
import { WebOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { unknownGroup } from '../../constants/Group';
import { setSubMenuItem } from '../../middleware/menuThunk';

export const SitesSelect: React.FC = () => {
  const dispatch = useAppDispatch();

  const clientId = useAppSelector((state) => state.clientData.clientId);
  const configurators = useAppSelector((state) => (state.currentUser.group || unknownGroup).configurators || []);

  return (
    <Box sx={{ pl: '18px', pr: configurators.length > 1 ? '18px' : '0px' }}>
      <Select
        value={clientId}
        onChange={(event) => {
          event.preventDefault();
          dispatch(setSubMenuItem(event.target.value));
        }}
        variant="standard"
        fullWidth
        disableUnderline
        startAdornment={
          <InputAdornment position="start">
            <WebOutlined color="primary" />
          </InputAdornment>
        }
        disabled={configurators.length <= 1}
        sx={{
          fontWeight: 600,
          '& .MuiSelect-icon': {
            color: (theme) => theme.palette.primary.main,
            display: configurators.length > 1 ? 'block' : 'none',
          },
          '& .MuiInputBase-input': {
            color: (theme) => theme.palette.primary.main,
            WebkitTextFillColor: (theme) => theme.palette.primary.main,
            pr: '0px',
          },
        }}
      >
        {configurators.map(({ clientId: configuratorClientId, name }) => (
          <MenuItem key={configuratorClientId} value={configuratorClientId}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
