import { DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reset, submit, getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../../constants/Dialogs';
import { Forms } from '../../constants/Forms';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { AppState } from '../../types/AppState';
import { Dialog } from './Dialog';
import { LoadingButton } from '../LoadingButton';
import { I18nDialogFormFields } from '../../constants/FormFields';
import { FormData, I18nForm } from './I18nForm';
import { SALESVIEW } from '../../constants/App';
import { I18nKeys } from '../../constants/I18nKeys';
import { deleteI18nKey } from '../../ducks/settings';

const useStyles = makeStyles({
  delete: {
    color: '#B00020',
  },
});

interface StateProps {
  initialValues: FormData;
  submitting: boolean;
  values: {
    CLIENT_ID: string;
    LANGUAGE: string;
    KEY: string;
    VALUE: string;
    OVERRIDE: string;
  };
}

interface DispatchProps {
  removeI18n(values: FormData): void;
  saveI18n(): void;
  closeDialog(): void;
}

type Props = StateProps & DispatchProps;

const Spacer = styled('div')({
  flexGrow: 1,
});

const I18nDialogComponent: React.FC<Props> = ({
  removeI18n,
  saveI18n,
  closeDialog,
  initialValues,
  values,
  submitting,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog dialogKey={Dialogs.I18n}>
      <DialogTitle>{`Key: ${initialValues.KEY}, ${initialValues.LANGUAGE}`}</DialogTitle>
      <DialogContent>
        <I18nForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={submitting} onClick={(): void => removeI18n(values)} className={classes.delete}>
          {t(initialValues.CLIENT_ID === SALESVIEW ? I18nKeys.I18nDialogRemoveKey : I18nKeys.I18nDialogRemoveOverride)}
        </LoadingButton>
        <Spacer />
        <LoadingButton loading={submitting} onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogCloseButton)}
        </LoadingButton>
        <LoadingButton loading={submitting} onClick={(): void => saveI18n()} color="primary">
          {t(I18nKeys.DialogSaveButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { i18nDialog } = state.settings;

  return {
    initialValues: {
      [I18nDialogFormFields.ClientId]: i18nDialog.clientId,
      [I18nDialogFormFields.Language]: i18nDialog.language,
      [I18nDialogFormFields.Key]: i18nDialog.key,
      [I18nDialogFormFields.Value]: i18nDialog.value,
      [I18nDialogFormFields.Override]: i18nDialog.override || i18nDialog.value,
    } as FormData,
    submitting: i18nDialog.updating,
    values: getFormValues(Forms.I18n)(state) as FormData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeI18n: (values: FormData): void => {
    if (values.CLIENT_ID === SALESVIEW) {
      dispatch(deleteI18nKey(values));
    }
  },
  saveI18n: (): void => {
    dispatch(submit(Forms.I18n));
  },
  closeDialog: (): void => {
    dispatch(reset(Forms.I18n));
    dispatch(closeDialogFunc());
  },
});

export const I18nDialog = connect(mapStateToProps, mapDispatchToProps)(I18nDialogComponent);
