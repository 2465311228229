import React from 'react';
import { Field, Form, formValueSelector, InjectedFormProps, reduxForm, Validator } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid2 as Grid, Stack, Typography } from '@mui/material';
import { Forms } from '../../constants/Forms';
import { InputField } from './InputField';
import { useAppSelector } from '../../hooks';
import { SetupPaymentIntegrationFormFields } from '../../constants/FormFields';
import { AppState } from '../../types/AppState';
import { I18nKeys } from '../../constants/I18nKeys';

export interface FormData {
  [SetupPaymentIntegrationFormFields.LegalEntityName]: string;
  [SetupPaymentIntegrationFormFields.ShopperStatement]: string;
}

type FormProps = InjectedFormProps<FormData>;

const useStyles = makeStyles(() => ({
  field: {
    width: '100%',
  },
  fieldBottomText: {
    marginTop: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#00000061',
  },
  fieldBottomStartText: {
    paddingStart: '16px',
  },
  fieldBottomEndText: {
    textAlign: 'end',
  },
}));

const nullOrEmpty: Validator = (value: string) => {
  if (!value || value.length === 0) {
    return 'error';
  }
  return undefined;
};

const MAX_SHOPPER_STATEMENT_LENGTH = 22;

const SetupPaymentIntegrationFormComponent: React.FC<FormProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const shopperStatement = useAppSelector((state: AppState) =>
    formValueSelector(Forms.SetupPaymentIntegration)(state, SetupPaymentIntegrationFormFields.ShopperStatement),
  );

  return (
    <Form>
      <Stack spacing={2}>
        <Field
          autoFocus
          type="string"
          autoComplete="off"
          hideHelperText
          component={InputField}
          label={t(I18nKeys.SetupPaymentIntegrationDialogLegalName)}
          validate={nullOrEmpty}
          name={SetupPaymentIntegrationFormFields.LegalEntityName}
          variant="filled"
        />
        <Grid container>
          <Grid size={12}>
            <Field
              className={classes.field}
              type="string"
              autoComplete="off"
              hideHelperText
              component={InputField}
              validate={nullOrEmpty}
              normalize={(value: any, previousValue: any, allValues: any[]) =>
                value && value.trim().length > MAX_SHOPPER_STATEMENT_LENGTH ? previousValue.trim() : value
              }
              label={t(I18nKeys.SetupPaymentIntegrationDialogShopperStatement)}
              name={SetupPaymentIntegrationFormFields.ShopperStatement}
              variant="filled"
            />
          </Grid>
          <Grid container size={12}>
            <Grid size={10}>
              <Typography className={`${classes.fieldBottomText} ${classes.fieldBottomStartText}`}>
                {t(I18nKeys.SetupPaymentIntegrationDialogShopperStatementHelperText)}
              </Typography>
            </Grid>
            <Grid size={2}>
              <Typography className={`${classes.fieldBottomText} ${classes.fieldBottomEndText}`}>
                {t(I18nKeys.SetupPaymentIntegrationDialogShopperStatementLengthCount, {
                  max: `${MAX_SHOPPER_STATEMENT_LENGTH}`,
                  count: (shopperStatement.trim() || '').length,
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Form>
  );
};

export const SetupPaymentIntegrationForm = reduxForm<FormData>({
  form: Forms.SetupPaymentIntegration,
})(SetupPaymentIntegrationFormComponent);
