import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../../constants/Dialogs';
import { Dialog } from './Dialog';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeDialog } from '../../ducks/dialogSlice';
import { I18nKeys } from '../../constants/I18nKeys';
import { ClientDataGoToSourceDataDialogOptions } from '../../types/DialogState';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '15px',
  },
}));

export const ClientDataGoToSourceDataDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { menuItems = [] } = options as ClientDataGoToSourceDataDialogOptions;

  return (
    <Dialog dialogKey={Dialogs.ClientDataGoToSourceData}>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton disabled>
            <OpenInNew classes={{ root: classes.icon }} />
            <Trans i18nKey={I18nKeys.ClientDataHeaderMenuGoToSource as string} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {menuItems.map((item) => (
          <ListItem disableGutters disablePadding key={item.name}>
            <ListItemButton
              onClick={() => {
                item.action();
                dispatch(closeDialog());
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
