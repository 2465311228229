import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Cancel, CheckCircle, ContentCopy } from '@mui/icons-material';
import { Dialog } from './library/Dialog';
import { Button } from './library/Button';
import { Dialogs } from '../constants/Dialogs';
import { useAppDispatch, useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { closeDialog } from '../ducks/dialogSlice';
import { useCreateMailgunDomainMutation } from '../services/domainApi';
import { DomainStatus } from '../types/DomainVerification';
import { EMPTY_CLIENT_EMAIL_DOMAIN_REFERENCE, useDomainRepo } from '../hooks/useDomainRepo';
import { CliendDataEmailDomainDialogOptions } from '../types/DialogState';

export const EmailDomainDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [validationClicked, setValidationClicked] = React.useState(false);

  const { key: dialogKey } = useAppSelector((state) => state.dialog);
  const { clientId } = useAppSelector((state) => state?.clientData);
  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { emailDomain = undefined } = options as CliendDataEmailDomainDialogOptions;
  const [createMailgunDomain, { isLoading: isLoadingMailgun }] = useCreateMailgunDomainMutation();

  const {
    clientEmailDomain = EMPTY_CLIENT_EMAIL_DOMAIN_REFERENCE,
    isLoadingClientEmailDomain,
    isFetchingClientEmailDomain,
    refetchClientEmailDomain,
  } = useDomainRepo();

  useEffect(() => {
    if (dialogKey === Dialogs.EmailDomain) {
      createMailgunDomain({ clientId })
        .unwrap()
        .then(() => refetchClientEmailDomain());
    }
  }, [clientId, dialogKey]); // Intentionally omitting functions from dependency array

  const handleCopy = (text: string): void => {
    navigator.clipboard.writeText(text);
  };

  const validateEmailDomain = (): void => {
    setValidationClicked(true);
    refetchClientEmailDomain();
  };

  const { status, records } = clientEmailDomain;
  const verified = status === DomainStatus.Verified;

  return (
    <>
      {(!validationClicked || isLoadingMailgun || isLoadingClientEmailDomain) && (
        <Dialog dialogKey={Dialogs.EmailDomain}>
          <DialogContent>
            <Stack direction="row" spacing="16px">
              <CircularProgress color="primary" size={20} />
              <Typography>{t(I18nKeys.SitesEmailDomainLoadingProgress)}</Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      {!isLoadingMailgun && !isLoadingClientEmailDomain && (
        <Dialog dialogKey={Dialogs.EmailDomain} maxWidth="md" fullWidth>
          <DialogTitle>{t(I18nKeys.SitesEmailDomainDialogTitle)}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container size={{ xs: 12 }} spacing="16px">
                <Grid size={{ xs: 12 }}>
                  {t(
                    !validationClicked && verified
                      ? I18nKeys.SitesEmailDomainDialogContentVerified
                      : I18nKeys.SitesEmailDomainDialogContent,
                  )}
                  <Link href={t(I18nKeys.SitesEmailDomainDialogLearnMoreLink)} target="_blank" rel="noopener">
                    {t(I18nKeys.SitesEmailDomainDialogLearnMore)}
                  </Link>
                </Grid>
                {records && (
                  <Grid container size={{ xs: 12 }} spacing="16px">
                    {Object.keys(records).map((dnsRecord: string) => {
                      const record = records[dnsRecord];
                      return (
                        <Grid container key={dnsRecord} size={{ xs: 12 }} spacing="8px">
                          <Grid container size={{ xs: 12 }} spacing={1}>
                            <Grid size={{ xs: 2 }}>
                              <Typography variant="body1">{`${dnsRecord.toUpperCase()} Record`}</Typography>
                            </Grid>
                            <Grid size={{ xs: 10 }}>
                              {dnsRecord === 'dmarc' ? t(I18nKeys.SitesEmailDomainRecordNoteDmarc) : null}
                            </Grid>
                          </Grid>
                          <Grid container size={{ xs: 12 }} spacing="16px">
                            <Grid size={{ xs: 2 }}>
                              <TextField
                                id={`filled-read-only-input-${dnsRecord}-type`}
                                label="Type"
                                defaultValue={record.type}
                                variant="filled"
                                fullWidth
                                sx={{ input: { cursor: 'pointer' } }}
                                onClick={() => handleCopy(record.type)}
                                onMouseDown={() => handleCopy(record.type)}
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="copy to clipboard"
                                          onClick={() => handleCopy(record.type)}
                                          onMouseDown={() => handleCopy(record.type)}
                                          edge="end"
                                        >
                                          <ContentCopy />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            </Grid>
                            <Grid size={{ xs: 4 }}>
                              <TextField
                                id={`filled-read-only-input-${dnsRecord}-host`}
                                label="Host"
                                defaultValue={record.host}
                                variant="filled"
                                fullWidth
                                sx={{ input: { cursor: 'pointer' } }}
                                onClick={() => handleCopy(record.host)}
                                onMouseDown={() => handleCopy(record.host)}
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="copy to clipboard"
                                          onClick={() => handleCopy(record.host)}
                                          onMouseDown={() => handleCopy(record.host)}
                                          edge="end"
                                        >
                                          <ContentCopy />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            </Grid>
                            <Grid size={{ xs: record.priority ? 4 : 6 }}>
                              <TextField
                                id={`filled-read-only-input-${dnsRecord}-expectedValue`}
                                label="Value"
                                defaultValue={record.expectedValue}
                                variant="filled"
                                fullWidth
                                sx={{ input: { cursor: 'pointer' } }}
                                onClick={() => handleCopy(record.expectedValue)}
                                onMouseDown={() => handleCopy(record.expectedValue)}
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="copy to clipboard"
                                          onClick={() => handleCopy(record.expectedValue)}
                                          onMouseDown={() => handleCopy(record.expectedValue)}
                                          edge="end"
                                        >
                                          <ContentCopy />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            </Grid>
                            {record.priority && (
                              <Grid size={{ xs: 2 }}>
                                <TextField
                                  id={`filled-read-only-input-${dnsRecord}-priority`}
                                  label="Priority"
                                  defaultValue={record.priority}
                                  variant="filled"
                                  fullWidth
                                  sx={{ input: { cursor: 'pointer' } }}
                                  onClick={() => handleCopy(record.priority || '')}
                                  onMouseDown={() => handleCopy(record.priority || '')}
                                  slotProps={{
                                    input: {
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="copy to clipboard"
                                            onClick={() => handleCopy(record.priority || '')}
                                            onMouseDown={() => handleCopy(record.priority || '')}
                                            edge="end"
                                          >
                                            <ContentCopy />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    },
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {!validationClicked && verified ? undefined : (
                  <>
                    <Grid size={{ xs: 12 }}>{t(I18nKeys.SitesEmailDomainDialogVerifyText)}</Grid>
                    <Grid size={{ xs: 12 }}>
                      <Grid container size={{ xs: 12 }} spacing="16px">
                        <Grid>
                          <Button variant="contained" onClick={validateEmailDomain}>
                            {t(I18nKeys.SitesEmailDomainVerifyButton)}
                          </Button>
                        </Grid>
                        <Grid alignContent="center">
                          {isFetchingClientEmailDomain && (
                            <Stack direction="row" spacing="16px">
                              <CircularProgress color="primary" size={20} />
                              <Typography>{t(I18nKeys.SitesEmailDomainVerifying)}</Typography>
                            </Stack>
                          )}
                          {validationClicked && !isFetchingClientEmailDomain && !verified && verified !== undefined && (
                            <Stack direction="row" spacing="16px">
                              <Cancel color="error" />
                              <Typography color="error.main">
                                {t(I18nKeys.SitesEmailDomainDomainVerificationFailed, { domain: emailDomain })}
                              </Typography>
                            </Stack>
                          )}
                          {validationClicked && !isFetchingClientEmailDomain && verified && (
                            <Stack direction="row" spacing="16px">
                              <CheckCircle color="success" />
                              <Typography color="success.main">
                                {t(I18nKeys.SitesEmailDomainDomainVerificationSucceeded, { domain: emailDomain })}
                              </Typography>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(): void => {
                dispatch(closeDialog());
              }}
              variant="outlined"
            >
              {t(I18nKeys.DialogCloseButton)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
