import { IntegrationStatus } from '@idearoom/types';
import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { MenuIconKey } from '../components/MenuIcon';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { User } from '../types/User';
import { ViewerState } from '../types/ViewerState';
import { isCurrentUserAdmin, isCurrentUserManager, isCurrentUserSuperUser, isIdeaRoomUser } from '../utils/userUtils';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { I18nKeys } from './I18nKeys';
import { canUsePayments } from '../utils/permissionUtils';
import { CurrentUserState } from '../types/CurrentUserState';
import { unknownGroup } from './Group';
import { VendorDataCategory } from './VendorData';
import { MainMenuItems } from './MainMenuItems';
import {
  BusinessInfoFormFields,
  ColorsAndLogosFormFields,
  LeadCaptureFormFields,
  BannersAndMessagesFormFields,
  DocumentsFormFields,
  FloorplanFormFields,
  ControlsAndFeaturesFormFields,
  VisibilityFormFields,
} from './FormFields';
import { snakeCaseToCamelCase } from '../utils/stringUtils';

type MainMenuSection = MainMenuItem[];
type SecondaryMenuSection = {
  label: (t: TFunction) => string;
  items: SecondaryMenuItem[];
};

type SecondaryMenuItem = {
  id?: string;
  label: (t: TFunction) => string;
  ideaRoomOnly?: (user: User, selectedGroupId: string, viewerState: ViewerState) => boolean;
  fields?: string[];
};

type MainMenuItem = {
  id: MainMenuItems;
  visible: (
    user: User,
    isIdearoomGroup: boolean,
    configurators: any[],
    selectedGroupId: string,
    viewerState: ViewerState,
  ) => boolean;
  label: (t: TFunction) => string;
  ideaRoomOnly: (user: User, selectedGroupId: string, viewerState: ViewerState) => boolean;
  primaryIcon?: (viewerState: ViewerState) => MenuIconKey | undefined;
  secondaryIcon?: (viewerState: ViewerState) => MenuIconKey | undefined;
  subMenu?: (dispatch: Dispatch<any>, currentUserState: CurrentUserState) => SecondaryMenuItem[];
  secondaryMenu?: SecondaryMenuSection[];
};

export const mainMenu: MainMenuSection[] = [
  [
    {
      id: MainMenuItems.Home,
      visible: (_user: User, isIdearoomGroup: boolean, configurators: any[]) =>
        !isIdearoomGroup && configurators && configurators[0]?.vendorData?.vendor?.maxioSubscriptionId,
      label: (t: TFunction) => t(I18nKeys.MenuHomeOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.HOME,
    },
    {
      id: MainMenuItems.Sites,
      visible: (user: User, isIdearoomGroup: boolean) =>
        !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
      label: (t: TFunction) => t(I18nKeys.MenuSitesOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.SITES,
      secondaryMenu: [
        {
          label: () => 'Site Basics',
          items: [
            {
              id: VendorDataCategory.BusinessInfo,
              label: () => 'Business Info',
              fields: Object.values(BusinessInfoFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.ColorsAndLogos,
              label: () => 'Colors & Logos',
              fields: Object.values(ColorsAndLogosFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.LeadCapture,
              label: () => 'Lead Capture',
              fields: Object.values(LeadCaptureFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.BannersAndMessages,
              label: () => 'Banners & Messages',
              fields: Object.values(BannersAndMessagesFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.Documents,
              label: () => 'Documents',
              fields: Object.values(DocumentsFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.Floorplan,
              label: () => 'Floorplan',
              fields: Object.values(FloorplanFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.ControlsAndFeatures,
              label: () => 'Controls & Features',
              fields: Object.values(ControlsAndFeaturesFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
            {
              id: VendorDataCategory.Visibility,
              label: () => 'Visibility',
              fields: Object.values(VisibilityFormFields).map((field) => snakeCaseToCamelCase(field)),
            },
          ],
        },
      ],
      subMenu: (dispatch, { group: { configurators = [] } = unknownGroup }: CurrentUserState) =>
        configurators.map(({ clientId = '', name = '' }) => ({
          id: clientId,
          label: () => name,
        })),
    },
    {
      id: MainMenuItems.Sales,
      visible: (_user: User, isIdearoomGroup: boolean) => !isIdearoomGroup,
      label: (t: TFunction) => t(I18nKeys.MenuSalesOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.SALES,
      subMenu: () => [
        {
          id: MainMenuItems.Leads,
          visible: (_user: User, isIdearoomGroup: boolean) => !isIdearoomGroup,
          label: (t: TFunction) => t(I18nKeys.MenuLeadsOption),
          ideaRoomOnly: () => false,
        },
        {
          id: MainMenuItems.Orders,
          visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
          label: (t: TFunction) => t(I18nKeys.MenuOrdersOption),
          ideaRoomOnly: () => false,
        },
        {
          id: MainMenuItems.Payments,
          visible: (
            user: User,
            isIdearoomGroup: boolean,
            _configurators: any[],
            selectedGroupId: string,
            viewerState: ViewerState,
          ) => !isIdearoomGroup && canUsePayments(user, selectedGroupId, viewerState.paymentIntegrationStatus?.status),
          label: (t: TFunction) => t(I18nKeys.MenuPaymentsOption),
          ideaRoomOnly: (user, selectedGroupId, viewerState) =>
            !canUsePayments(user, selectedGroupId, viewerState.paymentIntegrationStatus?.status),
          secondaryIcon: (viewerState: ViewerState) => {
            switch (viewerState.paymentIntegrationStatus?.status) {
              case IntegrationStatus.Disconnected:
                return MenuIconKey.NEW;
              case IntegrationStatus.Pending:
                return MenuIconKey.ATTENTION;
              case IntegrationStatus.Failed:
                return MenuIconKey.WARNING;
              default:
                return undefined;
            }
          },
        },
      ],
    },
    {
      id: MainMenuItems.Analyze,
      visible: (user: User, isIdearoomGroup: boolean) =>
        !isIdearoomGroup && (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
      label: (t: TFunction) => t(I18nKeys.MenuUsageOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.ANALYZE,
    },
    {
      id: MainMenuItems.Groups,
      visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
      label: (t: TFunction) => t(I18nKeys.MenuGroupsOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.TEAM,
    },
    {
      id: MainMenuItems.Pricing,
      visible: (user: User, isIdearoomGroup: boolean, configurators: any[]) =>
        !isIdearoomGroup &&
        (isCurrentUserAdmin(user) || isIdeaRoomUser(user)) &&
        configurators.some((c) => getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true)),
      label: (t: TFunction) => t(I18nKeys.MenuPricingOption),
      ideaRoomOnly: () => true,
      primaryIcon: () => MenuIconKey.SALES,
    },
    {
      id: MainMenuItems.ClientData,
      visible: (user: User) => isIdeaRoomUser(user) || isCurrentUserSuperUser(user),
      label: (t: TFunction) => t(I18nKeys.MenuClientDataOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.SITES,
    },
  ],
  [
    {
      id: MainMenuItems.Team,
      visible: (user: User) => isIdeaRoomUser(user) || isCurrentUserAdmin(user) || isCurrentUserManager(user),
      label: (t: TFunction) => t(I18nKeys.MenuTeamOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.TEAM,
    },
    {
      id: MainMenuItems.Dealers,
      visible: (user: User, _: boolean, configurators: any[]) =>
        configurators.some((c) =>
          getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
        ) &&
        (isIdeaRoomUser(user) || isCurrentUserAdmin(user)),
      label: (t: TFunction) => t(I18nKeys.MenuDealersOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.DEALERS,
    },
    {
      id: MainMenuItems.Integrations,
      visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup && isIdeaRoomUser(user),
      label: (t: TFunction) => t(I18nKeys.MenuIntegrationsOption),
      ideaRoomOnly: () => true,
      primaryIcon: () => MenuIconKey.INTEGRATIONS,
    },
    {
      id: MainMenuItems.Reports,
      visible: (_user: User, isIdearoomGroup: boolean) => isIdearoomGroup,
      label: (t: TFunction) => t(I18nKeys.MenuReportsOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.REPORTS,
    },
  ],
  [
    {
      id: MainMenuItems.Settings,
      visible: (user: User, _: boolean, _configurators: any[], selectedGroupId: string) =>
        /* TODO: Add back in commented line after soft release */
        /* {(isIdeaRoomUser(user) || isCurrentUserAdmin(user)) && ( */
        isIdeaRoomUser(user) ||
        (['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId) && isCurrentUserAdmin(user)),
      label: (t: TFunction) => t(I18nKeys.MenuSettingsOption),
      ideaRoomOnly: (user: User, selectedGroupId: string) =>
        isIdeaRoomUser(user) && !['EagleCarports', 'Hillhout', 'IdeaRoom'].includes(selectedGroupId),
      primaryIcon: () => MenuIconKey.SETTINGS,
    },
    {
      id: MainMenuItems.Help,
      visible: (user: User, isIdearoomGroup: boolean) => !isIdearoomGroup,
      label: (t: TFunction) => t(I18nKeys.MenuHelpOption),
      ideaRoomOnly: () => false,
      primaryIcon: () => MenuIconKey.HELP,
    },
  ],
];
