import { Step } from 'react-joyride';
import { TFunction } from 'i18next';
import { tourSteps } from '../constants/TourSteps';
import {
  Columns,
  allAvailableColumns,
  TableFilterType,
  defaultAvailableColumns,
  unknownUserDefaultColumns,
  mobileLogoBarHeight,
  logoBarHeight,
  clientDataTopBarHeight,
  breadcrumbsHeight,
  SalesViewUILayout,
} from '../constants/Viewer';
import { GroupFilter } from '../types/CustomFilter';
import { Dealer } from '../types/Dealer';
import { Group, GroupData } from '../types/Group';
import { unknownGroup } from '../constants/Group';
import { unknownUser, User } from '../types/User';
import { compoundCaseToTitleCase, pluralizeString } from './stringUtils';
import { isCurrentUserAdmin, isCurrentUserManager } from './userUtils';
import { getEnabledOnProperty } from './vendorDataUtils';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { AppRoutes } from '../constants/AppRoutes';
import { I18nKeys } from '../constants/I18nKeys';
import menuIntegrationsIcon from '../images/menuIntegrationsIcon.svg';
import homeIcon from '../images/homeIcon.svg';
import cashMultipleIcon from '../images/cashMultipleIcon.svg';
import { MenuIconKey } from '../components/MenuIcon';

export const getTourSteps = (groups: GroupData[]): Step[] => {
  const steps = tourSteps
    .filter((step) => step.filter(groups))
    .map((step): any => ({
      target: step.target,
      content: step.content,
    }));
  return steps.length > 0 ? [{ ...steps[0], disableBeacon: true }, ...steps.slice(1)] : [];
};

export const getFilterMessage = (
  groupId: string | undefined,
  groupFilters: GroupFilter[],
  tableFilterType: TableFilterType,
): string => {
  let message = '';
  const tableFilters = groupFilters.find((groupfilter) => groupfilter.groupId === groupId);
  const filtersOfType = tableFilters
    ? tableFilters.tableFilters.find((tableFilter) => tableFilter.tableFilterType === tableFilterType)
    : undefined;
  if (filtersOfType && filtersOfType.filters.length > 0) {
    message += 'for';
    // Get filters not containing 'all'
    const filterableResults = filtersOfType.filters.filter(
      (filter) => !filter.selectedFilterValues.some((selected) => selected.key === 'all'),
    );
    filterableResults.forEach((filter, index) => {
      const filterLabel = compoundCaseToTitleCase(filter.filterType || '').toLowerCase();
      const pluralFilter = filter.selectedFilterValues.length > 1 ? pluralizeString(filterLabel) : filterLabel;
      message +=
        filter.filterType && filter.selectedFilterValues.length > 0
          ? ` ${filter.selectedFilterValues.length} ${pluralFilter}`
          : '';
      if (index !== filterableResults.length - 1) {
        if (filterableResults.length > 2 && index === filterableResults.length - 2) {
          message += ', and';
        } else if (filterableResults.length === 2 && index === 0) {
          message += ' and';
        } else {
          message += ',';
        }
      }
    });
  }
  return message;
};

export const getUsersAvailableColumns = (
  user: User = unknownUser,
  group: Group = unknownGroup,
  availableDealers: Dealer[] = [],
): string[] => {
  let availableColumns = defaultAvailableColumns;

  if (user) {
    if (isCurrentUserAdmin(user) || isCurrentUserManager(user)) {
      availableColumns = allAvailableColumns;
    }
  } else {
    availableColumns = unknownUserDefaultColumns;
  }

  if (availableDealers.length < 2) {
    availableColumns = availableColumns.filter((column) => column !== Columns.Dealer);
  }

  if (group && group.configurators) {
    if (group.configurators.length < 2) {
      availableColumns = availableColumns.filter((column) => column !== Columns.Site);
    }
    if (
      !group.configurators.some((config) =>
        getEnabledOnProperty(config.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      )
    ) {
      availableColumns = availableColumns.filter((column) => column !== Columns.Dealer);
    }
  } else {
    availableColumns = availableColumns.filter((column) => column !== Columns.Site && column !== Columns.Dealer);
  }

  return availableColumns;
};

export const getUnsavedChangesMessage = (path: string, t: TFunction): string => {
  let messageKey;
  if (path.startsWith(AppRoutes.Pricing)) {
    messageKey = I18nKeys.PricingUnsavedChangesConfirmationMessage;
  }
  if (path.startsWith(AppRoutes.Sites)) {
    messageKey = I18nKeys.SitesUnsavedChangesConfirmationMessage;
  }
  return messageKey ? t(messageKey) : '';
};

export const getLogoBarHeight = ({
  layout,
  mobileView,
  path,
}: {
  layout: SalesViewUILayout;
  mobileView: boolean;
  path: string;
}): number => {
  if (mobileView) {
    return mobileLogoBarHeight;
  }
  if (path.startsWith(AppRoutes.ClientData)) {
    return clientDataTopBarHeight;
  }
  // UI Layout Remove Me
  if (layout !== SalesViewUILayout.Horizon) {
    return 86 + breadcrumbsHeight;
  }

  return logoBarHeight;
};

export const getBreadcrumbsHeight = ({ path }: { path: string }): number => {
  if ([AppRoutes.ClientData, AppRoutes.Sites, AppRoutes.Profile].some((route) => path.startsWith(route))) {
    return 0;
  }
  return breadcrumbsHeight;
};

export const getMenuIconSvgs = async (color: string) =>
  Object.fromEntries(
    await Promise.all(
      Object.entries({
        [MenuIconKey.HOME]: homeIcon,
        [MenuIconKey.INTEGRATIONS]: menuIntegrationsIcon,
        [MenuIconKey.SALES]: cashMultipleIcon,
      }).map(([key, svgUrl]) =>
        fetch(svgUrl)
          .then((response) => response.text())
          .then((svgText) => {
            const coloredSvgText = svgText.replace(/fill="[^"]*"/g, `fill="${color}"`);
            const blob = new Blob([coloredSvgText], { type: 'image/svg+xml' });
            return [key, [svgUrl, URL.createObjectURL(blob)]];
          }),
      ),
    ),
  );
