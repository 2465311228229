import React from 'react';
import { PriceCalculation, PriceColumn } from '@idearoom/types';
import { Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid, MenuItem, FormControl, FormHelperText, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Forms } from '../constants/Forms';
import { Form } from './redux-form/Form';
import { PricingComponentEditFields } from '../constants/FormFields';
import { InputField } from './redux-form/InputField';
import { PriceField } from './redux-form/PriceField';
import { SelectField } from './redux-form/SelectField';
import { AppState } from '../types/AppState';
import { I18nKeys } from '../constants/I18nKeys';
import {
  ActionColumn,
  CLIENT_UPDATE_CATEGORY_MAPPING,
  ComponentAttributeColumns,
  DisplayColumns,
  ComponentFormData,
  HelperColumns,
  MiscPriceColumns,
  PricingCalculationColumns,
  componentColumnMap,
} from '../constants/PricingClientUpdate';
import {
  getComponentFieldLabel,
  getPricingTypeLabel,
  isPricingField,
  isRegionalPricingField,
  displayVaryByRegion,
  onComponentFieldChange,
  getComponentAttributeLabel,
  contactSupportForPricing,
} from '../utils/pricingClientUpdateUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { LabeledCheckboxField } from './redux-form/LabeledCheckboxField';

export interface FormData {
  [PricingComponentEditFields.Component]: string;
  [PricingComponentEditFields.Table]: string;
  [DisplayColumns.Label]: string;
  [PriceColumn.price]?: string;
  [PriceColumn.region1]?: string;
  [PriceColumn.region2]?: string;
  [PriceColumn.region3]?: string;
  [PriceColumn.region4]?: string;
  [PriceColumn.region5]?: string;
  [PriceColumn.region6]?: string;
  [PriceColumn.region7]?: string;
  [PricingCalculationColumns.PriceCalculation]?: string;
  [MiscPriceColumns.UpgradePrice]?: string;
  [DisplayColumns.PriceExpression]?: string;
  [ComponentAttributeColumns.WallPosition]?: string;
  [ComponentAttributeColumns.WidthTags]?: string;
  [ComponentAttributeColumns.Height]?: string;
  [ComponentAttributeColumns.Width]?: string;
  [ComponentAttributeColumns.CustomExpression]?: string;
  [HelperColumns.VariesByRegion]: boolean;
  [HelperColumns.Key]: string;
}

interface CustomProps {
  formFields: (
    | PricingComponentEditFields
    | DisplayColumns
    | ComponentAttributeColumns
    | HelperColumns.Key
    | HelperColumns.VariesByRegion
    | ActionColumn
    | PriceColumn
    | MiscPriceColumns
    | PricingCalculationColumns
  )[];
  showKeyAsHelperText?: boolean;
  onSubmit: { (values: ComponentFormData): void };
}

type FormProps = CustomProps & InjectedFormProps<ComponentFormData, CustomProps>;

const PricingComponentEditFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  formFields,
  showKeyAsHelperText = false,
  onSubmit,
}: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    component: { selectedCategoryKey: componentCategoryKey = '' },
  } = useAppSelector((state: AppState) => state?.pricing);

  const currentValues = useAppSelector((state) => formValueSelector(Forms.PricingComponentEdit)(state, ...formFields));

  const {
    clientTableColumns: { [currentValues[PricingComponentEditFields.Table]]: componentTableColumns = [] },
    isLoadingClientTableColumns,
  } = useClientDataRepo({
    useClientTablesColumns: true,
  });

  const {
    componentCategoryItemsWithConditions = [],
    clientUpdateRegions: regions = [],
    clientUpdateStyles: styles = [],
  } = useClientUpdatePricingRepo({
    useComponentCategoryItemsWithConditions: true,
    useClientUpdateRegions: true,
    useClientUpdateStyles: true,
  });
  const selectedItemWithConditions = componentCategoryItemsWithConditions.find(
    ({ item }) => item.rowId === currentValues[PricingComponentEditFields.Component],
  );

  if (!componentCategoryKey || !selectedItemWithConditions) return null;

  const enableRegions = displayVaryByRegion(regions, componentTableColumns);
  const expressionExists = formFields.includes(DisplayColumns.PriceExpression);
  const varyPriceByRegion = enableRegions && currentValues[HelperColumns.VariesByRegion];
  const disablePriceCalculation = !componentTableColumns.includes(PricingCalculationColumns.PriceCalculation);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="16px" size={{ xs: 12 }}>
        {formFields.map((field) => {
          if (
            (
              [[DisplayColumns.Label, DisplayColumns.PriceExpression], PriceColumn, MiscPriceColumns].flatMap((e) =>
                Object.values(e),
              ) as string[]
            ).includes(field)
          ) {
            const isLabelField = DisplayColumns.Label === field;
            const isExpressionField = DisplayColumns.PriceExpression === field;
            const isPriceField = isPricingField(field);
            const displayPricingCalculation = !!componentColumnMap[field as string]?.calculation;
            const allowEditing = !contactSupportForPricing(field as string, selectedItemWithConditions);

            const attributes = [...Object.values(ComponentAttributeColumns)].filter(
              (c) =>
                selectedItemWithConditions.item[c] &&
                !contactSupportForPricing(PriceColumn.price, selectedItemWithConditions),
            );

            // Only display region prices that exist for the table and are used by the vendor's regions
            if (
              isRegionalPricingField(field) &&
              (!varyPriceByRegion ||
                !enableRegions ||
                regions.every(({ priceColumn }) => priceColumn !== (field as string)))
            ) {
              return null;
            }
            return (
              <Grid
                container
                direction="row"
                key={field}
                spacing={2}
                size={{ xs: isLabelField || displayPricingCalculation ? 12 : 6 }}
              >
                {displayPricingCalculation && (
                  <Grid size={{ xs: 6 }}>
                    <FormControl fullWidth>
                      <Field
                        component={SelectField}
                        label={t(I18nKeys.PricingCalculationField)}
                        name={componentColumnMap[field as string]?.calculation}
                        variant="filled"
                        slotProps={{
                          select: {
                            displayEmpty: true,
                            disabled: disablePriceCalculation,
                            renderValue: (value: PriceCalculation) => getPricingTypeLabel(value),
                          },
                        }}
                      >
                        {Object.entries(PriceCalculation).map(([, value]) => (
                          <MenuItem key={value} value={value}>
                            {getPricingTypeLabel(value)}
                          </MenuItem>
                        ))}
                      </Field>
                      {!isLoadingClientTableColumns &&
                        !componentTableColumns.includes(PricingCalculationColumns.PriceCalculation) && (
                          <FormHelperText
                            sx={{ width: '300px' }}
                            dangerouslySetInnerHTML={{
                              __html: t(I18nKeys.PricingComponentNoPricingType, {
                                category: t(CLIENT_UPDATE_CATEGORY_MAPPING[componentCategoryKey]?.label),
                              }),
                            }}
                          />
                        )}
                    </FormControl>
                  </Grid>
                )}
                <Grid size={{ xs: displayPricingCalculation ? 6 : 12 }}>
                  <FormControl fullWidth>
                    {isPriceField ? (
                      <Field
                        component={PriceField}
                        name={field}
                        autoComplete="off"
                        label={getComponentFieldLabel(field, regions, varyPriceByRegion)}
                        variant="filled"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onComponentFieldChange(event, currentValues as ComponentFormData, dispatch)
                        }
                        disabled={(isPriceField && expressionExists) || !allowEditing}
                        hideHelperText
                        errorWithoutTouch={false}
                      />
                    ) : (
                      <Field
                        name={field}
                        autoComplete="off"
                        component={InputField}
                        label={getComponentFieldLabel(field, regions, varyPriceByRegion)}
                        variant="filled"
                        slotProps={{
                          input: {
                            multiline: isExpressionField,
                            minRows: 5,
                            maxRows: 5,
                            style: {
                              ...(isExpressionField ? { padding: '12px 8px', fontFamily: 'monaco' } : {}),
                            },
                          },
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          onComponentFieldChange(event, currentValues as FormData, dispatch)
                        }
                        disabled={isExpressionField || (isPriceField && expressionExists) || !allowEditing}
                      />
                    )}
                    {isExpressionField && (
                      <FormHelperText
                        dangerouslySetInnerHTML={{
                          __html: t(I18nKeys.PricingComponentPriceExpressionHelp),
                        }}
                      />
                    )}
                    {showKeyAsHelperText && isLabelField && (
                      <FormHelperText>
                        {`${t(I18nKeys.PricingComponentKeyLabel)} ${currentValues[HelperColumns.Key]}`}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {isLabelField &&
                  !!attributes.length &&
                  attributes
                    .filter((c) => ![ComponentAttributeColumns.CustomExpression].includes(c))
                    .map((attribute) => {
                      const attributeLabel = getComponentAttributeLabel(
                        attribute,
                        currentValues[PricingComponentEditFields.Component],
                        componentCategoryKey,
                        componentCategoryItemsWithConditions,
                        styles,
                        false,
                      );
                      return (
                        attributeLabel && (
                          <Grid size={{ xs: 12 }} key={attribute}>
                            <Typography>{attributeLabel}</Typography>
                          </Grid>
                        )
                      );
                    })}
              </Grid>
            );
          }

          if (enableRegions && HelperColumns.VariesByRegion === field) {
            return (
              <Grid size={{ xs: 12 }}>
                <Field
                  name={field}
                  autoComplete="off"
                  component={LabeledCheckboxField}
                  label={t(I18nKeys.PricingVaryByRegion)}
                  variant="filled"
                />
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </Form>
  );
};

export const PricingComponentEditForm = reduxForm<ComponentFormData, CustomProps>({
  form: Forms.PricingComponentEdit,
})(PricingComponentEditFormComponent);
