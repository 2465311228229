export const sanitizeColor = (input: string): string | null => {
  // Regex to match allowed characters in CSS color values
  const allowedColorRegex = /[#0-9a-fA-F,.\s()a-zA-Z]+/g;

  // Clean the input by removing disallowed characters
  const cleanedInput = (input?.match(allowedColorRegex) || []).join('').trim();

  // Regexes for validating color formats
  const hexColorRegex = /^#([0-9a-fA-F]{3}){1,2}$/;
  const rgbColorRegex = /^rgb\(\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?)\s*\)$/;
  const rgbaColorRegex = /^rgba\(\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*(0|1|0?\.\d+)\s*\)$/;
  const namedColorRegex =
    /^(red|blue|green|yellow|black|white|gray|grey|pink|orange|purple|brown|lime|cyan|magenta|teal)$/i;

  // Validate if the cleaned string is a valid color
  if (
    hexColorRegex.test(cleanedInput) ||
    rgbColorRegex.test(cleanedInput) ||
    rgbaColorRegex.test(cleanedInput) ||
    namedColorRegex.test(cleanedInput)
  ) {
    return cleanedInput.toLocaleUpperCase();
  }

  // Return null if the cleaned string is not a valid color
  return null;
};

export const replaceTokens = (string: string | undefined, tokens: Record<string, string | number | boolean | null>) =>
  Object.entries(tokens)
    .reduce((str, [key, value]) => str.replace(`{{${key}}}`, `${value || ''}`), string || '')
    .replace(/<[^>]*>/g, '');
