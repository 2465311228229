import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, Grid2 as Grid, Stack, useMediaQuery } from '@mui/material';
import { FloorplanLabelingMethod } from '@idearoom/types';
import { reduxForm, Form, InjectedFormProps, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { FloorplanFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { Header } from '../library/Header';
import { RadioGroupField } from '../redux-form/RadioGroupField';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { RadioField } from '../redux-form/RadioField';
import { isIdeaRoomUser } from '../../utils/userUtils';
import { unknownUser } from '../../types/User';
import { unknownGroup } from '../../constants/Group';

export interface FormData {
  [FloorplanFormFields.UseFloorPlanGridDimensions]: number;
  [FloorplanFormFields.FloorplanLabelingMethod]: string | number | boolean;
  [FloorplanFormFields.FloorplanShowSideTexts]: boolean;
  [FloorplanFormFields.UseFloorPlanGridDimensionsLegend]: boolean;
}

const FloorplanFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state) => state.clientData);
  const groupId = useAppSelector((state) => (state.currentUser.group || unknownGroup).groupId);
  const ideaRoomUser = useAppSelector((state) => isIdeaRoomUser(state.currentUser.user || unknownUser));
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [initializing, setInitializing] = useState(true);

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Box sx={{ m: '-24px' }}>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid
            container
            size={{ xs: 12, lg: 'grow' }}
            sx={{
              p: '24px',
              borderRight: mobileView ? 'none' : '1px solid #DEDEDE',
              borderBottom: mobileView ? '1px solid #DEDEDE' : 'none',
            }}
          >
            <Stack spacing="24px">
              <Stack spacing="8px" sx={{ width: '100%' }}>
                <Header initializing={initializing} header="Grid style" />
                <FormControl fullWidth>
                  <Field
                    name={FloorplanFormFields.UseFloorPlanGridDimensions}
                    component={RadioGroupField}
                    onChange={(value: any) => onChange(FloorplanFormFields.UseFloorPlanGridDimensions, !!Number(value))}
                    initializing={initializing}
                  >
                    <Stack rowGap="8px">
                      <RadioField
                        initializing={initializing}
                        value={0}
                        label="No grid"
                        color="secondary"
                        sx={{ py: '4px' }}
                      />
                      <RadioField
                        initializing={initializing}
                        value={1}
                        label="1 foot grid lines"
                        color="secondary"
                        sx={{ py: '4px' }}
                      />
                    </Stack>
                  </Field>
                </FormControl>
              </Stack>
              {ideaRoomUser && groupId === 'WeatherKingPortableBuildings' && (
                <Stack spacing="8px" sx={{ width: '100%' }}>
                  <Header initializing={initializing} header="Component labels" ideaRoomOnly />
                  <FormControl fullWidth>
                    <Field
                      name={FloorplanFormFields.FloorplanLabelingMethod}
                      component={RadioGroupField}
                      onChange={(value: any) => onChange(FloorplanFormFields.FloorplanLabelingMethod, value)}
                      initializing={initializing}
                    >
                      <Stack rowGap="8px">
                        {Object.values(FloorplanLabelingMethod).map((method) => (
                          <RadioField
                            initializing={initializing}
                            label={t(`sites-floorplan-floorplan-labeling-method-field-${method}`)}
                            value={method}
                            color="secondary"
                            sx={{ py: '4px' }}
                          />
                        ))}
                      </Stack>
                    </Field>
                  </FormControl>
                </Stack>
              )}
              <Stack spacing="8px" sx={{ width: '100%' }}>
                <Header initializing={initializing} header="Include on floorplan" />
                {ideaRoomUser && groupId === 'WeatherKingPortableBuildings' && (
                  <FormControl fullWidth>
                    <Field
                      name={FloorplanFormFields.FloorplanShowSideTexts}
                      component={LabeledCheckboxField}
                      label="Front, back, left, right labels"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(FloorplanFormFields.FloorplanShowSideTexts, !!event.target.checked)
                      }
                      ideaRoomOnly
                      initializing={initializing}
                    />
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <Field
                    name={FloorplanFormFields.UseFloorPlanGridDimensionsLegend}
                    component={LabeledCheckboxField}
                    label="Grid line size legend"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(FloorplanFormFields.UseFloorPlanGridDimensionsLegend, !!event.target.checked)
                    }
                    initializing={initializing}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
          <Grid container sx={{ p: '24px', pl: mobileView ? '24px' : '18px' }}>
            <Box height="572px" width="572px">
              {/* The Floorplan preview section will be here */}
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export const FloorplanForm = reduxForm<FormData>({
  form: Forms.Floorplan,
  enableReinitialize: true,
})(FloorplanFormComponent);
