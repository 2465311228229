/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  alpha,
  Box,
  CircularProgress,
  SelectProps,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContentSkeleton } from './ContentSkeleton';

type Props = SelectProps & {
  helperText?: string;
  loading?: boolean;
  initializing?: boolean;
  height?: string;
};

const LoadingOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  textAlign: 'center',
  padding: '25px 12px 0px',
}));

export const SelectField: React.FC<Props> = ({
  id,
  children,
  margin = 'none',
  disabled,
  loading,
  initializing,
  height = '56px',
  label,
  helperText,
  ...customProps
}: Props): JSX.Element => (
  <ContentSkeleton initializing={initializing} variant="input" height={height}>
    <Box width="100%">
      <FormControl fullWidth variant="filled">
        <InputLabel
          sx={{
            opacity: disabled || loading ? 0.6 : 0.7,
            color: disabled || loading ? '#000000' : '#323B4B',
            transform: 'translate(14px, 7px) scale(0.75)',
          }}
          id={`${id}-label`}
        >
          {label}
        </InputLabel>
        <Select
          {...customProps}
          id={id}
          disabled={disabled || loading}
          label={label}
          displayEmpty
          autoWidth={false}
          sx={{
            width: '100%',
            height,
            backgroundColor: disabled || loading ? '#DFE0E1' : '#F2F4F5',
            color: alpha('#323B4B', disabled || loading ? 0.6 : 1),
            borderTopLeftRadius: '4px !important',
            borderTopRightRadius: '4px !important',
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
            PaperProps: {
              style: {
                maxHeight: 500,
              },
            },
          }}
          slotProps={{
            input: {
              sx: {
                px: '16px',
                ...(!label ? { py: '16.5px' } : {}),
              },
            },
          }}
          margin={margin}
        >
          {children}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      {loading && (
        <LoadingOverlay>
          <CircularProgress size={20} sx={{ color: '#323B4B', opacity: 0.6 }} />
        </LoadingOverlay>
      )}
    </Box>
  </ContentSkeleton>
);
