import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, Grid2 as Grid, Stack } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, Field } from 'redux-form';
import { FormatLineSpacing, Share, ViewInAr } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Forms } from '../../constants/Forms';
import { ControlsAndFeaturesFormFields } from '../../constants/FormFields';
import { updateSiteDetails } from '../../middleware/siteDetailsThunk';
import { snakeCaseToCamelCase } from '../../utils/stringUtils';
import { useSharedDeleteBranchMutation } from '../../services/clientDataApi';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { isNotSiteDetailBranch } from '../../utils/vendorDataUtils';
import { LabeledCheckboxField } from '../redux-form/LabeledCheckboxField';
import { Header } from '../library/Header';
import changeBackgroundSrc from '../../images/changeBackgroundIcon.svg';

export interface FormData {
  [ControlsAndFeaturesFormFields.DisableSharing]: boolean;
  [ControlsAndFeaturesFormFields.DisableAR]: boolean;
  [ControlsAndFeaturesFormFields.HideCenterComponentsButton]: boolean;
  [ControlsAndFeaturesFormFields.DisableSceneEnvironmentChange]: boolean;
  [ControlsAndFeaturesFormFields.EnableDesignAutoSave]: boolean;
}

const ControlsAndFeaturesFormComponent: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const dispatch = useAppDispatch();

  const { isCreatingBranch } = useAppSelector((state) => state.clientData);
  const [, { isLoading: isDeletingBranch }] = useSharedDeleteBranchMutation();
  const { isInitializingSelectedTableData, isUninitializedSelectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    setInitializing(
      isInitializingSelectedTableData || isUninitializedSelectedTableData || isCreatingBranch || isDeletingBranch,
    );
  }, [isInitializingSelectedTableData, isUninitializedSelectedTableData, isCreatingBranch, isDeletingBranch]);

  const onChange = useCallback(
    (column: string, value: string | boolean) => {
      dispatch(updateSiteDetails([{ column: snakeCaseToCamelCase(column), value }]));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing="24px">
        <Grid container size={{ xs: 12, lg: 6 }}>
          <Stack spacing="8px" sx={{ width: '100%' }}>
            <Header sx={{ mb: '8px' }} initializing={initializing} header="Allowed controls for buyers" />
            <FormControl fullWidth>
              <Field
                name={ControlsAndFeaturesFormFields.DisableSharing}
                component={LabeledCheckboxField}
                label="Share"
                startAdornment={<Share sx={{ opacity: 0.7 }} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // Inverting value, because property is negative
                  onChange(ControlsAndFeaturesFormFields.DisableSharing, !event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
            <FormControl fullWidth>
              <Field
                name={ControlsAndFeaturesFormFields.DisableAR}
                component={LabeledCheckboxField}
                label="View in your space"
                startAdornment={<ViewInAr sx={{ opacity: 0.7 }} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // Inverting value, because property is negative
                  onChange(ControlsAndFeaturesFormFields.DisableAR, !event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
            <FormControl fullWidth>
              <Field
                name={ControlsAndFeaturesFormFields.HideCenterComponentsButton}
                component={LabeledCheckboxField}
                label="Center items"
                startAdornment={<FormatLineSpacing sx={{ opacity: 0.7, rotate: '90deg' }} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // Inverting value, because property is negative
                  onChange(ControlsAndFeaturesFormFields.HideCenterComponentsButton, !event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
            <FormControl fullWidth>
              <Field
                name={ControlsAndFeaturesFormFields.DisableSceneEnvironmentChange}
                component={LabeledCheckboxField}
                label="Change background"
                startAdornment={<img alt="Change background" src={changeBackgroundSrc} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // Inverting value, because property is negative
                  onChange(ControlsAndFeaturesFormFields.DisableSceneEnvironmentChange, !event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }}>
          <Stack spacing="8px" sx={{ width: '100%' }}>
            <Header sx={{ mb: '8px' }} initializing={initializing} header="Site behavior" />
            <FormControl fullWidth>
              <Field
                name={ControlsAndFeaturesFormFields.EnableDesignAutoSave}
                component={LabeledCheckboxField}
                label="Auto-save design"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(ControlsAndFeaturesFormFields.EnableDesignAutoSave, !!event.target.checked)
                }
                initializing={initializing}
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export const ControlsAndFeaturesForm = reduxForm<FormData>({
  form: Forms.ControlsAndFeatures,
  enableReinitialize: true,
})(ControlsAndFeaturesFormComponent);
