import React from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm, Validator } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, Grid2 as Grid } from '@mui/material';
import { Forms } from '../constants/Forms';
import { InputField } from './redux-form/InputField';
import { useAppSelector } from '../hooks';
import { SetupPaymentIntegrationFormFields } from '../constants/FormFields';
import { AppState } from '../types/AppState';
import { I18nKeys } from '../constants/I18nKeys';
import { Form } from './redux-form/Form';

export interface FormData {
  [SetupPaymentIntegrationFormFields.LegalEntityName]: string;
  [SetupPaymentIntegrationFormFields.ShopperStatement]: string;
}

type FormProps = InjectedFormProps<FormData>;

const nullOrEmpty: Validator = (value: string) => {
  if (!value || value.length === 0) {
    return 'error';
  }
  return undefined;
};

const MAX_SHOPPER_STATEMENT_LENGTH = 22;

const SetupPaymentIntegrationFormComponent: React.FC<FormProps> = () => {
  const { t } = useTranslation();

  const shopperStatement = useAppSelector((state: AppState) =>
    formValueSelector(Forms.SetupPaymentIntegration)(state, SetupPaymentIntegrationFormFields.ShopperStatement),
  );

  return (
    <Form>
      <Field
        autoFocus
        type="string"
        autoComplete="off"
        hideHelperText
        component={InputField}
        label={t(I18nKeys.SetupPaymentIntegrationDialogLegalName)}
        validate={nullOrEmpty}
        name={SetupPaymentIntegrationFormFields.LegalEntityName}
        variant="filled"
      />
      <Grid container>
        <Grid size={12}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            component={InputField}
            validate={nullOrEmpty}
            normalize={(value: any, previousValue: any) =>
              value && value.trim().length > MAX_SHOPPER_STATEMENT_LENGTH ? previousValue.trim() : value
            }
            label={t(I18nKeys.SetupPaymentIntegrationDialogShopperStatement)}
            name={SetupPaymentIntegrationFormFields.ShopperStatement}
            variant="filled"
          />
        </Grid>
        <Grid container size={12} sx={{ pl: '16px' }}>
          <FormHelperText>{t(I18nKeys.SetupPaymentIntegrationDialogShopperStatementHelperText)}</FormHelperText>
          <Box flexGrow={1} />
          <FormHelperText>
            {t(I18nKeys.SetupPaymentIntegrationDialogShopperStatementLengthCount, {
              max: `${MAX_SHOPPER_STATEMENT_LENGTH}`,
              count: (shopperStatement.trim() || '').length,
            })}
          </FormHelperText>
        </Grid>
      </Grid>
    </Form>
  );
};

export const SetupPaymentIntegrationForm = reduxForm<FormData>({
  form: Forms.SetupPaymentIntegration,
})(SetupPaymentIntegrationFormComponent);
