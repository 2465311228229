/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import {
  TextFieldProps,
  FilledInputProps,
  InputLabelProps,
  Popper,
  Paper,
  ClickAwayListener,
  Grid2 as Grid,
  Box,
  alpha,
  FormControl,
  styled,
} from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { InputField } from './InputField';
import { ContentSkeleton } from '../library/ContentSkeleton';

type Props = WrappedFieldProps &
  TextFieldProps & {
    hideHelperText?: boolean;
    errorWithoutTouch?: boolean;
    initializing?: boolean;
    slotProps?: {
      input?: Partial<FilledInputProps>;
      inputLabel?: Partial<InputLabelProps>;
    };
    onChangeComplete?: (color: string) => void;
  };

const ColorSwatch = styled(Box)({
  width: '56px',
  height: '56px',
  border: `1px solid ${alpha('#000000', 0.2)}`,
  borderRadius: '4px',
});

export const ColorField: React.FC<Props> = (props: Props) => {
  const { input, disabled, label, initializing, onChangeComplete } = props;

  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLDivElement>(null);

  const onColorChange: ColorChangeHandler = ({ hex }) => {
    input?.onChange?.(hex);
  };

  const onColorChangeComplete: ColorChangeHandler = ({ hex }) => {
    input?.onChange?.(hex);
    onChangeComplete?.(hex);
  };

  return (
    <>
      <Grid container columnSpacing="24px" size={12}>
        <Grid size="grow" alignContent="start">
          <ContentSkeleton sx={{ my: '16px' }} initializing={initializing}>
            {label}
          </ContentSkeleton>
        </Grid>
        <Grid>
          <ContentSkeleton initializing={initializing} variant="rounded-rectangular">
            <ColorSwatch sx={{ backgroundColor: input.value }} />
          </ContentSkeleton>
        </Grid>
        <Grid size={4}>
          <ContentSkeleton initializing={initializing} variant="input">
            <FormControl fullWidth>
              <InputField
                className="color-picker"
                ref={anchorEl}
                {...props}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  setOpen(!disabled);
                  input?.onFocus(e);
                }}
                label=""
                slotProps={{ input: { sx: { py: '16.5px', px: '16px' } } }}
                initializing={initializing}
              />
            </FormControl>
          </ContentSkeleton>
        </Grid>
      </Grid>
      <Popper
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
        open={Boolean(anchorEl.current) && open}
        anchorEl={anchorEl.current}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={(event) => {
            // Don't close the picker if the target is the input field
            const isInputField = !!anchorEl.current?.contains(event?.target as Node);
            setOpen(isInputField);
          }}
        >
          <Paper elevation={0}>
            <ChromePicker
              color={input.value}
              onChange={onColorChange}
              onChangeComplete={onColorChangeComplete}
              disableAlpha
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
