import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm, Validator } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid2 as Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Forms } from '../../constants/Forms';
import { InputField } from './InputField';
import { IntegrationsFormFields } from '../../constants/FormFields';
import { I18nKeys } from '../../constants/I18nKeys';

export interface FormData {
  [IntegrationsFormFields.GoogleAnalyticsId]: string;
}

type FormProps = InjectedFormProps<FormData>;

const useStyles = makeStyles(() => ({
  field: {
    width: '100%',
  },
  fieldBottomText: {
    marginTop: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#00000061',
  },
  fieldBottomStartText: {
    paddingStart: '16px',
  },
  fieldBottomEndText: {
    textAlign: 'end',
  },
}));

const nullOrEmpty: Validator = (value: string) => {
  if (!value || value.length === 0) {
    return 'error';
  }
  return undefined;
};

const IntegrationsGoogleAnalyticsFormComponent: React.FC<FormProps> = ({ handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.IntegrationsGoogleAnalyticsForm}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Field
            autoFocus
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormGoogleAnalyticsId)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.GoogleAnalyticsId}
            variant="filled"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export const IntegrationsGoogleAnalyticsForm = reduxForm<FormData>({
  form: Forms.IntegrationsGoogleAnalyticsForm,
})(IntegrationsGoogleAnalyticsFormComponent);
