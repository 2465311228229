import React from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { InputField } from './InputField';
import { SceneEnvironmentDialogFormFields } from '../../constants/FormFields';
import { Forms } from '../../constants/Forms';
import { I18nKeys } from '../../constants/I18nKeys';
import { ImageDropField } from './ImageDropField';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: theme.spacing(3), // 2022-02-25 verified with Greg
    width: '100%',
  },
}));

export interface FormData {
  [SceneEnvironmentDialogFormFields.Key]: string;
  [SceneEnvironmentDialogFormFields.Label]: string;
  [SceneEnvironmentDialogFormFields.FileUrl]: string;
  [SceneEnvironmentDialogFormFields.PreviewUrl]: string;
  [SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier]: number;
  [SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength]: number;
}

type FormProps = InjectedFormProps<FormData>;

const SceneEnvironmentFormComponent: React.FC<FormProps> = ({ error, handleSubmit, initialValues }: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.SceneEnvironment}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.SceneEnvironmentFieldKey)}
        name={SceneEnvironmentDialogFormFields.Key}
        disabled={!!initialValues[SceneEnvironmentDialogFormFields.Key]}
      />
      <Field
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.SceneEnvironmentFieldLabel)}
        name={SceneEnvironmentDialogFormFields.Label}
      />
      <Field
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.SceneEnvironmentFieldFileUrl)}
        name={SceneEnvironmentDialogFormFields.FileUrl}
      />
      <Field
        autoComplete="off"
        className={classes.field}
        component={ImageDropField}
        label={t(I18nKeys.SceneEnvironmentFieldPreviewUrl)}
        name={SceneEnvironmentDialogFormFields.PreviewUrl}
        maxSize={150000} // 150 KB
      />
      <Field
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.SceneEnvironmentFieldMaxCameraDistanceMultiplier)}
        name={SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier}
        type="number"
      />
      <Field
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.SceneEnvironmentFieldMaxDiagonalBuildingLength)}
        name={SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength}
        type="number"
      />
      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

export const SceneEnvironmentForm = reduxForm<FormData>({ form: Forms.SceneEnvironment })(
  SceneEnvironmentFormComponent,
);
