/* eslint-disable @typescript-eslint/no-empty-function */
import { AppBar, Box, Hidden, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { usePath } from 'hookrouter';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { TFunction } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { MenuStatus } from '../../constants/Viewer';
import { AppRoutes } from '../../constants/AppRoutes';
import { useSharedDeleteBranchMutation, useSharedPublishClientDataMutation } from '../../services/clientDataApi';
import { useClientDataRepo } from '../../hooks/useClientDataRepo';
import { PricingTab } from '../../constants/Pricing';
import { usePricingRepo } from '../../hooks/usePricingRepo';
import { getClientIdFromClientSupplier } from '../../utils/clientIdUtils';
import { unknownGroup } from '../../constants/Group';
import { LoadingButton } from '../LoadingButton';
import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { I18nKeys } from '../../constants/I18nKeys';
import {
  onRevert as onRevertFunc,
  onPreview as onPreviewFunc,
  onPublish as onPublishFunc,
} from '../../utils/publishBarUtils';
import { UserPreference } from '../../constants/User';
import { PreferencesFormFields } from '../../constants/FormFields';

const getPublishBarMessages = (path: string, t: TFunction, pricingTab?: string) => {
  let previewMessageKey = I18nKeys.PricingTextPreviewInstructions;
  let publishMessageKey = I18nKeys.PricingTextPublishInstructions;

  if (path.startsWith(AppRoutes.Sites)) {
    previewMessageKey = I18nKeys.SiteTextPreviewInstructions;
    publishMessageKey = I18nKeys.SiteTextPublishInstructions;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Base) {
    previewMessageKey = I18nKeys.PricingBasePreviewMessage;
    publishMessageKey = I18nKeys.PricingBasePublishMessage;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Component) {
    previewMessageKey = I18nKeys.PricingComponentPreviewMessage;
    publishMessageKey = I18nKeys.PricingComponentPublishMessage;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Surcharge) {
    previewMessageKey = I18nKeys.PricingSurchargePreviewMessage;
    publishMessageKey = I18nKeys.PricingSurchargePublishMessage;
  }
  const [previewMessage, publishMessage] = [previewMessageKey, publishMessageKey].map((key) => (key ? t(key) : ''));

  return {
    previewMessage,
    publishMessage,
  };
};

const useStyles = makeStyles<Theme, { menuStatus: MenuStatus; showPublishBar: boolean; mobileView: boolean }>(
  (theme: Theme) => ({
    appBar: {
      top: 'auto',
      bottom: 0,
      height: ({ showPublishBar }) => `${showPublishBar ? 64 : 0}px`,
      width: ({ menuStatus, mobileView }) =>
        `calc(100% - ${mobileView || menuStatus === MenuStatus.Closed ? 0 : 260}px)`,
      transition: theme.transitions.create(['height', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      justifyContent: 'center',
      overflow: 'hidden',
    },
    appBarContent: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: { justifyContent: 'flex-end' },
      [theme.breakpoints.up('md')]: { justifyContent: 'space-between' },
    },
    messages: {
      flex: 1,
      flexDirection: 'column',
      width: 'auto',
    },
    section: {
      display: 'flex',
    },
    button: {
      '&:not(:first-child)': {
        margin: '0px 0px 0px 12px',
      },
    },
  }),
);

export const PublishBar: React.FC = () => {
  const { t } = useTranslation();
  const path = usePath();
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    selectedTabId,
    selectedClientId,
    menuStatus,
    selectedPricingTabId: pricingTab,
  } = useAppSelector((state) => state.viewer);
  const { showPublishBar } = useAppSelector((state) => state.menu);
  const {
    clientId: clientDataClientId,
    clientDataType,
    clientDataBranch,
    isCreatingBranch,
  } = useAppSelector((state) => state.clientData);
  const { groupId } = useAppSelector((state) => state.currentUser.group || unknownGroup);
  const layout = useAppSelector(
    (state) => state.currentUser?.preferences?.[UserPreference.ProfilePreferences]?.[PreferencesFormFields.Layout],
  );

  const [publishClientData, { isLoading: isPublishing = false }] = useSharedPublishClientDataMutation();
  const [deleteBranch] = useSharedDeleteBranchMutation();
  const { vendorData = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });
  const { selectedPricingSurcharge, pricingSurchargeVaryConditions } = usePricingRepo({
    useSelectedPricingSurcharge: true,
    usePricingSurchargeVaryConditions: true,
  });
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });

  const classes = useStyles({ menuStatus, showPublishBar, mobileView });

  const { previewMessage, publishMessage } = getPublishBarMessages(path, t, pricingTab);

  const branch: ClientDataBranch = useMemo(() => {
    let b: ClientDataBranch = ClientDataBranch.SiteDetail;
    if (path.startsWith(AppRoutes.Pricing)) {
      switch (pricingTab) {
        case PricingTab.Base:
          b = ClientDataBranch.Pricing;
          break;
        case PricingTab.Component:
          b = ClientDataBranch.ClientUpdate;
          break;
        case PricingTab.Surcharge:
          b = ClientDataBranch.PricingSurcharge;
          break;
        case PricingTab.SizeBased:
          b = ClientDataBranch.PricingSizeBased;
          break;
        default:
          break;
      }
    }
    return b;
  }, [path, pricingTab]);

  const clientId = useMemo(
    () =>
      path.startsWith(AppRoutes.Sites)
        ? clientDataClientId
        : getClientIdFromClientSupplier(selectedTabId || selectedClientId),
    [path, clientDataClientId, selectedTabId, selectedClientId],
  );

  const onRevert = useCallback(() => {
    onRevertFunc(
      {
        clientId,
        groupId,
        clientDataType,
        pricingTab,
        path,
        activeBranches,
        branch,
        layout,
      },
      deleteBranch,
      dispatch,
      t,
    );
  }, [clientId, groupId, clientDataType, pricingTab, path, activeBranches, branch, layout, deleteBranch, dispatch, t]);

  const onPreview = useCallback(() => {
    onPreviewFunc(
      {
        clientId,
        vendorData,
        clientDataBranch,
        pricingTab,
        selectedPricingSurcharge,
        pricingSurchargeVaryConditions,
        path,
      },
      dispatch,
      t,
    );
  }, [
    clientId,
    vendorData,
    isLoadingVendorData,
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge,
    pricingSurchargeVaryConditions,
    path,
    dispatch,
    t,
  ]);

  const onPublish = useCallback(() => {
    onPublishFunc(
      {
        clientId,
        groupId,
        clientDataType,
        clientDataBranch,
        pricingTab,
        selectedPricingSurcharge,
        pricingSurchargeVaryConditions,
        path,
      },
      publishClientData,
      dispatch,
      t,
    );
  }, [
    clientId,
    groupId,
    clientDataType,
    clientDataBranch,
    pricingTab,
    selectedPricingSurcharge,
    pricingSurchargeVaryConditions,
    path,
    publishClientData,
    dispatch,
    t,
  ]);

  const disabled = useMemo(
    () =>
      clientDataBranch !== branch ||
      isCreatingBranch ||
      isPublishing ||
      !activeBranches.find((b) => b.branchType === branch),
    [branch, clientDataBranch, isCreatingBranch, isPublishing, activeBranches],
  );

  return (
    <AppBar id="publish-bar-id" position="fixed" color="inherit" className={classes.appBar}>
      <Toolbar style={{ width: '100%' }}>
        <div className={classes.appBarContent}>
          <Hidden mdDown>
            <Box className={classes.messages}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: previewMessage,
                }}
              />
              <Typography
                dangerouslySetInnerHTML={{
                  __html: publishMessage,
                }}
              />
            </Box>
          </Hidden>
          <div className={classes.section}>
            <LoadingButton
              id={`publish-bar-${t(I18nKeys.RevertButton)}`}
              key={t(I18nKeys.RevertButton)}
              className={classes.button}
              disabled={disabled}
              loading={isPublishing}
              onClick={onRevert}
            >
              {t(I18nKeys.RevertButton)}
            </LoadingButton>
            <LoadingButton
              id={`publish-bar-${t(I18nKeys.PreviewButton)}`}
              key={t(I18nKeys.PreviewButton)}
              className={classes.button}
              variant="contained"
              disabled={disabled}
              loading={isPublishing}
              color="primary"
              onClick={onPreview}
            >
              {t(I18nKeys.PreviewButton)}
            </LoadingButton>
            <LoadingButton
              id={`publish-bar-${t(I18nKeys.PublishButton)}`}
              key={t(I18nKeys.PublishButton)}
              className={classes.button}
              variant="contained"
              disabled={disabled}
              loading={isPublishing}
              color="primary"
              onClick={onPublish}
            >
              {t(I18nKeys.PublishButton)}
            </LoadingButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
