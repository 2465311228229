import { DialogActions, DialogContent, Theme, Typography } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialogs } from '../../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../../constants/I18nKeys';
import { useAppDispatch } from '../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: { padding: '0px 8px 8px 8px' },
  dialogContent: {
    display: 'flex',
  },
  statusIcon: {
    width: '30px',
    height: '30px',
    marginRight: '15px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
}));

export const ClientDataCantGenerateIconsDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Dialog dialogKey={Dialogs.ClientDataCantGenerateIconsDialog} maxWidth="xs">
      <DialogContent className={classes.dialogContent}>
        <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />
        <Typography>
          <Trans i18nKey={I18nKeys.ClientDataCantGenerateIconsError as string} />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="error"
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
