/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, RadioGroup, RadioGroupProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & RadioGroupProps;

export const RadioGroupField: React.FC<Props> = ({ input, children, ...customProps }: Props): JSX.Element => (
  <Box>
    <RadioGroup
      {...customProps}
      {...input}
      value={input.value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => input.onChange(event.target.value)}
    >
      {children}
    </RadioGroup>
  </Box>
);
